<template>
  <form v-on:submit.prevent="save()" data-vv-scope="statusMessage_form">
    <div
      class="input m-0"
      :class="
        errors.first('statusMessage_form.pkFkStatusCodeId') !== undefined
          ? 'input-error'
          : ''
      "
    >
      <label
        for="pkFkStatusCodeId"
        v-lang="'statusMessages.status_code'"
      ></label>
      <select
        v-model="model.pkFkStatusCodeId"
        v-validate="'required|excluded:0'"
        name="pkFkStatusCodeId"
        id="dropdownStatusCode"
      >
        <option value disabled hidden>{{
          $lang("statusMessages.placeholder_for_status_code")
        }}</option>
        <option v-for="(obj, key) in statusCodes" :key="key" :value="obj.id">{{
          obj.name
        }}</option>
      </select>
      <div>
        <span
          class="error"
          v-html="errors.first('statusMessage_form.pkFkStatusCodeId')"
        ></span>
      </div>
    </div>
    <div
      class="input m-0"
      :class="
        errors.first('statusMessage_form.pkFkPriorityId') !== undefined
          ? 'input-error'
          : ''
      "
    >
      <label for="pkFkPriorityId" v-lang="'statusMessages.priority'"></label>
      <select
        v-model="model.pkFkPriorityId"
        v-validate="'required|excluded:0'"
        name="pkFkPriorityId"
        id="dropdownPriority"
      >
        <option value disabled hidden>{{
          $lang("statusMessages.placeholder_for_priority")
        }}</option>
        <option v-for="(obj, key) in priorities" :key="key" :value="obj.id">{{
          obj.name
        }}</option>
      </select>
      <div>
        <span
          class="error"
          v-html="errors.first('statusMessage_form.pkFkPriorityId')"
        ></span>
      </div>
    </div>
    <div class="input m-0">
      <label for="userMessage" v-lang="'statusMessages.user_message'"></label>
      <input
        class="m-0"
        v-model="model.userMessage"
        type="text"
        name="userMessage"
        autocomplete="off"
      />
    </div>

    <div class="input m-0">
      <label
        for="userMessageTitleId"
        v-lang="'statusMessages.user_message_titleId'"
      ></label>
      <input
        class="m-0"
        v-model="model.userMessageTitleId"
        type="text"
        name="userMessageTitleId"
        autocomplete="off"
      />
    </div>
    <div class="input m-0">
      <label
        for="userLogMessage"
        v-lang="'statusMessages.user_log_message'"
      ></label>
      <input
        class="m-0"
        v-model="model.userLogMessage"
        type="text"
        name="userLogMessage"
        autocomplete="off"
      />
    </div>
    <div class="input m-0">
      <label
        for="userLogMessageTitleId"
        v-lang="'statusMessages.user_log_message_titleId'"
      ></label>
      <input
        class="m-0"
        v-model="model.userLogMessageTitleId"
        type="text"
        name="userLogMessageTitleId"
        autocomplete="off"
      />
    </div>
    <div class="input m-0">
      <label
        for="actionLogMessage"
        v-lang="'statusMessages.action_log_message'"
      ></label>
      <input
        class="m-0"
        v-model="model.actionLogMessage"
        type="text"
        name="actionLogMessage"
        autocomplete="off"
      />
    </div>
    <div class="input m-0">
      <label
        for="actionLogMessageTitleId"
        v-lang="'statusMessages.action_log_message_titleId'"
      ></label>
      <input
        class="m-0"
        v-model="model.actionLogMessageTitleId"
        type="text"
        name="actionLogMessageTitleId"
        autocomplete="off"
      />
    </div>
    <div class="input m-0">
      <label
        for="dataLogMessage"
        v-lang="'statusMessages.data_log_message'"
      ></label>
      <input
        class="m-0"
        v-model="model.dataLogMessage"
        type="text"
        name="dataLogMessage"
        autocomplete="off"
      />
    </div>
    <div class="input m-0">
      <label
        for="dataLogMessagTitleId"
        v-lang="'statusMessages.data_log_message_titleId'"
      ></label>
      <input
        class="m-0"
        v-model="model.dataLogMessageTitleId"
        type="text"
        name="dataLogMessagTitleId"
        autocomplete="off"
      />
    </div>
    <div class="input m-0">
      <label
        for="errorLogMessage"
        v-lang="'statusMessages.error_log_message'"
      ></label>
      <input
        class="m-0"
        v-model="model.errorLogMessage"
        type="text"
        name="dataLogMessage"
        autocomplete="off"
      />
    </div>
    <div class="input m-0">
      <label
        for="errorLogMessageTitleId"
        v-lang="'statusMessages.error_log_message_titleId'"
      ></label>
      <input
        class="m-0"
        v-model="model.errorLogMessageTitleId"
        type="text"
        name="errorLogMessageTitleId"
        autocomplete="off"
      />
    </div>
    <div class="submit m-0" id="sub">
      <button class="primary" v-lang="'general.button_submit'"></button>
    </div>
  </form>
</template>

<script>
import Vue from "vue";
import { lang } from "@/global-functions";
import VeeValidate from "vee-validate";
import store from "../../store";
Vue.use(VeeValidate);
export default {
  props: {
    messageData: {
      default: null,
    },
    errorModel: {
      key: "",
      message: "",
    },
    usedStatusCodes: Array,
    update: Boolean,
  },
  data() {
    return {
      statusCodes: [],
      priorities: [],
      selected: "",
      model: {
        pkFkActionId: this.$route.query.PkFkActionId,
        pkFkController: this.$route.query.PkFkController,
        pkFkStatusCodeId: "",
        pkFkPriorityId: "",
        userMessage: "",
        userMessageTitleId: "",
        userLogMessage: "",
        userLogMessageTitleId: "",
        actionLogMessage: "",
        actionLogMessageTitleId: "",
        dataLogMessage: "",
        dataLogMessageTitleId: "",
        errorLogMessage: "",
        errorLogMessageTitleId: "",
      },
      validation_messages: {
        custom: {
          pkFkStatusCodeId: {
            required: lang("validation.required"),
            excluded: lang("validation.required"),
          },
          pkFkPriorityId: {
            required: lang("validation.required"),
            excluded: lang("validation.required"),
          },
        },
      },
    };
  },

  watch: {
    messageData() {
      this.model = this.messageData;
      this.selected =
        this.model.pkFkStatusCodeId + "|" + this.model.pkFkPriorityId;
    },
    errorModel() {
      this.$importModelStateErrors(
        this.errors,
        this.errorModel.modelState,
        "statusMessage_form"
      );
    },
  },
  computed: {
    getNotUsedStatusCodes() {
      var $this = this;
      var usedCodes = [];
      var validCodes = [];

      for (var i = 0; i < $this.usedStatusCodes.length; i++) {
        var res = $this.usedStatusCodes[i].split("|");
        usedCodes.push(res[0]);
        usedCodes.push(res[1]);
      }

      var i, j, flag;
      for (i = 0; i < $this.statusCodes.length; i++) {
        flag = 0;
        for (j = 0; j < usedCodes.length; j += 2)
          if ($this.statusCodes[i].pkStatusCodeId == usedCodes[j]) flag = 1;

        if (flag == 0)
          validCodes.push({
            pkStatusCodeId: $this.statusCodes[i].pkStatusCodeId,
            pkFkPriorityId: $this.statusCodes[i].pkFkPriorityId,
            statusCode: $this.statusCodes[i].statusCode,
          });
      }
      return validCodes;
    },
  },
  created() {
    this.getAllStatusCodesAndPrioritiesList();
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  methods: {
    onChange(event) {
      var res = event.target.value.split("|");
      this.model.pkFkStatusCodeId = res[0];
      this.model.pkFkPriorityId = res[1];
    },
    getAllStatusCodesAndPrioritiesList() {
      var $this = this;
      store
        .dispatch("GET_ALL_STATUS_CODES_PRIORITIES_LIST")
        .then((response) => {
          $this.statusCodes = response.data.statusCodesList;
          $this.priorities = response.data.prioritiesList;
        })
        .catch((error) => {});
    },
    save() {
      this.$validator.validateAll("statusMessage_form").then((valid) => {
        if (valid) {
          this.$emit("save", this.model);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/colors";
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  & > div {
    // display: flex;
    // flex: 1;
    // align-items: center;
    justify-content: center;
  }

  .submit {
    color: red;
    grid-column: 1/3;
  }
}
.white {
  color: white;
}
label {
  input {
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
  }
}

div.input-checkbox {
  color: black;
  display: -webkit-inline-flex;
  width: 150px;
  margin: 0px 5px;
  margin-top: 5px;
}

.fieldset {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  display: -webkit-inline-flex;
  font-size: 0.9em;
}
.logs-for-action {
  display: grid;
  grid-template-rows: auto auto;
  grid-column: 1 / 2;
  div {
    box-sizing: border-box;
    max-width: 25%;
    label {
      white-space: nowrap;
    }
  }
}
fieldset {
  border: 1px solid $primaryColor;
}
legend {
  font-size: 0.9em;
}
</style>
