import Index from "./Index.vue";
import Insert from "./Create.vue";
import Edit from "./Edit.vue";
import AdminLayout from "../../UserAndRolesManagement/AdminPanel/AdminLayout.vue";
import auth from "@/middleware";

const router = [
  {
    path: "/working-place",
    name: "AdminLayout",
    component: AdminLayout,
    meta: {
      middleware: auth
    },
    children: [
      {
        path: "index",
        name: "WorkingPlaceIndex",
        component: Index
      },
      {
        path: "insert",
        name: "WorkingPlaceInsert",
        component: Insert
      },
      {
        path: "edit",
        name: "WorkingPlaceEdit",
        component: Edit
      }
    ]
  }
];

export default router;
