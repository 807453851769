<template>
  <div class="container settings">
    <div class="z-index">
      <ul class="settings-menu">
        <!-- <li v-if="$role(10143)" v-on:click="setComponent('SettingsDetails')">
          <span
            v-lang="'general.settings_general'"
            :class="component == 'Settings' ? 'active' : ''"
          ></span>
        </li>-->
        <li v-if="$role(10143)" v-on:click="setComponent('UserIndex')">
          <span
            v-lang="'user.label_users'"
            :class="component == 'UserIndex' ? 'active' : ''"
          ></span>
        </li>
        <li
          v-if="$role(10116) && !$role(50004)"
          v-on:click="setComponent('RoleIndex')"
        >
          <span
            v-lang="'menu.menu_item_roles'"
            :class="component == 'RoleIndex' ? 'active' : ''"
          ></span>
        </li>
        <!-- <li class="has-submenu" v-if="$role(10084) || $role(10086) || $role(10088) || $role(10090)">
          <span v-on:click="showSubmenu($event)" v-lang="'logs.logs_index'"></span>
          <ul class="submenu-settings">
            <li v-if="$role(10084)" v-on:click="setComponent('ActionLogsIndex')">
              <span
                v-lang="'menu.menu_item_actionlogs'"
                v-on:click="setComponent('ActionLogsIndex')"
                :class="component == 'ActionLogsIndex' ? 'active' : ''"
              ></span>
            </li>
            <li v-if="$role(10086)" v-on:click="setComponent('DataLogsIndex')">
              <span
                v-lang="'menu.menu_item_datalogs'"
                :class="component == 'DataLogsIndex' ? 'active' : ''"
              ></span>
            </li>
            <li v-if="$role(10088)" v-on:click="setComponent('UserLogsIndex')">
              <span
                v-lang="'menu.menu_item_userlogs'"
                :class="component == 'UserLogsIndex' ? 'active' : ''"
              ></span>
            </li>
            <li v-if="$role(10090)" v-on:click="setComponent('ErrorLogsIndex')">
              <span
                v-lang="'menu.menu_item_errorlogs'"
                :class="component == 'ErrorLogsIndex' ? 'active' : ''"
              ></span>
            </li>
          </ul>
        </li>-->
        <li
          class="has-submenu"
          v-if="
            !$role(50004) &&
            showActions &&
            ($role(10097) || $role(10102) || $role(10110))
          "
        >
          <span
            v-on:click="showSubmenu($event)"
            v-lang="'menu.menu_item_actions'"
          ></span>
          <ul class="submenu-settings">
            <li v-if="$role(10097)" v-on:click="setComponent('ActionIndex')">
              <span
                v-lang="'menu.menu_subitem_actions'"
                :class="component == 'ActionIndex' ? 'active' : ''"
              ></span>
            </li>
            <li v-if="$role(10102)" v-on:click="setComponent('Functions')">
              <span
                v-lang="'menu.menu_subitem_functions'"
                :class="component == 'Functions' ? 'active' : ''"
              ></span>
            </li>
            <li v-if="$role(10110)" v-on:click="setComponent('ActionGroups')">
              <span
                v-lang="'menu.menu_subitem_action_groups'"
                :class="component == 'ActionGroups' ? 'active' : ''"
              ></span>
            </li>
          </ul>
        </li>
        <li
          v-if="$role(10123) && !$role(50004)"
          v-on:click="setComponent('TypesOfCodesIndex')"
        >
          <span
            v-lang="'codes.label_codes'"
            :class="component == 'TypesOfCodesIndex' ? 'active' : ''"
          ></span>
        </li>
        <li
          v-if="$role(40280) || $role(40281)"
          v-on:click="setComponent('EmmausOrganizationIndex')"
        >
          <span
            v-lang="'menu.menu_emmaus_organisations'"
            :class="component == 'EmmausOrganizationIndex' ? 'active' : ''"
          ></span>
        </li>
        <li
          v-if="$role(20264) && !$role(50004)"
          v-on:click="setComponent('WorkingPlaceIndex')"
        >
          <span
            v-lang="'workingPlace.working_place'"
            :class="component == 'WorkingPlaceIndex' ? 'active' : ''"
          ></span>
        </li>
        <!-- <li v-if="$role(10139)" v-on:click="setComponent('TranslationIndex')">
          <span
            v-lang="'codes.translation_codes'"
            :class="component == 'TranslationIndex' ? 'active' : ''"
          ></span>
        </li>-->
        <!-- <li v-if="$role(10139)" v-on:click="setComponent('EntityRelations')">
          <span
            v-lang="'entity_relations.entity_relations'"
            :class="component == 'EntityRelations' ? 'active' : ''"
          ></span>
        </li>-->
        <!-- <li v-if="$role(10139)" v-on:click="setComponent('BoroadcastSend')">
          <span
            v-lang="'notifications.broadcast_menu_item'"
            :class="component == 'BoroadcastSend' ? 'active' : ''"
          ></span>
        </li>-->
      </ul>
    </div>
    <div>
      <keep-alive>
        <component v-bind:is="component" :key="component"></component>
      </keep-alive>
    </div>
  </div>
</template>
<script>
import RoleIndex from "@/components/UserAndRolesManagement/Roles/Index.vue";
import ActionLogsIndex from "@/components/UserAndRolesManagement/Logs/ActionLogs/Index.vue";
import DataLogsIndex from "@/components/UserAndRolesManagement/Logs/DataLogs/Index.vue";
import UserLogsIndex from "@/components/UserAndRolesManagement/Logs/UserLogs/Index.vue";
import ErrorLogsIndex from "@/components/UserAndRolesManagement/Logs/ErrorLogs/Index.vue";
import TypesOfCodesIndex from "@/components/UserAndRolesManagement/TypeOfCodes/Index.vue";
import TranslationIndex from "@/components/UserAndRolesManagement/Translation/Index.vue";
import ActionIndex from "@/components/UserAndRolesManagement/Action/ListOfActions/Index.vue";
import Functions from "@/components/UserAndRolesManagement/Action/Functions/Index.vue";
import ActionGroups from "@/components/UserAndRolesManagement/Action/ActionGroups/Index.vue";
import UserIndex from "@/components/UserAndRolesManagement/User/Register/Index.vue";
import EntityRelations from "@/components/PageDesk/EntityRelations/Index.vue";
import BoroadcastSend from "@/components/Notifications/BroadcastNotifications.vue";
import EmmausOrganizationIndex from "@/components/EMMAUS/Organisation/Index.vue";
import WorkingPlaceIndex from "@/components/IOM/WorkingPlace/Index.vue";

import { mapState } from "vuex";

export default {
  components: {
    RoleIndex,
    ActionLogsIndex,
    DataLogsIndex,
    ErrorLogsIndex,
    TypesOfCodesIndex,
    TranslationIndex,
    ActionIndex,
    Functions,
    UserIndex,
    ActionGroups,
    UserLogsIndex,
    EntityRelations,
    BoroadcastSend,
    EmmausOrganizationIndex,
    WorkingPlaceIndex
  },
  data() {
    return {
      component: UserIndex,
      showActions: false
    };
  },
  created() {
    if (this.$route.query.component) {
      this.component = this.$route.query.component;
    }
    this.showActions = this.api.includes("localhost");
  },
  methods: {
    showSubmenu(event) {
      var element = event.target.closest("li").getElementsByTagName("ul")[0];
      if (element.classList.contains("active")) {
        element.classList.remove("active");
        return;
      }
      element.classList.add("active");
    },
    setComponent(componentName) {
      this.component = componentName;
      this.$router.push({
        query: {
          component: componentName
        }
      });
      console.log("comp this", this.$route);
    }
  },
  watch: {
    "$route.query.component"() {
      this.component = this.$route.query.component;
    }
  },
  computed: {
    ...mapState(["api"])
  }
};
</script>
<style lang="scss" scoped>
@import "../../../assets/css/colors";
.z-index {
  z-index: 0;
  color: $white;
  background: $lightSecondaryColor;
  margin-left: -10px;
}
.container.settings {
  display: grid;
  grid-template-columns: 0.15fr 1fr;
  .container {
    position: relative;
    top: 0;
  }
  .has-submenu::before {
    top: 10px;
  }
  .settings-menu {
    list-style: none;
    padding: 0px;
    background: transparent !important;
    .submenu-settings {
      display: none;
      background: transparent !important;
      padding: 0px;

      li {
        span {
          display: block;
        }
        background: transparent !important;
        cursor: pointer;
      }
      &.active {
        display: block;
      }
    }
    ul {
      list-style: none;
      background: transparent !important;
      display: none;
      li {
        span {
          display: block;
        }
        background: transparent !important;
        cursor: pointer;
        padding: 0 10px;
      }
    }
    li {
      span {
        display: block;
        padding: 10px;
        border-left: 5px solid $lightSecondaryColor;
        &:hover,
        &.active {
          border-left: 5px solid $darkPrimaryColor;
          box-shadow: 0px 4px 5px -5px black;
        }
      }
      background: transparent !important;
      cursor: pointer;
    }
  }
}
</style>
