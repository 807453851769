<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-users"></i>
        <span v-lang="'logs.logs_data_details'"></span>
      </h1>
      <div class="actions">
        <button class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></button>
      </div>
    </div>
    <div class="card table-container scroll">
      <h2>
        <i class="icon-info"></i>
        <span>Info</span>
      </h2>
      <table class="info">
        <tr>
          <td>ID</td>
          <td>{{ dataLog.idUser }}</td>
        </tr>
        <tr>
          <td v-lang="'user.username'"></td>
          <td>{{ dataLog.userName }}</td>
        </tr>
        <tr>
          <td v-lang="'general.client_info'"></td>
          <td>{{ dataLog.clientInfo }}</td>
        </tr>
        <tr>
          <td v-lang="'general.ip_address'"></td>
          <td>{{ dataLog.host }}</td>
        </tr>
        <tr>
          <td v-lang="'general.requestAPI'"></td>
          <td>{{ dataLog.requestApi }}</td>
        </tr>
        <tr>
          <td>API</td>
          <td>{{ dataLog.api }}</td>
        </tr>
        <tr>
          <td v-lang="'general.description'"></td>
          <td>{{ dataLog.description }}</td>
        </tr>
        <tr>
          <td v-lang="'action.label_name_of_http_method'"></td>
          <td>{{ dataLog.httpMethod }}</td>
        </tr>
        <tr>
          <td v-lang="'action.label_name_of_action_type'"></td>
          <td>{{ dataLog.actionType }}</td>
        </tr>
        <tr>
          <td v-lang="'general.status'"></td>
          <td>{{ dataLog.statusCode }}</td>
        </tr>
      </table>
    </div>
    <div class="card table-container overflow">
      <h2>
        <i class="icon-users"></i>
        <span v-lang="'general.data'"></span>
      </h2>
      <table class="data">
        <tr>
          <!-- <td v-for="(str, key) in modelKeys" :key="key" v-lang="dataLog.objectType + '.' + str"></td> -->
          <td v-for="(str, key) in modelKeys" :key="key">{{ str }}</td>
        </tr>
        <tr>
          <td v-for="(str, key) in modelKeys" :key="key">{{ dataLog.data[str] }}</td>
        </tr>
      </table>
    </div>
    <!-- <div class="card">
      <component :is="component" :dataLog="dataLog.data" />
    </div>-->
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import { lang } from "@/global-functions";
export default {
  data() {
    return {
      dataLog: {},
      modelKeys: []
      // component: "PersonDetails"
    };
  },
  // components: {
  //   PersonDetails
  // },
  created() {
    this.getDataLogById();
  },
  methods: {
    getDataLogById() {
      var $this = this;
      store
        .dispatch("GET_DATA_LOG_BY_ID", $this.$route.params.id)
        .then(response => {
          $this.dataLog = response.data.model;
          $this.dataLog.data = JSON.parse(response.data.model.data);
          $this.modelKeys = Object.keys($this.dataLog.data);
        })
        .catch(error => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll {
  overflow: auto;
}
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}

.info td:nth-child(2) {
  font-weight: bold;
}

.data tr:first-child td {
  font-weight: bold;
}
.overflow {
  overflow: auto;
}
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
}
</style>
