<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-paperplane"></i>
        <span v-lang="'statusMessages.status_messages'"></span>
      </h1>
      <div class="actions">
        <router-link
          class="primary small"
          :to="{
            name: 'StatusMessagesCreate',
            query: {
              PkFkActionId: this.$route.query.PkFkActionId,
              PkFkController: this.$route.query.PkFkController
            },
            params: { usedStatusCodes }
          }"
          v-lang="'general.label_add'"
        ></router-link>
      </div>
    </div>
    <div class="card table-container">
      <table v else class="table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th v-lang="'statusMessages.status_code'">&nbsp;</th>
            <th v-lang="'statusMessages.user_message'">&nbsp;</th>
            <th v-lang="'statusMessages.priority'">&nbsp;</th>

            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, key) in statusMessage" :key="key">
            <td>{{ key + 1 }}</td>
            <td>{{ obj.statusCode.statusCode }}</td>
            <td>{{ obj.userMessage }}</td>
            <td>{{ obj.priority == null ? "" : obj.priority.name }}</td>

            <td>
              <router-link
                class="default small"
                :to="{
                  name: 'StatusMessagesDetails',
                  query: {
                    PkFkActionId: obj.pkFkActionId,
                    PkFkController: obj.pkFkController,
                    PkFkStatusCodeId: obj.pkFkStatusCodeId,
                    PkFkPriorityId: obj.pkFkPriorityId
                  }
                }"
                v-lang="'general.label_details'"
              ></router-link>
              <router-link
                class="primary small"
                :to="{
                  name: 'StatusMessagesEdit',
                  query: {
                    PkFkActionId: obj.pkFkActionId,
                    PkFkController: obj.pkFkController,
                    PkFkStatusCodeId: obj.pkFkStatusCodeId,
                    PkFkPriorityId: obj.pkFkPriorityId
                  }
                }"
                v-lang="'general.label_edit'"
              ></router-link>
              <a
                class="error small"
                v-on:click="deleteStatusMessage(obj)"
                v-lang="'general.label_delete'"
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import { lang } from "@/global-functions";
import Paginate from "vuejs-paginate";

export default {
  components: {
    paginate: Paginate
  },
  data() {
    return {
      statusMessage: [],

      yes: lang("general.label_yes"),
      no: lang("general.label_no")
    };
  },
  computed: {
    usedStatusCodes() {
      var usedStatusCodes = [];
      this.statusMessage.forEach(element => {
        usedStatusCodes.push(
          element.pkFkStatusCodeId + "|" + element.pkFkPriorityId
        );
      });

      return usedStatusCodes;
    }
  },
  created() {
    this.getStatusMessagesByActionID();
  },

  methods: {
    changePage: function(pageNum) {
      this.pageNum = pageNum;
    },
    getStatusMessagesByActionID() {
      var $this = this;
      store
        .dispatch("GET_STATUS_MESSAGES_BY_ACTION_ID", {
          PkFkActionId: $this.$route.query.PkFkActionId,
          PkFkController: $this.$route.query.PkFkController
        })
        .then(response => {
          $this.statusMessage = response.data.list;
        })
        .catch(error => {});
    },
    deleteStatusMessage(model) {
      this.$removeToast();
      Vue.toasted.show(
        lang("action.action_do_you_want_to_delete_this_action"),
        {
          theme: "toasted-primary",
          position: "top-center",
          duration: 5000,
          action: [
            {
              text: lang("general.label_yes"),
              onClick: (e, toastObject) => {
                var $this = this;
                store
                  .dispatch("DELETE_STATUS_MESSAGE", model)
                  .then(response => {
                    $this.getStatusMessagesByActionID();
                    this.$error("action.action_successfully_deleted");
                    this.notifyKey += 1;
                  })
                  .catch(error => {});
                toastObject.goAway(0);
              }
            },
            {
              text: lang("general.label_no"),
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              }
            }
          ]
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}

td:nth-child(1) {
  font-weight: bold;
}

td {
  width: 100px;
  text-align: left !important;
}
</style>
