<template>
  <div>
    <div class="container">
      <div class="header card">
        <h1>
          <i class="icon-izvjestaji"></i>
          <span>{{ $route.query.reportName }}</span>
        </h1>
        <div class="actions">
          <i
            v-tooltip="$lang('reports.export_to_pdf')"
            class="icon-file-pdf"
            v-on:click="Export2PDF('body')"
          ></i>

          <!-- <a
            class="primary error export-pdf"
           
          >{{ $lang("reports.export_to_pdf") }}</a>-->

          <i
            v-tooltip="$lang('victims.export_excel')"
            class="icon-microsoftexcel"
            v-on:click="Export2Excel()"
          ></i>

          <!-- <a
            class="primary error export-pdf"
            v-on:click="Export2Excel()"
          >{{ $lang("victims.export_excel") }}</a>-->
          <a
            class="primary"
            v-on:click="clickBack()"
            v-lang="'general.button_back'"
          ></a>
        </div>
      </div>
      <Loader2 v-if="!resultsLoaded" />
      <span v-else>
        <NoData v-if="!report || report.length == 0"></NoData>
        <div v-else>
          <component
            ref="HomeComponent"
            class="report"
            v-bind:is="templateName"
            :report="report"
          ></component>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import moment from "moment";
import globalStore from "@/store";

import { ResponsiveDirective } from "vue-responsive-components";
import store from "./store";
import Loader2 from "@/components/General/Loader2";
import NoData from "@/components/General/NoData";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import { Arimo } from "@/assets/fonts/Arimo-Regular-normal.js";
import VueDocPreview from "vue-doc-preview";
// import image2base64 from "image-to-base64";
import { header } from "@/assets/imgs/imgs.js";
import "jspdf-autotable";
import { mapState } from "vuex";
import VTooltip from "v-tooltip";

Vue.use(VTooltip);
Vue.use(Toasted);
Vue.component("Template1", () => import("./Templates/Template1"));

export default {
  directives: {
    responsive: ResponsiveDirective
  },
  components: {
    Loader2,
    NoData
  },
  data() {
    return {
      templateName: "",
      report: [],
      resultsLoaded: false,
      searchModel: {
        id: 0,
        yearFrom: this.$route.query.yearFrom,
        yearTo: this.$route.query.yearTo
      }
    };
  },
  updated() {},
  created() {
    var $this = this;

    $this.getReport($this.$route.query.id);
    this.getCompontent($this.$route.query.template).then((x) => {
      $this.templateName = x;
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeName = from.name;
      vm.query = from.query;
      vm.nameRoute = from.name;
    });
  },
  methods: {
    getCompontent: async function (component) {
      return () => import(`./Templates/${component}`);
    },
    clickBack() {
      this.$router.push({
        name: "ReportsIndex",
        query: {
          searchName: this.$route.query.searchName,
          page: this.$route.query.page
        }
      });
    },
    getReport(id) {
      var $this = this;
      $this.resultsLoaded = false;
      $this.searchModel.id = Number(id);
      store
        .dispatch("GET_REPORT", $this.searchModel)
        .then((response) => {
          $this.report = response.data.list;
          $this.resultsLoaded = true;
        })
        .catch((error) => {});
    },
    async Export2PDF(element) {
      var element = "body";
      var doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true
      });

      var $this = this;
      var base64Img = null;
      doc.addFileToVFS("Arimo-Regular.ttf", Arimo);
      doc.addFont("Arimo-Regular.ttf", "Arimo", "normal");
      doc.setFont("Arimo");

      var footer = async function (data) {};
      base64Img = header;

      var title = $this.$route.query.reportName;
      var outputName =
        title +
        "_" +
        $this.searchModel.yearFrom +
        " - " +
        $this.searchModel.yearTo +
        "_" +
        ".pdf";

      var Font = $this.report[0].name == "reports.country" ? 8 : 8;
      Font =
        $this.$route.query.id == 42901 ||
        $this.$route.query.id == 42898 ||
        $this.$route.query.id == 42899
          ? 7
          : Font;

      doc.autoTable({
        styles: {
          fontSize: Font,
          font: "Arimo",
          halign: "center",
          lineColor: [44, 62, 80],
          lineWidth: 0.55
        },
        headStyles: {
          halign: "left",
          fontStyle: "Arimo",
          fontSize: 12,
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineColor: [255, 255, 255]
        },
        columnStyles: {
          0: {
            halign: "center",
            valign: "middle"
          },
          1: {
            halign: "center",
            valign: "middle",
            cellWidth: "auto"
          },
          2: {
            halign: "center",
            valign: "middle",
            cellWidth: "auto"
          }
        },
        html: "#my-table",

        didParseCell: function (HookData) {
          //
          // // if (HookData.row.raw.id == "1") {
          //   HookData.cell.styles.textColor = [0, 0, 0];
          //   HookData.cell.styles.fontSize = 9;
          //   HookData.cell.styles.fillColor = [192, 192, 192];
          // }
          // if (HookData.row.raw.id == "2") {
          //   HookData.cell.styles.fontSize = 13;
          // }
          if (HookData.row.raw.id == "countryRow") {
            var rows = HookData.table.body;

            HookData.cell.styles.textColor = [0, 0, 0];
            HookData.cell.styles.fontSize = 10;
            HookData.cell.styles.fillColor = [176, 224, 230];
          }
        },
        drawHeader: function (HookData) {
          return false;
        },

        didDrawPage: async function (data) {
          data.settings.margin.top = 35;
          var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
          var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
          if (base64Img) {
            doc.addImage(
              base64Img,
              "PNG",
              pageWidth / 2 - 25,
              2,
              50,
              35,
              undefined,
              "FAST"
            );
          }
          if (data.pageNumber === 1) {
            var splitTitle = doc.splitTextToSize(title, 180);
            var dim = doc.getTextDimensions(title);

            var yearsPos = dim.w > 179 ? 64 : 57;
            doc.setFontSize(18);
            doc.setTextColor(60);
            doc.text(splitTitle, pageWidth / 2, 50, "center");
            doc.setFontSize(13);
            doc.setTextColor(60);
            // doc.setFontType("bold");
            doc.setFont(undefined, "bold");
            doc.text(
              $this.$route.query.yearFrom + " - " + $this.$route.query.yearTo,
              pageWidth / 2,
              yearsPos,
              "center"
            );
          }
        },
        didDrawCell: function (HookData) {},
        margin: { top: 60 },
        rowPageBreak: "avoid",
        pageBreak: "avoid"
      });

      var pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      var number_of_pages = doc.internal.getNumberOfPages();
      //doc.deletePage(number_of_pages);
      number_of_pages = doc.internal.getNumberOfPages();
      for (var i = 1; i <= number_of_pages; i++) {
        doc.setPage(i);
        doc.setFontSize(12);
        doc.text(i + "/" + number_of_pages, 10, 290);
        if (i < number_of_pages) {
          doc.setFontSize(9);
          // doc.text(
          //   $this.$lang("reports.date_of_print") +
          //     " " +
          //     $this.$moment($this.today).format("DD.MM.YYYY hh:mm:ss"),
          //   pageWidth / 2 + 34,
          //   doc.internal.pageSize.height - 10
          // );
          // doc.text(
          //   $this.user.user.firstName + " " + $this.user.user.lastName,
          //   pageWidth / 2 + 58,
          //   doc.internal.pageSize.height - 5
          // );
        }
      }
      // doc.text(
      //   "_______________________",
      //   pageWidth / 2 + 34,
      //   doc.internal.pageSize.height - 14
      // );
      // doc.setFontSize(9);
      // doc.text(
      //   $this.user.user.firstName + " " + $this.user.user.lastName,
      //   pageWidth / 2 + 58,
      //   doc.internal.pageSize.height - 10
      // );
      // doc.setFontSize(9);
      // doc.text(
      //   $this.$lang("reports.date_of_print") +
      //     " " +
      //     $this.$moment($this.today).format("DD.MM.YYYY hh:mm:ss"),
      //   pageWidth / 2 + 34,
      //   doc.internal.pageSize.height - 5
      // );

      await doc.save(outputName);

      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        window.open(doc.output("bloburl"), "_blank");
      }
    },
    async Export2Excel() {
      var $this = this;
      var title = $this.$route.query.reportName;

      var element = document.getElementsByTagName("td");
      for (let i = 0; i < element.length; i++) {
        element[i].style.verticalAlign = "middle";
      }

      var tab_text = "<table border='2px'><tr >";
      var textRange;
      var j = 0;

      var tab = document.getElementById("my-table"); // id of table
      var clspan = tab.rows[0].innerHTML.split("<th").length - 1;

      tab_text +=
        "<th  bgcolor='#87AFC6' colspan='" +
        clspan.toString() +
        "' id='activity'>" +
        title +
        "<br style='mso-data-placement:same-cell;' />" +
        $this.$route.query.yearFrom +
        " - " +
        $this.$route.query.yearTo +
        "</th></tr>";

      for (j = 1; j < tab.rows.length; j++) {
        tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";

        //tab_text=tab_text+"</tr>";
      }

      tab_text = tab_text.replace(
        /<td/gi,
        "<td class='xl70' width='300' align='center' vertical-align:'top' "
      );
      tab_text = tab_text.replace(/12-17/gi, "&nbsp;12-17");
      tab_text = tab_text + "</table>";
      tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, ""); //remove if u want links in your table
      tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
      tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");

      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        // If Internet Explorer
        txtArea1.document.open("txt/html", "replace");
        txtArea1.document.write(tab_text);
        txtArea1.document.close();
        txtArea1.focus();
        sa = txtArea1.document.execCommand(
          "SaveAs",
          true,
          "Say Thanks to Sumit.xls"
        );
      } //other browser not tested on IE 11
      else {
        var a = window.document.createElement("a");
        a.setAttribute(
          "href",
          "data:attachment/xlsx;charset=utf-8,%EF%BB%BF" +
            encodeURIComponent(tab_text)
        );
        a.setAttribute("download", title + ".xls");
        window.document.body.appendChild(a);
        a.click();
      }
    }
  },
  watch: {},
  computed: {
    ...mapState(["user"])
  }
};
</script>

<style lang="scss" scoped>
.select-template {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 10px;
}
.report {
  margin-left: 5px;
  margin-right: 5px;
}
.export-pdf {
  margin-right: 10px;
}
.actions {
  display: flex;
  align-items: center;
  i {
    font-size: 2em;
    margin-right: 10px;
    cursor: pointer;
  }
  .icon-file-pdf {
    color: red;
    margin-left: 5px;
  }
}
</style>
