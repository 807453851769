<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-key"></i>
        <span v-html="model.name"></span>
      </h1>
      <div class="actions">
        <a
          v-if="$role(10122) && $route.params.id!=1"
          class="error set-functions"
          v-on:click="redirectToPermissionsForRole()"
          v-lang="'user.button_set_functions_for_role'"
        ></a>
        <a
          class="primary"
          v-on:click="$router.push({ name: 'SettingsForm', query:{component:'RoleIndex'} })"
          v-lang="'general.button_back'"
        ></a>
      </div>
    </div>
    <notify />
    <div class="card table-container">
      <table>
        <tr>
          <td v-lang="'general.label_name'"></td>
          <td>{{ model.name }}</td>
        </tr>
        <tr>
          <td v-lang="'general.label_description'"></td>
          <td>{{ model.description }}</td>
        </tr>
        <tr>
          <td v-lang="'general.label_default'"></td>
          <td>{{ model.default == false ? no : yes }}</td>
        </tr>
        <tr>
          <td v-lang="'user.label_admin_type'"></td>
          <td>{{ model.adminUserType == false ? no : yes }}</td>
        </tr>
        <tr>
          <td v-lang="'user.label_is_system_role'"></td>
          <td>{{ model.isSystem == false ? no : yes }}</td>
        </tr>
        <tr>
          <td v-lang="'user.label_is_builtin_role'"></td>
          <td>{{ model.isBuiltin == false ? no : yes }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../store";
import { lang } from "@/global-functions";

export default {
  data() {
    return {
      model: {},
      yes: lang("general.label_yes"),
      no: lang("general.label_no")
    };
  },
  created() {
    this.getRoleByID();
  },
  methods: {
    getRoleByID() {
      var $this = this;
      store
        .dispatch("GET_ROLE_BY_ID", $this.$route.params.id)
        .then(response => {
          $this.model = response.data.model;
        })
        .catch(error => {});
    },
    redirectToPermissionsForRole() {
      this.$router.push({
        name: "PermissionsForRole",
        params: { id: this.$route.params.id }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}

td:nth-child(2) {
  font-weight: bold;
}
.actions {
  .set-functions {
    margin-right: 10px;
  }
}
</style>
