import AdminLayout from "@/components/UserAndRolesManagement/AdminPanel/AdminLayout.vue";
const auth = () => import("@/middleware");
const Index = () => import("./Index.vue");
const Details = () => import("./Details.vue");
const Form = () => import("./Form.vue");

const router = [
  {
    path: "/victims",
    name: "VictimsMain",
    component: AdminLayout,
    meta: {
      middleware: auth
    },
    children: [
      {
        path: "index",
        name: "VictimsIndex",
        component: Index
      },
      {
        path: "details/:id",
        name: "VictimsDetails",
        component: Details
      },
      {
        path: "form/:id?",
        name: "VictimsForm",
        component: Form
      }
    ]
  }
];

export default router;
