<template>
  <div>
    <table id="my-table" style="display:none;width:100%;">
      <template v-for="(obj, index) in model">
        <tr :key="index" v-if="obj.questionNumber == 1">
          <td>
            {{ obj.goalNumber + ". " + obj.goalTitle }}
          </td>
        </tr>
        <tr :key="index + randKey">
          <td>
            {{
              obj.goalNumber +
                "." +
                obj.questionNumber +
                " " +
                obj.questionTitle
            }}
          </td>
        </tr>
        <tr :key="index + randKey">
          <td v-html="obj.questionDescription"></td>
        </tr>
        <tr :key="index + randKey">
          <td v-if="!showAllAnswers">
            {{ $lang("survey.answer") }}
          </td>
        </tr>
        <template v-if="!showAllAnswers">
          <tr
            :key="index + randKey"
            v-if="obj.answers && obj.answers.length > 0"
            id="answerRow"
          >
            <td>
              {{ obj.answers[0].text ? obj.answers[0].text : "-" }}
            </td>
          </tr>
        </template>
        <template v-else>
          <template v-for="(answer, key) in obj.answers">
            <tr :key="index + key" id="orgName">
              <td>
                {{ $lang("survey.orgnisation") }}
                {{ answer.emmausOrgnisationName }}
              </td>
            </tr>
            <tr :key="index + key + randKey" id="answerRow">
              <td class="answerCell">
                {{ answer.text ? answer.text : "-" }}
              </td>
            </tr>
          </template>
        </template>
      </template>
    </table>
    <table id="no-data" style="display: none;">
      <tbody>
        <tr>
          <td>{{ $lang("general.no_data_to_show") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    model: {
      type: Array,
      default: null
    },
    showAllAnswers: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    randKey() {
      return Math.random();
    }
  }
};
</script>
<style lang="scss" scoped>
tr,
td {
  background-color: #fff !important;
}
tr:nth-of-type(odd) {
  background-color: #fff !important;
}
.bold {
  font-weight: bold !important;
}
.answerCell {
  background-color: rgb(230, 176, 176);
}
</style>
