<template>
  <div>
    <table id="my-table" style="display:none;">
      <thead>
        <tr></tr>
      </thead>
      <tbody>
        <tr>
          <th
            v-lang="'general.first_name'"
            style="width: 50% !important; text-align:left !important;"
          ></th>
          <td style="text-align: left;">
            {{ model.firstName }}
          </td>
        </tr>
        <tr>
          <th
            v-lang="'general.last_name'"
            style="width: 100px !important; text-align:left !important;"
          ></th>
          <td style="text-align: left;">{{ model.lastName }}</td>
        </tr>
        <tr>
          <th
            v-lang="'emmaus.emmaus_institution'"
            style="width: 100px !important; text-align:left !important;"
          ></th>
          <td style="text-align: left;">{{ model.emmausInstitutionName }}</td>
        </tr>
        <tr>
          <th
            v-lang="'general.gender'"
            style="width: 100px !important; text-align: left !important;"
          ></th>
          <td style="text-align: left;">{{ model.genderName }}</td>
        </tr>
        <!-- <tr>
          <th
            v-lang="'emmaus.emmaus_additional_question_one'"
            style="width: 100px !important; text-align: left !important;"
          ></th>
          <td style="text-align: left;">
            {{
              model.additionalQuestionOne
                ? $lang("general.label_yes")
                : $lang("general.label_no")
            }}
          </td>
        </tr>
        <tr>
          <th
            v-lang="'emmaus.emmaus_additional_question_two'"
            style="width: 100px !important; text-align: left !important;"
          ></th>
          <td style="text-align: left;">
            {{
              model.additionalQuestionTwo
                ? $lang("general.label_yes")
                : $lang("general.label_no")
            }}
          </td>
        </tr> -->
        <tr>
          <th
            v-lang="'emmaus.createdBy'"
            style="width: 100px !important; text-align: left !important;"
          ></th>
          <td style="text-align: left;">{{ model.createdByName }}</td>
        </tr>
        <tr>
          <th
            v-lang="'emmaus.emmaus_createdOn'"
            style="width: 100px !important; text-align: left !important;"
          ></th>
          <td style="text-align: left;">
            {{ $moment(model.createdOn).format("DD.MM.YYYY.") }}
          </td>
        </tr>
      </tbody>
    </table>
    <table style="display:none;" id="details_trainig_person">
      <thead>
        <tr>
          <th class="text-align-center">#</th>
          <th>
            {{ $lang("training.emmaus_training_name") }}
            <span></span>
          </th>
          <th>
            {{ $lang("training.label_training_type") }}
            <span></span>
          </th>
          <th>
            {{ $lang("training.training_date") }}
            <span></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(obj, key) in trainings" :key="key">
          <td>
            {{ key + 1 }}
          </td>
          <td>{{ obj.name }}</td>
          <td>{{ obj.typeName }}</td>
          <td>
            {{
              obj.date != null
                ? $moment(obj.date).format("DD.MM.YYYY.")
                : $lang("general.unknow")
            }}
          </td>
        </tr>
      </tbody>
    </table>
    <table style="display:none;" id="details_institutions_person">
      <thead>
        <tr>
          <th class="text-align-center">#</th>
          <th>
            {{ $lang("general.label_name") }}
            <span></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(obj, key) in model.personInstitutions" :key="key">
          <td>
            {{ key + 1 }}
          </td>
          <td>{{ obj.name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    model: Object,
    trainings: Array,
  },
};
</script>
<style scoped></style>
