<template>
  <div class="container">
    <div class="login-form">
      <h1 v-lang="'forms.forgot_password_title'">
        <p v-lang="'forms.enter_email_to_restart_password'"></p>
        <span></span>
      </h1>
      <form
        v-if="success.length == 0"
        v-on:submit.prevent="requestNewPassword()"
        data-vv-scope="forgot"
      >
        <div
          class="input"
          :class="
                  errors.first('forgot.email') !== undefined
                    ? 'input-error'
                    : ''
                "
        >
          <label for="email">
            <span v-lang="'user.label_email'"></span>
            <span class="required-field-star">&#x2605;</span>
          </label>
          <input
            v-model="email"
            name="email"
            v-validate="'required|email'"
            type="text"
            autocomplete="off"
          />
          <div class="error" v-html="errors.first('forgot.email')"></div>
        </div>
        <div class="input error" v-html="error"></div>
        <div class="button-container">
          <button class="primary login right-angle">
            <content v-lang="'forms.submit'"></content>
            <span></span>
          </button>
          <router-link class="forgot-password" :to="{ name:'Login'}" v-lang="'forms.back_to_login'"></router-link>
        </div>
      </form>
      <div v-else class="success-sended-reset">
        <span v-lang="success"></span>
        <router-link class="forgot-password" :to="{ name:'Login'}" v-lang="'forms.back_to_login'"></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import globalStore from "@/store";
import { ToastSuccess, ToastError, lang } from "@/global-functions";
export default {
  data() {
    return {
      email: "",
      error: "",
      success: "",
      validation_messages: {
        custom: {
          email: {
            required: lang("validation.required"),
            email: lang("validation.email")
          }
        }
      }
    };
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  methods: {
    requestNewPassword() {
      var $this = this;
      this.$validator.validateAll("forgot").then(valid => {
        if (valid) {
          globalStore.commit("SHOW_LOADER");
          store
            .dispatch("REQUEST_NEW_PASSWORD", this.email)
            .then(response => {
              $this.success = response.data.message;
            })
            .catch(error => {
              
              $this.success = "";
              $this.error = lang(error.data.message);
            })
            .finally(() => {
              globalStore.commit("HIDE_LOADER");
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/colors";
.container {
  display: grid;
  height: 100vh;
  position: fixed;
  width: 100%;
  justify-items: center;
  align-items: center;
  background: $secondaryColor;
  .success-sended-reset {
    padding: 10px;
    font-size: 0.8rem;
    span {
      display: block;
      padding: 10px;
      font-size: 0.8rem;
    }
  }
  a {
    padding: 10px;
    font-size: 0.8rem;
    text-decoration: none;
    color: $primaryColor;
    &:hover {
      text-decoration: underline;
    }
  }
  .logo {
    display: grid;
    justify-items: center;
    align-items: center;
  }
  h1 {
    width: calc(65% - 30px);
    color: white;
    background: $primaryColor;
    margin: 0;
    margin-bottom: 15px;
    padding: 10px 15px;
    position: relative;
    z-index: 10;
    p {
      font-size: 10px;
      margin: 0;
      font-weight: 100;
      letter-spacing: -0px;
    }
    // border-top-left-radius: 10px;
    span {
      border-right: 30px solid transparent;
      border-top: 64px solid $primaryColor;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -30px;
    }
  }
  .login-form {
    background: white;
    // border-radius: 10px;
    min-width: 400px;
  }
  .button-container {
    line-height: 39px;

    button.login {
      float: right;
      width: calc(35% - 30px);
      position: relative;
      font-weight: bold;
      height: 40px;
      cursor: pointer;
      &:hover {
        background: $primaryColor;
        color: $secondaryColor;
      }
      span {
        border-left: 20px solid transparent;
        border-bottom: 40px solid $primaryColor;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -20px;
      }
    }
  }
}
</style>