<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-paperplane"></i>
        <span v-lang="'functions.label_edit_function'"></span>
      </h1>
      <div class="actions">
        <button class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></button>
      </div>
    </div>
    <notify />
    <div>
      <div>
        <Form v-on:save="edit" :functionData="functionById" :errorModel="errorModel" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import Form from "./Form";
import { lang } from "@/global-functions";
import { mapState } from "vuex";
import globalStore from "@/store";

export default {
  components: {
    Form
  },
  data() {
    return {
      functionById: {},
      errorModel: {
        message: "",
        key: "",
        yes: lang("general.label_yes"),
        no: lang("general.label_no")
      }
    };
  },
  created() {
    this.getFunctionByID();
  },
  methods: {
    edit: function(value) {
      var $this = this;
      store
        .dispatch("EDIT_FUNCTION", value)
        .then(response => {
          $this.$success("functions.function_successfully_updated");
          $this.$router.push({ name: "Functions" });
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;
          
        });
    },
    getFunctionByID() {
      var $this = this;
      store
        .dispatch("GET_FUNCTION_BY_ID", $this.$route.params.id)
        .then(response => {
          $this.functionById = response.data.model;
        })
        .catch(error => {
          
        });
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}
</style>
