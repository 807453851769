<template>
  <div>
    <div class="container">
      <div class="header card">
        <h1>
          <i class="icon-survey"></i>
          <span v-lang="'lap.title'"> </span>
        </h1>
        <div class="actions">
          <!-- Export2PDF button -->
          <button
            class="primary mr-5"
            v-lang="'lap.export_to_pdf_goal'"
            @click="getAllForReport"
            v-if="
              strategicProgramsSearchModel.fkModuleIIIGoalId &&
              searchModel.fkReportYearId
            "
          ></button>
          <button
            class="primary mr-5"
            v-lang="'lap.export_to_pdf_sp'"
            @click="getAllForReportByStrategicProgram"
            v-if="
              searchModel.fkStrategicProgramId && searchModel.fkReportYearId
            "
          ></button>
          <a
            class="primary"
            v-on:click="$router.go(-1)"
            v-lang="'general.button_back'"
          ></a>
        </div>
      </div>
      <!-- SELECT YEAR BLOCK -->
      <div class="card">
        <div
          class="form grid-search"
          v-responsive="{ small: (el) => el.width < 650 }"
        >
          <div class="input m-0 col-span-2">
            <label for="createdBy">
              <span v-lang="'lap.label_select_year'"></span>
            </label>
            <select
              v-model="searchModel.fkReportYearId"
              name="createdBy"
              id="dropdownStatusCode"
            >
              <option :value="null">
                {{ $lang("lap.option_select_year") }}
              </option>
              <option
                v-for="(obj, key) in allReportYears"
                :key="key"
                :value="obj.pkReportYearId"
              >
                {{
                  obj.year +
                  (obj.isLockedForInstitutions
                    ? " (" + $lang("survey.locked") + ")"
                    : "")
                }}
              </option>
            </select>
          </div>

          <!-- SELECT GOAL BLOCK -->

          <div class="input m-0 col-span-2">
            <label for="createdBy">
              <span v-lang="'lap.label_select_goal'"></span>
            </label>
            <select
              v-model="strategicProgramsSearchModel.fkModuleIIIGoalId"
              name="createdBy"
              id="dropdownStatusCode"
              @change="searchModel.fkStrategicProgramId = null"
            >
              <option :value="null">
                {{ $lang("lap.option_select_goal") }}
              </option>
              <option
                v-for="(obj, key) in allGoals"
                :key="key"
                :value="obj.pkModuleIIIGoalId"
              >
                {{ obj.translated }}
              </option>
            </select>
          </div>

          <!-- SELECT ORGANISATION BLOCK -->

          <div v-if="$role(50007)" class="input m-0 col-span-4">
            <label for="createdBy">
              <span v-lang="'lap.label_select_team_coordinator'"></span>
            </label>
            <select
              v-model="searchModel.fkEmmausOrganisationId"
              name="createdBy"
              id="dropdownStatusCode"
            >
              <option :value="null">
                {{ $lang("lap.option_select_team_coordinator") }}
              </option>
              <option v-for="(obj, key) in regions" :key="key" :value="obj.id">
                {{ obj.name }}
              </option>
            </select>
          </div>

          <!-- SELECT STRATEGIC PROGRAM BLOCK -->

          <!-- :class="{ 'col-span-8': $role(50007), 'col-span-4': $role(60007) }" -->
          <div
            class="input m-0"
            :class="{ 'col-span-4': $role(50007), 'col-span-2': $role(60007) }"
          >
            <label for="createdBy">
              <span v-lang="'lap.label_select_strategic_program'"></span>
            </label>
            <select
              v-model="searchModel.fkStrategicProgramId"
              name="createdBy"
              id="dropdownStatusCode"
              :disabled="isDisabled"
            >
              <option :value="null">
                {{ $lang("lap.option_select_strategic_program") }}
              </option>
              <option
                v-for="(obj, key) in allStrategicPrograms"
                :key="key"
                :value="obj.pkStrategicProgramId"
              >
                {{ obj.translated }}
              </option>
            </select>
          </div>

          <div
            class="input m-0 internal-link"
            :class="{
              'internal-link-disabled': !redirectInfo.all,
              'col-span-4': $role(50007),
              'col-span-2': $role(60007),
            }"
          >
            &nbsp;

            <router-link
              :disabled="!redirectInfo.all"
              :to="{
                name: 'AnswersIndex',
                query: {
                  fkEmmausOrganisationId: null,
                  fkGoalId: redirectInfo.id,
                  fkReportYearId: searchModel.fkReportYearId,
                },
              }"
              ><i class="icon-info-circle"></i> Izvještavanje organizacija
              <i v-if="redirectInfo.all">
                ({{ redirectInfo.answered }}/{{ redirectInfo.all }})
              </i>
            </router-link>
          </div>
        </div>
      </div>

      <!-- TABLE WITH ACTIVITIES BLOCK -->
      <form @submit.prevent="saveAnswers" class="mb-4">
        <div class="overflow-auto">
          <table class="table-hover align-left">
            <thead>
              <tr>
                <th></th>
                <th v-lang="'lap.activity'"></th>
                <th v-lang="'lap.deadline'"></th>
                <th v-lang="'lap.institution'"></th>
                <th v-lang="'lap.indicators'"></th>
                <th v-lang="'lap.required_resources'"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(obj, key) in activities">
                <tr :key="key">
                  <td>
                    {{ getOrdinalNumber(obj.ordinalNumber.toString()) }}
                  </td>
                  <td style="">{{ obj.name }}</td>
                  <td>
                    {{ obj.deadline }}
                  </td>
                  <td>
                    {{ obj.institution }}
                  </td>
                  <td>
                    {{ obj.implementationIndicators }}
                  </td>
                  <td>
                    {{ obj.requiredResources }}
                  </td>
                </tr>
                <tr :key="key">
                  <td></td>
                  <td colspan="4" class="pb-0">
                    <!-- :disabled=" $role(50007)" -->
                    <div class="container-textarea">
                      <textarea
                        :disabled="!submitCondition"
                        v-model="obj.answer.comment"
                        maxlength="500"
                        :placeholder="
                          $role(60007) ? $lang('lap.enter_comment') : ''
                        "
                        class="min-h-50 w-100 disable-resize disabled-style"
                      ></textarea>
                      <div
                        class="char-counter"
                        :class="{
                          'max-chars':
                            obj.answer.comment &&
                            obj.answer.comment.length == 500,
                        }"
                      >
                        {{ obj.answer.comment ? obj.answer.comment.length : 0 }}
                        / 500
                      </div>
                    </div>
                  </td>
                  <td colspan="1">
                    <div class="status-title">
                      {{ $lang("lap.status") + ":" }}
                    </div>
                    <div class="input m-0 status-container">
                      <label
                        :for="'not_started' + key"
                        :class="{ 'cursor-pointer': $role(60007) }"
                      >
                        <input
                          :disabled="!$role(60007)"
                          :id="'not_started' + key"
                          class="not-started"
                          type="radio"
                          :value="0"
                          v-model="obj.answer.status"
                        />
                        <span>
                          {{ $lang("lap.status_not_started") }}
                        </span>
                      </label>
                      <label
                        :for="'in_progress' + key"
                        :class="{ 'cursor-pointer': $role(60007) }"
                      >
                        <input
                          :disabled="!$role(60007)"
                          :id="'in_progress' + key"
                          class="in-progress"
                          type="radio"
                          :value="1"
                          v-model="obj.answer.status"
                        />
                        <span>
                          {{ $lang("lap.status_in_progress") }}
                        </span>
                      </label>
                      <label
                        :for="'implemented' + key"
                        :class="{ 'cursor-pointer': $role(60007) }"
                      >
                        <input
                          :disabled="!$role(60007)"
                          :id="'implemented' + key"
                          class="implemented"
                          type="radio"
                          :value="2"
                          v-model="obj.answer.status"
                        />
                        <span>
                          {{ $lang("lap.status_implemented") }}
                        </span>
                      </label>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div v-if="submitCondition" class="flex-end">
          <button class="primary w-100 p-4" type="submit">
            {{ $lang("lap.save") }}
          </button>
        </div>
      </form>
      <modal name="pdfViewer" :draggable="true" width="50%" height="600">
        <Loader2 v-show="loading" />
        <div v-show="!loading">
          <div id="viewerRender"></div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import store from "./store";
import rootStore from "@/store";
import organizationStore from "@/components/EMMAUS/Organisation/store";
import Toasted from "vue-toasted";
import Vue from "vue";
import vmodal from "vue-js-modal";
import Loader2 from "../General/Loader2";
import LapReportByGoal from "./mixins/LAPReportByGoal.vue";
import LapReportByStrategicProgram from "./mixins/LAPReportByStrategicProgram.vue";

// import "../../stimulsoft/stimulsoft.viewer.js";
Vue.use(Toasted);
Vue.use(vmodal);

export default {
  mixins: [LapReportByGoal, LapReportByStrategicProgram],
  components: {
    Loader2,
  },
  data() {
    return {
      activities: [],
      resultsLoaded: true,
      searchModel: {
        fkStrategicProgramId: null,
        fkEmmausOrganisationId:
          this.$role(60007) && !rootStore.state.user.superAdmin
            ? rootStore.state.user.fkEmmausOrganizationId
            : null,

        fkReportYearId: null,
        pageNum: 1,
        pageSize: 10000,
      },
      organizationsSearchModel: {
        page: 1,
        limit: 1000000,
        orderColumn: "",
        orderSort: "",
      },
      goalsSearchModel: {
        pageNum: 1,
        pageSize: 10000,
      },
      strategicProgramsSearchModel: {
        pageNum: 1,
        pageSize: 10000,
        fkModuleIIIGoalId: null,
      },
      allReportYears: [],
      allGoals: [],
      allStrategicPrograms: [],
      reportData: null,
      loading: false,
      strategicReportData: null,
      redirectInfo: {
        id: null,
        all: null,
        answered: null,
      },
    };
  },
  created() {
    this.getAllReportYears();
    this.getAllGoals();
    this.getAllOrganizations();
  },
  mounted() {},
  methods: {
    getActivitesWithAnswers() {
      this.resultsLoaded = false;

      store
        .dispatch("GET_ACTVITIES_WITH_ANSWERS", this.searchModel)
        .then((response) => {
          this.resultsLoaded = true;
          this.activities = response.data.activitiesWithAnswers;
        })
        .catch((error) => {
          if (error.status == 400) this.errorModel = error.data;
          this.reportYears = true;
        });
    },
    getAllReportYears() {
      store
        .dispatch("GET_ALL_REPORT_YEARS")
        .then((response) => {
          this.allReportYears = response.data;
          this.searchModel.fkReportYearId = response.data.find(
            ({ year }) => year == new Date().getFullYear()
          ).pkReportYearId;
        })
        .catch((error) => {
          if (error.status == 400) this.errorModel = error.data;
        });
    },
    getAllGoals() {
      store
        .dispatch("GET_ALL_GOALS", this.goalsSearchModel)
        .then((response) => {
          this.allGoals = response.data.moduleIIIGoals;
        })
        .catch((error) => {
          if (error.status == 400) this.errorModel = error.data;
        });
    },
    getAllOrganizations() {
      store.dispatch("GET_REGIONS").then((response) => {
        this.regions = response.data.list;
      });
    },
    getStrategicPrograms() {
      store
        .dispatch("GET_STRATEGIC_PROGRAMS", this.strategicProgramsSearchModel)
        .then((response) => {
          this.allStrategicPrograms = response.data.strategicPrograms;
        })
        .catch((error) => {
          if (error.status == 400) this.errorModel = error.data;
        });
    },
    getOrdinalNumber(ordinalNum) {
      return `${ordinalNum[0]}.${ordinalNum[1]}.${ordinalNum.substring(
        2,
        ordinalNum.length
      )}.`;
    },
    saveAnswers() {
      store.dispatch("SAVE_ANSWERS", this.mappedAnswers).then((response) => {
        this.succesfullySavedToast();
      });
    },
    succesfullySavedToast() {
      this.$removeToast();
      this.$success("lap.answers_saved");
    },
    async getAllForReport() {
      if (
        this.searchModel.fkReportYearId == null ||
        this.strategicProgramsSearchModel.fkModuleIIIGoalId == null
      )
        return;

      this.$modal.show("pdfViewer", {});

      this.loading = true;
      try {
        let $this = this;
        await store
          .dispatch("GET_REPORT_BY_GOAL", {
            fkModuleIIIGoalId:
              $this.strategicProgramsSearchModel.fkModuleIIIGoalId,
            fkReportYearId: $this.searchModel.fkReportYearId,
            fkEmmausOrganizationId:
              this.$role(60007) && !rootStore.state.user.superAdmin
                ? rootStore.state.user.fkEmmausOrganizationId
                : null,
          })
          .then((response) => {
            $this.reportData = response.data;
          })
          .catch((error) => {
            if (error.status == 400) $this.errorModel = error.data;
          });
        this.stimulsoftCreateReportByGoal();
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    async getAllForReportByStrategicProgram() {
      if (
        !this.searchModel.fkReportYearId ||
        !this.searchModel.fkStrategicProgramId
      )
        return;

      this.$modal.show("pdfViewer", {});

      this.loading = true;
      try {
        let $this = this;
        await store
          .dispatch("GET_REPORT_BY_STRATEGIC_PROGRAM", {
            fkStrategicProgramId: $this.searchModel.fkStrategicProgramId,
            fkReportYearId: $this.searchModel.fkReportYearId,
            fkEmmausOrganizationId:
              this.$role(60007) && !rootStore.state.user.superAdmin
                ? rootStore.state.user.fkEmmausOrganizationId
                : null,
          })
          .then((response) => {
            $this.strategicReportData = response.data;
          })
          .catch((error) => {
            if (error.status == 400) $this.errorModel = error.data;
          });
        this.stimulsoftCreateByStrategicProgram();
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    getLinkForOrganizations() {
      store
        .dispatch("GET_ORGANIZATION_REDIRECT", {
          fkReportYearId: this.searchModel.fkReportYearId,
          goalNumber: Number(
            this.allGoals.find(
              (x) =>
                x.pkModuleIIIGoalId ==
                this.strategicProgramsSearchModel.fkModuleIIIGoalId
            ).ordinalNumber
          ),
        })
        .then((res) => (this.redirectInfo = res.data));
    },
  },
  computed: {
    isDisabled() {
      if (this.strategicProgramsSearchModel.fkModuleIIIGoalId == null) {
        return true;
      } else {
        return false;
      }
    },
    checkForActivitiesWithAnswersCall() {
      if (
        this.searchModel.fkEmmausOrganisationId &&
        this.searchModel.fkStrategicProgramId &&
        this.searchModel.fkReportYearId
      ) {
        return true;
      } else {
        return false;
      }
    },
    mappedAnswers() {
      let answers = [];
      for (let i = 0; i < this.activities.length; i++) {
        answers.push({
          comment: this.activities[i].answer.comment,
          fkActivityId: this.activities[i].pkActivityId,
          fkReportYearId: this.searchModel.fkReportYearId,
          status: this.activities[i].answer.status,
        });
      }
      return answers;
    },
    submitCondition() {
      return (
        this.$role(60007) &&
        this.mappedAnswers.length > 0 &&
        !this.allReportYears.find(
          ({ pkReportYearId }) =>
            pkReportYearId == this.searchModel.fkReportYearId
        ).isLockedForInstitutions
      );
    },
  },
  watch: {
    "strategicProgramsSearchModel.fkModuleIIIGoalId"(newVal) {
      if (newVal) {
        this.getStrategicPrograms();
      }
      if (newVal && this.searchModel.fkReportYearId) {
        this.getLinkForOrganizations();
      } else {
        this.redirectInfo = {
          id: null,
          all: null,
          answered: null,
        };
      }
    },
    searchModel: {
      deep: true,
      handler(newVal) {
        if (
          newVal.fkReportYearId &&
          this.strategicProgramsSearchModel.fkModuleIIIGoalId
        ) {
          this.getLinkForOrganizations();
        } else {
          this.redirectInfo = {
            id: null,
            all: null,
            answered: null,
          };
        }

        if (this.checkForActivitiesWithAnswersCall) {
          this.getActivitesWithAnswers();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-end {
  display: flex;
  justify-content: flex-end;
}

.overflow-auto {
  overflow: auto;
}
.grid-search {
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  grid-gap: 10px;
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}
.col-span-8 {
  grid-column: span 8 / span 8;
  @media (max-width: 650px) {
    grid-column: span 1 / span 1;
  }
}
.col-span-4 {
  grid-column: span 4 / span 4;
  @media (max-width: 650px) {
    grid-column: span 1 / span 1;
  }
}
.col-span-2 {
  grid-column: span 2 / span 2;
  @media (max-width: 650px) {
    grid-column: span 1 / span 1;
  }
}

.min-h-50 {
  min-height: 50px;
}

.w-100 {
  width: 100%;
}
.form.small {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 10px;
}

.align-left :last-child {
  text-align: left;
}

.p-4 {
  padding: 1rem;
}
.mb-4 {
  margin-bottom: 1rem;
}

.disable-resize {
  resize: none;
}
.disabled-style:disabled {
  color: black;
  background-color: white;
  opacity: 100;
}
.mr-5 {
  margin-right: 5px;
}
.container-textarea {
  position: relative;
  display: flex;
  width: 100%;
  padding-bottom: 13px;
}
.char-counter {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  font-size: 11px;
}
.max-chars {
  color: rgb(144, 0, 0);
}
.pb-0 {
  padding-bottom: 0;
}

.status-container {
  label {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 1px;
  }

  input:enabled {
    cursor: pointer;
  }
  input {
    appearance: none;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 1px solid white;
    padding: 0;
    margin-right: 5px;
    padding: 1px;
  }

  .not-started {
    box-shadow: 0px 0px 0px 2px rgb(144, 0, 0);
    -webkit-box-shadow: 0px 0px 0px 2px rgb(144, 0, 0);
    -moz-box-shadow: 0px 0px 0px 2px rgb(144, 0, 0);

    &:active:enabled {
      border-color: rgb(206, 0, 0);
    }
    &:checked {
      background-color: rgb(206, 0, 0);
    }
  }
  .in-progress {
    box-shadow: 0px 0px 0px 2px #eb9409;
    -webkit-box-shadow: 0px 0px 0px 2px #eb9409;
    -moz-box-shadow: 0px 0px 0px 2px #eb9409;
    &:active:enabled {
      border-color: #ff9f05;
    }
    &:checked {
      background-color: #ff9f05;
    }
  }
  .implemented {
    box-shadow: 0px 0px 0px 2px #5a9c0d;
    -webkit-box-shadow: 0px 0px 0px 2px #5a9c0d;
    -moz-box-shadow: 0px 0px 0px 2px #5a9c0d;
    &:active:enabled {
      border-color: #86c232;
    }
    &:checked {
      background-color: #86c232;
    }
  }
}
.status-title {
  display: flex;
  font-weight: 600;
}
.cursor-pointer {
  cursor: pointer;
}
.internal-link {
  justify-content: end;
}
.internal-link > a {
  background: #1267c7;
  display: flex;
  align-items: center;
  gap: 12px;
  height: 100%;
}
.internal-link:hover > a {
  background: #0a50a0;
}
.internal-link > a > i {
  //font-size: 16px;
}
.internal-link-disabled > a {
  background: #6b6e70;
  cursor: default;
  pointer-events: none;
}
.internal-link-disabled:hover > a {
  background: #6b6e70;
}
</style>
<style lang="scss">
#viewerRender td {
  text-align: left !important;
}
</style>
