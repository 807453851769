import Status404 from "@/components/status-pages/404.vue";
import Status500 from "@/components/status-pages/500.vue";
import { RouteConfig } from "vue-router";

const router: RouteConfig[] = [
  {
    path: "/404",
    name: "404",
    component: Status404
  },
  {
    path: "/500",
    name: "500",
    component: Status500
  }
];

export default router;
