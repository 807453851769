var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(!_vm.peopleList || _vm.peopleList.length == 0)?_c('NoData'):_c('div',{staticClass:"table-container scroll",class:_vm.$route.name == 'EmmausPeopleIndex' ? 'card' : ''},[_c('table',{staticClass:"table-hover",attrs:{"id":"report_person"}},[_c('thead',[_c('tr',{attrs:{"id":_vm.tableId}},[_c('th',{staticClass:"text-align-center"},[_vm._v("#")]),_c('th',{attrs:{"FirstName":""},on:{"click":function($event){return _vm.sort('FirstName')}}},[_vm._v(" "+_vm._s(_vm.$lang("general.first_name"))+" "),_c('span')]),_c('th',{attrs:{"LastName":""},on:{"click":function($event){return _vm.sort('LastName')}}},[_vm._v(" "+_vm._s(_vm.$lang("general.last_name"))+" "),_c('span')]),_c('th',{attrs:{"FkGenderCodeId":""},on:{"click":function($event){return _vm.sort('FkGenderCodeId')}}},[_vm._v(" "+_vm._s(_vm.$lang("general.gender"))+" "),_c('span')]),_c('th',{attrs:{"FkEmmausInstitutionId":""},on:{"click":function($event){return _vm.sort('FkEmmausInstitutionId')}}},[_vm._v(" "+_vm._s(_vm.$lang("emmaus.emmaus_institution"))+" "),_c('span')]),_c('th')])]),_c('tbody',_vm._l((_vm.peopleList),function(obj,key){return _c('tr',{key:key},[_c('td',[_vm._v(" "+_vm._s((_vm.searchModel.page - 1) * _vm.searchModel.limit + key + 1)+" ")]),_c('td',[_vm._v(_vm._s(obj.firstName))]),_c('td',[_vm._v(_vm._s(obj.lastName))]),_c('td',[_vm._v(_vm._s(obj.genderName))]),_c('td',[_vm._v(_vm._s(obj.emmausInstitutionName))]),_c('td',{staticClass:"actions"},[(
                _vm.$role(50003) &&
                  _vm.$route.name != 'EmmausTrainingsDetails' &&
                  _vm.$route.name != 'EmmausTrainingsForm' &&
                  obj.isDeleted
              )?_c('a',{directives:[{name:"lang",rawName:"v-lang",value:('general.label_restore'),expression:"'general.label_restore'"}],staticClass:"relation  small",on:{"click":function($event){return _vm.restorePerson(obj.pkEmmausPersonId)}}}):_vm._e(),(
                _vm.$role(50002) &&
                  _vm.$route.name != 'EmmausTrainingsDetails' &&
                  _vm.$route.name != 'EmmausTrainingsForm' &&
                  !obj.isDeleted
              )?_c('a',{directives:[{name:"lang",rawName:"v-lang",value:('general.label_remove'),expression:"'general.label_remove'"}],staticClass:"error small",on:{"click":function($event){return _vm.deletePerson(obj.pkEmmausPersonId)}}}):_vm._e(),(!_vm.form && _vm.$route.name == 'EmmausTrainingsForm')?_c('a',{directives:[{name:"lang",rawName:"v-lang",value:('general.label_add'),expression:"'general.label_add'"}],staticClass:"primary small",on:{"click":function($event){return _vm.addPerson(obj)}}}):_vm._e(),(_vm.form && _vm.$route.name == 'EmmausTrainingsForm')?_c('a',{directives:[{name:"lang",rawName:"v-lang",value:('general.label_delete'),expression:"'general.label_delete'"}],staticClass:"error small",on:{"click":function($event){return _vm.removePerson(obj)}}}):_vm._e(),(_vm.$role(40284) && _vm.$route.name != 'EmmausTrainingsForm')?_c('a',{directives:[{name:"lang",rawName:"v-lang",value:('general.label_details'),expression:"'general.label_details'"}],staticClass:"default small details",on:{"click":function($event){return _vm.personDetails(obj.pkEmmausPersonId)}}}):_vm._e(),(
                _vm.$role(40287) &&
                  _vm.$route.name != 'EmmausTrainingsDetails' &&
                  _vm.$route.name != 'EmmausTrainingsForm'
              )?_c('a',{directives:[{name:"lang",rawName:"v-lang",value:('general.label_edit'),expression:"'general.label_edit'"}],staticClass:"primary small",on:{"click":function($event){return _vm.editPerson(obj.pkEmmausPersonId)}}}):_vm._e(),(
                _vm.$role(40291) &&
                  _vm.$route.name != 'EmmausTrainingsDetails' &&
                  _vm.$route.name != 'EmmausTrainingsForm'
              )?_c('a',{directives:[{name:"lang",rawName:"v-lang",value:('general.label_delete'),expression:"'general.label_delete'"}],staticClass:"error small",on:{"click":function($event){return _vm.removePersonDB(obj.pkEmmausPersonId)}}}):_vm._e()])])}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }