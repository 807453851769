import Vue from "vue";
import Vuex from "vuex";
import Store from "../../store";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pkSelectedYearId: null,
    pkSelectedGoalId: null,
    selectedYear:null
  },
  mutations: {
    SET_SELECTED_YEAR: function(state: any, pkSelectedYearId: any) {
      state.pkSelectedYearId = pkSelectedYearId;
    },
    SET_SELECTED_GOAL: function(state: any, pkSelectedGoalId: any) {
      state.pkSelectedGoalId = pkSelectedGoalId;
    },
    SET_SELECTED_YEAR_VALUE: function(state: any, selectedYear: any) {
      state.selectedYear = selectedYear;
    },
  },
  actions: {
    GET_ALL_REPORT_YEARS: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .get("/reportyear/get-all")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_GOALS: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .get("/goal/get-all")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_GOAL_QA: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .post("/QuestionAnswer/get-goal-with-answers", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    SAVE_GOAL_QA: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .put("/QuestionAnswer/", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    DOWNLOAD_TEMPLATE: function(state: any, goalId: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .get("/Goal/download-template/" + goalId)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    UPLOAD_FILE_ANSWER: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .post("/FileAnswer/upload/", model,{ headers: {
            'Content-Type': 'multipart/form-data'
          }})
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    }
  },
});
