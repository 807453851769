<template>
  <div class="container">
    <div class="header card">
      <h1 v-lang="id != 0?'codes.label_edit_type_of_codes':'codes.label_create_type_of_codes'"></h1>
      <div class="actions">
        <button class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></button>
      </div>
    </div>
    <div>
      <form v-on:submit.prevent="id == 0? save() : update()" data-vv-scope="code_form">
        <div class="card">
          <div
            class="input m-0"
            :class="
              errors.first('code_form.codeName') !== undefined
                ? 'input-error'
                : ''
            "
          >
            <label for="codeName">
              <span v-lang="'general.label_name'"></span>
              <sup class="required-field-star">&#x2605;</sup>
            </label>
            <input
              v-validate="'required'"
              class="m-0"
              v-model="model.name"
              type="text"
              name="codeName"
              autocomplete="off"
              autofocus
            />
            <div class="error" v-html="errors.first('code_form.codeName')"></div>
          </div>
        </div>
        <div class="card">
          <fieldset>
            <legend v-lang="'general.translates'"></legend>
            <div
              class="input m-0"
              :class="errors.first('user_form.titleId') !== undefined ? 'input-error' : '' "
            >
              <label for="titleId" v-lang="'language.titleId'"></label>
              <input
                v-validate="'required'"
                class="m-0"
                v-model="model.titleId"
                type="text"
                name="titleId"
                autocomplete="off"
                :disabled="$route.params.id != null"
              />
              <div class="error" v-html="errors.first('user_form.titleId')"></div>
            </div>
            <div v-for="(obj,key) in langauges" :key="key" class="input m-0">
              <label for="en">{{obj.name}}</label>
              <input
                class="m-0"
                type="text"
                v-model="translationModel.translations[Number(key)]"
                name="en"
                autocomplete="off"
              />
            </div>
          </fieldset>
        </div>

        <div class="card table-container">
          <fieldset>
            <legend v-lang="'general.customFileds'"></legend>
            <div v-if="customFileds.length > 0">
              <table>
                <thead>
                  <th>#</th>
                  <th v-lang="'general.label_name'">&nbsp;</th>
                  <th v-lang="'general.filed_type'">&nbsp;</th>
                  <th v-lang="'general.label_value'">&nbsp;</th>
                  <th>&nbsp;</th>
                </thead>
                <tbody>
                  <tr v-for="(obj, key) in customFileds" :key="key">
                    <td>{{ key + 1 }}</td>
                    <td>{{ obj.name }}</td>
                    <td v-html="getFiledTypeByID(obj.pkCustomFieldTypeId)"></td>
                    <td>{{ obj.value }}</td>
                    <td>
                      <button
                        v-if="obj.pkCustomFieldId !== undefined"
                        type="button"
                        class="primary small"
                        v-lang="'general.label_edit'"
                        v-on:click="editItemFromArray(obj)"
                      ></button>
                      <button
                        type="button"
                        class="error small"
                        v-lang="'general.label_delete'"
                        v-on:click="deleteItemFromArray(obj)"
                      ></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <!-- <form v-on:submit.prevent="id == 0? save() : update()" data-vv-scope="code_form"> -->
              <div class="class-form">
                <div class="input m-0">
                  <label for="name" v-lang="'general.label_name'"></label>
                  <input
                    class="m-0"
                    v-model="customFiled.name"
                    type="text"
                    name="name"
                    autocomplete="off"
                    autofocus
                  />
                  <div v-if="validCustomTypeName()" class="error" v-lang="'validation.required'"></div>
                </div>
                <div class="input m-0">
                  <label for="name" v-lang="'general.filed_type'"></label>
                  <select v-model="customFiled.pkCustomFieldTypeId">
                    <option value="0" v-lang="'general.select_filed_type'"></option>
                    <option
                      v-for="(obj, key) in customFiledTypes"
                      :key="key"
                      :value="obj.pkCustomFieldTypeId"
                    >{{ obj.name }}</option>
                  </select>
                  <div class="error" v-if="validCustomType()" v-lang="'validation.required'"></div>
                </div>
                <div class="input m-0">
                  <label for="name" v-lang="'general.label_value'"></label>
                  <input
                    class="m-0"
                    v-model="customFiled.value"
                    type="text"
                    name="name"
                    autocomplete="off"
                    autofocus
                  />
                  <div class="error" v-if="!validCustomTypeValue()">{{ errorMessage }}</div>
                </div>
                <div class="input m-0 button-top">
                  <button
                    type="button"
                    class="primary"
                    for="name"
                    v-on:click="addNew()"
                  >{{ titleEditOrAdd }}</button>
                </div>
              </div>
            </div>
          </fieldset>
          <div class="submit m-0">
            <Spinner
              :showSpinner="isSubmited"
              :buttonText="$lang('general.button_submit')"
              :buttonClass="'primary'"
            />
            <!-- <button type="submit" class="primary" v-lang="'general.button_submit'"></button> -->
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import { lang } from "@/global-functions";
import LoginVue from "../../User/Login/Login.vue";

export default {
  data() {
    return {
      boolEdit: false,
      titleEditOrAdd: lang("general.label_add"),
      deleteCodesCustomFieldModel: [],
      customFileds: [],
      customFiledTypes: [],
      typeOfCodeModel: {
        model: {
          pkTypesOfCodesId: this.$route.query.typeOfCodeId,
          nameOfTypeOfCode: ""
        }
      },
      model: {
        fkTypeOfCodeId: this.$route.query.typeOfCodeId,
        name: "",
        customFiled: [],
        valuesForLanguages: []
      },
      translationModel: {
        titleId: "",
        translations: []
      },
      langauges: [],
      errorMessage: "",
      customFiled: {
        name: "",
        value: "",
        pkCustomFieldTypeId: 0
      },
      id: this.$route.params.id ? this.$route.params.id : 0,
      pkTypesOfCodesId: this.$route.query.typeOfCodeId,
      validation_messages: {
        custom: {
          codeName: {
            required: lang("validation.required")
          },
          name: {
            required: lang("validation.required")
          },
          value: {
            required: lang("validation.required")
          }
        }
      },
      isSubmited: false
    };
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  updated() {},
  created() {
    this.getAllLanguages();

    this.getCustomFiledType();
    this.getTypeOfCodeByID();
    if (this.id !== 0 && this.id !== "0") {
      this.getCodeByID();
    }
  },
  methods: {
    getAllLanguages() {
      var $this = this;
      store
        .dispatch("GET_ALL_LANGUAGES")
        .then(response => {
          $this.langauges = response.data.list;
          resolve();
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    editItemFromArray(model) {
      this.boolEdit = true;
      this.titleEditOrAdd = lang("general.label_edit");
      this.customFiled = JSON.parse(JSON.stringify(model));
    },
    deleteItemFromArray(model) {
      if (model.pkCustomFieldId !== undefined)
        this.deleteCodesCustomFieldModel.push(model);
      this.customFileds.splice(this.customFileds.indexOf(model), 1);
    },
    addNew() {
      if (
        !this.validCustomTypeName() &&
        !this.validCustomType() &&
        this.validCustomTypeValue()
      ) {
        if (
          this.customFiled.pkCustomFieldId === null ||
          this.customFiled.pkCustomFieldId === undefined
        ) {
          this.customFileds.push(this.customFiled);
        } else {
          this.customFileds.forEach(element => {
            if (element.pkCustomFieldId == this.customFiled.pkCustomFieldId) {
              element.name = this.customFiled.name;
              element.value = this.customFiled.value;
              element.pkCustomFieldTypeId = this.customFiled.pkCustomFieldTypeId;
            }
          });
        }

        this.customFiled = { name: "", value: "", pkCustomFieldTypeId: 0 };
        this.errorMessage = "";
        this.titleEditOrAdd = lang("general.label_add");
        this.boolEdit = false;
      }
    },
    getFkParentId() {
      if (this.$route.query.parentCodeId == 0) return this.pkTypesOfCodesId;
      else return this.$route.query.parentCodeId;
    },
    getCustomFiledType() {
      var $this = this;
      store
        .dispatch("GET_ALL_CUSTOM_FILED_TYPE")
        .then(response => {
          $this.customFiledTypes = response.data.model;
        })
        .catch(error => {});
    },
    update: function() {
      this.model.valuesForLanguages = [];
      for (let i = 0; i < this.translationModel.translations.length; i++) {
        var model = {
          titleId: this.model.titleId,
          langCode: this.langauges[i].code,
          titleValue: this.translationModel.translations[i]
        };
        this.model.valuesForLanguages.push(model);
      }
      var $this = this;
      $this.model.codesCustomFieldModel = $this.customFileds;
      $this.model.deleteCodesCustomFieldModel =
        $this.deleteCodesCustomFieldModel;
      $this.isSubmited = true;
      store
        .dispatch("UPDATE_CODE", $this.model)
        .then(response => {
          $this.$success("codes.code_sucessfuly_updated");
          // $this.$router.push({
          //   name: "ShowTypeOfCode",
          //   params: { id: $this.pkTypesOfCodesId }
          // });
          // $this.$router.push({
          //   name: "TranslationCreate",
          //   params: { id: response.data.titleId }
          // });
          $this.$router.go(-1);
          $this.isSubmited = false;
        })
        .catch(error => {
          $this.$error("general.general_error");
        });
    },
    validCustomTypeName() {
      if (this.customFiled.name.length < 1) return true;
      return false;
    },
    validCustomType() {
      if (
        this.customFiled.pkCustomFieldTypeId == 0 ||
        this.customFiled.pkCustomFieldTypeId == "0"
      )
        return true;
      return false;
    },
    validCustomTypeValue() {
      var tmp = false;
      switch (this.customFiled.pkCustomFieldTypeId) {
        case 1:
          var numbers = /^[0-9]+$/;
          if (!this.customFiled.value.match(numbers)) {
            this.errorMessage = this.$lang("general.validation_int");
            return false;
          }
          return true;
          break;
        case 2:
          var numbers = /^[0-9]+$/;
          if (!this.customFiled.value.match(numbers)) {
            this.errorMessage = this.$lang("general.validation_int");
            return false;
          }
          return true;
          break;
        case 3:
          if (this.customFiled.value.length < 1) {
            this.errorMessage = this.$lang("validation.required");
            return false;
          }
          return true;
          break;
        case 4:
          var numbers = /^[0-1]$/;
          if (!this.customFiled.value.match(numbers)) {
            this.errorMessage = this.$lang("general.validation_boolean");
            return false;
          }
          return true;
        default:
          break;
          return tmp;
      }
    },
    getTranslationsByID(id) {
      var $this = this;
      store
        .dispatch("GET_TRANSLATION_BY_ID", id)
        .then(response => {
          $this.model.valuesForLanguages = response.data;
          $this.model.titleId = $this.model.valuesForLanguages[0].titleId;

          for (let i = 0; i < $this.langauges.length; i++) {
            $this.translationModel.translations[i] = $this.seacrh(
              $this.langauges[i].code
            );
          }
          $this.$forceUpdate();
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    seacrh(code) {
      for (var i = 0; i < this.model.valuesForLanguages.length; i++) {
        if (this.model.valuesForLanguages[i].langCode === code) {
          return this.model.valuesForLanguages[i].titleValue;
        }
      }
    },
    save: function() {
      this.model.valuesForLanguages = [];
      for (let i = 0; i < this.translationModel.translations.length; i++) {
        var model = {
          titleId: this.translationModel.titleId,
          langCode: this.langauges[i].code,
          titleValue: this.translationModel.translations[i]
        };
        this.model.valuesForLanguages.push(model);
      }
      var $this = this;

      var newCodeModel = {
        fkTypesOfCodesId: $this.pkTypesOfCodesId,
        fkParentId: this.getFkParentId(),
        name: this.model.name,
        codesCustomFieldModel: this.customFileds,
        valuesForLanguages: this.model.valuesForLanguages
      };
      $this.$validator.validateAll("code_form").then(valid => {
        if (valid) {
          store
            .dispatch("CREATE_CODE", newCodeModel)
            .then(response => {
              $this.isSubmited = true;
              $this.$success("codes.code_sucessfuly_created");
              // $this.$router.push({
              //   name: "ShowTypeOfCode",
              //   params: { id: $this.pkTypesOfCodesId }
              // });
              // $this.$router.push({
              //   name: "TranslationCreate",
              //   params: { id: "codes." + response.data.titleId }
              // });
              $this.$router.go(-1);
              $this.isSubmited = false;
            })
            .catch(error => {
              $this.$error("general.general_error");
            });
        }
      });
    },
    getTypeOfCodeByID() {
      var $this = this;
      store
        .dispatch("GET_TYPE_OF_CODES_BY_ID", $this.pkTypesOfCodesId)
        .then(response => {
          $this.typeOfCodeModel = response.data;
        })
        .catch(error => {});
    },
    getCodeByID() {
      var $this = this;
      store
        .dispatch("GET_CODE_BY_ID", $this.id)
        .then(response => {
          // $this.model = response.data.model;
          $this.customFileds = response.data.model.codesCustomFieldModel;
          $this.model = response.data.model;
          $this.getTranslationsByID($this.model.titleId);
        })
        .catch(error => {});
    },
    getFiledTypeByID(id) {
      var name = "";
      this.customFiledTypes.forEach(element => {
        if (element.pkCustomFieldTypeId == id) {
          name = element.name;
        }
      });
      return name;
    }
  }
};
</script>
<style lang="scss" scoped>
// form {
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr auto;
//   grid-gap: 10px;
//   flex-wrap: wrap;
//   align-items: center;
//   justify-content: center;
//   & > div {
//     // display: flex;
//     // flex: 1;
//     // align-items: center;
//     justify-content: center;
//   }
// }
.class-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  grid-gap: 10px;
  flex-wrap: wrap;
  // align-items: center;
  // justify-content: center;
  & > div {
    // display: flex;
    // flex: 1;
    // align-items: center;
    // justify-content: center;
  }
}
.button-top {
  top: 22px;
}
.submit {
  margin-top: 10px !important;
  width: 100%;
}
</style>
