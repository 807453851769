<template>
  <form v-on:submit.prevent="save()" data-vv-scope="actionGroup_form">
    <div
      class="input m-0"
      :class="errors.first('actionGroup_form.name') !== undefined ? 'input-error' : '' "
    >
      <label for="name" v-lang="'actionGroup.label_for_name'"></label>
      <input
        class="m-0"
        v-validate="'required'"
        v-model="model.name"
        type="text"
        name="name"
        autocomplete="off"
      />
      <div>
        <span class="error" v-html="errors.first('actionGroup_form.name')"></span>
      </div>
    </div>
    <div
      class="input m-0"
      :class="errors.first('action_form.description') !== undefined ? 'input-error' : '' "
    >
      <label for="description" v-lang="'actionGroup.label_for_description'"></label>
      <input
        class="m-0"
        type="text"
        v-model="model.description"
        name="description"
        autocomplete="off"
      />
      <div>
        <span class="error" v-html="errors.first('action_form.description')"></span>
      </div>
    </div>

    <div
      class="input m-0"
      :class="errors.first('action_form.titleId') !== undefined ? 'input-error' : '' "
    >
      <label for="titleId">
        <span v-lang="'actionGroup.label_for_titleId'"></span>
      </label>
      <input class="m-0" type="text" v-model="model.titleId" name="titleId" autocomplete="off" />
      <div>
        <span class="error" v-html="errors.first('action_form.titleId')"></span>
      </div>
    </div>

    <div class="fieldset">
      <fieldset>
        <legend v-lang="'actionGroup.label_group_type'"></legend>
        <div class="input-checkbox">
          <label for="isSystem">
            <input
              class="m-0"
              type="checkbox"
              v-model="model.isSystem"
              id="isSystem"
              autocomplete="off"
              hidden
            />
            <span v-lang="'actionGroup.label_for_isSystem'"></span>
          </label>
        </div>

        <div class="input-checkbox">
          <label for="isBuiltIn">
            <input
              class="m-0"
              type="checkbox"
              v-model="model.isBuiltin"
              id="isBuiltIn"
              autocomplete="off"
              hidden
            />
            <span v-lang="'actionGroup.label_for_isBuiltIn'"></span>
          </label>
        </div>
        <div class="input-checkbox">
          <label for="anonymousGroup">
            <input
              class="m-0"
              type="checkbox"
              v-model="model.anonymousGroup"
              id="anonymousGroup"
              autocomplete="off"
              hidden
            />
            <span v-lang="'actionGroup.label_for_anonymousGroup'"></span>
          </label>
        </div>
        <div class="input-checkbox">
          <label for="adminActionGroup">
            <input
              class="m-0"
              type="checkbox"
              v-model="model.adminActionGroup"
              id="adminActionGroup"
              autocomplete="off"
              hidden
            />
            <span v-lang="'actionGroup.label_for_adminActionGroup'"></span>
          </label>
        </div>
        <div class="input-checkbox">
          <label for="default">
            <input
              class="m-0"
              type="checkbox"
              v-model="model.default"
              id="default"
              autocomplete="off"
              hidden
            />
            <span v-lang="'actionGroup.label_for_default'"></span>
          </label>
        </div>
      </fieldset>
    </div>
    <div class="submit m-0" id="sub">
      <button class="primary" v-lang="'general.button_submit'"></button>
    </div>
  </form>
</template>

<script>
import Vue from "vue";
import { lang } from "@/global-functions";
import VeeValidate from "vee-validate";
import store from "../../store";
Vue.use(VeeValidate);
export default {
  props: {
    actionGroupData: {
      default: null
    },
    errorModel: {
      key: "",
      message: ""
    }
  },
  data() {
    return {
      model: {
        name: "",
        titleId: "",
        description: "",
        adminActionGroup: false,
        isSystem: false,
        isBuiltin: false,
        isDeleted: false,
        default: false,
        anonymousGroup: false
      },
      validation_messages: {
        custom: {
          name: {
            required: lang("validation.required")
          }
        }
      }
    };
  },

  watch: {
    actionGroupData() {
      this.model = this.actionGroupData;
    },
    errorModel() {
      this.$importModelStateErrors(
        this.errors,
        this.errorModel.modelState,
        "actionGroup_form"
      );
    }
  },

  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  methods: {
    save() {
      this.$validator.validateAll("actionGroup_form").then(valid => {
        if (valid) {
          this.$emit("save", this.model);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/colors";
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  & > div {
    // display: flex;
    // flex: 1;
    // align-items: center;
    justify-content: center;
  }

  .submit {
    color: red;
    grid-column: 1/3;
  }
}
.white {
  color: white;
}
label {
  input {
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
  }
}

div.input-checkbox {
  color: black;
  display: -webkit-inline-flex;
  width: 150px;
  margin: 0px 5px;
  margin-top: 5px;
}

.fieldset {
  font-size: 0.9em;
}

fieldset {
  border: 1px solid $primaryColor;
}
legend {
  font-size: 0.9em;
}
</style>
