<template>
  <div>
    <ul v-for="(obj, key) in model" :key="key">
      <li>
        <div
          class="text-container"
          :class="[
            obj.children.length > 0 ? 'has-children' : 'no-childern',
            selectedParents.includes(obj.pkCodeId) ? 'open' : ''
          ]"
          v-on:click="showChildren(obj.pkCodeId)"
        >
          <span>{{ obj.name }}</span>
          <div class="actions">
            <button
              v-if="$role(10128)"
              class="error small"
              v-on:click="deleteCode(obj.pkCodeId)"
              v-lang="'general.label_delete'"
            ></button>
            <router-link
              v-if="$role(10129)"
              class="default small"
              :to="{
                name: 'ValuesForm',
                params: { id: obj.pkCodeId },
                query: { typeOfCodeId: typeOfCodesModel.pkTypesOfCodesId }
              }"
              v-lang="'general.label_edit'"
            ></router-link>
            <router-link
              v-if="$role(10126)"
              class="primary small"
              :to="{
                name: 'ValuesForm',
                params: { id: 0 },
                query: {
                  typeOfCodeId: typeOfCodesModel.pkTypesOfCodesId,
                  parentCodeId: obj.pkCodeId
                }
              }"
              v-lang="'general.label_add'"
            ></router-link>
          </div>
        </div>
        <recursive-component
          v-show="selectedParents.includes(obj.pkCodeId)"
          :model="obj.children"
          :typeOfCodesModel="typeOfCodesModel"
        ></recursive-component>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../store";
import globalStore from "@/store";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";

Vue.use(Toasted);

export default {
  name: "recursive-component",
  props: {
    model: {
      type: Array
    },
    typeOfCodesModel: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      selectedParents: []
    };
  },
  methods: {
    showChildren: function(id) {
      if (this.selectedParents.includes(id)) {
        this.selectedParents.splice(this.selectedParents.indexOf(id), 1);
      } else {
        this.selectedParents.push(id);
      }
    },
    deleteCode: function(id) {
      var $this = this;
      this.$removeToast();
      Vue.toasted.show(lang("codes.do_you_want_to_delete_this_code"), {
        theme: "toasted-primary",
        position: "top-center",
        duration: 5000,
        action: [
          {
            text: lang("general.label_yes"),
            onClick: (e, toastObject) => {
              store
                .dispatch("DELETE_CODE", { id: id })
                .then(response => {
                  $this.$success("codes.code_sucessfuly_deleted");
                  this.$router.go();
                })
                .catch(error => {
                  $this.$error("general.general_error");
                });
              toastObject.goAway(0);
            }
          },
          {
            text: lang("general.label_no"),
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            }
          }
        ]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
  margin: 5px 0;
  list-style-type: none;
  ul {
    padding-left: 20px;
  }
}
.text-container {
  padding: 5px;
  font-size: 16px;
  display: flex;
  justify-content: center;

  flex-direction: row;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.6);
  transition: all 0.3s;
  span {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  &.has-children {
    &::before {
      transform-origin: 50% 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-family: "icomoon" !important;
      content: "\e901";
      margin-right: 5px;
      transition: all 0.2s;
    }
    &.open {
      &::before {
        transform: rotate(90deg);
      }
    }
  }
  &.no-childern {
    &::before {
      font-family: "icomoon" !important;
      margin-right: 5px;
      font-size: 0.8em;
      width: 16px;
      text-align: center;
      content: "\e902";
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}

.actions {
  display: flex;
  margin-left: auto;
}
</style>
