<template>
  <div>
    <div class="container">
      <div class="header card">
        <h1>
          <i class="icon-calendar"></i>
          <span v-lang="'lap.year_managment_institutions'"></span>
        </h1>
        <div class="actions">
          <a
            class="primary"
            v-on:click="clickBack()"
            v-lang="'general.button_back'"
          ></a>
        </div>
      </div>

      <!-- TABLE -->
      <Loader2 v-if="!resultsLoaded" />
      <NoData v-else-if="!reportYears || reportYears.length == 0"></NoData>
      <span v-else>
        <div class="card table-container scroll">
          <table class="table-hover">
            <thead>
              <tr :id="tableId">
                <th>#</th>
                <th>
                  {{ $lang("surveyYears.year") }}
                  <span></span>
                </th>
                <th>
                  {{ $lang("surveyYears.status") }}
                  <span></span>
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(obj, key) in reportYears" :key="key">
                <td>
                  {{
                    key + 1 + (searchModel.pageNum - 1) * searchModel.pageSize
                  }}
                </td>

                <td>{{ obj.year }}</td>
                <td>
                  {{
                    obj.isLockedForInstitutions
                      ? $lang("surveyYears.locked")
                      : $lang("surveyYears.unlocked")
                  }}
                </td>
                <td>
                  <a
                    v-if="$role(50006)"
                    class="small"
                    :class="obj.isLockedForInstitutions ? 'primary' : 'error'"
                    v-on:click="
                      updateYearStatus(
                        obj.pkReportYearId,
                        obj.isLockedForInstitutions
                      )
                    "
                    v-lang="
                      obj.isLockedForInstitutions
                        ? 'surveyYears.unlock'
                        : 'surveyYears.lock'
                    "
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination-container">
            <paginate
              :page-count="pageCount"
              :click-handler="changePage"
              v-model="searchModel.pageNum"
              :prev-text="this.$lang('general.previous')"
              :next-text="this.$lang('general.next_page')"
              :container-class="'paggination'"
            ></paginate>
          </div>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "./store";
import Toasted from "vue-toasted";
import NoData from "@/components/General/NoData";
import Loader2 from "@/components/General/Loader2";
import Paginate from "vuejs-paginate";

Vue.use(Toasted);

export default {
  components: {
    Loader2,
    Paginate,
    NoData,
  },
  data() {
    return {
      resultsLoaded: true,
      reportYears: [],
      searchModel: {
        pageNum: 1,
        pageSize: 10,
      },
      pageCount: 1,
      tableId: Math.random(),
    };
  },
  beforeDestroy() {
    this.$removeToast();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeName = from.name;
      vm.query = from.query;
      vm.nameRoute = from.name;
    });
  },
  created() {
    this.getAllReportYears();
  },
  methods: {
    clickBack() {
      this.$router.push({
        name: this.nameRoute,
        query: Object.assign({}, this.query),
      });
    },
    getAllReportYears() {
      var $this = this;
      $this.resultsLoaded = false;
      store
        .dispatch("GET_ALL_REPORT_YEARS_WITH_PAGINATION", {
          pageNum: this.searchModel.pageNum,
          pageSize: this.searchModel.pageSize,
        })
        .then((response) => {
          $this.resultsLoaded = true;
          $this.reportYears = response.data.years;
          $this.pageCount = response.data.totalPages;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    changePage(pageNum) {
      this.searchModel.pageNum = pageNum;
      this.getAllReportYears();
    },
    updateYearStatus(id, isLockedForInstitutions) {
      let $this = this;
      store
        .dispatch("UPDATE_STATUS", {
          pkReportYearId: id,
          isLockedForInstitutions: !isLockedForInstitutions,
        })
        .then((response) => {
          $this.$success("surveyYears.year_status_successfully_updated");
          $this.resultsLoaded = false;
          $this.getAllReportYears();
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-container {
  margin: 0 !important;
}
</style>
