<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-key"></i>
        <span>{{$lang('user.label_set_funtions_for_role')}}:</span>
        <span>&nbsp;</span>
        <span>{{data.role.name}}</span>
      </h1>
      <div class="actions">
        <a class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></a>
      </div>
    </div>
    <notify />
    <div>
      <div class="card">
        <form id="functions_form" v-on:submit.prevent="save()" data-vv-scope="functions_form">
          <fieldset
            v-for="(value, key) in AllFunctionsWithGroup"
            :key="key"
            :disabled="value[0].actionGroup.anonymousGroup"
            :name="key"
            :class="value[0].actionGroup.anonymousGroup?'anonymous':''"
          >
            <legend>
              <span class="name-group" v-html="key"></span>
              <span v-if="value[0].actionGroup.anonymousGroup">&nbsp;(</span>
              <span v-html="value[0].actionGroup.anonymousGroup ? labelAnonymous:''"></span>
              <span v-if="value[0].actionGroup.anonymousGroup">)</span>
            </legend>
            <div class="fieldset-div">
              <div v-for="(func,key) in value" class="input-checkbox" :key="key">
                <label :for="func.pkFunctionId">
                  <input
                    v-if="func.actionGroup.anonymousGroup"
                    :id="func.pkFunctionId"
                    class="m-0"
                    type="checkbox"
                    autocomplete="off"
                    :value="func"
                    hidden
                    checked
                  />
                  <input
                    v-else
                    :id="func.pkFunctionId"
                    v-model="data.functions"
                    class="m-0"
                    type="checkbox"
                    autocomplete="off"
                    :value="func"
                    hidden
                  />
                  <span v-html="func.name"></span>
                </label>
              </div>
            </div>
          </fieldset>
          <div class="button-search">
            <button class="primary" v-lang="'general.button_submit'"></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../store";
import { lang } from "@/global-functions";
import { mapState } from "vuex";
import globalStore from "@/store";
import NoData from "../../General/NoData";

export default {
  components: {
    NoData
  },
  data() {
    return {
      data: {
        pkFkRoleId: 0,
        role: {},
        functions: {},
        allFunctions: {}
      },
      labelAnonymous: this.$lang("user.label_anonymous")
    };
  },
  created() {
    this.getAllData();
  },
  methods: {
    save() {
      var $this = this;
      store
        .dispatch("SET_FUNCTIONS_FOR_ROLE", $this.data)
        .then(response => {
          $this.$success("user.functions_for_role_sucessfully_setted");
          $this.$router.push({
            name: "RoleDetails",
            params: { id: $this.$route.params.id }
          });
        })
        .catch(error => {});
    },
    getAllData() {
      var $this = this;
      store
        .dispatch("GET_ALL_PERMISIONS_FOR_ROLE", $this.$route.params.id)
        .then(response => {
          $this.data.pkFkRoleId = response.data.model.pkFkRoleId;
          $this.data.role = response.data.model.role;
          $this.data.functions = response.data.model.functions;
          $this.data.allFunctions = response.data.model.allFunctions;
        })
        .catch(error => {});
    }
  },
  computed: {
    AllFunctionsWithGroup() {
      var $this = this;
      for (let value of Object.values($this.data.allFunctions)) {
        value.forEach(function(element) {
          var index = $this.data.functions.findIndex(function(e, index, array) {
            if (e.pkFunctionId == element.pkFunctionId) {
              return true;
            }
            return false;
          });
          if (index >= 0) {
            $this.data.functions[index] = element;
          }
        });
      }

      return $this.data.allFunctions;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/colors";
form {
  display: grid;
  grid-template-columns: 1fr;
  .button-search {
    grid-column: 1/2;
  }
  .anonymous {
    border-color: $lightSecondaryColor;
    color: $lightSecondaryColor;
  }
  fieldset {
    margin-bottom: 10px;
  }
  .fieldset-div {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
  }
}
.name-group {
  text-transform: uppercase;
}
</style>