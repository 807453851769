<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-users"></i>
        <span v-lang="'user.label_edit_user'"></span>
      </h1>
      <div class="actions">
        <button
          v-if="$role(10149)"
          class="error"
          v-lang="'user.label_set_password'"
          v-on:click="goToSetPass($event)"
        ></button>
        <button
          v-if="$role(10148)"
          class="error"
          v-on:click="redirectToPermissionsForUser()"
          v-lang="'user.button_set_functions_for_role'"
        ></button>
        <button
          class="primary"
          v-on:click="$router.go(-1)"
          v-lang="'general.button_back'"
        ></button>
      </div>
    </div>
    <notify />
    <div>
      <div class="card">
        <Form
          v-on:save="edit"
          :userData="user"
          :errorModel="errorModel"
          :isSubmited="isSubmited"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import Form from "./Form";
import { lang } from "@/global-functions";
import { mapState } from "vuex";
import globalStore from "@/store";

export default {
  components: {
    Form
  },
  data() {
    return {
      user: {},
      errorModel: {},
      isSubmited: false
    };
  },
  computed: {
    ...mapState(["token"])
  },
  created() {
    this.getUserByID();
  },
  methods: {
    redirectToPermissionsForUser() {
      this.$router.push({
        name: "PermissionsForUser",
        params: { id: this.$route.params.id }
      });
    },
    edit: function(value, imgBlob, deleteAvatar) {
      var $this = this;
      $this.isSubmited = true;
      store
        .dispatch("EDIT_USER", value)
        .then((response) => {
          if (deleteAvatar) {
            store
              .dispatch("DELETE_AVATAR_IMAGE", $this.user.username)
              .then((response) => {})
              .catch((error) => {});
          }
          store
            .dispatch("GET_CURRENT_USER_ATUH", this.token)
            .then((response) => {
              globalStore.commit("SET_USER", response.data);
              localStorage.setItem("user", JSON.stringify(response.data));
            })
            .catch((error) => {});
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
          console.log("error", error.data);
        });

      if (imgBlob.type == "image/png") {
        store
          .dispatch("UPLOAD_AVATAR_IMAGE", {
            username: $this.user.username,
            avatar: imgBlob
          })
          .then((response) => {
            $this.$success("user.user_successfully_updated");
            $this.$router.push({ name: "UserIndex" });
            $this.isSubmited = false;
          })
          .catch((error) => {});
      } else {
        $this.$success("user.user_successfully_updated");
        $this.$router.push({ name: "UserIndex" });
        $this.isSubmited = false;
      }
    },
    getUserByID() {
      var $this = this;
      store
        .dispatch("GET_USER_BY_ID", $this.$route.params.id)
        .then((response) => {
          $this.user = response.data;
        })
        .catch((error) => {});
    },
    goToSetPass(e) {
      this.$router.push({ name: "SetPassword" });
      e.preventDefault();
      e.stopPropagation();
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}
button {
  margin-right: 5px;
}
</style>
