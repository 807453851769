<script>
export default {
  mounted() {
    Stimulsoft.Base.StiLicense.key =
      "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkTJWH3V8MdWtSLGewDGyZrkFX74dkT8i70/scK" +
      "YQgh+OApvQpva4CfIqPZx5wBGXZJwqPeMCCRtu02ccNfUczlFnA+GIqnPfrLG3/siYoTq2SS+/lk1y0J" +
      "wiobtA9p2QIYlV4JcLdnq/Kmjsu/n9htlThqiqrceKK3NTb0F3s0q7OYEPDBPbL0tZFSwf9j5Fh7gRX/" +
      "4SYsFAvl55/HFua93CmJ2XW1OogAX+OhY7QykGycm+5V00QfxNVRCgXo/Sv8n+ERE8kycUTumSiAYSTS" +
      "zJmYvwV/gkKs1TI1XFyM1N69YUggXaaCIIyd/ksg4aVnU3C/TTWgnTexOHnLWZK3jY+Qmo1glM49JPi3" +
      "TXVcUX00N/veHHGlxZ5imYUYNCXqWTl5RDKw39Y9FiQCuoQUUgbuDapy4/9qXjg+G2rQPukIAvawkXwI" +
      "c1g3vdb0fiF5KM/6UDktfSmBPmkh0Y1kPZ3pYgm/4uGh3Rcz+qdzVU9XuRY8xoLarEDY0Y9W9/1jfUN0" +
      "5GYtImwS+utvlbk0";
  },
  methods: {
    stimulsoftCreate() {
      let year = this.allReportYears.find(
        (ry) => ry.pkReportYearId == this.searchModel.fkReportYearId
      ).year;
      var report = Stimulsoft.Report.StiReport.createNewReport();
      report.loadFile("/stimulsoft/MRT/LapStatistic.mrt");

      var options = new Stimulsoft.Viewer.StiViewerOptions();
      options.appearance.scrollbarsMode = true;
      options.appearance.fullScreenMode = false;
      options.height = "600px";
      options.width = "100%";

      var viewer = new Stimulsoft.Viewer.StiViewer(options, "StiViewer", false);

      var dsInvoice_Data = new Stimulsoft.System.Data.DataSet("data");
      dsInvoice_Data.readJson(this.reportData);
      report.regData("data", "data", dsInvoice_Data);
      report.dictionary.synchronize();
      report.dictionary.variables.getByName(
        "trans_statistic_for_goal"
      ).valueObject = this.$lang("lap.statistic_for_goal");
      report.dictionary.variables.getByName("trans_not_started").valueObject =
        this.$lang("lap.statistic_totalNotStarted");
      report.dictionary.variables.getByName("trans_started").valueObject =
        this.$lang("lap.statistic_totalInProgress");
      report.dictionary.variables.getByName("trans_implemented").valueObject =
        this.$lang("lap.statistic_totalImplemented");
      report.dictionary.variables.getByName("trans_total").valueObject =
        this.$lang("lap.statistic_total");
      report.dictionary.variables.getByName(
        "trans_team_coordinator"
      ).valueObject = this.$lang("lap.label_select_team_coordinator");

      report.dictionary.variables.getByName("trans_lap_title").valueObject =
        this.$lang("lap.lap_report_title");
      report.dictionary.variables.getByName(
        "trans_statistic_report_title"
      ).valueObject = this.$lang("lap.statistic");
      report.dictionary.variables.getByName(
        "goal_title_translated"
      ).valueObject = this.allGoals.find(
        ({ pkModuleIIIGoalId }) =>
          pkModuleIIIGoalId == this.searchModel.fkModuleIIIGoalId
      ).translated;
      report.dictionary.variables.getByName("trans_year").valueObject =
        String(year);
      viewer.report = report;
      viewer.renderHtml("viewerRender");
    }
  }
};
</script>
