<template>
  <div>
    <div v-if="showLoader" class="loader" id="loader">
      <p class="logo" v-lang="'general.loading'">Loading</p>
      <span class="big"></span>
      <span class="small"></span>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      interval: undefined
    };
  },
  watch: {
    showLoader() {
      if (this.showLoader) {
        this.interval = setTimeout(function() {
          window.location.reload();
        }, 10000);
      }
      if (!this.showLoader) {
        clearTimeout(this.interval);
      }
    }
  },
  computed: {
    ...mapState(["showLoader"])
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/loader";
</style>
