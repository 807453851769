import { state } from "vuex";
export default function auth({ router, next, store }) {
  //login
  if (store.state.user == null || store.state.user.user == null) {
    return router.push("/login");
  }

  //required password change
  if (store.state.user.user.requiredPasswordChange) {
    return router.push({ name: "RequiredPasswordChange" });
  }

  return next();
}
