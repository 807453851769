import ReportsIndex from "./Index.vue";
import ReportsExamples from "./ReportsExamplesTemplates.vue";
import ReportDetails from "./Details.vue";

import AdminLayout from "../../UserAndRolesManagement/AdminPanel/AdminLayout.vue";
import auth from "@/middleware";

const router = [
  {
    path: "/reports",
    name: "AdminLayout",
    component: AdminLayout,
    meta: {
      middleware: auth
    },
    children: [
      {
        path: "index",
        name: "ReportsIndex",
        component: ReportsIndex
      },
      {
        path: "reports-examples",
        name: "ReportsExamples",
        component: ReportsExamples
      },
      {
        path: "details",
        name: "ReportDetails",
        component: ReportDetails
      }
    ]
  }
];

export default router;
