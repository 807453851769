import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

// const $api = "https://emmaus-api.page-services.net/"; //aws
// const $api = "http://213.91.82.3:8089/"; //naš server
// const $api = "https://emmausbackendstari.pagedev.net/"; //local
// const $api = "http://20.67.103.25:81/"; //iom
// const $api = "http://20.67.103.25:82/"; //iom upgrade
//const $api = "http://localhost:64807/"; //local

const $api = "https://api.statistike-thb.msb.gov.ba/"; //iom https

//const $core_api = "https://emmausbackendnovi.pagedev.net/";
const $core_api = "https://coreapi.statistike-thb.msb.gov.ba/";
//const $core_api = "http://localhost:58932/";

Vue.use(Vuex);

// var axios_data = ;

export default new Vuex.Store({
  state: {
    user: localStorage.user != null ? JSON.parse(localStorage.user) : null,
    api: $api,
    token: localStorage.token,
    success: undefined,
    error: undefined,
    lang: localStorage.lang != null ? localStorage.lang : "bs",
    showLoader: false,
    timeoutVarSuccess: null,
    timeoutVarError: null,
    $axios_auth: axios.create({
      baseURL: $api + "api",
      // timeout: 6000,
      headers: {
        Authorization: localStorage.token
      }
    }),
    $axios_auth_core: axios.create({
      baseURL: $core_api + "api",
      // timeout: 6000,
      headers: {
        Authorization: localStorage.token
      }
    }),
    $axios: axios.create({
      baseURL: $api
      // timeout: 60000
    }),
    core_api: $core_api
  },
  mutations: {
    SET_TOKEN: function (state: any, token: any) {
      state.token = localStorage.token;
      state.$axios_auth.defaults.headers.common.Authorization =
        localStorage.token;
      state.$axios_auth_core.defaults.headers.common.Authorization =
        localStorage.token;
    },
    SET_USER: function (state: any, user: any) {
      state.user = user;
    },
    GET_USER: function (state: any) {
      return state.user;
    },
    SHOW_LOADER: function (state: any) {
      state.showLoader = true;
    },
    HIDE_LOADER: function (state: any) {
      state.showLoader = false;
    },
    HIDE_LOADER_500: function (state: any) {
      setTimeout(function () {
        state.showLoader = false;
      }, 500);
    },
    SUCCESS_MESSAGE: function (state: any, message: any) {
      state.success = message;
      state.timeoutVarSuccess = setTimeout(function () {
        state.success = undefined;
      }, 50000);
    },
    ERROR_MESSAGE: function (state: any, message: any) {
      state.error = message;
      state.timeoutVarError = setTimeout(function () {
        state.error = undefined;
      }, 5000);
    },
    SET_LANGUAGE: function (state: any, language: any) {
      state.lang = language;
    },
    GET_LANGUAGE: function (state: any) {
      return state.lang;
    }
  },
  actions: {
    MARK_AS_READ_BRODCAST_MESSAGE: function (state: any, id: any) {
      return new Promise((resolve, reject) => {
        this.state.$axios_auth
          .put("/Notifications/mark-as-read-broadcast-message", {
            PkNotificationId: id
          })
          .then(function (response: any) {
            resolve(response);
          })
          .catch(function (error: any) {
            reject(error.response);
          });
      });
    },
    GET_UNSEEN_BROADCAST_MESSAGES: function (state: any) {
      return new Promise((resolve, reject) => {
        this.state.$axios_auth
          .get("/Notifications/unseen-broadcast-messages")
          .then(function (response: any) {
            resolve(response);
          })
          .catch(function (error: any) {
            reject(error.response);
          });
      });
    },
    GET_BROADCAST_MESSAGE: function (state: any, id: any) {
      return new Promise((resolve, reject) => {
        this.state.$axios_auth
          .get("/Notifications/broadcast-message/" + id)
          .then(function (response: any) {
            resolve(response);
          })
          .catch(function (error: any) {
            reject(error.response);
          });
      });
    }
  }
});
