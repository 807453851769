<template>
  <div class="container">
    <div class="header card">
      <h1>
        <span v-lang="'entity_relations.entity_relations_types'"></span>
      </h1>
      <div class="actions">
        <a class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></a>
      </div>
    </div>
    <div class="card">
      <form v-on:submit.prevent="submit" data-vv-scope="entity_type">
        <div
          class="input m-0"
          :class="
          errors.first('entity_type.name') !== undefined
            ? 'input-error'
            : ''
        "
        >
          <label for="name">{{$lang('entity_relations.name')}}</label>
          <input type="text" v-validate="'required'" id="name" name="name" v-model="model.name" />
          <div class="error" v-html="errors.first('entity_type.name')"></div>
        </div>
        <div
          class="input m-0"
          :class="
          errors.first('entity_type.pkFkEntityTypeId') !== undefined
            ? 'input-error'
            : ''
        "
        >
          <label for="pkFkEntityTypeId">{{$lang("entity_relations.entity")}}</label>
          <select
            name="pkFkEntityTypeId"
            v-model="model.pkFkEntityTypeId"
            v-validate="'required|excluded:0'"
          >
            <option value="0">{{ $lang('entity_relations.select_entity_type')}}</option>
            <option v-for="(obj, key) in list" :key="key" :value="obj.pkEntityTypeId">{{ obj.name }}</option>
          </select>
          <div class="error" v-html="errors.first('entity_type.pkFkEntityTypeId')"></div>
        </div>
        <div
          class="input m-0"
          :class="
          errors.first('entity_type.description') !== undefined
            ? 'input-error'
            : ''
        "
        >
          <label for="description" v-lang="'entity_relations.description'">
            <span></span>
            <sup class="required-field-star">&#x2605;</sup>
          </label>

          <textarea name="description" v-model="model.description" v-validate="'required'"></textarea>
          <div class="error" v-html="errors.first('entity_type.description')"></div>
        </div>
        <div class="button-search input">
          <button class="primary">{{ $lang('general.button_submit')}}</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { lang } from "@/global-functions";
import store from "./store";
export default {
  data() {
    return {
      list: [],
      model: {},
      validation_messages: {
        custom: {
          titleId: {
            required: lang("validation.required")
          },
          name: {
            required: lang("validation.required")
          },
          pkFkEntityTypeId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          },
          description: {
            required: lang("validation.required")
          }
        }
      }
    };
  },
  created() {
    this.initModel();
    this.getEntityTypes();
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  methods: {
    getEntityTypes() {
      var $this = this;
      store.dispatch("GET_RELATION_TYPES").then(response => {
        $this.list = response.list;
      });
    },
    submit() {
      var $this = this;
      this.$validator.validateAll("entity_type").then(valid => {
        if (valid) {
          store
            .dispatch("CREATE_RELATION_TYPE", this.model)
            .then(respnse => {
              $this.$success("entity_relations.entity_relation_type_saved");
              $this.$router.go(-1);
            })
            .catch(error => {
              $this.$error("entity_relations.entity_relations_error");
            });
        }
      });
    },
    initModel() {
      this.model = {
        name: "",
        description: "",
        titleId: "",
        isDeleted: false,
        pkRelationTypeId: 0,
        pkFkEntityTypeId: 0
      };
    }
  }
};
</script>
<style lang="scss">
.submit {
  margin-right: 0px;
  button {
    margin-left: auto;
  }
}
</style>