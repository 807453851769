<template>
  <div>
    <div class="container">
      <div class="header card">
        <h1>
          <i class="icon-bilteni"></i>
          <span v-lang="'menu.menu_review_answers'"></span>
        </h1>
        <div class="actions">
          <!-- Export2PDF button -->
          <button
            class="primary mr-5"
            v-lang="'survey.export_to_pdf'"
            @click="Export2PDF"
            v-if="
              (reportAnswers || reportAnswers.length > 0) &&
              searchModel.fkReportYearId &&
              ($role(50005) || $role(50006))
            "
          ></button>
          <a
            class="primary"
            v-on:click="clickBack()"
            v-lang="'general.button_back'"
          ></a>
        </div>
      </div>

      <!-- Search -->
      <div class="card">
        <form
          class="search"
          action
          v-on:submit.prevent="search()"
          v-responsive="{ small: (el) => el.width < 650 }"
        >
          <div class="input m-0">
            <label
              for="fkReportYearId"
              v-lang="'surveyAnswers.orgnisation'"
            ></label>
            <select
              name="fkReportYearId"
              v-model="searchModel.fkEmmausOrganisationId"
              v-validate="'required|excluded:null'"
            >
              <option
                :value="null"
                v-lang="'surveyAnswers.placeholder_for_organization'"
              ></option>
              <option
                v-for="(obj, key) in organizations"
                :key="key"
                :value="obj.pkEmmausOrganizationId"
              >
                {{ obj.name }}
              </option>
            </select>
          </div>
          <div class="input m-0">
            <label for="fkGoalId" v-lang="'surveyAnswers.select_goal'"></label>
            <select
              name="fkGoalId"
              v-model="searchModel.fkGoalId"
              v-validate="'required|excluded:null'"
            >
              <option
                :value="null"
                v-lang="'surveyAnswers.placeholder_for_goal'"
              ></option>
              <option
                v-for="(obj, key) in allGoals"
                :key="key"
                :value="obj.pkGoalId"
              >
                {{
                  $lang("survey.label_goal").toUpperCase() +
                  " " +
                  obj.number +
                  " - " +
                  $lang("surveyTemplates." + obj.titleId).toUpperCase()
                }}
              </option>
            </select>
          </div>
          <div class="input m-0">
            <label
              for="fkReportYearId"
              v-lang="'surveyAnswers.select_year'"
            ></label>
            <select
              name="fkReportYearId"
              v-model="searchModel.fkReportYearId"
              v-validate="'required|excluded:null'"
            >
              <option
                :value="null"
                v-lang="'surveyAnswers.placeholder_for_year'"
              ></option>
              <option
                v-for="(obj, key) in reportYears"
                :key="key"
                :value="obj.pkReportYearId"
              >
                {{ obj.year }}
              </option>
            </select>
          </div>
          <div class="buttons-search">
            <button
              type="button"
              class="warning"
              v-on:click="resetSearch($event)"
              v-lang="'general.button_reset'"
            ></button
            >&nbsp;
            <!-- <button class="primary" v-lang="'general.button_search'"></button> -->
          </div>
        </form>
      </div>

      <!-- ANSWERS -->
      <Loader2 v-if="!resultsLoaded" />
      <NoData v-else-if="answers.length == 0"></NoData>
      <div class="card" v-else>
        <Answer
          v-for="(obj, index) in answers"
          :question="obj"
          :key="index"
          :goalNumber="goalNumber"
          class="mb-20"
        />
      </div>
    </div>
    <SurveyReport
      :model="reportAnswers"
      :showAllAnswers="this.searchModel.fkEmmausOrganisationId ? false : true"
      v-if="showReport"
    />
  </div>
</template>

<script>
import store from "./store";
import NoData from "@/components/General/NoData";
import Loader2 from "../../../General/Loader2";
import goalStore from "../../store";
import yearStore from "../YearManagment/store";
import emmausOrgaizationStore from "../../../EMMAUS/Organisation/store";
import { ResponsiveDirective } from "vue-responsive-components";
import Answer from "./Answer.vue";
import SurveyReport from "../../SurveyReport.vue";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Arimo } from "@/assets/fonts/Arimo-Regular-normal.js";
import { ArimoBold } from "@/assets/fonts/Arimo_Bold.js";
import { header } from "@/assets/imgs/imgs.js";

export default {
  directives: {
    responsive: ResponsiveDirective,
  },
  components: {
    Loader2,
    NoData,
    Answer,
    SurveyReport,
  },
  data() {
    return {
      allGoals: [],
      reportYears: [],
      searchModel: {
        fkEmmausOrganisationId: this.$route.query.fkEmmausOrganisationId
          ? Number(this.$route.query.fkEmmausOrganisationId)
          : null,
        fkGoalId: this.$route.query.fkGoalId
          ? Number(this.$route.query.fkGoalId)
          : null,
        fkReportYearId: this.$route.query.fkReportYearId
          ? Number(this.$route.query.fkReportYearId)
          : null,
      },
      answers: [],
      goalNumber: 0,
      resultsLoaded: true,
      organizations: [],
      organizationsSearchModel: {
        page: 1,
        limit: 1000000,
        orderColumn: "",
        orderSort: "",
      },
      reportAnswers: [],
      selectedYear: null,
      loading: false,
      selectedOrganizationName: "",
      showReport: false,
    };
  },
  beforeDestroy() {
    this.$removeToast();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeName = from.name;
      vm.query = from.query;
      vm.nameRoute = from.name;
    });
  },
  created() {
    this.getAllReportYears();
    this.getAllGoals();
    if (this.searchModel.fkGoalId && this.searchModel.fkReportYearId) {
      this.search();
    }
    this.getAllOrganizations();
  },
  methods: {
    clickBack() {
      this.$router.push({
        name: this.nameRoute,
        query: Object.assign({}, this.query),
      });
    },
    search() {
      this.answers = [];
      this.$router.push({
        query: { ...this.$route.query, ...this.searchModel },
      });
      var $this = this;
      $this.resultsLoaded = false;
      store
        .dispatch("SEARCH", $this.searchModel)
        .then((response) => {
          $this.resultsLoaded = true;
          $this.answers = response.data;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
          $this.reportYears = true;
        });
    },
    getAllGoals() {
      var $this = this;
      goalStore
        .dispatch("GET_ALL_GOALS")
        .then((response) => {
          $this.allGoals = response.data;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    getAllReportYears() {
      var $this = this;
      yearStore
        .dispatch("GET_ALL_REPORT_YEARS")
        .then((response) => {
          $this.reportYears = response.data;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    resetSearch() {
      this.searchModel.fkGoalId = null;
      this.searchModel.fkReportYearId = null;
      this.searchModel.fkEmmausOrganisationId = null;
      this.answers = [];
      this.reportAnswers = [];
    },
    getGoalNumber(id) {
      let goal = this.allGoals.find((e) => e.pkGoalId == id);
      return goal.number;
    },
    getAllOrganizations() {
      var $this = this;
      emmausOrgaizationStore
        .dispatch("SEARCH_EMMAUS_ORGANIZATION", $this.organizationsSearchModel)
        .then((response) => {
          $this.organizations = response.data.list;
        })
        .catch((error) => {});
    },
    async getAllForReport() {
      if (this.searchModel.fkReportYearId == null) return;

      this.loading = true;
      let $this = this;
      await store
        .dispatch("GET_ALL_FOR_REPORT", {
          fkEmmausOrganisationId: $this.searchModel.fkEmmausOrganisationId,
          fkReportYearId: $this.searchModel.fkReportYearId,
          hasOrganization: false,
        })
        .then((response) => {
          $this.reportAnswers = response.data;
          $this.loading = false;
          $this.showReport = true;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    async Export2PDF(element) {
      await this.getAllForReport();
      this.selectedYear = this.reportYears.find(
        (ry) => ry.pkReportYearId == this.searchModel.fkReportYearId
      ).year;
      this.selectedOrganizationName = "";
      if (this.searchModel.fkEmmausOrganisationId) {
        this.selectedOrganizationName = this.organizations.find(
          (o) =>
            o.pkEmmausOrganizationId == this.searchModel.fkEmmausOrganisationId
        ).name;
      }
      var $this = this;

      var element = "body";
      var doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
      });
      var base64Img = null;

      doc.addFileToVFS("Arimo-Regular.ttf", Arimo);
      doc.addFont("Arimo-Regular.ttf", "Arimo", "normal");
      doc.setFont("Arimo");

      doc.addFileToVFS("Arimo-Bold.ttf", ArimoBold);
      doc.addFont("Arimo-Bold.ttf", "Arimo", "bold");
      doc.setFillColor(255, 255, 255);

      var footer = async function (data) {};
      base64Img = header;

      var organization = this.selectedOrganizationName
        ? this.$lang("survey.orgnisation") + this.selectedOrganizationName
        : "";
      var title = this.$lang("survey.year") + this.selectedYear;

      var outputName =
        this.$lang("survey.survey") +
        `_${this.selectedOrganizationName.replace(" ", "_")}` +
        "_" +
        this.selectedYear +
        "_" +
        this.$moment(new Date()).format("DD.MM.YYYY._HH:mm") +
        ".pdf";

      var Font = 8;
      Font =
        $this.$route.query.id == 42901 ||
        $this.$route.query.id == 42898 ||
        $this.$route.query.id == 42899
          ? 7
          : Font;

      var pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      var number_of_pages = doc.internal.getNumberOfPages();

      if (base64Img) {
        doc.addImage(
          base64Img,
          "PNG",
          pageWidth / 2 - 25,
          2,
          50,
          35,
          undefined,
          "FAST"
        );
      }
      // TABELA
      doc.autoTable({
        styles: {
          fontSize: Font,
          font: "Arimo",
          halign: "left",
          lineColor: [44, 62, 80],
          lineWidth: 0.55,
        },
        headStyles: {
          halign: "left",
          fontStyle: "Arimo",
          fontSize: 12,
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineColor: [255, 255, 255],
        },
        columnStyles: {
          0: {
            halign: "left",
            valign: "middle",
            cellWidth: "auto",
          },
        },
        html:
          this.reportAnswers && this.reportAnswers.length > 0
            ? "#my-table"
            : "#no-data",
        margin: { top: 60 },
        didParseCell: function (HookData) {
          //
          // // if (HookData.row.raw.id == "1") {
          //   HookData.cell.styles.textColor = [0, 0, 0];
          //   HookData.cell.styles.fontSize = 9;
          //   HookData.cell.styles.fillColor = [192, 192, 192];
          // }
          // if (HookData.row.raw.id == "2") {
          //   HookData.cell.styles.fontSize = 13;
          // }
          // if (HookData.row.raw.id == "countryRow") {
          //   var rows = HookData.table.body;
          //   HookData.cell.styles.textColor = [0, 0, 0];
          //   HookData.cell.styles.fontSize = 10;
          //   HookData.cell.styles.fillColor = [176, 224, 230];
          HookData.cell.styles.fillColor = [255, 255, 255];
          // if (HookData.row.raw.id == "answerRow") {
          //   HookData.cell.styles.fillColor = [227, 230, 232];
          // }
          // }\
          if (HookData.row.raw._element.id == "answerRow") {
            HookData.cell.styles.fillColor = [240, 240, 240];
          }
          if (HookData.row.raw._element.id == "orgName") {
            HookData.cell.styles.fontStyle = "bold";
          }
        },
        drawHeader: function (HookData) {
          return false;
        },

        didDrawPage: async function (data) {
          data.settings.margin.top = 30;

          var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
          var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

          if (data.pageNumber === 1) {
            var splitTitle = doc.splitTextToSize(title, 250);
            var splitOrganizationTitle = doc.splitTextToSize(organization, 250);

            var dim = doc.getTextDimensions(title);
            var yearsPos = dim.w > 179 ? 64 : 57;
            doc.setFontSize(10);
            doc.setTextColor(60);
            doc.text(splitOrganizationTitle, pageWidth / 2, 45, "center");
            doc.text(splitTitle, pageWidth / 2, 53, "center");
          }
        },
        didDrawCell: function (HookData) {},
        rowPageBreak: "avoid",
        // pageBreak: "avoid"
      });

      //doc.deletePage(number_of_pages);
      number_of_pages = doc.internal.getNumberOfPages();

      for (var i = 1; i <= number_of_pages; i++) {
        doc.setPage(i);
        doc.setFontSize(12);
        doc.text(i + "/" + number_of_pages, 10, 290);
        if (i < number_of_pages) {
          doc.setFontSize(9);
        }
      }

      await doc.save(outputName);

      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        window.open(doc.output("bloburl"), "_blank");
      }
      // $this.showLoader = false;
      // $this.templateData = null;
      // $this.selectedReportNumber = 0;
      this.showReport = false;
    },
  },
  watch: {
    "searchModel.fkGoalId"(newVal) {
      if (this.searchModel.fkGoalId) {
        this.goalNumber = this.getGoalNumber(this.searchModel.fkGoalId);
      }
      if (this.searchModel.fkReportYearId) {
        this.search();
      }
    },
    "searchModel.fkReportYearId": {
      immediate: true,
      handler(newVal) {
        if (this.searchModel.fkReportYearId) {
          this.search();
          // this.getAllForReport();
        }
      },
    },
    "searchModel.fkEmmausOrganisationId"() {
      if (this.searchModel.fkReportYearId) {
        this.search();
        // this.getAllForReport();
      }
    },
    allGoals(newVal) {
      if (newVal && this.searchModel.fkGoalId) {
        this.goalNumber = this.getGoalNumber(this.searchModel.fkGoalId);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-search {
  grid-column: 1/4;
}
.actions {
  position: relative;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  .buttons-search {
    grid-column: 1/3;
    text-align: right;
  }
}
.form.small {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 10px;
  .buttons-search {
    grid-column: 1/2;
    text-align: right;
  }
}
.center-nodata {
  margin: 0 5px 0 5px;
}
.search {
  grid-template-columns: 1fr 1fr 1fr !important;
}
.mb-20 {
  margin-bottom: 20px;
}
.mr-5 {
  margin-right: 5px;
}
</style>
