<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-paperplane"></i>
        <span v-lang="'statusMessages.create_status_message'"></span>
      </h1>
      <div class="actions">
        <button class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></button>
      </div>
    </div>
    <div class="card">
      <Form
        v-bind:update="false"
        v-bind:usedStatusCodes="usedStatusCodes"
        v-on:save="save"
        :errorModel="errorModel"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import Form from "./Form";

export default {
  components: {
    Form
  },
  props: ["usedStatusCodes"],
  data() {
    return {
      user: {},
      errorModel: {}
    };
  },
  methods: {
    save: function(value) {
      var $this = this;
      store
        .dispatch("CREATE_STATUS_MESSAGE", value)
        .then(response => {
          this.$success("statusMessages.status_message_successfully_created");

          $this.$router.push({
            name: "StatusMessages",
            query: {
              PkFkActionId: $this.$route.query.PkFkActionId,
              PkFkController: $this.$route.query.PkFkController
            }
          });
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;

          
        });
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}
</style>