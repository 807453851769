<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-users"></i>
        <span v-lang="'user.label_edit_account'"></span>
      </h1>

      <div class="actions">
        <button
          class="error"
          v-lang="'user.change_password'"
          v-on:click="goToChangePass($event)"
        ></button>
        <button
          class="primary"
          v-on:click="$router.go(-1)"
          v-lang="'general.button_back'"
        ></button>
      </div>
    </div>

    <notify />
    <div>
      <div class="card">
        <Form v-on:save="edit" :userData="userModel" :errorModel="errorModel" />
      </div>
    </div>
    <Loader2 v-if="resultsLoaded" />
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import Form from "./Form";
import { lang } from "@/global-functions";
import globalStore from "@/store";
import { mapState } from "vuex";
import Loader2 from "@/components/General/Loader2";

export default {
  components: {
    Form,
    Loader2
  },
  data() {
    return {
      userModel: {},
      resultsLoaded: false,
      errorModel: {
        message: "",
        key: ""
      }
    };
  },
  computed: {
    ...mapState(["token", "user"])
  },
  created() {
    this.getUserByID();
  },
  methods: {
    edit: function(value, imgBlob, deleteAvatar) {
      var $this = this;
      $this.resultsLoaded = true;
      store
        .dispatch("EDIT_USER_SELF", value)
        .then(async (response) => {
          if (deleteAvatar) {
            await store
              .dispatch("DELETE_AVATAR_IMAGE", $this.userModel.username)
              .then((response) => {})
              .catch((error) => {});
          }

          if (imgBlob.type == "image/png") {
            await store
              .dispatch("UPLOAD_AVATAR_IMAGE", {
                username: $this.userModel.username,
                avatar: imgBlob
              })
              .then((response) => {
                $this.$success("user.user_successfully_updated");
              })
              .catch((error) => {});
          } else {
            $this.$success("user.user_successfully_updated");

            $this.$router.push({ name: "EditAccount" });
          }

          await store
            .dispatch("GET_CURRENT_USER_ATUH", $this.token)
            .then((response) => {
              globalStore.commit("SET_USER", response.data);
              localStorage.removeItem("user");
              localStorage.setItem("user", JSON.stringify(response.data));
            })
            .catch((error) => {});

          await store
            .dispatch("GET_LANGUAGE_FOR_USER", value.fkLanguageId)
            .then((response) => {
              globalStore.commit("SET_LANGUAGE", response.data.model.code);
              localStorage.removeItem("lang");
              localStorage.setItem("lang", response.data.model.code);
            })
            .catch((error) => {});
          setTimeout(() => {
            $this.$router.go();
          }, 600);
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        })
        .finally(() => {
          // $this.$router.go();
        });
    },
    getUserByID() {
      this.userModel = JSON.parse(JSON.stringify(this.user.user));
      // var $this = this;
      // store
      //   .dispatch("GET_USER_BY_ID", $this.user.user.pkUserId)
      //   .then(response => {
      //     $this.userModel = response.data;
      //   })
      //   .catch(error => {
      //
      //   });
    },
    goToChangePass(e) {
      this.$router.push({ name: "ChangePassword" });
      e.preventDefault();
      e.stopPropagation();
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}
</style>
