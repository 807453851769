import Vue from "vue";
import Vuex from "vuex";
import Store from "../../../../store";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {
    // SET_SELECTED_YEAR: function(state: any, pkSelectedYearId: any) {
    //   state.pkSelectedYearId = pkSelectedYearId;
    // },
  },
  actions: {
    SEARCH: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .post("/questionAnswer/admin-get-answers", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    DOWNLOAD: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .post("/fileAnswer/admin-download", model, { responseType: "stream" })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_FOR_REPORT: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .post("/questionAnswer/admin-get-answers-for-report", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_EMMAUS_ORGANIZATION: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/EmmausOrganization")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
  },
});
