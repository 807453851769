<template>
  <Admin />
</template>
<script>
import Admin from "./components/UserAndRolesManagement/AdminPanel/AdminPanel";
import store from "./store";
export default {
  components: {
    Admin
  },
  data() {
    return {
      hideMenuForRoutes: [
        "Login",
        "ForegetPassword",
        "ResetPassword",
        "RequiredPasswordChange",
        "AdminPanel",
        "ShowError"
      ]
    };
  },
  created() {
    // store.state.$axios_auth.interceptors.request.use(
    //   config => {
    //     if (localStorage.token) {
    //       config.headers["Authorization"] = localStorage.token;
    //     }
    //     return config;
    //   },
    //   error => {
    //     return Promise.reject(error);
    //   }
    // );
    var $this = this;
    store.state.$axios_auth.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        setTimeout(() => {
          if (error.response && error.response.status == 600) {
            $this.$router.push("/logout");
          }
        }, 500);
        return Promise.reject(error);
      }
    );
  }
};
</script>

<style lang="scss">
@import "/assets/css/main";
@import "/assets/css/animate";
</style>
