import Vue from "vue";
import App from "./Layout.vue";
import router from "./router";
import store from "./store";
import { directives } from "./global-functions";
import VeeValidate from "vee-validate";
import Notify from "@/components/General/Notifications.vue";
import DatePicker from "@/components/DatePicker/DatePicker.vue";
import Spinner from "@/components/General/Spinner.vue";

// Vue.use(axios);
Vue.use(VeeValidate);
Vue.use(directives);

Vue.component("notify", Notify);
Vue.component("date-picker", DatePicker);
Vue.component("Spinner", Spinner);

Vue.config.productionTip = false;
Vue.config.silent = true;

new Vue({
  router,
  store,
  render: (h) => h(App),
  created: function() {
    var $this = this;
    store.state.$axios_auth.interceptors.response.use(
      function(response: any) {
        return response;
      },
      function(error: any) {
        setTimeout(() => {
          if (error.response && error.response.status == 600) {
            console.error(error);
            $this.$router.push("/logout");
          } else if (error.response && error.response.status == 401) {
            $this.$router.push({
              name: "ErrorPage",
              params: { statusCode: "401" },
            });
          } else if (error.response && error.response.status == 403) {
            $this.$router.push({
              name: "ErrorPage",
              params: { statusCode: "403" },
            });
          } else if (error.response && error.response.status == 404) {
            $this.$router.push({
              name: "ErrorPage",
              params: { statusCode: "404" },
            });
          } else if (error.response && error.response.status == 405) {
            Vue.prototype.$error("general.operation_not_allowed");
          } else if (error.response && error.response.status == 500) {
            $this.$router.push({
              name: "ErrorPage",
              params: { statusCode: "500" },
            });
          }
        }, 500);
        return Promise.reject(error);
      }
    );

    //AUTH CORE
    store.state.$axios_auth_core.interceptors.response.use(
      function(response: any) {
        return response;
      },
      function(error: any) {
        setTimeout(() => {
          if (error.response && error.response.status == 600) {
            console.error(error);
            $this.$router.push("/logout");
          } else if (error.response && error.response.status == 401) {
            $this.$router.push({
              name: "ErrorPage",
              params: { statusCode: "401" },
            });
          } else if (error.response && error.response.status == 403) {
            $this.$router.push({
              name: "ErrorPage",
              params: { statusCode: "403" },
            });
          } else if (error.response && error.response.status == 404) {
            $this.$router.push({
              name: "ErrorPage",
              params: { statusCode: "404" },
            });
          } else if (error.response && error.response.status == 405) {
            Vue.prototype.$error("general.operation_not_allowed");
          } else if (error.response && error.response.status == 500) {
            $this.$router.push({
              name: "ErrorPage",
              params: { statusCode: "500" },
            });
          }
        }, 500);
        return Promise.reject(error);
      }
    );
  },
}).$mount("#app");
