<template>
  <div class="container">
    <div class="header card">
      <h1
        v-lang="$route.params.id!=0?'codes.label_edit_type_of_codes':'codes.label_create_type_of_codes'"
      ></h1>
      <div class="actions">
        <button class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></button>
      </div>
    </div>
    <notify />
    <div>
      <div class="card">
        <form v-on:submit.prevent="id == 0? save() : update()" data-vv-scope="form">
          <div
            class="input m-0"
            :class="errors.first('form.name') !== undefined ? 'input-error' : '' "
          >
            <label for="name">
              <span v-lang="'codes.label_name_of_type_of_code'"></span>
              <sup class="required-field-star">&#x2605;</sup>
            </label>
            <input
              ref="typeName"
              class="m-0"
              v-validate="'required'"
              v-model="model.nameOfTypeOfCode"
              type="text"
              name="name"
              autocomplete="off"
              autofocus
            />
            <div class="error" v-html="errors.first('form.name')"></div>
          </div>
          <fieldset>
            <legend v-lang="'general.translates'"></legend>
            <div
              class="input m-0"
              :class="errors.first('user_form.titleId') !== undefined ? 'input-error' : '' "
            >
              <label for="titleId" v-lang="'language.titleId'"></label>
              <input
                v-validate="'required'"
                class="m-0"
                v-model="model.titleId"
                type="text"
                name="titleId"
                autocomplete="off"
                :disabled="$route.params.id != null"
              />
              <div class="error" v-html="errors.first('user_form.titleId')"></div>
            </div>
            <div v-for="(obj,key) in langauges" :key="key" class="input m-0">
              <label for="en">{{obj.name}}</label>
              <input
                class="m-0"
                type="text"
                v-model="translationModel.translations[Number(key)]"
                name="en"
                autocomplete="off"
              />
            </div>
          </fieldset>
          <div class="button-search">
            <Spinner
              :showSpinner="isSubmited"
              :buttonText="$lang('general.button_submit')"
              :buttonClass="'primary'"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../store";
import { lang } from "@/global-functions";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

export default {
  data() {
    return {
      model: {
        pkTypesOfCodesId: this.$route.params.id,
        nameOfTypeOfCode: "",
        valuesForLanguages: []
      },
      translationModel: {
        titleId: "",
        translations: []
      },
      langauges: [],
      id: this.$route.params.id,
      validation_messages: {
        custom: {
          name: {
            required: lang("validation.required")
          }
        }
      },
      isSubmited: false
    };
  },
  created() {
    if (Number(this.$route.params.id) != 0) {
      this.getTypeOfCodeByID();
    }
  },
  updated() {
    //this.getAllLanguages();
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
    this.$refs.typeName.focus();
  },
  methods: {
    seacrh(code) {
      for (var i = 0; i < this.model.valuesForLanguages.length; i++) {
        if (this.model.valuesForLanguages[i].langCode === code) {
          return this.model.valuesForLanguages[i].titleValue;
        }
      }
    },
    getTranslationsByID(id) {
      var $this = this;
      store
        .dispatch("GET_TRANSLATION_BY_ID", id)
        .then(response => {
          $this.model.valuesForLanguages = response.data;
          $this.model.titleId = $this.model.valuesForLanguages[0].titleId;

          for (let i = 0; i < $this.langauges.length; i++) {
            $this.translationModel.translations[i] = $this.seacrh(
              $this.langauges[i].code
            );
          }
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    getAllLanguages() {
      var $this = this;
      store
        .dispatch("GET_ALL_LANGUAGES")
        .then(response => {
          $this.langauges = response.data.list;
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    update: function() {
      this.model.valuesForLanguages = [];
      for (let i = 0; i < this.translationModel.translations.length; i++) {
        var model = {
          titleId: this.model.titleId,
          langCode: this.langauges[i].code,
          titleValue: this.translationModel.translations[i]
        };
        this.model.valuesForLanguages.push(model);
      }
      var $this = this;

      this.$validator.validateAll("form").then(valid => {
        if (valid) {
          $this.isSubmited = true;

          store
            .dispatch("EDIT_TYPE_OF_CODES", $this.model)
            .then(response => {
              $this.$success("codes.type_of_code_updated");
              // $this.$router.push({ name: "TypesOfCodesIndex" });
              $this.$router.go(-1);
              $this.isSubmited = false;
            })
            .catch(error => {
              $this.$error("general.general_error");
            });
        }
      });
    },
    save: function() {
      this.model.valuesForLanguages = [];
      for (let i = 0; i < this.translationModel.translations.length; i++) {
        var model = {
          titleId: this.translationModel.titleId,
          langCode: this.langauges[i].code,
          titleValue: this.translationModel.translations[i]
        };
        this.model.valuesForLanguages.push(model);
      }
      var $this = this;
      this.$validator.validateAll("form").then(valid => {
        if (valid) {
          $this.isSubmited = true;

          store
            .dispatch("CREATE_TYPE_OF_CODES", $this.model)
            .then(response => {
              $this.$success("codes.type_of_code_created");

              // $this.$router.push({ name: "TypesOfCodesIndex" });
              $this.$router.go(-1);
              $this.isSubmited = false;
            })
            .catch(error => {
              $this.$error("general.general_error");
            });
        }
      });
    },
    getTypeOfCodeByID() {
      var $this = this;
      store
        .dispatch("GET_TYPE_OF_CODES_BY_ID", $this.id)
        .then(response => {
          $this.model = response.data.model;
          $this.getTranslationsByID($this.model.titleId);
        })
        .catch(error => {});
    }
  }
};
</script>
<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  & > div {
    // display: flex;
    // flex: 1;
    // align-items: center;
    justify-content: center;
  }
}
</style>

