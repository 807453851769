<template>
  <div>
    <table
      id="my-table"
      style="display: none"
      v-if="this.data && this.data.report && this.data.report.length > 0"
    >
      <thead>
        <tr>
          <th style="align:center;">
            {{ $lang("emmaus.label_organizator") }}
          </th>
          <th style="align:center;">
            {{ $lang("emmaus.label_broj_unosa_osoba") }}
          </th>
          <th style="align:center;">
            {{ $lang("emmaus.label_broj_unosa_obuka") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="data && data.report && data.report.length > 0">
          <tr v-for="row in listItems" :key="row">
            <td>{{ row.organization }}</td>
            <td>{{ row.personCount }}</td>
            <td>{{ row.trainingCount }}</td>
          </tr></template
        >
      </tbody>
    </table>
    <table id="my-table" style="display: none" v-else>
      <tbody>
        <tr>
          <td>{{ $lang("general.no_data_to_show") }}</td>
        </tr>
      </tbody>
    </table>

    <apexchart
      v-if="renderChart"
      style="visibility: hidden"
      type="pie"
      width="380"
      height="350"
      :options="chartOptions"
      :series="series"
      ref="pieChart"
    ></apexchart>

    <apexchart
      v-if="renderChart2"
      style="visibility: hidden"
      type="pie"
      width="380"
      height="350"
      :options="chartOptions2"
      :series="series2"
      ref="pieChart2"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts
  },
  props: ["data"],
  data() {
    return {
      renderChart: false,
      renderChart2: false,
      hasPerson: false,
      series: [],
      chartOptions: {
        legend: {
          position: "bottom",
          horizontalAlign: "center"
        },
        chart: {
          width: 200,

          type: "pie"
        },
        labels: []
      },
      series2: [],
      chartOptions2: {
        legend: {
          position: "bottom",
          horizontalAlign: "center"
        },
        chart: {
          width: 200,

          type: "pie"
        },
        labels: []
      }
    };
  },
  methods: {
    dataMap() {
      if (this.data && this.data.chartData && this.data.chartData.length > 0) {
        var trainingsMap = new Map();
        this.data.chartData.forEach((element) => {
          if (!trainingsMap.has(element.organization))
            trainingsMap.set(element.organization, element.personCount);
          else
            trainingsMap.set(
              element.organization,
              trainingsMap.get(element.organization) + element.personCount
            );
          if (element.personCount > 0) {
            this.hasPerson = true;
          }
        });

        return trainingsMap;
      } else return new Map();
    },
    dataMap2() {
      if (this.data && this.data.chartData && this.data.chartData.length > 0) {
        var trainingsMap = new Map();

        this.data.chartData.forEach((element) => {
          if (!trainingsMap.has(element.organization))
            trainingsMap.set(element.organization, element.trainingCount);
          else
            trainingsMap.set(
              element.organization,
              trainingsMap.get(element.organization) + element.trainingCount
            );
        });
        return trainingsMap;
      } else return new Map();
    }
  },
  computed: {
    listItems() {
      const data = this.data.report.sort((a, b) =>
        a.trainingCount > b.trainingCount ? -1 : 1
      );

      return data;
    }
  },
  watch: {
    data(val) {
      this.chartOptions.labels = Array.from(this.dataMap().keys());

      this.series = Array.from(this.dataMap().values());

      this.chartOptions2.labels = Array.from(this.dataMap().keys());

      this.series2 = Array.from(this.dataMap2().values());
      if (this.hasPerson) {
        this.renderChart = true;
      }
      this.renderChart2 = true;
    }
  }
};
</script>
<style lang="scss">
.apexcharts-legend {
  margin-top: 20px;
}
</style>
