import radnoMjestoRouter from "./WorkingPlace/router";
import reportsRouter from "./Reports/router";
import victimRouter from "./Victim/router";
import personRouter from "./Person/router";

const router = [
  ...radnoMjestoRouter,
  ...reportsRouter,
  ...victimRouter,
  ...personRouter
];

export default router;
