import Index from "./Index.vue";
import Form from "./Form.vue";
import Details from "./Details.vue";
import AdminLayout from "../../UserAndRolesManagement/AdminPanel/AdminLayout.vue";
import auth from "@/middleware";

const router = [
  {
    path: "/emmaus-trainings",
    name: "AdminLayout",
    component: AdminLayout,
    meta: {
      middleware: auth,
    },
    children: [
      {
        path: "index",
        name: "EmmausTrainingsIndex",
        component: Index,
      },
      {
        path: "form/:id?",
        name: "EmmausTrainingsForm",
        component: Form,
      },
      {
        path: "details",
        name: "EmmausTrainingsDetails",
        component: Details,
      },
    ],
  },
];

export default router;
