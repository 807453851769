<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-users"></i>
        <span v-lang="'user.label_user_details'"></span>
      </h1>
      <div class="actions">
        <button
          v-if="
            $role(10148) &&
            $route.params.id != '46f68135-37bb-4c88-81dc-b670015cc42a'
          "
          class="error"
          v-on:click="redirectToPermissionsForUser()"
          v-lang="'user.button_set_functions_for_role'"
        ></button>
        <button
          class="primary"
          v-on:click="goBack()"
          v-lang="'general.button_back'"
        ></button>
      </div>
    </div>
    <div class="card table-container">
      <table>
        <tr>
          <td v-lang="'user.label_first_name'"></td>
          <td>{{ user.firstName }}</td>
        </tr>
        <tr>
          <td v-lang="'user.label_last_name'"></td>
          <td>{{ user.lastName.toUpperCase() }}</td>
        </tr>
        <tr>
          <td v-lang="'user.username'"></td>
          <td>{{ user.username }}</td>
        </tr>
        <tr>
          <td v-lang="'user.label_email'"></td>
          <td>{{ user.email }}</td>
        </tr>
        <tr>
          <td v-lang="'user.phone'"></td>
          <td>{{ user.phone }}</td>
        </tr>
        <tr>
          <td v-lang="'user.label_organisation_unit'"></td>
          <td>{{ user.institutionName }}</td>
        </tr>
        <tr v-if="emmausOrgName && emmausOrgName != ''">
          <td v-lang="'general.emmaus_org'"></td>
          <td>{{ emmausOrgName }}</td>
        </tr>
        <tr>
          <td v-lang="'general.role'"></td>
          <td>{{ $lang(roleName) }}</td>
        </tr>
        <tr>
          <td v-lang="'user.legend_user_type'"></td>
          <td>
            {{
              (user.isSurveyFiller
                ? $lang("user.is_survey_filler") + ", "
                : "") +
              (user.isTrainingOragnizer
                ? $lang("user.is_training_organiser")
                : "")
            }}
          </td>
        </tr>
      </table>
    </div>
    <div class="card">
      <div class="functions-description" v-lang="'user.user_functions'"></div>
      <fieldset
        v-for="(value, key) in functions"
        :key="key"
        :disabled="value[0].actionGroup.anonymousGroup"
        :name="key"
        :class="value[0].actionGroup.anonymousGroup ? 'anonymous' : ''"
      >
        <legend>
          <span class="name-group" v-html="value[0].actionGroup.name"></span>
          <span v-if="value[0].actionGroup.anonymousGroup">&nbsp;(</span>
          <span
            v-html="value[0].actionGroup.anonymousGroup ? labelAnonymous : ''"
          ></span>
          <span v-if="value[0].actionGroup.anonymousGroup">)</span>
        </legend>

        <div class="fieldset-div">
          <div v-for="(func, key) in value" class="input-checkbox" :key="key">
            <label :for="func.pkFunctionId">
              <span v-html="func.name"></span>
            </label>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import { lang } from "@/global-functions";

export default {
  data() {
    return {
      user: {},
      yes: lang("general.label_yes"),
      no: lang("general.label_no"),
      labelAnonymous: this.$lang("user.label_anonymous"),
      functions: [],
      institutionName: "",
      emmausOrgName: "",
      nameRoute: "",
      roleName: "",
      query: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.query = from.query;
      vm.nameRoute = from.name;
    });
  },
  created() {
    this.getAllData();
  },
  methods: {
    goBack() {
      if (this.nameRoute != "PermissionsForUser") {
        this.$router.push({
          name: this.nameRoute,
          query: Object.assign({}, this.query),
        });
      } else {
        this.$router.push({
          name: "SettingsForm",
          query: { component: "UserIndex" },
        });
      }
    },
    redirectToPermissionsForUser() {
      this.$router.push({
        name: "PermissionsForUser",
        params: { id: this.$route.params.id },
      });
    },
    getAllData() {
      var $this = this;
      store
        .dispatch("GET_FUNCTIONS_FOR_USER", $this.$route.params.id)
        .then((response) => {
          $this.functions = response.data.model.functions;
          $this.user = response.data.model.user;
          $this.user.institutionName = response.data.model.institutionName;
          $this.roleName = response.data.model.roleName;
          $this.emmausOrgName = response.data.model.emmausOrganization;
        })
        .catch((error) => {});
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}

td:nth-child(2) {
  font-weight: bold;
}
.functions-description {
  font-size: 1.4em;
  padding-bottom: 20px;
}
fieldset {
  margin-bottom: 10px;
  legend {
    text-transform: uppercase;
  }
}
.fieldset-div {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
button {
  margin-right: 5px;
}
</style>
