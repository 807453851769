<template>
  <div>
    <div class="container">
      <div class="header card">
        <h1>
          <i class="icon-izvjestaji"></i>
          <span v-lang="'menu.menu_template_management'"></span>
        </h1>
        <div class="actions">
          <a
            class="primary"
            v-on:click="clickBack()"
            v-lang="'general.button_back'"
          ></a>
        </div>
      </div>

      <!-- SELECT GOAL - YEAR BLOCK -->
      <div class="card">
        <div class="input m-0">
          <label for="createdBy">
            <span v-lang="'surveyTemplates.choose_goal'"></span>
          </label>
          <select
            name="createdBy"
            id="dropdownStatusCode"
            v-model="selectedGoal"
            @change="changeGoal"
          >
            <option
              :value="null"
              v-lang="'surveyAnswers.placeholder_for_goal'"
            ></option>
            <option v-for="(obj, key) in allGoals" :key="key" :value="obj">
              {{
                $lang("survey.label_goal").toUpperCase() +
                " " +
                obj.number +
                " - " +
                $lang("surveyTemplates." + obj.titleId).toUpperCase()
              }}
            </option>
          </select>
        </div>
      </div>

      <!--  Template List -->
      <NoData
        v-if="!selectedGoal || !selectedGoal.templatePath"
        class="mb-10"
      ></NoData>
      <span v-else>
        <div class="card table-container scroll">
          <table class="table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>
                  {{ $lang("surveyTemplates.template_name") }}
                  <span></span>
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>

                <td>{{ selectedGoal.templateName }}</td>
                <td>
                  <a
                    v-if="$role(50006)"
                    class="primary small"
                    v-lang="'surveyTemplates.download'"
                    :href="
                      mainStore.state.core_api +
                      'api/Goal/download-template-anonymous/' +
                      selectedGoal.templatePath
                    "
                  ></a>
                  <a
                    v-if="$role(50006)"
                    class="error small"
                    v-lang="'surveyTemplates.delete'"
                    @click="remove(selectedGoal.pkGoalId)"
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </span>

      <!-- Template upload-->
      <div v-show="showFileUploadDialog">
        <div class="header card mb-0">
          <h1>
            <i class="icon-paper-plus2"></i>
            <span v-lang="'surveyTemplates.add_template'"></span>
          </h1>
        </div>
        <div class="card mt-0" transition="expand">
          <form
            v-on:submit.prevent="uploadFileAnswer()"
            data-vv-scope="file_upload_form"
          >
            <div
              class="input m-0"
              :class="
                errors.first('file_upload_form.fileName') !== undefined
                  ? 'input-error'
                  : ''
              "
            >
              <label for="number">
                <span v-lang="'survey.label_file_name'"></span>
              </label>
              <input
                class="m-0"
                v-model="templateName"
                v-validate="'required'"
                type="text"
                name="fileName"
                autocomplete="off"
              />
              <div>
                <span
                  class="error"
                  v-html="errors.first('file_upload_form.fileName')"
                ></span>
              </div>
            </div>

            <UploadInput
              v-model="file"
              :containerClass="
                errors.first('file_upload_form.file') !== undefined
                  ? 'input-error'
                  : ''
              "
              :uploadAreaClass="
                errors.first('file_upload_form.file') !== undefined
                  ? 'upload-area-error'
                  : ''
              "
              :placeholder="$lang('surveyTemplates.select_file_for_upload')"
              :ref="'fileUpload'"
              name="file"
              v-validate="'required|ext:.xlsx,.xls'"
              :errorText="errors.first('file_upload_form.file')"
              :triggerReset="triggerReset"
              :acceptFileTypes="'.xls,.xslx'"
            />

            <div class="question-with-answer">
              <div class="button-search">
                <Spinner
                  :showSpinner="fileUploadingInProgress"
                  :buttonText="$lang('general.button_submit')"
                  :buttonClass="'primary'"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "./store";
import goalStore from "../../store";
import mainStore from "@/store";
import Toasted from "vue-toasted";
import NoData from "@/components/General/NoData";
import Loader2 from "../../../General/Loader2";
import { ResponsiveDirective } from "vue-responsive-components";
import UploadInput from "../../UploadInput";
Vue.use(Toasted);

export default {
  directives: {
    responsive: ResponsiveDirective
  },
  components: {
    Loader2,
    NoData,
    UploadInput
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      allGoals: [],
      showFileUploadDialog: false,
      fileUploadingInProgress: false,
      templateName: "",
      file: null,
      selectedGoal: null,
      validation_messages: {
        custom: {
          fileName: {
            required: this.$lang("validation.required")
          },
          file: {
            required: this.$lang("validation.required"),
            ext: this.$lang("validation.ext")
          }
        }
      },
      triggerReset: 0
    };
  },
  beforeDestroy() {
    this.$removeToast();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeName = from.name;
      vm.query = from.query;
      vm.nameRoute = from.name;
    });
  },
  created() {
    this.getAllGoals();
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  methods: {
    clickBack() {
      this.$router.push({
        name: this.nameRoute,
        query: Object.assign({}, this.query)
      });
    },
    getAllGoals() {
      var $this = this;
      goalStore
        .dispatch("GET_ALL_GOALS")
        .then((response) => {
          $this.allGoals = response.data;
          // $this.pkSelectedYearId = $this.allReportYears.find(
          //   (ry) => ry.year == $this.currentYear
          // ).pkReportYearId;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    changeGoal() {
      this.errors.clear();
      if (this.selectedGoal) {
        this.showFileUploadDialog = true;
      } else {
        this.showFileUploadDialog = false;
      }
    },
    uploadFileAnswer() {
      this.$validator.validateAll("file_upload_form").then((valid) => {
        if (valid) {
          let formData = new FormData();
          formData.append("file", this.file);
          formData.append("TemplateName", this.templateName);
          formData.append("FkGoalId", this.selectedGoal.pkGoalId);

          var $this = this;
          $this.fileUploadingInProgress = true;
          store
            .dispatch("UPLOAD_TEMPLATE", formData)
            .then((response) => {
              $this.fileUploadingInProgress = false;
              $this.selectedGoal.templateName = this.templateName;
              $this.selectedGoal.templatePath = response.data;
              $this.$success("surveyTemplates.template_uploaded_successfully");
              $this.showFileUploadDialog = false;
              $this.templateName = "";
              $this.triggerReset++;
              this.errors.clear();
              this.$nextTick(() => {
                this.$validator.reset();
              });
            })
            .catch((error) => {
              if (error.status == 400) $this.errorModel = error.data;
            });
        }
      });
    },
    remove(id) {
      var $this = this;
      this.$removeToast();
      Vue.toasted.show(
        $this.$lang("surveyTemplates.do_you_want_to_delete_this_template"),
        {
          theme: "toasted-primary",
          position: "top-center",
          duration: 5000,
          action: [
            {
              text: $this.$lang("general.label_yes"),
              onClick: (e, toastObject) => {
                store
                  .dispatch("DELETE_TEMPLATE", id)
                  .then((response) => {
                    $this.selectedGoal.templateName = "";
                    $this.selectedGoal.templatePath = "";
                    $this.templateName = "";
                    $this.file = null;

                    $this.showFileUploadDialog = true;
                    $this.$success("surveyTemplates.success_deleted");

                    $this.triggerReset++;
                    this.errors.clear();
                    this.$nextTick(() => {
                      this.$validator.reset();
                    });
                  })
                  .catch((error) => {
                    if (error.status == 400) $this.errorModel = error.data;
                  });
              }
            },
            {
              text: $this.$lang("general.label_no"),
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              }
            }
          ]
        }
      );
    }
  },
  computed: {
    mainStore: {
      get() {
        return mainStore;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/colors";
.container {
  position: static;
}
.answer-input {
  width: calc(100% - 15px);
  height: 80px;
}
.pagination-container {
  margin: 0 !important;
}
.description-place {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 500px;
  word-wrap: break-word;
  //float: left;
}
.small {
  .description-place {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 120px;
    word-wrap: break-word;
    //float: left;
  }
  #description-place {
    width: 120px;
  }
}
#description-place {
  width: 500px;
}

.pointer {
  cursor: pointer;
  word-break: break-all;
}
.expand-transition {
  transition: all 1.5s ease;
}
/* .expand-enter defines the starting state for entering */
/* .expand-leave defines the ending state for leaving */
.expand-enter,
.expand-leave {
  height: 0;
  opacity: 0;
}
.custom-file-field-error {
  line-height: 0px;
}
.question-with-answer {
  margin-top: 20px;
}

.button-search {
  width: 100%;
  position: relative;
  left: 10px;
  bottom: 10px;
}
.mt-10 {
  margin-top: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mt-0 {
  margin-top: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
</style>
