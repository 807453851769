<template>
  <div class>
    <NoData v-if="!peopleList || peopleList.length == 0"></NoData>
    <div
      v-else
      class="table-container scroll"
      :class="$route.name == 'EmmausPeopleIndex' ? 'card' : ''"
    >
      <table class="table-hover" id="report_person">
        <thead>
          <tr :id="tableId">
            <th class="text-align-center">#</th>
            <th v-on:click="sort('FirstName')" FirstName>
              {{ $lang("general.first_name") }}
              <span></span>
            </th>
            <th v-on:click="sort('LastName')" LastName>
              {{ $lang("general.last_name") }}
              <span></span>
            </th>
            <th v-on:click="sort('FkGenderCodeId')" FkGenderCodeId>
              {{ $lang("general.gender") }}
              <span></span>
            </th>
            <th
              v-on:click="sort('FkEmmausInstitutionId')"
              FkEmmausInstitutionId
            >
              {{ $lang("emmaus.emmaus_institution") }}
              <span></span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <!-- :class="$route.query.pkPersonId == obj.pkPersonId ? 'selected' : ''"
            :id="obj.pkPersonId" -->
          <tr v-for="(obj, key) in peopleList" :key="key">
            <!-- :style="
                obj.value != null
                  ? 'border-left:solid 8px ' + obj.value
                  : 'padding-left: 18px;'
              "
              :title="
                (obj.watchlistTypeName != null
                  ? $lang('general.level_class')
                  : '') +
                  (obj.watchlistTypeName != null ? obj.watchlistTypeName : '')
              " -->
            <td>
              {{ (searchModel.page - 1) * searchModel.limit + key + 1 }}
            </td>
            <td>{{ obj.firstName }}</td>
            <td>{{ obj.lastName }}</td>
            <td>{{ obj.genderName }}</td>
            <td>{{ obj.emmausInstitutionName }}</td>

            <td class="actions">
              <!-- RESTORE PERSON -->
              <a
                v-if="
                  $role(50003) &&
                    $route.name != 'EmmausTrainingsDetails' &&
                    $route.name != 'EmmausTrainingsForm' &&
                    obj.isDeleted
                "
                class="relation  small"
                v-on:click="restorePerson(obj.pkEmmausPersonId)"
                v-lang="'general.label_restore'"
              ></a>
              <!--SOFT DELETE -->
              <a
                v-if="
                  $role(50002) &&
                    $route.name != 'EmmausTrainingsDetails' &&
                    $route.name != 'EmmausTrainingsForm' &&
                    !obj.isDeleted
                "
                class="error small"
                v-on:click="deletePerson(obj.pkEmmausPersonId)"
                v-lang="'general.label_remove'"
              ></a>
              <a
                v-if="!form && $route.name == 'EmmausTrainingsForm'"
                class="primary small"
                v-on:click="addPerson(obj)"
                v-lang="'general.label_add'"
              ></a>

              <a
                v-if="form && $route.name == 'EmmausTrainingsForm'"
                class="error small"
                v-on:click="removePerson(obj)"
                v-lang="'general.label_delete'"
              ></a>
              <a
                v-if="$role(40284) && $route.name != 'EmmausTrainingsForm'"
                class="default small details"
                v-on:click="personDetails(obj.pkEmmausPersonId)"
                v-lang="'general.label_details'"
              ></a>
              <a
                v-if="
                  $role(40287) &&
                    $route.name != 'EmmausTrainingsDetails' &&
                    $route.name != 'EmmausTrainingsForm'
                "
                class="primary small"
                v-on:click="editPerson(obj.pkEmmausPersonId)"
                v-lang="'general.label_edit'"
              ></a>

              <!--  HARD DELETE 40291 -->
              <a
                v-if="
                  $role(40291) &&
                    $route.name != 'EmmausTrainingsDetails' &&
                    $route.name != 'EmmausTrainingsForm'
                "
                class="error small"
                v-on:click="removePersonDB(obj.pkEmmausPersonId)"
                v-lang="'general.label_delete'"
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { lang } from "@/global-functions";
import store from "./store";
import NoData from "../../General/NoData";
import { mapState } from "vuex";
import Vue from "vue";
import Spinner from "../../General/Spinner";

export default {
  components: {
    NoData,
    Spinner,
  },
  props: {
    people: {
      type: Array,
      default: function() {
        return [];
      },
    },
    searchModel: {
      type: Object,
      default: function() {
        return { page: 1, limit: 5 };
      },
    },
    form: {
      type: Object,
      default: function() {
        return false;
      },
    },
  },
  created() {},
  data() {
    return {
      tmppkPersonId: null,
      showSpinner: false,
      localStorageUser: JSON.parse(localStorage.user),
      // model: {
      //   pkFkKnowledgeId: Number(this.$route.params.id),
      //   pkFkPersonId: null,
      // },
      column: JSON.parse(JSON.stringify(this.searchModel.orderColumn)),
      columnSort: JSON.parse(JSON.stringify(this.searchModel.orderSort)),
      tableId: Math.random(),
      deleteModel: null,
    };
  },
  computed: {
    peopleList() {
      let peopleList = this.people;

      if (this.localStorageUser.user.superAdmin != true) {
        let peopleList = this.people.filter((people) => {
          if (people.isDeleted != true) {
            return people;
          }
        });

        return peopleList;
      } else {
        return this.people;
      }
    },
  },
  mounted() {
    this.setIcon();
  },
  methods: {
    addPerson(training) {
      this.$emit("addExistPerson", training.pkEmmausPersonId);
    },
    removePerson(training) {
      this.$emit("removeExistPerson", training.pkEmmausPersonId);
    },
    sort(columnName) {
      if (this.column != columnName) {
        this.column = columnName;
        this.columnSort = "DESC";
      } else if (this.columnSort == "DESC") {
        this.columnSort = "ASC";
      } else {
        this.columnSort = "DESC";
      }
      this.$emit("searchSort", this.column, this.columnSort);
    },
    setIcon() {
      var element = document.getElementById(this.tableId);
      if (element == null) return;
      var array = element.children;
      var item;
      for (let index = 0; index < array.length; index++) {
        if (
          array[index].hasAttribute(
            this.searchModel.orderColumn.replace(".", "-")
          )
        ) {
          item = array[index];
        }
      }
      if (
        this.searchModel.orderColumn != "" &&
        this.searchModel.orderSort == "DESC"
      ) {
        item.classList.add("desc");
      } else if (this.searchModel.orderSort == "ASC") {
        item.classList.add("asc");
      }
    },
    personDetails(id) {
      this.$router.push({
        name: "EmmausPersonDetails",
        query: { pkEmmausPersonId: id, details: true },
      });
    },
    editPerson(id) {
      this.$router.push({
        name: "EmmausPersonForm",
        query: { pkEmmausPersonId: id },
      });
    },
    //SOFT DELETE
    deletePerson(id) {
      var $this = this;
      this.$removeToast();
      Vue.toasted.show(lang("emmaus_persons.message_remove_person"), {
        theme: "toasted-primary",
        position: "top-center",
        duration: 5000,
        action: [
          {
            text: lang("general.label_yes"),
            onClick: (e, toastObject) => {
              store
                .dispatch("REMOVE_EMMAUS_PERSON", id)
                .then((response) => {
                  $this.$emit("updatePeopleList");
                  $this.$success(
                    "emmaus_persons.message_person_successfully_removed"
                  );
                  this.$router.push({
                    query: {
                      ...this.$route.query,
                      delete: true,
                    },
                  });
                  $this.notifyKey += 1;
                  if ($this.$route.query.pkPersonId == id) {
                    var query = Object.assign({}, $this.$route.query);
                    delete query.pkPersonId;
                    delete query.details;
                    $this.$router.push({
                      query,
                    });
                  }
                })
                .catch((error) => {});
              toastObject.goAway(0);
            },
          },
          {
            text: lang("general.label_no"),
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        ],
      });
    },
    restorePerson(id) {
      var $this = this;
      this.$removeToast();
      Vue.toasted.show(lang("emmaus_persons.message_restore_person"), {
        theme: "toasted-primary",
        position: "top-center",
        duration: 5000,
        action: [
          {
            text: lang("general.label_yes"),
            onClick: (e, toastObject) => {
              store
                .dispatch("RESTORE_EMMAUS_PERSON", id)
                .then((response) => {
                  $this.$emit("updatePeopleList");
                  $this.$success(
                    "emmaus_persons.message_person_successfully_restored"
                  );
                  this.$router.push({
                    query: {
                      ...this.$route.query,
                      delete: true,
                    },
                  });
                  $this.notifyKey += 1;
                  if ($this.$route.query.pkPersonId == id) {
                    var query = Object.assign({}, $this.$route.query);
                    delete query.pkPersonId;
                    delete query.details;
                    $this.$router.push({
                      query,
                    });
                  }
                })
                .catch((error) => {});
              toastObject.goAway(0);
            },
          },
          {
            text: lang("general.label_no"),
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        ],
      });
    },
    //HARD DELETE
    removePersonDB(id) {
      var $this = this;
      this.$removeToast();
      Vue.toasted.show(lang("emmaus_persons.message_delete_person"), {
        theme: "toasted-primary",
        position: "top-center",
        duration: 5000,
        action: [
          {
            text: lang("general.label_yes"),
            onClick: (e, toastObject) => {
              store
                .dispatch("DELETE_EMMAUS_PERSON", id)
                .then((response) => {
                  $this.$emit("updatePeopleList");
                  $this.$success(
                    "emmaus_persons.message_person_successfully_deleted"
                  );
                  this.$router.push({
                    query: {
                      ...this.$route.query,
                      delete: true,
                    },
                  });
                  $this.notifyKey += 1;
                  if ($this.$route.query.pkPersonId == id) {
                    var query = Object.assign({}, $this.$route.query);
                    delete query.pkPersonId;
                    delete query.details;
                    $this.$router.push({
                      query,
                    });
                  }
                })
                .catch((error) => {});
              toastObject.goAway(0);
            },
          },
          {
            text: lang("general.label_no"),
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  & > .small {
    text-align: center;
    margin: auto;
  }
}
.left {
  text-align: left;
}
#avatarImg {
  width: 40px !important;
  height: 40px !important;
}
.relation {
  background-color: blueviolet;
  &:hover {
    background-color: darkmagenta;
  }
  padding-left: 14px !important;
  padding-right: 14px !important;
}
.details {
  margin-left: 5px !important;
}
</style>
