import auth from "@/middleware";
import PageDeskLayout from "@/components/PageDesk/PageDeskLayout.vue";
import Index from "@/components/PageDesk/EntityRelations/Index.vue";
import EntityRelationForm from "@/components/PageDesk/EntityRelations/Form.vue";
import EntityRelationTypesForm from "@/components/PageDesk/EntityRelations/RelationTypes/Form.vue";
const router = [
  {
    path: "/entity-relations",
    name: "MainEntityRelationsIndex",
    component: PageDeskLayout,
    meta: {
      middleware: auth
    },
    children: [
      {
        path: "index",
        name: "EntityRelationsIndex",
        component: Index,
        meta: {
          middleware: auth
        }
      },
      {
        path: "form",
        name: "EntityRelationForm",
        component: EntityRelationForm,
        meta: {
          middleware: auth
        }
      },
      {
        path: "entity-relation-types-form",
        name: "EntityRelationTypesForm",
        component: EntityRelationTypesForm,
        meta: {
          middleware: auth
        }
      }
    ]
  }
];

export default router;
