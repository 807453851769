<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-file-text"></i>
        <span>{{typeOfCodesModel.nameOfTypeOfCode}}</span>
      </h1>
      <div class="actions">
        <a class="primary back" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></a>
        <router-link
          v-if="$role(10126)"
          class="primary"
          :to="{name: 'ValuesForm', params: {id: 0}, query: {typeOfCodeId: typeOfCodesModel.pkTypesOfCodesId, parentCodeId: 0}}"
          v-lang="'general.label_add'"
        ></router-link>
      </div>
    </div>
    <notify />
    <Loader2 v-if="loadingCodes" />
    <NoData v-else-if="!rootCode.children || rootCode.children.length == 0"></NoData>
    <div v-else class="card">
      <ULRecursion :model="rootCode.children" :typeOfCodesModel="typeOfCodesModel"></ULRecursion>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../store";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import ULRecursion from "./ULRecursion";
import NoData from "../../General/NoData";
import Loader2 from "@/components/General/Loader2";

Vue.use(Toasted);

export default {
  name: "type-details",
  components: {
    ULRecursion,
    NoData,
    Loader2
  },
  data() {
    return {
      typeOfCodesModel: {
        pkTypesOfCodesId: this.$route.params.id,
        nameOfTypeOfCode: ""
      },
      rootCode: {},
      loadingCodes: false
    };
  },
  created() {
    this.getTypeOfCodeByID();
    this.getCodesByTypeID();
  },
  methods: {
    getTypeOfCodeByID() {
      var $this = this;
      store
        .dispatch("GET_TYPE_OF_CODES_BY_ID", this.$route.params.id)
        .then(response => {
          $this.typeOfCodesModel = response.data.model;
        })
        .catch(error => {});
    },
    getCodesByTypeID() {
      var $this = this;
      $this.loadingCodes = true;
      store
        .dispatch("GET_CODES_BY_TYPE_ID", this.$route.params.id)
        .then(response => {
          $this.rootCode = response.data.model;
          $this.loadingCodes = false;
        })
        .catch(error => {});
    }
  }
};
</script>

<style lang="scss" scoped>
i {
  padding-right: 5px;
  font-size: 1.1em;
}
.actions {
  .back {
    margin-right: 10px;
  }
}
</style>
