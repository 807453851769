<template>
  <div
    id="app"
    class="admin-content"
    v-responsive="{ 'small-menu': (el) => el.width < 1025 }"
  >
    <div v-if="hideMenuForRoutes.indexOf(this.$route.name) == -1" id="nav">
      <div class="top-bar">
        <span class="burger" v-on:click="toggleMenu">
          <i class="icon-chevrons-left"></i>
          <!-- <span></span>
          <span></span>
          <span></span>-->
        </span>
        <MenuNotifications />
      </div>
      <div class="user-data">
        <div class="profile-menu">
          <div class="img-container">
            <img
              v-on:click="editAccount()"
              class="avatar-image"
              :src="
                api +
                'api/users/avatars?username=' +
                user.user.username +
                '&nocache=' +
                new Date()
              "
            />
          </div>
        </div>
        <span v-on:click="editAccount()" class="user-name">
          <h4>{{ user.user.firstName }} {{ user.user.lastName }}</h4>
          <div>{{ user.user.email }}</div>
        </span>
      </div>
      <!-- Pocetna -->
      <router-link to="/home">
        <i class="icon-pocetna"></i>
        <span v-lang="'menu.menu_item_home'"></span>
      </router-link>
      <div
        v-if="
          ($role(30273) || $role(20272) || $role(20268) || $role(20270)) &&
          !$role(50004)
        "
        class="has-inner-menu"
        id="statistics-inner-menu"
        @click="openMenu($event, 'statistics-inner-menu')"
      >
        <div class="inner-menu-title">
          <i class="icon-statistics"></i>
          <span v-lang="'menu.menu_statistics'" class="label"></span>
        </div>
        <div class="inner-menu">
          <!-- Osobe -->
          <router-link
            v-if="$role(30273) && !$role(50004)"
            :to="{ name: 'PersonsIndex' }"
          >
            <i class="icon-people_outline"></i>
            <span v-lang="'menu.menu_persons'"></span>
          </router-link>
          <!-- Žrtve -->
          <router-link
            v-if="$role(20272) && !$role(50004)"
            :to="{ name: 'VictimsIndex' }"
          >
            <i class="icon-victim"></i>
            <span v-lang="'menu.menu_victims'"></span>
          </router-link>

          <!-- Izvjestaji -->
          <router-link
            v-if="($role(20268) || $role(20270)) && !$role(50004)"
            :to="{ name: 'ReportsIndex' }"
          >
            <i class="icon-izvjestaji"></i>
            <span v-lang="'reports.reports'"></span>
          </router-link>
        </div>
      </div>
      <!-- Radno mjesto -->
      <!-- <a class="has-submenu" v-if="$role(20264) && !$role(50004)">
        <i class="icon-office"></i>
        <span v-lang="'workingPlace.working_place'"></span>
        <div class="submenu">
          <router-link
            v-if="$role(20264)"
            name="click-menu"
            :to="{ name: 'WorkingPlaceIndex' }"
            >{{ $lang("workingPlace.preview") }}</router-link
          >
          <router-link
            v-if="$role(20263)"
            name="click-menu"
            :to="{ name: 'WorkingPlaceInsert' }"
            >{{ $lang("workingPlace.insert") }}</router-link
          >
        </div>
      </a> -->

      <!-- EMMAUS -->
      <!-- v-if="$role(20264)" -->
      <a
        class="has-submenu"
        v-if="$role(40274) || $role(40280) || $role(50004)"
      >
        <i class="icon-graduation-cap"></i>
        <span v-lang="'menu.menu_emmaus'"></span>
        <EmmausMenu />
      </a>

      <!-- ORGANIZACIJE-->
      <!-- <a class="has-submenu" v-if="$role(40280) || $role(40281)">
        <i class="icon-library"></i> -->
      <!-- <span v-lang="'menu.menu_emmaus_organisations'"></span> -->
      <!-- <span v-lang="'menu.menu_emmaus_organisations'"></span>
        <div class="submenu">
          <router-link
            v-if="$role(40280)"
            name="click-menu"
            :to="{ name: 'EmmausOrganizationIndex', query: { mainMenu: true } }"
            >{{ $lang("workingPlace.preview") }}</router-link
          >
          <router-link
            v-if="$role(40281)"
            name="click-menu"
            :to="{
              name: 'EmmausOrganizationInsert',
              query: { mainMenu: true }
            }"
            >{{ $lang("workingPlace.insert") }}</router-link
          >
        </div>
      </a> -->

      <div
        class="has-inner-menu"
        v-if="$role(50005) || $role(50006) || $role(50007) || $role(60007)"
        id="monitoring-inner-menu"
        @click="openMenu($event, 'monitoring-inner-menu')"
      >
        <div class="inner-menu-title">
          <i class="icon-monitoring"></i>
          <!-- <span v-lang="'menu.menu_emmaus_organisations'"></span> -->
          <span v-lang="'menu.menu_monitoring'" class="label"></span>
        </div>
        <div class="inner-menu">
          <!-- ANKETA -->
          <router-link :to="{ name: 'SurveyIndex' }" v-if="$role(50005)">
            <i class="icon-survey"></i>
            <span v-lang="'menu.menu_survey'"></span>
          </router-link>
          <!-- ANKETA ADMIN -->
          <a class="has-submenu" v-if="$role(50006)">
            <i class="icon-survey"></i>
            <!-- <span v-lang="'menu.menu_emmaus_organisations'"></span> -->
            <span v-lang="'menu.menu_survey'"></span>
            <div class="submenu">
              <router-link name="click-menu" :to="{ name: 'AnswersIndex' }">{{
                $lang("menu.menu_review_answers")
              }}</router-link>
              <router-link
                name="click-menu"
                :to="{
                  name: 'FilesIndex',
                }"
                >{{ $lang("menu.menu_files_management") }}</router-link
              >
              <router-link
                name="click-menu"
                :to="{
                  name: 'TemplatesIndex',
                }"
                >{{ $lang("menu.menu_template_management") }}</router-link
              >
              <router-link
                name="click-menu"
                :to="{
                  name: 'YearsIndex',
                }"
                >{{ $lang("menu.menu_year_managment") }}</router-link
              >
            </div>
          </a>
          <!-- Lokalni akcioni planovi -->
          <a class="has-submenu" v-if="$role(50007) || $role(60007)">
            <i class="icon-akcioni-planovi"></i>
            <span v-lang="'menu.akcioni_planovi'"></span>
            <div class="submenu">
              <router-link name="click-menu" :to="{ name: 'LapIndex' }">{{
                $lang("menu.menu_item_review")
              }}</router-link>

              <router-link name="click-menu" :to="{ name: 'LapStatistic' }">{{
                $lang("menu.menu_statistic")
              }}</router-link>

              <router-link
                v-if="$role(50007)"
                name="click-menu"
                :to="{
                  name: 'LapYears',
                }"
                >{{ $lang("menu.menu_year_managment") }}</router-link
              >
            </div>
          </a>
        </div>
      </div>
      <!-- Podesavanja -->
      <router-link
        v-if="$role(10173) || $role(50004)"
        :to="{ name: 'SettingsForm', query: { component: 'UserIndex' } }"
      >
        <i class="icon-podesavanja"></i>
        <span v-lang="'settings.label'"></span>
      </router-link>
    </div>

    <div
      :class="
        hideMenuForRoutes.indexOf(this.$route.name) == -1
          ? ''
          : 'login-background'
      "
    >
      {{ msg }}
      <router-view />
    </div>
  </div>
</template>

<script>
// import store from "@/store";
import MenuNotifications from "./Notification/Notification.vue";
import { mapState } from "vuex";
import mainstore from "@/store";
import EmmausMenu from "./EmmausMenu.vue";

import { ResponsiveDirective } from "vue-responsive-components";

export default {
  directives: {
    responsive: ResponsiveDirective,
  },
  components: {
    MenuNotifications,
    EmmausMenu,
  },
  data() {
    return {
      msg: "",
      hideMenuForRoutes: [
        "Login",
        "ForegetPassword",
        "ResetPassword",
        "RequiredPasswordChange",
      ],
      showMenuLog: false,
      showMenuAction: false,
      active: false,
    };
  },
  computed: {
    ...mapState(["user", "api", "$axios_auth"]),
  },

  created() {},

  methods: {
    toggleMenu: function () {
      const nav = document.getElementById("app");
      if (nav.classList.contains("small-menu")) {
        nav.classList.remove("small-menu");
        return;
      }
      nav.classList.add("small-menu");
    },
    clickButton: function (data) {
      this.$socket.emit("emit_method", data);
    },
    showLogsMenu() {
      this.showMenuLog = !this.showMenuLog;
    },
    showActionsMenu() {
      this.showMenuAction = !this.showMenuAction;
    },
    editAccount() {
      this.$router.push({ name: "EditAccount" });
    },
    openMenu(e, id) {
      if (
        e.target.classList.contains("has-inner-menu") ||
        e.target.classList.contains("inner-menu-title") ||
        e.target.classList.contains("icon-graduation-cap") ||
        e.target.classList.contains("icon-survey") ||
        e.target.classList.contains("label")
      )
        document.getElementById(id).classList.toggle("active");
    },
  },
  mounted() {
    var tmp = document.getElementsByName("click-menu");
    for (let index = 0; index < tmp.length; index++) {
      tmp[index].addEventListener("click", function () {
        tmp[index].parentNode.classList.add("display-none");
        tmp[index].classList.add("display-none");
        setTimeout(() => {
          tmp[index].parentNode.classList.remove("display-none");
          tmp[index].classList.remove("display-none");
        }, 100);
      });
    }
  },
};
</script>

<style lang="scss">
@import "../../../assets/css/admin-panel";
@import "../../../assets/css/colors";
.display-none {
  display: none !important;
}
.report-meni {
  &.router-link-active {
    background-color: $background-color !important;
  }
  background-color: transparent !important;
  &:hover {
    background-color: $background-color !important;
  }
  &:focus {
    background-color: $background-color !important;
  }
}
.profile-menu {
  overflow: hidden;
  transition: all 0.5s ease;
  position: relative;
  padding: 0 !important;
  div {
    padding: 0 !important;
  }
  .img-container {
    width: 100%;
    transition: all 0.5s ease;
  }
  &:hover {
    .img-container {
      opacity: 0.2;
      // transform: translateX(-55%);
    }
    .user-menu {
      transform: translateX(0%) translateY(-50%);
    }
  }
  .user-menu {
    transition: all 0.5s ease;
    padding: 0 !important;
    font-size: 2.1em !important;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateX(100%) translateY(-50%);
    width: 100%;
    & > div {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}
// .icon-pencil,
// .icon-logout {
//   color: $white;
//   border: 2px solid rgba($color: $darkPrimaryColor, $alpha: 0.5);
//   &:hover {
//     color: $primaryColor;
//     background-color: $white;
//     cursor: pointer;
//   }
// }

.icon-pencil {
  padding: 8px;
}
</style>
