<template>
  <div>
    <div class="bold mt-10 px-10">
      {{ question.goalNumber + "." + question.questionNumber }}
      {{ question.questionTitle }}
    </div>
    <div
      class="mt-10 question-description px-10"
      v-html="question.questionDescription"
    ></div>
    <div class="mt-20 answers-container">
      <div class="ta-center bold">
        {{ $lang("surveyAnswers.answers") }}
      </div>

      <div class="mt-10" v-for="(obj, index) in question.answers" :key="index">
        <div class="mb-10">
          {{ $lang("surveyAnswers.orgnisation") }}
          <span class="bold">{{ obj.emmausOrgnisationName }}</span>
        </div>
        <textarea
          class="answer-input min-h"
          v-model="obj.text"
          :disabled="true"
        ></textarea>
        <!-- <a
          type="button"
          v-if="hasFile"
          class="warning ml-auto"
          v-lang="'general.download'"
        ></a> -->
      </div>
    </div>
  </div>
</template>

<script>
import store from "./store";

export default {
  props: {
    question: {
      type: Object,
      default: () => {}
    },
    goalNumber: {
      type: Number,
      default: 0
    },
    hasFile: {
      type: Boolean,
      default: false
    },
    fkGoalId: {
      type: Number,
      default: 0
    },
    fkReportYearId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      searchModel: {
        fkGoalId: this.fkGoalId,
        fkReportYearId: this.fkReportYearId
      }
    };
  },
  methods: {
    download(orgId, orgName) {
      this.searchModel.fkEmmausOrganisationId = orgId;
      var $this = this;

      store
        .dispatch("DOWNLOAD", $this.searchModel)
        .then((response) => {
          // $this.allGoals = response.data;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.answer-input {
  width: calc(100% - 15px);
  height: 80px;
}
.mt-10 {
  margin-top: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.question-description {
  text-align: justify;
}
.min-h {
  min-height: 130px;
}
.ta-center {
  text-align: center;
}
.answers-container {
  padding: 10px;
}
.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.ml-auto {
  margin-left: auto;
}
</style>
