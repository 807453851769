<template>
  <div>
    <table id="report_training" style="display:none;">
      <thead>
        <tr>
          <th class="text-align-center">#</th>
          <th>
            {{ $lang("training.emmaus_training_name") }}
            <span></span>
          </th>
          <th>
            {{ $lang("training.label_training_type") }}
            <span></span>
          </th>
          <th>
            {{ $lang("training.training_date") }}
            <span></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(obj, key) in training.list" :key="key">
          <td>
            {{ key + 1 }}
          </td>
          <td>{{ obj.name }}</td>
          <td>{{ obj.typeName }}</td>
          <td>
            {{
              obj.date != null
                ? $moment(obj.date).format("DD.MM.YYYY.")
                : $lang("general.unknow")
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    training: Array,
  },
};
</script>
