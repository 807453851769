<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-radio m-right-5"></i>
        <span v-lang="'notifications.label_broadcast_message'"></span>
      </h1>
      <div class="actions">
        <a class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></a>
      </div>
    </div>
    <div class="card">
      <form v-on:submit.prevent="broadcast()" data-vv-scope="broadcast_notification_form">
        <div class="input m-0">
          <div
            class="input m-0 m-top-10"
            :class="
              errors.first('broadcast_notification_form.messageContent') !==
              undefined
                ? 'input-error'
                : ''
            "
          >
            <label for="note">
              <span v-lang="'notifications.label_message_content'"></span>
            </label>
            <textarea
              name="messageContent"
              cols="30"
              rows="5"
              v-model="model.messageContent"
              v-validate="'required'"
            ></textarea>
            <div>
              <span
                class="error"
                v-html="
                  errors.first('broadcast_notification_form.messageContent')
                "
              ></span>
            </div>
          </div>

          <div class="buttons-search m-top-10" id="sub">
            <button class="primary" v-lang="'notifications.label_broadcast'"></button>
          </div>
        </div>
      </form>
    </div>
    <div class="card">
      <form v-on:submit.prevent="search">
        <div class="input m-0">
          <label for>Pretraga</label>
          <input type="text" v-model="searchModel.content" />
        </div>
        <div class="buttons-search m-top-10 search-emit">
          <button
            type="button"
            class="warning"
            v-on:click="resetSearch($event)"
            v-lang="'general.button_reset'"
          ></button>&nbsp;
          <button class="primary" v-lang="'general.button_search'"></button>
        </div>
      </form>
    </div>
    <NoData v-if="!list || list.length == 0"></NoData>
    <div v-for="(obj, key) in list" :key="key" class="card">
      <p>{{ obj.messageContent }}</p>
      <p>
        <small>{{ $moment(obj.createdOn).format("DD.MM.YYYY.") }}</small>
      </p>
    </div>
    <div class="pagination-container">
      <paginate
        v-if="list.length > 0 && pageCount > 1"
        :page-count="pageCount"
        v-model="searchModel.pageNum"
        :clickHandler="changePage"
        :prev-text="this.$lang('general.previous')"
        :next-text="this.$lang('general.next_page')"
        :container-class="'paggination'"
      ></paginate>
    </div>
  </div>
</template>

<script>
var $this;
import store from "./store";
import Paginate from "vuejs-paginate";
import NoData from "@/components/General/NoData";

export default {
  components: {
    paginate: Paginate,
    NoData
  },
  data() {
    return {
      model: { messageContent: "" },
      searchModel: {},
      pageCount: 0,
      list: [],
      validation_messages: {
        custom: {
          messageContent: {
            required: this.$lang(
              "notifications.message_message_content_cant_be_empty"
            )
          }
        }
      }
    };
  },
  mounted() {
    $this = this;
    this.initSearchModel();
    this.$validator.localize("en", this.validation_messages);
    this.changePage();
  },
  methods: {
    initSearchModel() {
      this.searchModel = {
        pageNum: 1,
        pageOffset: 10,
        content: ""
      };
    },
    resetSearch() {
      this.initSearchModel();
      this.changePage();
    },
    search() {
      this.searchModel.pageNum = 1;
      this.searchModel.pageOffset = 10;
      this.changePage();
    },
    changePage() {
      store
        .dispatch("GET_ALL_BROADCAST_NOTIFICATIONS", this.searchModel)
        .then(response => {
          $this.list = response.data.list;

          $this.pageCount = Math.ceil(
            $this.list[0].totalCount / $this.searchModel.pageOffset
          );
        });
    },
    broadcast() {
      var $this = this;
      this.$validator.validateAll("broadcast_notification_form").then(valid => {
        if (valid) {
          store
            .dispatch("BROADCAST_MESSAGE", {
              model: $this.model,
              notificationModel: {
                FunctionId: 10192
              }
            })
            .then(response => {
              $this.$success(
                "notifications.message_messsage_successfully_broadcasted"
              );

              $this.initSearchModel();
              $this.model = { messageContent: "" };
              $this.changePage();
              $this.$validator.reset();
            })
            .catch(error => {
              if (error.status == 400) $this.errorModel = error.data;
              $this.$error("notifications.message_message_bradcast_failed");
            });
        }
      });
    }
  }
};
</script>
<style lang="scss">
.pagination-container {
  .paggination {
    position: relative !important;
    margin-left: auto;
  }
}
</style>
<style lang="scss" scoped>
form {
  display: grid;
  grid-template: 1fr;
}
p {
  margin: 0;
}
.submit {
  margin-top: 10px !important;
  text-align: right;
}
.pagination-container {
  display: flex;
}
</style>
