<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-file-text"></i>
        <span v-lang="'codes.label_codes'"></span>
      </h1>
      <div class="actions">
        <router-link
          v-if="$role(10124)"
          class="primary"
          :to="{ name: 'CodesForm', params: { id: 0 } }"
          v-lang="'general.label_add'"
        ></router-link>
      </div>
    </div>
    <notify />
    <div class="card table-container">
      <table class="table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th v-lang="'codes.label_name'">&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, key) in codes" :key="key">
            <td>{{ key + 1 }}</td>
            <td>{{ obj.nameOfTypeOfCode }}</td>
            <td>
              <router-link
                v-if="$role(10125)"
                class="default small"
                :to="{
                  name: 'ShowTypeOfCode',
                  params: { id: obj.pkTypesOfCodesId }
                }"
                v-lang="'general.label_details'"
              ></router-link>
              <router-link
                v-if="$role(10130)"
                class="primary small"
                :to="{
                  name: 'CodesForm',
                  params: { id: obj.pkTypesOfCodesId }
                }"
                v-lang="'general.label_edit'"
              ></router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../store";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import Loader2 from "@/components/General/Loader2";

Vue.use(Toasted);

export default {
  components: {
    Loader2
  },
  data() {
    return {
      codes: []
    };
  },
  created() {
    this.getAllCodes();
  },
  methods: {
    getAllCodes() {
      var $this = this;
      store
        .dispatch("GET_All_TYPES_OF_CODES")
        .then(response => {
          $this.codes = response.data.model;
        })
        .catch(error => {});
    }
  }
};
</script>

<style lang="scss" scoped></style>
