<template>
  <div class="container">
    <div class="header card">
      <h1>
        <span v-lang="'entity_relations.entity_relations'"></span>
      </h1>
      <div class="actions">
        <router-link
          class="primary add-relation"
          v-if="$role(10049)"
          :to="{ name: 'EntityRelationTypesForm' }"
          v-lang="'entity_relations.entity_relations_types'"
        ></router-link>
        <router-link
          class="primary"
          v-if="$role(10049)"
          :to="{ name: 'EntityRelationForm' }"
          v-lang="'entity_relations.entity_relations_create'"
        ></router-link>
      </div>
    </div>
    <div class="card table-container">
      <table>
        <thead>
          <tr>
            <th>{{ $lang("entity_relations.entity_type_name") }}</th>
            <th>{{ $lang("entity_relations.entity_Type_Managed_Name") }}</th>
            <th>{{ $lang("entity_relations.relation_Type_Code_Name") }}</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, key) in list" :key="key">
            <td>{{ obj.entityTypeName }}</td>
            <td>{{ obj.entityTypeManagedName }}</td>
            <td>{{ obj.relationTypeCodeName }}</td>
            <td>
              <router-link
                class="small error"
                :to="{
                  name: 'EntityRelationForm',
                  query: {
                    pkFkEntityTypeId: obj.pkFkEntityTypeId,
                    pkFkEntityTypeIdManaged: obj.pkFkEntityTypeIdManaged,
                    pkFkRelationTypeId: obj.pkFkRelationTypeId
                  }
                }"
                >{{ $lang("general.label_delete") }}</router-link
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import store from "./store";
export default {
  data() {
    return {
      list: []
    };
  },
  created() {
    this.search();
  },
  methods: {
    search() {
      var $this = this;
      store.dispatch("GET_ENTITY_RELATIONS").then(response => {
        $this.list = response.list;
      });
    }
  },
  computed: {}
};
</script>
<style lang="scss" scoped>
.add-relation {
  margin-right: 10px;
}
</style>
