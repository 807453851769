<template>
  <div>
    <div class="container">
      <div class="header card">
        <h1>
          <i class="icon-bilteni"></i>
          <span v-lang="'menu.menu_files_management'"></span>
        </h1>
        <div class="actions">
          <a
            class="primary"
            v-on:click="clickBack()"
            v-lang="'general.button_back'"
          ></a>
        </div>
      </div>

      <!-- Search -->
      <div class="card">
        <form
          class="search"
          action
          v-on:submit.prevent="search()"
          v-responsive="{ small: (el) => el.width < 650 }"
        >
          <div class="input m-0">
            <label
              for="fkEmmausOrganisationId"
              v-lang="'surveyAnswers.orgnisation'"
            ></label>
            <select
              name="fkEmmausOrganisationId"
              v-model="searchModel.fkEmmausOrganisationId"
              v-validate="'required|excluded:null'"
            >
              <option
                :value="null"
                v-lang="'surveyAnswers.placeholder_for_organization'"
              ></option>
              <option
                v-for="(obj, key) in organizations"
                :key="key"
                :value="obj.pkEmmausOrganizationId"
              >
                {{ obj.name }}
              </option>
            </select>
          </div>
          <div class="input m-0">
            <label for="fkGoalId" v-lang="'surveyAnswers.select_goal'"></label>
            <select
              name="fkGoalId"
              v-model="searchModel.fkGoalId"
              v-validate="'required|excluded:null'"
            >
              <option
                :value="null"
                v-lang="'surveyAnswers.placeholder_for_goal'"
              ></option>
              <option
                v-for="(obj, key) in allGoals"
                :key="key"
                :value="obj.pkGoalId"
              >
                {{
                  $lang("survey.label_goal").toUpperCase() +
                  " " +
                  obj.number +
                  " - " +
                  $lang("surveyTemplates." + obj.titleId).toUpperCase()
                }}
              </option>
            </select>
          </div>
          <div class="input m-0">
            <label
              for="fkReportYearId"
              v-lang="'surveyAnswers.select_year'"
            ></label>
            <select
              name="fkReportYearId"
              v-model="searchModel.fkReportYearId"
              v-validate="'required|excluded:null'"
            >
              <option
                :value="null"
                v-lang="'surveyAnswers.placeholder_for_year'"
              ></option>
              <option
                v-for="(obj, key) in reportYears"
                :key="key"
                :value="obj.pkReportYearId"
              >
                {{ obj.year }}
              </option>
            </select>
          </div>
          <div class="buttons-search">
            <button
              type="button"
              class="warning"
              v-on:click="resetSearch($event)"
              v-lang="'general.button_reset'"
            ></button
            >&nbsp;
            <!-- <button class="primary" v-lang="'general.button_search'"></button> -->
          </div>
        </form>
      </div>

      <!-- TABLE -->
      <Loader2 v-if="!resultsLoaded" />
      <NoData v-else-if="!fileAnswers || fileAnswers.length == 0"></NoData>
      <span v-else>
        <div class="card table-container scroll">
          <table class="table-hover">
            <thead>
              <tr :id="tableId">
                <th>#</th>
                <th>
                  {{ $lang("surveyFiles.file_name") }}
                  <span></span>
                </th>
                <th>
                  {{ $lang("surveyFiles.organization") }}
                  <span></span>
                </th>
                <th>
                  {{ $lang("surveyFiles.goal") }}
                  <span></span>
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(obj, key) in fileAnswers" :key="key">
                <td>
                  {{
                    key + 1 + (searchModel.pageNum - 1) * searchModel.pageSize
                  }}
                </td>

                <td>{{ obj.fileName }}</td>
                <td>
                  {{ obj.emmausOrganizationName }}
                </td>
                <td>
                  {{ obj.goalNumber + "." + obj.goalName }}
                </td>
                <td>
                  <a
                    v-if="$role(50006)"
                    class="primary small"
                    v-lang="'general.download'"
                    :href="
                      mainStore.state.core_api +
                      'api/FileAnswer/download-file-answer/' +
                      obj.filePath
                    "
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination-container">
            <paginate
              v-if="fileAnswers.length != 0 && pageCount > 1"
              :page-count="pageCount"
              :click-handler="changePage"
              v-model="searchModel.pageNum"
              :prev-text="this.$lang('general.previous')"
              :next-text="this.$lang('general.next_page')"
              :container-class="'paggination'"
            ></paginate>
          </div>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import store from "./store";
import NoData from "@/components/General/NoData";
import Loader2 from "../../../General/Loader2";
import goalStore from "../../store";
import yearStore from "../YearManagment/store";
import mainStore from "@/store";
import { ResponsiveDirective } from "vue-responsive-components";
import Paginate from "vuejs-paginate";
import emmausOrgaizationStore from "../../../EMMAUS/Organisation/store";
export default {
  directives: {
    responsive: ResponsiveDirective
  },
  components: {
    Loader2,
    NoData,
    Paginate
  },
  data() {
    return {
      allGoals: [],
      reportYears: [],
      searchModel: {
        pageNum: this.$route.query.pageNum
          ? Number(this.$route.query.pageNum)
          : 1,
        pageSize: this.$route.query.pageSize
          ? Number(this.$route.query.pageSize)
          : 10,
        fkGoalId: this.$route.query.fkGoalId
          ? Number(this.$route.query.fkGoalId)
          : null,
        fkReportYearId: this.$route.query.fkReportYearId
          ? Number(this.$route.query.fkReportYearId)
          : null,
        fkEmmausOrganisationId: this.$route.query.fkEmmausOrganisationId
          ? Number(this.$route.query.fkEmmausOrganisationId)
          : null
      },
      pageCount: 1,
      fileAnswers: [],
      hasFile: false,
      resultsLoaded: true,
      organizations: [],
      organizationsSearchModel: {
        page: 1,
        limit: 1000000,
        orderColumn: "",
        orderSort: ""
      }
    };
  },
  beforeDestroy() {
    this.$removeToast();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeName = from.name;
      vm.query = from.query;
      vm.nameRoute = from.name;
    });
  },
  created() {
    this.getAllReportYears();
    this.getAllGoals();
    if (this.searchModel.fkReportYearId) {
      this.search();
    }
    this.getAllOrganizations();
  },
  methods: {
    clickBack() {
      this.$router.push({
        name: this.nameRoute,
        query: Object.assign({}, this.query)
      });
    },
    search() {
      this.$router.push({
        query: { ...this.$route.query, ...this.searchModel }
      });
      var $this = this;
      $this.resultsLoaded = false;
      store
        .dispatch("SEARCH", $this.searchModel)
        .then((response) => {
          // $this.allGoals = response.data;
          $this.resultsLoaded = true;
          $this.fileAnswers = response.data.fileAnswers;
          $this.pageCount = response.data.totalPages;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    getAllGoals() {
      var $this = this;
      goalStore
        .dispatch("GET_ALL_GOALS")
        .then((response) => {
          $this.allGoals = response.data;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    getAllReportYears() {
      var $this = this;
      // $this.resultsLoaded = false;
      yearStore
        .dispatch("GET_ALL_REPORT_YEARS")
        .then((response) => {
          // $this.resultsLoaded = true;
          $this.reportYears = response.data;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    resetSearch() {
      this.searchModel.fkGoalId = null;
      this.searchModel.fkReportYearId = null;
      this.searchModel.fkEmmausOrganisationId = null;
      this.fileAnswers = [];
    },
    changePage(pageNum) {
      this.searchModel.page = pageNum;
      this.search();
    },
    getAllOrganizations() {
      var $this = this;
      emmausOrgaizationStore
        .dispatch("SEARCH_EMMAUS_ORGANIZATION", $this.organizationsSearchModel)
        .then((response) => {
          $this.organizations = response.data.list;
        })
        .catch((error) => {});
    }
  },
  computed: {
    mainStore: {
      get() {
        return mainStore;
      }
    }
  },
  watch: {
    "searchModel.fkGoalId"(newVal) {
      if (this.searchModel.fkReportYearId) {
        this.search();
      }
    },
    "searchModel.fkReportYearId"(newVal) {
      if (this.searchModel.fkReportYearId) {
        this.search();
      }
    },
    "searchModel.fkEmmausOrganisationId"(newVal) {
      if (this.searchModel.fkReportYearId) {
        this.search();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.buttons-search {
  grid-column: 1/4;
}
.actions {
  position: relative;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  .buttons-search {
    grid-column: 1/3;
    text-align: right;
  }
}
.form.small {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 10px;
  .buttons-search {
    grid-column: 1/2;
    text-align: right;
  }
}
.center-nodata {
  margin: 0 5px 0 5px;
}
.search {
  grid-template-columns: 1fr 1fr 1fr !important;
}
.mb-20 {
  margin-bottom: 20px;
}
.pagination-container {
  margin: 0 !important;
}
</style>
