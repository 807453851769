import Index from "./Index.vue";
import AdminLayout from "../../../UserAndRolesManagement/AdminPanel/AdminLayout.vue";
import auth from "@/middleware";

const router = [
  {
    path: "/survey/answers",
    name: "AdminLayout",
    component: AdminLayout,
    meta: {
      middleware: auth,
    },
    children: [
      {
        path: "index",
        name: "AnswersIndex",
        component: Index,
      }
    ],
  },
];

export default router;
