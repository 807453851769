<template>
  <div class="container">
    <div class="login-form">
      <h1>
        <content v-lang="'forms.reset-password'"></content>
        <p v-lang="'forms.insert_new_password'"></p>
        <span></span>
      </h1>
      <form v-on:submit.prevent="sendData()" data-vv-scope="password_form">
        <div
          class="input"
          :class="
            errors.first('password_form.newPassword') !== undefined
              ? 'input-error'
              : ''
          "
        >
          <label for="password" v-lang="'forms.new_password'"></label>
          <input
            v-model="model.newPassword"
            name="newPassword"
            v-validate="'required'"
            ref="password"
            type="password"
            autocomplete="off"
          />
          <div
            class="error"
            v-html="errors.first('password_form.newPassword')"
          ></div>
        </div>
        <div
          class="input"
          :class="
            errors.first('password_form.confirm_password') !== undefined
              ? 'input-error'
              : ''
          "
        >
          <label
            for="confirm_password"
            v-lang="'forms.confirm_new_password'"
          ></label>
          <input
            v-model="model.confirm_password"
            type="password"
            name="confirm_password"
            v-validate="'required|confirmed:password'"
            autocomplete="off"
          />
          <div
            class="error"
            v-html="errors.first('password_form.confirm_password')"
          ></div>
        </div>
        <div class="error error-message" v-html="error"></div>
        <div class="button-container">
          <button class="primary login right-angle">
            <content v-lang="'forms.submit'"></content>
            <span></span>
          </button>
          <!--<router-link class="forgot-password" :to="{ name:'Login'}" v-lang="'forms.back_to_login'"></router-link>-->
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { ToastSuccess, ToastError } from "@/global-functions";
import { lang } from "@/global-functions";
import globalStore from "@/store";
import store from "@/components/UserAndRolesManagement/store";
export default {
  data() {
    return {
      model: {
        newPassword: ""
      },
      validation_messages: {
        custom: {
          confirm_password: {
            confirmed: lang("user.passwords_dont_match"),
            required: lang("validation.required")
          },
          newPassword: {
            required: lang("validation.required")
          }
        }
      },
      error: ""
    };
  },
  created() {
    if (!globalStore.state.user.user.requiredPasswordChange)
      this.$router.push("/");
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  methods: {
    sendData() {
      var $this = this;
      this.$validator.validateAll("password_form").then((valid) => {
        if (valid) {
          globalStore.commit("SHOW_LOADER");
          store
            .dispatch("CHANGE_PASSWORD_REQUIRED", $this.model)
            .then((response) => {
              $this.$success(response.data.message);
              store
                .dispatch("GET_CURRENT_USER_ATUH", this.token)
                .then((response) => {
                  console.log("response:", response.data);
                  globalStore.commit("SET_USER", response.data);
                  localStorage.setItem("user", JSON.stringify(response.data));
                  if (response.data.user.superAdmin)
                    $this.$router.push({ name: "AdminHome" });
                  else $this.$router.push({ name: "Login" });
                })
                .catch((error) => {});
            })
            .catch((error) => {
              if (error.status == 400)
                this.error = lang(error.data.modelState.newPassword[0]);
              // this.$importModelStateErrors(
              //   this.errors,
              //   error.data.modelState,
              //   "password_form"
              // );
            })
            .finally(() => {
              globalStore.commit("HIDE_LOADER_500");
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/colors";
.error-message {
  margin-left: 10px;
  width: 360px;
}
.container {
  display: grid;
  height: 100vh;
  background: $secondaryColor;
  position: fixed;
  width: 100%;
  justify-items: center;
  align-items: center;
  .user-data {
  }
  hr {
    background-color: $primaryColor;
    width: 95%;
    height: 1px;
    border: 0;
  }
  h1 {
    width: calc(65% - 30px);
    color: white;
    background: $primaryColor;
    margin: 0;
    margin-bottom: 15px;
    padding: 10px 15px;
    position: relative;
    z-index: 10;
    p {
      font-size: 10px;
      margin: 0;
      font-weight: 100;
      letter-spacing: -0px;
    }
    // border-top-left-radius: 10px;
    span {
      border-right: 30px solid transparent;
      border-top: 64px solid $primaryColor;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -30px;
    }
    &:hover {
      background: $primaryColor;
      color: $secondaryColor;
    }
  }
  .login-form {
    background: white;
    // border-radius: 10px;
    min-width: 400px;
  }
  .button-container {
    line-height: 39px;
    a {
      padding: 10px;
      font-size: 0.8rem;
      text-decoration: none;
      color: $primaryColor;
      &:hover {
        text-decoration: underline;
      }
    }

    button.login {
      float: right;
      width: calc(35% - 30px);
      position: relative;
      height: 40px;
      cursor: pointer;
      span {
        border-left: 20px solid transparent;
        border-bottom: 40px solid $primaryColor;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -20px;
      }
      &:hover {
        background: $primaryColor;
        color: $secondaryColor;
      }
    }
  }
}
.input > label {
  color: black;
}
</style>
