var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"header card"},[_c('h1',[_c('i',{staticClass:"icon-key"}),_c('span',{directives:[{name:"lang",rawName:"v-lang",value:('menu.menu_item_roles'),expression:"'menu.menu_item_roles'"}]})]),_c('div',{staticClass:"actions"},[(_vm.$role(10117))?_c('router-link',{directives:[{name:"lang",rawName:"v-lang",value:('general.label_add'),expression:"'general.label_add'"}],staticClass:"primary",attrs:{"to":{ name: 'RoleEditCreate' }}}):_vm._e()],1)]),_c('notify',{key:_vm.notifyKey}),(!_vm.roles || _vm.roles.length == 0)?_c('NoData'):_c('div',{staticClass:"card table-container"},[_c('table',{staticClass:"table-hover"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',{directives:[{name:"lang",rawName:"v-lang",value:('general.label_name'),expression:"'general.label_name'"}]},[_vm._v(" ")]),_c('th',{directives:[{name:"lang",rawName:"v-lang",value:('general.label_description'),expression:"'general.label_description'"}]},[_vm._v(" ")]),_c('th',[_vm._v(" ")])])]),_c('tbody',_vm._l((_vm.paginatedRoles),function(obj,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key + 1))]),_c('td',[_vm._v(_vm._s(obj.name))]),_c('td',[_vm._v(_vm._s(obj.description))]),_c('td',[(_vm.$role(10121))?_c('router-link',{directives:[{name:"lang",rawName:"v-lang",value:('general.label_details'),expression:"'general.label_details'"}],staticClass:"default small",attrs:{"to":{ name: 'RoleDetails', params: { id: obj.pkRoleId } }}}):_vm._e(),(
                _vm.$role(10118) &&
                  obj.pkRoleId != 1 &&
                  obj.pkRoleId != 3 &&
                  obj.pkRoleId != 4
              )?_c('router-link',{directives:[{name:"lang",rawName:"v-lang",value:('general.label_edit'),expression:"'general.label_edit'"}],staticClass:"primary small",attrs:{"to":{ name: 'RoleEditCreate', params: { id: obj.pkRoleId } }}}):_vm._e(),(
                _vm.$role(10119) &&
                  obj.pkRoleId != 1 &&
                  obj.pkRoleId != 3 &&
                  obj.pkRoleId != 4
              )?_c('a',{directives:[{name:"lang",rawName:"v-lang",value:('general.label_delete'),expression:"'general.label_delete'"}],staticClass:"error small",on:{"click":function($event){return _vm.deleteRole(obj.pkRoleId)}}}):_vm._e()],1)])}),0)])]),_c('div',{staticClass:"pagination-container"},[(_vm.pageCount > 1)?_c('paginate',{attrs:{"page-count":_vm.pageCount,"click-handler":_vm.changePage,"prev-text":this.$lang('general.previous'),"next-text":this.$lang('general.next_page'),"container-class":'paggination'}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }