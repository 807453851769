<template>
  <div :class="'container'">
    <div class="header card">
      <h1>
        <span v-lang="'emmaus_persons.label_person_details'"></span>
      </h1>
      <div class="actions">
        <button
          class="primary"
          v-on:click="Export2Excel()"
          v-lang="'victims.export_excel'"
        ></button>
        <button
          class="error"
          v-on:click="Export2PDF"
          v-lang="'general.report-print'"
        ></button>
        <button
          class="primary"
          v-on:click="$router.go(-1)"
          v-lang="'general.button_back'"
        ></button>
      </div>
    </div>
    <Loader2 v-if="loading" />
    <div v-else class="card table-container">
      <table>
        <tr>
          <td style="width: 50%" v-lang="'general.first_name'"></td>
          <td>{{ model.firstName }}</td>
        </tr>
        <tr>
          <td v-lang="'general.last_name'"></td>
          <td>{{ model.lastName }}</td>
        </tr>
        <tr>
          <td v-lang="'emmaus.emmaus_institution'"></td>
          <td>{{ model.emmausInstitutionName }}</td>
        </tr>
        <tr>
          <td v-lang="'general.gender'"></td>
          <td>{{ model.genderName }}</td>
        </tr>
        <!-- <tr>
          <td v-lang="'emmaus.emmaus_additional_question_one'"></td>
          <td>
            {{
              model.additionalQuestionOne
                ? $lang("general.label_yes")
                : $lang("general.label_no")
            }}
          </td>
        </tr>
        <tr>
          <td v-lang="'emmaus.emmaus_additional_question_two'"></td>
          <td>
            {{
              model.additionalQuestionTwo
                ? $lang("general.label_yes")
                : $lang("general.label_no")
            }}
          </td>
        </tr> -->
        <tr>
          <td v-lang="'emmaus.createdBy'"></td>
          <td>{{ model.createdByName }}</td>
        </tr>
        <tr>
          <td v-lang="'emmaus.emmaus_createdOn'"></td>
          <td>{{ $moment(model.createdOn).format("DD.MM.YYYY.") }}</td>
        </tr>
      </table>
    </div>
    <div class="header card" v-if="!loading">
      <h1>
        <span v-lang="'training.label_trainings'"></span>
      </h1>
    </div>
    <div class="card table-container" v-if="!loading">
      <NoData
        v-if="!model.emmausTrainings || model.emmausTrainings.length == 0"
      ></NoData>
      <span v-else>
        <TableTraining
          :trainings="filteredTrainings"
          :searchModel="searchModel"
          @searchSort="searchSort"
          :orderColumn="searchModel.orderColumn"
          :orderSort="searchModel.orderSort"
        />
        <div class="pagination-container table-paggination">
          <paginate
            v-if="filteredTrainings.length != 0 && pageCount > 1"
            :page-count="pageCount"
            v-model="searchModel.page"
            :click-handler="changePage"
            :prev-text="this.$lang('general.previous')"
            :next-text="this.$lang('general.next_page')"
            :container-class="'paggination'"
          ></paginate>
        </div>
      </span>
    </div>
    <div class="header card" v-if="!loading">
      <h1>
        <span v-lang="'emmaus.label_person_institutions'"></span>
      </h1>
    </div>
    <div class="card table-container" v-if="!loading">
      <NoData
        v-if="!model.personInstitutions || model.personInstitutions.length == 0"
      ></NoData>
      <span v-else>
        <table class="table-hover" id="report_person">
          <thead>
            <tr>
              <th>
                #
              </th>
              <th>
                {{ $lang("general.label_name") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(obj, key) in model.personInstitutions" :key="key">
              <td>{{ key + 1 }}</td>
              <td class="left-align">{{ obj.name }}</td>
            </tr>
          </tbody>
        </table>
      </span>
    </div>
    <DetialsReport
      :model="model"
      :trainings="model.emmausTrainings"
    ></DetialsReport>
  </div>
</template>

<script>
import Vue from "vue";
import store from "./store";
import globalStore from "@/store";
import moment from "moment";
import { lang } from "@/global-functions";
import NoData from "../../General/NoData";
import TableTraining from "../Traning/Table.vue";
import Paginate from "vuejs-paginate";
import Loader2 from "../../General/Loader2";
import DetialsReport from "./DetilsReport.vue";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Arimo } from "@/assets/fonts/Arimo-Regular-normal.js";
import { ArimoBold } from "@/assets/fonts/Arimo_Bold.js";

import { mapState } from "vuex";

export default {
  components: {
    NoData,
    TableTraining,
    Paginate,
    Loader2,
    DetialsReport,
  },
  data() {
    return {
      searchModel: {
        page: 1,
        limit: 5,
        orderColumn: "",
        orderSort: "",
      },
      moment: moment,
      model: {},
      loading: true,
    };
  },
  computed: {
    filteredTrainings() {
      return this.$_.chunk(this.model.emmausTrainings, this.searchModel.limit)[
        this.searchModel.page - 1
      ];
    },
    pageCount() {
      return this.$_.chunk(this.model.emmausTrainings, this.searchModel.limit)
        .length;
      // var resultCount = this.model.emmausTrainings.length;
      // return parseInt(
      //   resultCount > this.searchModel.limit
      //     ? resultCount / this.searchModel.limit + 1
      //     : resultCount / this.searchModel.limit
      // );
    },
  },
  created() {
    if (this.$route.query.pkEmmausPersonId != null) this.getPersonById();
  },
  methods: {
    changePage: function(page) {
      this.searchModel.page = page;
    },
    searchSort(orderColumn, orderSort) {
      this.searchModel.orderColumn = orderColumn;
      this.searchModel.orderSort = orderSort;
    },
    getPersonById() {
      var $this = this;
      this.loading = true;
      store
        .dispatch(
          "GET_EMMAUS_PERSON_BY_ID",
          $this.$route.query.pkEmmausPersonId
        )
        .then((response) => {
          $this.model = response.data.model;
          $this.loading = false;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    Export2Excel() {
      var $this = this;
      var title = $this.$lang("emmaus_persons.title_report_details_person");

      var element = document.getElementsByTagName("td");
      for (let i = 0; i < element.length; i++) {
        element[i].style.verticalAlign = "middle";
      }

      // DETALJI
      var tab_text = "<table ><tr border='2px'>";
      var textRange;
      var j = 0;

      var tab = document.getElementById("my-table"); // id of table
      var clspan = tab.rows[0].innerHTML.split("<th").length - 1;

      tab_text +=
        "<th  bgcolor='#87AFC6' colspan='2' id='activity'>" + title + "</tr>";

      for (j = 1; j < tab.rows.length; j++) {
        tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";

        //tab_text=tab_text+"</tr>";
      }

      //OBUKE
      tab_text += "<tr></tr>";
      tab_text += "<tr></tr>";
      tab_text += "<tr></tr>";
      textRange;
      j = 0;

      tab = document.getElementById("details_trainig_person"); // id of table
      clspan = tab.rows[0].innerHTML.split("<th").length - 1;

      tab_text +=
        "<th  bgcolor='#87AFC6' colspan='4' id='activity'>" +
        $this.$lang("training.label_trainings") +
        "</tr>";

      for (j = 1; j < tab.rows.length; j++) {
        tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";

        //tab_text=tab_text+"</tr>";
      }

      //INSTITUCIJE
      tab_text += "<tr></tr>";
      tab_text += "<tr></tr>";
      tab_text += "<tr></tr>";
      textRange;
      j = 0;

      tab = document.getElementById("details_institutions_person"); // id of table
      clspan = tab.rows[0].innerHTML.split("<th").length - 1;

      tab_text +=
        "<th  bgcolor='#87AFC6' colspan='2' id='activity'>" +
        $this.$lang("emmaus.label_person_institutions") +
        "</tr>";

      for (j = 1; j < tab.rows.length; j++) {
        tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";

        //tab_text=tab_text+"</tr>";
      }

      tab_text = tab_text.replace(
        /<td/gi,
        "<td class='xl70' width='300' align='center' vertical-align:'top' "
      );
      tab_text = tab_text.replace(/12-17/gi, "&nbsp;12-17");
      tab_text = tab_text + "</table>";

      tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, ""); //remove if u want links in your table
      tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
      tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");

      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        // If Internet Explorer
        txtArea1.document.open("txt/html", "replace");
        txtArea1.document.write(tab_text);
        txtArea1.document.close();
        txtArea1.focus();
        sa = txtArea1.document.execCommand(
          "SaveAs",
          true,
          "Say Thanks to Sumit.xls"
        );
      } //other browser not tested on IE 11
      else {
        var a = window.document.createElement("a");
        a.setAttribute(
          "href",
          "data:attachment/xlsx;charset=utf-8,%EF%BB%BF" +
            encodeURIComponent(tab_text)
        );
        a.setAttribute("download", title + ".xls");
        window.document.body.appendChild(a);
        a.click();
      }
    },
    async Export2PDF(element) {
      var element = "body";
      var doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
      });

      var $this = this;
      doc.addFileToVFS("Arimo-Regular.ttf", Arimo);
      doc.addFont("Arimo-Regular.ttf", "Arimo", "normal");
      doc.setFont("Arimo");

      doc.addFileToVFS("Arimo-Bold.ttf", ArimoBold);
      doc.addFont("Arimo-Bold.ttf", "Arimo", "bold");

      var footer = async function(data) {};

      var title =
        $this.$lang("emmaus_persons.title_report_details_person") +
        " " +
        $this.model.firstName +
        " " +
        $this.model.lastName;

      var outputName = title + ".pdf";

      var Font = 8;
      Font =
        $this.$route.query.id == 42901 ||
        $this.$route.query.id == 42898 ||
        $this.$route.query.id == 42899
          ? 7
          : Font;

      var pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      var number_of_pages = doc.internal.getNumberOfPages();

      // TABELA
      doc.autoTable({
        styles: {
          fontSize: Font,
          font: "Arimo",
          halign: "left",
          lineColor: [44, 62, 80],
          lineWidth: 0.55,
        },
        headStyles: {
          halign: "left",
          fontStyle: "Arimo",
          fontSize: 12,
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineColor: [255, 255, 255],
        },
        columnStyles: {
          0: {
            halign: "left",
            valign: "middle",
            cellWidth: 100,
          },
          1: {
            halign: "left",
            valign: "middle",
            cellWidth: "auto",
            fontStyle: "bold",
          },
        },
        html: "#my-table",

        didParseCell: function(HookData) {
          //
          // // if (HookData.row.raw.id == "1") {
          //   HookData.cell.styles.textColor = [0, 0, 0];
          //   HookData.cell.styles.fontSize = 9;
          //   HookData.cell.styles.fillColor = [192, 192, 192];
          // }
          // if (HookData.row.raw.id == "2") {
          //   HookData.cell.styles.fontSize = 13;
          // }
          if (HookData.row.raw.id == "countryRow") {
            var rows = HookData.table.body;

            HookData.cell.styles.textColor = [0, 0, 0];
            HookData.cell.styles.fontSize = 10;
            HookData.cell.styles.fillColor = [176, 224, 230];
          }
        },
        drawHeader: function(HookData) {
          return false;
        },

        didDrawPage: async function(data) {
          data.settings.margin.top = 5;
          var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
          var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

          if (data.pageNumber === 1) {
            var splitTitle = doc.splitTextToSize(title, 250);
            var dim = doc.getTextDimensions(title);

            var yearsPos = dim.w > 179 ? 64 : 57;
            doc.setFontSize(13);
            doc.setTextColor(60);
            doc.text(splitTitle, pageWidth / 2, 15, "center");
          }
        },
        didDrawCell: function(HookData) {},
        margin: { top: 30 },
        rowPageBreak: "avoid",
        pageBreak: "avoid",
      });

      // OBUKE
      if (this.model.emmausTrainings.length > 0) {
        doc.text(
          20,
          doc.lastAutoTable.finalY + 10,
          this.$lang("training.label_trainings")
        );
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 15,
          styles: {
            fontSize: Font,
            font: "Arimo",
            halign: "left",
            lineColor: [44, 62, 80],
            lineWidth: 0.55,
          },
          headStyles: {
            halign: "left",
            fontStyle: "Arimo",
            fontSize: 12,
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            lineColor: [255, 255, 255],
          },
          columnStyles: {
            0: {
              halign: "left",
              valign: "middle",
              cellWidth: 10,
            },
            1: {
              halign: "left",
              valign: "middle",
              cellWidth: "auto",
            },
          },
          html: "#details_trainig_person",

          didParseCell: function(HookData) {
            //
            // // if (HookData.row.raw.id == "1") {
            //   HookData.cell.styles.textColor = [0, 0, 0];
            //   HookData.cell.styles.fontSize = 9;
            //   HookData.cell.styles.fillColor = [192, 192, 192];
            // }
            // if (HookData.row.raw.id == "2") {
            //   HookData.cell.styles.fontSize = 13;
            // }
            if (HookData.row.raw.id == "countryRow") {
              var rows = HookData.table.body;

              HookData.cell.styles.textColor = [0, 0, 0];
              HookData.cell.styles.fontSize = 10;
              HookData.cell.styles.fillColor = [176, 224, 230];
            }
          },
          drawHeader: function(HookData) {
            return false;
          },

          didDrawPage: async function(data) {
            data.settings.margin.top = 5;
            var pageHeight =
              doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            var pageWidth =
              doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

            if (data.pageNumber === 1) {
              var splitTitle = doc.splitTextToSize(title, 250);
              var dim = doc.getTextDimensions(title);

              var yearsPos = dim.w > 179 ? 64 : 57;
              doc.setFontSize(13);
              doc.setTextColor(60);
              doc.text(splitTitle, pageWidth / 2, 15, "center");
            }
          },
          didDrawCell: function(HookData) {},
          margin: { top: 30 },
          rowPageBreak: "avoid",
          pageBreak: "avoid",
        });
      }

      // INSTITUCIJE
      if (this.model.personInstitutions.length > 0) {
        doc.text(
          20,
          doc.lastAutoTable.finalY + 10,
          this.$lang("emmaus.label_person_institutions")
        );
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 15,
          styles: {
            fontSize: Font,
            font: "Arimo",
            halign: "left",
            lineColor: [44, 62, 80],
            lineWidth: 0.55,
          },
          headStyles: {
            halign: "left",
            fontStyle: "Arimo",
            fontSize: 12,
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            lineColor: [255, 255, 255],
          },
          columnStyles: {
            0: {
              halign: "left",
              valign: "middle",
              cellWidth: 10,
            },
            1: {
              halign: "left",
              valign: "middle",
              cellWidth: "auto",
            },
          },
          html: "#details_institutions_person",

          didParseCell: function(HookData) {
            //
            // // if (HookData.row.raw.id == "1") {
            //   HookData.cell.styles.textColor = [0, 0, 0];
            //   HookData.cell.styles.fontSize = 9;
            //   HookData.cell.styles.fillColor = [192, 192, 192];
            // }
            // if (HookData.row.raw.id == "2") {
            //   HookData.cell.styles.fontSize = 13;
            // }
            if (HookData.row.raw.id == "countryRow") {
              var rows = HookData.table.body;

              HookData.cell.styles.textColor = [0, 0, 0];
              HookData.cell.styles.fontSize = 10;
              HookData.cell.styles.fillColor = [176, 224, 230];
            }
          },
          drawHeader: function(HookData) {
            return false;
          },

          didDrawPage: async function(data) {
            data.settings.margin.top = 5;
            var pageHeight =
              doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            var pageWidth =
              doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

            if (data.pageNumber === 1) {
              var splitTitle = doc.splitTextToSize(title, 250);
              var dim = doc.getTextDimensions(title);

              var yearsPos = dim.w > 179 ? 64 : 57;
              doc.setFontSize(13);
              doc.setTextColor(60);
              doc.text(splitTitle, pageWidth / 2, 15, "center");
            }
          },
          didDrawCell: function(HookData) {},
          margin: { top: 30 },
          rowPageBreak: "avoid",
          pageBreak: "avoid",
        });
      }

      //doc.deletePage(number_of_pages);
      number_of_pages = doc.internal.getNumberOfPages();
      for (var i = 1; i <= number_of_pages; i++) {
        doc.setPage(i);
        doc.setFontSize(12);
        doc.text(i + "/" + number_of_pages, 10, 290);
        if (i < number_of_pages) {
          doc.setFontSize(9);
          // doc.text(
          //   $this.$lang("reports.date_of_print") +
          //     " " +
          //     $this.$moment($this.today).format("DD.MM.YYYY hh:mm:ss"),
          //   pageWidth / 2 + 34,
          //   doc.internal.pageSize.height - 10
          // );
          // doc.text(
          //   $this.user.user.firstName + " " + $this.user.user.lastName,
          //   pageWidth / 2 + 58,
          //   doc.internal.pageSize.height - 5
          // );
        }
      }
      // doc.text(
      //   "_______________________",
      //   pageWidth / 2 + 34,
      //   doc.internal.pageSize.height - 14
      // );
      // doc.setFontSize(9);
      // doc.text(
      //   $this.user.user.firstName + " " + $this.user.user.lastName,
      //   pageWidth / 2 + 58,
      //   doc.internal.pageSize.height - 10
      // );
      // doc.setFontSize(9);
      // doc.text(
      //   $this.$lang("reports.date_of_print") +
      //     " " +
      //     $this.$moment($this.today).format("DD.MM.YYYY hh:mm:ss"),
      //   pageWidth / 2 + 34,
      //   doc.internal.pageSize.height - 5
      // );

      await doc.save(outputName);

      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        window.open(doc.output("bloburl"), "_blank");
      }
      $this.showLoader = false;
      $this.templateData = null;
      $this.selectedReportNumber = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
td:nth-child(2) {
  font-weight: bold;
}
.functions-description {
  font-size: 1.4em;
  padding-bottom: 20px;
}
.fieldset-div {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
fieldset {
  margin-bottom: 10px;
  legend {
    text-transform: uppercase;
  }
}
.table-paggination {
  margin: 0 !important;
}
</style>
