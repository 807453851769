<template>
  <div>
    <div>
      <div>
        <div class="calendar-header">
          <button type="button" :disabled="!showPrev" v-on:click="prevYear()">&lsaquo;</button>
          <div>
            {{ Number(date.format("YYYY")) - 11>1900?Number(date.format("YYYY")) - 11:1900}} -
            {{ Number(date.format("YYYY")) }}
          </div>
          <button type="button" v-on:click="nextYear()">&rsaquo;</button>
        </div>
        <div class="calendar year">
          <span v-for="(str, key) in 12" :key="key">
            <div
              v-if="(lastYear - 11 + key)>1900"
              v-on:click="clickYear((lastYear - 11 + key )>1900?(lastYear - 11 + key ):1900)"
            >{{ (lastYear - 11 + key)>1900?(lastYear - 11 + key ):1900}}</div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment-with-locales-es6";

export default {
  props: {
    locale: {
      type: String,
      default: "sr"
    },
    format: {
      type: String,
      default: "DD.MM.YYYY."
    }
  },
  data() {
    return {
      model: null,
      trayout: null,
      date: null,
      last: 0,
      lastYear: 0,
      first_day_of_week: null,
      month: 0,
      showPrev: true
    };
  },
  methods: {
    clickYear(year) {
      this.$emit("changeYear", year);
    },
    clickShowMount() {
      if (!this.showMonth) {
        this.showMonth = true;
        this.showDay = false;
      } else {
        this.showMonth = false;
        this.showDay = true;
      }
    },
    nextYear() {
      this.date.add(12, "Y").format(this.format);
      this.init();
    },
    prevYear() {
      this.date.add(-12, "Y").format(this.format);
      this.init();
    },
    init() {
      this.lastYear = this.date.format("YYYY");
      if (this.lastYear < 1900 + 20) this.showPrev = false;
      else this.showPrev = true;
    },
    setDate(y, m, d) {
      this.date = this.date.set("date", d);
      this.model = this.date.format(this.format);
      this.init();
    }
  },

  created() {
    moment.locale(this.locale);
    this.model = moment().locale(this.locale);
    this.date = this.model;
    this.model = this.model.format(this.format);
    this.init();
  }
};
</script>
<style scoped>
.calendar.month,
.calendar.year {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 294px;
}
.calendar.month div,
.calendar.year div {
  padding: 15px 5px;
  font-size: 0.9em;
}
.datepicker-date .calendar-header {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
}
.datepicker-date .calendar-header > div,
.datepicker-date .calendar-header > button {
  background: var(--datepicker-calendar-header-background);
  color: var(--datepicker-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.datepicker-date .calendar-header > button {
  color: var(--datepicker-butotns-color, --datepicker-text-color);
}
button {
  background: transparent;
  border: none;
  padding: 10px 0px;
  font-size: 1.2em;
}
.calendar div:not(.empty):not(.calendar-header):hover {
  z-index: 9999;
  box-shadow: var(--datepicker-shadow-box-on-date-hover);
}
</style>
