<template>
  <div>
    <div class="container">
      <div class="header card">
        <h1>
          <i class="icon-izvjestaji"></i>
          <span v-lang="'reports.report'"></span>
        </h1>
        <div class="actions">
          <a
            class="primary"
            v-on:click="clickBack()"
            v-lang="'general.button_back'"
          ></a>
        </div>
      </div>
      <span class="select-template">
        <div class="input m-0 m-top-10">
          <select v-model="tempValue">
            <option value="Template1">Template1</option>
            <option value="Template2">Template2</option>
            ,
            <option value="Template3">Template3</option>
            <option value="Template4">Template4</option>
            <option value="Template5">Template5</option>
          </select>
          <div>
            <span
              class="error"
              v-html="errors.first('readmission_form.citizenship')"
            ></span>
          </div>
        </div>
      </span>
      <span>
        <div>
          <component v-bind:is="templateName" :report="report"></component>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import moment from "moment";
import globalStore from "@/store";
import { ResponsiveDirective } from "vue-responsive-components";

Vue.use(Toasted);
Vue.component("Template1", () => import("./Templates/Template1"));

export default {
  directives: {
    responsive: ResponsiveDirective
  },
  data() {
    return {
      templateName: "Template1",
      tempValue: "Template1",
      nameRoute: "",
      report: [
        { ime: "Petar", prezime: "petrovic" },
        { ime: "Petar", prezime: "petrovic" },
        { ime: "Petar", prezime: "petrovic" },
        { ime: "Petar", prezime: "petrovic" }
      ]
    };
  },
  created() {},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.routeName = from.name;
      vm.query = from.query;
      vm.nameRoute = from.name;
    });
  },
  methods: {
    getCompontent: async function(component) {
      return () => import(`./Templates/${component}`);
    },
    clickBack() {
      this.$router.push({
        name: this.nameRoute,
        query: Object.assign({}, this.query)
      });
    }
  },
  watch: {
    tempValue() {
      var $this = this;
      this.getCompontent(this.tempValue).then(x => {
        $this.templateName = x;
      });
    }
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
.select-template {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 10px;
}
</style>
