import institutionRouter from "./Institution/router";
import organisationRouter from "./Organisation/router";
import personRouter from "./Person/router";
import traningRouter from "./Traning/router";
import reportsRouter from "./Reports/router";

const router = [
  ...institutionRouter,
  ...organisationRouter,
  ...traningRouter,
  ...personRouter,
  ...reportsRouter,
];

export default router;
