<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-key"></i>
        <span v-lang="'menu.menu_item_roles'"></span>
      </h1>
      <div class="actions">
        <router-link
          v-if="$role(10117)"
          class="primary"
          :to="{ name: 'RoleEditCreate' }"
          v-lang="'general.label_add'"
        ></router-link>
      </div>
    </div>
    <notify :key="notifyKey" />
    <NoData v-if="!roles || roles.length == 0"></NoData>
    <div v-else class="card table-container">
      <table class="table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th v-lang="'general.label_name'">&nbsp;</th>
            <th v-lang="'general.label_description'">&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, key) in paginatedRoles" :key="key">
            <td>{{ key + 1 }}</td>
            <td>{{ obj.name }}</td>
            <td>{{ obj.description }}</td>
            <td>
              <router-link
                v-if="$role(10121)"
                class="default small"
                :to="{ name: 'RoleDetails', params: { id: obj.pkRoleId } }"
                v-lang="'general.label_details'"
              ></router-link>
              <router-link
                v-if="
                  $role(10118) &&
                    obj.pkRoleId != 1 &&
                    obj.pkRoleId != 3 &&
                    obj.pkRoleId != 4
                "
                class="primary small"
                :to="{ name: 'RoleEditCreate', params: { id: obj.pkRoleId } }"
                v-lang="'general.label_edit'"
              ></router-link>
              <a
                v-if="
                  $role(10119) &&
                    obj.pkRoleId != 1 &&
                    obj.pkRoleId != 3 &&
                    obj.pkRoleId != 4
                "
                class="error small"
                v-on:click="deleteRole(obj.pkRoleId)"
                v-lang="'general.label_delete'"
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination-container">
      <paginate
        v-if="pageCount > 1"
        :page-count="pageCount"
        :click-handler="changePage"
        :prev-text="this.$lang('general.previous')"
        :next-text="this.$lang('general.next_page')"
        :container-class="'paggination'"
      ></paginate>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../store";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import NoData from "../../General/NoData";
import Paginate from "vuejs-paginate";
import mainstore from "@/store";
import { mapState } from "vuex";

Vue.use(Toasted);

export default {
  name: "RoleIndex",
  components: {
    NoData,
    paginate: Paginate,
  },
  data() {
    return {
      notifyKey: 0,
      roles: [],
      pageSize: 10,
      pageNum: 1,
      pageCount: 0,
    };
  },
  created() {
    this.getAllRoles();
  },
  computed: {
    ...mapState(["api"]),
    paginatedRoles() {
      var proles = this.roles.slice(
        (this.pageNum - 1) * this.pageSize,
        (this.pageNum - 1) * this.pageSize + this.pageSize
      );
      return proles;
    },
  },
  methods: {
    changePage: function(pageNum) {
      this.pageNum = pageNum;
    },
    getAllRoles() {
      var $this = this;
      store
        .dispatch("GET_All_ROLES")
        .then((response) => {
          $this.roles = response.data.list;

          $this.pageCount = $this.roles.length / $this.pageSize;
        })
        .catch((error) => {});
    },
    deleteRole(id) {
      this.$removeToast();
      Vue.toasted.show(lang("user.do_you_want_to_delete_this_role"), {
        theme: "toasted-primary",
        position: "top-center",
        duration: 5000,
        action: [
          {
            text: lang("general.label_yes"),
            onClick: (e, toastObject) => {
              var $this = this;
              store
                .dispatch("DELETE_ROLE", id)
                .then((response) => {
                  $this.getAllRoles();
                  this.$success("user.role_successfully_deleted");
                  this.notifyKey += 1;
                })
                .catch((error) => {});
              toastObject.goAway(0);
            },
          },
          {
            text: lang("general.label_no"),
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.paggination {
  position: absolute !important;
}
</style>
