<template>
  <div class="submenu">
    <!-- EMMAUS ORGANIZACIJE-->
    <!-- <a class="has-submenu" v-if="$role(40280) || $role(40281)">
        <i class="icon-library"></i> -->
    <!-- <span v-lang="'menu.menu_emmaus_organisations'"></span> -->
    <!-- <span v-lang="'menu.menu_emmaus_training_organize'"></span>
        <div class="submenu">
          <router-link
            v-if="$role(40280)"
            name="click-menu"
            :to="{ name: 'EmmausOrganizationIndex' }"
            >{{ $lang("workingPlace.preview") }}</router-link
          >
          <router-link
            v-if="$role(40281)"
            name="click-menu"
            :to="{ name: 'EmmausOrganizationInsert' }"
            >{{ $lang("workingPlace.insert") }}</router-link
          >
        </div>
      </a> -->

    <!-- EMMAUS INSTITUACIJE-->
    <a class="has-submenu" v-if="$role(40274) || $role(40277)">
      <i class="icon-office"></i>
      <span v-lang="'workingPlace.working_place'"></span>
      <div class="submenu">
        <router-link
          v-if="$role(40274)"
          name="click-menu"
          :to="{ name: 'EmmausInstitutionIndex' }"
          >{{ $lang("workingPlace.preview") }}</router-link
        >
        <router-link
          v-if="$role(40277)"
          name="click-menu"
          :to="{ name: 'EmmausInstitutionInsert' }"
          >{{ $lang("workingPlace.insert") }}</router-link
        >
      </div>
    </a>
    <!-- EMMAUS OSOBE-->
    <a class="has-submenu" v-if="$role(40289) || $role(40290)">
      <i class="icon-users"></i>
      <span v-lang="'menu.menu_persons'"></span>
      <div class="submenu">
        <router-link
          v-if="$role(40289)"
          name="click-menu"
          :to="{ name: 'EmmausPeopleIndex' }"
          >{{ $lang("workingPlace.preview") }}</router-link
        >
        <router-link
          v-if="$role(40290)"
          name="click-menu"
          :to="{ name: 'EmmausPersonForm' }"
          >{{ $lang("workingPlace.insert") }}</router-link
        >
      </div>
    </a>
    <!-- EMMAUS OBUKE-->
    <a class="has-submenu" v-if="$role(40284) || $role(40285)">
      <i class="icon-office"></i>
      <span v-lang="'menu.menu_emmaus_courses'"></span>
      <div class="submenu">
        <router-link
          v-if="$role(40284)"
          name="click-menu"
          :to="{ name: 'EmmausTrainingsIndex' }"
          >{{ $lang("workingPlace.preview") }}</router-link
        >
        <router-link
          v-if="$role(40285)"
          name="click-menu"
          :to="{ name: 'EmmausTrainingsForm' }"
          >{{ $lang("workingPlace.insert") }}</router-link
        >
      </div>
    </a>
    <!-- EMMAUS IZVJESTAJ-->
    <router-link
      v-if="$role(50001)"
      :class="'report-meni'"
      :to="{ name: 'EMMAUSReportsIndex' }"
      ><i class="icon-izvjestaji"></i
      ><span>{{ $lang("menu.menu_item_reports") }}</span></router-link
    >
  </div>
</template>

<script>
export default {
  watch: {
    "$route.query": function() {
      if (this.$route.name == "AdminHome") {
        setTimeout(() => {
          var tmp = document.getElementsByName("click-menu");
          for (let index = 0; index < tmp.length; index++) {
            tmp[index].addEventListener("click", function() {
              tmp[index].parentNode.classList.add("display-none");
              tmp[index].classList.add("display-none");
              setTimeout(() => {
                tmp[index].parentNode.classList.remove("display-none");
                tmp[index].classList.remove("display-none");
              }, 100);
            });
          }
        }, 100);
      }
    }
  }
};
</script>

<style></style>
