<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-file-text"></i>
        <span v-lang="'codes.translation_codes'"></span>
      </h1>
      <div class="actions">
        <button class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></button>
      </div>
    </div>

    <div class="card p-0">
      <form id="user_form" v-on:submit.prevent="save()" data-vv-scope="user_form">
        <div
          class="input m-0"
          :class="errors.first('user_form.titleId') !== undefined ? 'input-error' : '' "
        >
          <label for="titleId" v-lang="'language.titleId'"></label>
          <input
            v-validate="'required'"
            class="m-0"
            v-model="model.titleId"
            type="text"
            name="titleId"
            autocomplete="off"
            :disabled="$route.params.id != null"
          />
          <div class="error" v-html="errors.first('user_form.titleId')"></div>
        </div>
        <div v-for="(obj,key) in langauges" :key="key" class="input m-0">
          <label for="en">{{obj.name}}</label>
          <input
            class="m-0"
            type="text"
            v-model="model.translations[key]"
            name="en"
            autocomplete="off"
          />
        </div>
        <div class="submit">
          <button class="primary" v-lang="'general.button_submit'"></button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { lang } from "@/global-functions";
import store from "../store";

export default {
  data() {
    return {
      disabledId: "",
      title: "",
      model: {
        titleId: "",
        translations: []
      },
      postModel: [],
      langauges: [],
      validation_messages: {
        custom: {
          titleId: {
            required: lang("validation.required")
          }
        }
      }
    };
  },
  created() {
    this.getAllLanguages();
    if (this.$route.params.id != null) {
      this.title = this.$lang("language.edit_translation");
      this.getByID(this.$route.params.id);
    } else {
      this.title = this.$lang("language.create_translation");
    }
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  methods: {
    save() {
      this.postModel = [];
      for (let i = 0; i < this.model.translations.length; i++) {
        var model = {
          titleId: this.model.titleId,
          langCode: this.langauges[i].code,
          titleValue: this.model.translations[i]
        };
        this.postModel.push(model);
      }
      if (!this.$route.params.id) {
        this.create();
      } else {
        this.edit();
      }
    },
    getByID(id) {
      var $this = this;
      store
        .dispatch("GET_TRANSLATION_BY_ID", id)
        .then(response => {
          $this.postModel = response.data;
          $this.model.titleId = $this.postModel[0].titleId;
          for (let i = 0; i < $this.langauges.length; i++) {
            $this.model.translations[i] = $this.seacrh($this.langauges[i].code);
          }
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    seacrh(code) {
      for (var i = 0; i < this.postModel.length; i++) {
        if (this.postModel[i].langCode === code) {
          return this.postModel[i].titleValue;
        }
      }
    },
    getAllLanguages() {
      var $this = this;
      store
        .dispatch("GET_ALL_LANGUAGES")
        .then(response => {
          this.langauges = response.data.list;
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    create() {
      this.$validator.validateAll("user_form").then(valid => {
        if (valid) {
          var $this = this;
          store
            .dispatch("CREATE_TRANSLATION", this.postModel)
            .then(response => {
              $this.$success($this.$lang("general.title_succ_add"));
              $this.$router.push({
                name: "TranslationIndex"
              });
            })
            .catch(error => {
              if (error.status == 400) $this.errorModel = error.data;
            });
        }
      });
    },
    edit() {
      this.$validator.validateAll("user_form").then(valid => {
        if (valid) {
          var $this = this;
          store
            .dispatch("UPDATE_TRANSLATION", this.postModel)
            .then(response => {
              $this.$success($this.$lang("general.title_succ_edit"));
              $this.$router.push({
                name: "TranslationIndex"
              });
            })
            .catch(error => {
              if (error.status == 400) $this.errorModel = error.data;
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.p-0 {
  padding: 0px !important;
}
form {
  .input {
    padding-left: 10px;
    padding-right: 10px;
    // padding-bottom: 10px;
    padding-top: 10px;
  }
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  .submit {
    margin: 0px !important;
    width: 100%;
  }
}
</style>