<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-users"></i>
        <span v-lang="'user.label_users'"></span>
      </h1>
      <div class="actions">
        <router-link
          v-if="$role(10144)"
          class="primary"
          :to="{ name: 'UserCreate' }"
          v-lang="'general.label_add'"
        ></router-link>
      </div>
    </div>
    <div class="card">
      <form class="search-form" v-on:submit.prevent="searchUsers()">
        <div class="input m-0">
          <label for="firstName" v-lang="'user.label_first_name'"></label>
          <input
            class="m-0"
            v-model="searchModel.firstName"
            type="text"
            name="firstName"
            autocomplete="off"
            autofocus
          />
        </div>

        <div class="input m-0">
          <label for="lastName" v-lang="'general.label_surName'"></label>
          <input
            class="m-0"
            v-model="searchModel.lastName"
            type="text"
            name="lastName"
            autocomplete="off"
            autofocus
          />
        </div>
        <div class="input m-0">
          <label for="username" v-lang="'user.username'"></label>
          <input
            class="m-0"
            v-model="searchModel.username"
            type="text"
            name="username"
            autocomplete="off"
            autofocus
          />
        </div>
        <div v-if="!$role(50004)" class="input m-0">
          <label
            for="firstName"
            v-lang="'user.label_organisation_unit'"
          ></label>
          <select
            v-model="searchModel.fkInstitutionId"
            name="organizationUnit"
            id="dropdownOrgUnit"
          >
            <option
              value="0"
              selected
              v-lang="'user.placeholder_for_organisation_unit'"
            ></option>
            <option
              v-for="(obj, key) in allOrganisationUnits"
              :key="key"
              :value="obj.id"
              >{{ obj.name }}</option
            >
          </select>
        </div>
        <div v-if="$role(50004)" class="input m-0">
          <label for="emausOrganisationUnit">
            <span v-lang="'general.emmaus_training_organize'"></span>
            <span class="required-field-star">&#x2605;</span>
          </label>
          <select
            v-model="searchModel.pkFkEmmausOrganizationId"
            name="emausOrganisationUnit"
            id="dropdownOrgUnit"
          >
            <option
              value="0"
              selected
              v-lang="'user.placeholder_for_emaus_training_organize'"
            ></option>
            <option
              v-for="(obj, key) in allEmausOrganisationUnits"
              :key="key"
              :value="obj.id"
              >{{ obj.name }}</option
            >
          </select>
        </div>
        <div class="button-search">
          <button
            type="button"
            class="warning"
            v-on:click="resetSearch()"
            v-lang="'general.button_reset'"
          ></button
          >&nbsp;
          <button class="primary" v-lang="'general.button_search'"></button>
        </div>
      </form>
    </div>
    <notify :key="notifyKey" />

    <Loader2 v-if="!usersLoaded" />
    <div v-else>
      <NoData class="center-nodata" v-if="!users || users.length == 0"></NoData>
      <div v-else class="card table-container">
        <table class="table-hover">
          <thead>
            <tr :id="tableId">
              <th>#</th>
              <th>&nbsp;</th>
              <th v-on:click="sort('FirstName')" FirstName>
                {{ $lang("user.label_first_name") }}
                <span></span>
              </th>
              <th v-on:click="sort('LastName')" LastName>
                {{ $lang("user.label_last_name") }}
                <span></span>
              </th>
              <th v-on:click="sort('Username')" Username>
                {{ $lang("user.username") }}
                <span></span>
              </th>
              <th
                v-if="!$role(50004)"
                v-on:click="sort('M.FkInstitutionId')"
                M-FkInstitutionId
              >
                {{ $lang("user.organisation_title") }}
                <span></span>
              </th>
              <th
                v-on:click="sort('O.PkFkEmmausOrganizationId')"
                O-PkFkEmmausOrganizationId
                v-else
              >
                {{ $lang("emmaus.label_organizacija") }}
                <span></span>
              </th>
              <th v-on:click="sort('M.LastAccessOn')" M-LastAccessOn>
                {{ $lang("user.last_login_title") }}
                <span></span>
              </th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(obj, key) in users" :key="key">
              <td>{{ (searchModel.pageNum - 1) * 10 + key + 1 }}</td>
              <td>
                <img
                  class="avatar-in-list"
                  :src="
                    api +
                      'api/users/avatars?username=' +
                      obj.username +
                      '&nocache=' +
                      new Date()
                  "
                />
              </td>
              <td>{{ obj.firstName }}</td>
              <td>{{ obj.lastName.toUpperCase() }}</td>
              <td>{{ obj.username }}</td>
              <td v-if="!$role(50004)">{{ obj.institutionName }}</td>
              <td v-else>{{ obj.emmausOrganization }}</td>
              <td>
                {{
                  obj.lastLoginDate == null
                    ? ""
                    : $moment(obj.lastLoginDate).format("DD.MM.YYYY. HH:mm:SS")
                }}
              </td>
              <td>
                <router-link
                  v-if="$role(10147)"
                  class="default small"
                  :to="{ name: 'UserDetails', params: { id: obj.pkUserId } }"
                  v-lang="'general.label_details'"
                ></router-link>
                <router-link
                  v-if="
                    $role(10145) &&
                      obj.pkUserId != '46f68135-37bb-4c88-81dc-b670015cc42a'
                  "
                  class="primary small"
                  :to="{ name: 'UserEdit', params: { id: obj.pkUserId } }"
                  v-lang="'general.label_edit'"
                ></router-link>
                <a
                  v-if="
                    $role(10146) &&
                      obj.pkUserId != '46f68135-37bb-4c88-81dc-b670015cc42a'
                  "
                  class="error small"
                  v-on:click="deleteUser(obj.pkUserId)"
                  v-lang="'general.label_delete'"
                ></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-container">
        <paginate
          v-if="pageCount > 1"
          :page-count="pageCount"
          v-model="searchModel.pageNum"
          :clickHandler="changePage"
          :prev-text="this.$lang('general.previous')"
          :next-text="this.$lang('general.next_page')"
          :container-class="'paggination'"
        ></paginate>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import NoData from "../../../General/NoData";
import Paginate from "vuejs-paginate";
import Loader2 from "@/components/General/Loader2";
import mainstore from "@/store";
import { mapState } from "vuex";

Vue.use(Toasted);

export default {
  components: {
    paginate: Paginate,
    NoData,
    Loader2
  },
  data() {
    return {
      allEmausOrganisationUnits: [],
      usersLoaded: false,
      pageCount: 1,
      pageNum: this.$route.query.pageNum > 0 ? this.$route.query.pageNum : 1,
      searchFormModel: {
        firstName: "",
        lastName: ""
      },
      searchModel: {
        firstName:
          this.$route.query.firstName != "" ? this.$route.query.firstName : "",
        lastName:
          this.$route.query.lastName != "" ? this.$route.query.lastName : "",
        orderColumn:
          this.$route.query.orderColumn != ""
            ? this.$route.query.orderColumn
            : "",
        orderSort:
          this.$route.query.orderSort != "" ? this.$route.query.orderSort : "",
        pageNum:
          this.$route.query.pageNum > 0 ? Number(this.$route.query.pageNum) : 1,
        pageOffset: 10,
        component: "UserIndex",
        fkInstitutionId:
          this.$route.query.fkInstitutionId > 0
            ? this.$route.query.fkInstitutionId
            : 0,
        pkFkEmmausOrganizationId:
          this.$route.query.pkFkEmmausOrganizationId > 0
            ? this.$route.query.pkFkEmmausOrganizationId
            : 0,
        username:
          this.$route.query.username != "" ? this.$route.query.username : "",
        emmausUsers: this.$role(50004)
      },
      pageCount: 0,
      notifyKey: 0,
      users: [],
      tableId: Math.random(),
      allOrganisationUnits: []
    };
  },
  created() {
    this.getAllOrganisationUnits();
    this.getAllEmausOrganisationUnits();
    setTimeout(() => {
      this.getAllRegistrationUsers();
    }, 200);
  },
  computed: {
    ...mapState(["api"])
  },
  watch: {},
  methods: {
    getAllOrganisationUnits() {
      var $this = this;
      store
        .dispatch("GET_ALL_ORGANISATION_UNITS")
        .then((response) => {
          $this.allOrganisationUnits = response.data.list;
        })
        .catch((error) => {});
    },
    getAllEmausOrganisationUnits() {
      var $this = this;
      store
        .dispatch("GET_ALL_EMAUS_ORGANISATION_UNITS")
        .then((response) => {
          $this.allEmausOrganisationUnits = response.data.list;
        })
        .catch((error) => {});
    },
    sort(columnName) {
      if (this.searchModel.orderColumn != columnName) {
        this.searchModel.orderColumn = columnName;
        this.searchModel.orderSort = "DESC";
      } else if (this.searchModel.orderSort == "DESC") {
        this.searchModel.orderSort = "ASC";
      } else {
        this.searchModel.orderSort = "DESC";
      }
      this.$router.push({ query: this.searchModel });
      this.getAllRegistrationUsers();
    },
    setIcon() {
      var element = document.getElementById(this.tableId);
      if (element == null) return;
      var array = element.children;
      var item;
      for (let index = 0; index < array.length; index++) {
        if (
          array[index].hasAttribute(
            this.searchModel.orderColumn
              ? this.searchModel.orderColumn.replace(".", "-")
              : this.searchModel.orderColumn
          )
        ) {
          item = array[index];
        }
      }
      if (
        this.searchModel.orderColumn != "" &&
        this.searchModel.orderSort == "DESC"
      ) {
        item.classList.add("desc");
      } else if (this.searchModel.orderSort == "ASC") {
        item.classList.add("asc");
      }
    },
    searchUsers() {
      this.searchModel.pageNum = 1;
      this.getAllRegistrationUsers();
      // this.searchModel = Object.assign({}, this.searchFormModel);
    },
    resetSearch() {
      this.searchModel.firstName = "";
      this.searchModel.lastName = "";
      this.searchModel.pageNum = 1;
      this.searchModel.fkInstitutionId = 0;
      this.searchModel.pkFkEmmausOrganizationId = 0;
      this.searchModel.username = "";
      this.getAllRegistrationUsers();
      // this.searchFormModel.firstName = "";
      // this.searchFormModel.lastName = "";
    },
    changePage: function(pageNum) {
      this.searchModel.pageNum = pageNum;
      this.getAllRegistrationUsers();
    },
    getAllRegistrationUsers() {
      this.$router.push({
        query: { ...this.$route.query, ...this.searchModel }
      });
      var $this = this;
      this.usersLoaded = false;
      store
        .dispatch("GET_All_USERS", this.searchModel)
        .then((response) => {
          $this.users = response.data.model;
          var tmp;
          if ($this.users.length > 0) tmp = response.data.model[0].totalCount;

          if (tmp == null) {
            $this.pageCount = 0;
            $this.usersLoaded = true;
            return;
          }

          var count = tmp;
          var size = $this.searchModel.pageOffset;

          if (count % size !== 0)
            $this.pageCount = Math.floor(count / size) + 1;
          else $this.pageCount = Math.floor(count / size);
          $this.usersLoaded = true;
          setTimeout(() => {
            $this.setIcon();
          }, 50);
        })
        .catch((error) => {});
    },
    deleteUser(id) {
      this.$removeToast();
      Vue.toasted.show(lang("user.do_you_want_to_delete_this_user"), {
        theme: "toasted-primary",
        position: "top-center",
        duration: 5000,
        action: [
          {
            text: lang("general.label_yes"),
            onClick: (e, toastObject) => {
              var $this = this;
              store
                .dispatch("DELETE_USER", id)
                .then((response) => {
                  $this.getAllRegistrationUsers();
                  if (
                    $this.users.length == 1 &&
                    $this.searchModel.pageNum > 1
                  ) {
                    $this.searchModel.pageNum--;
                    $this.getAllRegistrationUsers();
                  }
                  this.$success("user.user_successfully_deleted");
                  this.notifyKey += 1;
                })
                .catch((error) => {});
              toastObject.goAway(0);
            }
          },
          {
            text: lang("general.label_no"),
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            }
          }
        ]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.search-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  .button-search {
    margin-top: 10px;
    grid-column: 1/3;
    text-align: right;
  }
}
</style>
