<template>
  <div>
    <table style="display:none;" id="report_people">
      <thead>
        <tr>
          <th class="text-align-center">#</th>
          <th>
            {{ $lang("general.first_name") }}
            <span></span>
          </th>
          <th>
            {{ $lang("general.last_name") }}
            <span></span>
          </th>
          <th>
            {{ $lang("general.gender") }}
            <span></span>
          </th>
          <th>
            {{ $lang("emmaus.emmaus_institution") }}
            <span></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(obj, key) in peoples.list" :key="key">
          <td>
            {{ key + 1 }}
          </td>
          <td>{{ obj.firstName }}</td>
          <td>{{ obj.lastName }}</td>
          <td>
            {{ obj.genderName }}
          </td>
          <td>{{ obj.emmausInstitutionName }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    peoples: Array,
  },
};
</script>
