<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-users"></i>
        <span v-lang="'logs.logs_action_details'"></span>
      </h1>
      <div class="actions">
        <button
          class="primary"
          v-on:click="$router.go(-1)"
          v-lang="'general.button_back'"
        ></button>
      </div>
    </div>
    <div class="scroll card table-container">
      <table>
        <tr>
          <td>ID</td>
          <td>{{ actionLog.idUser }}</td>
        </tr>
        <tr>
          <td v-lang="'user.username'"></td>
          <td>{{ actionLog.userName }}</td>
        </tr>
        <tr>
          <td v-lang="'general.client_info'"></td>
          <td>{{ actionLog.clientInfo }}</td>
        </tr>
        <tr>
          <td v-lang="'general.ip_address'"></td>
          <td>{{ actionLog.host }}</td>
        </tr>
        <tr>
          <td v-lang="'general.requestAPI'"></td>
          <td>{{ actionLog.requestApi }}</td>
        </tr>
        <tr>
          <td>API</td>
          <td>{{ actionLog.api }}</td>
        </tr>
        <tr>
          <td v-lang="'general.description'"></td>
          <td>{{ actionLog.description }}</td>
        </tr>
        <tr>
          <td v-lang="'user.username'"></td>
          <td>{{ actionLog.userName }}</td>
        </tr>
        <tr>
          <td v-lang="'action.label_name_of_http_method'"></td>
          <td>{{ actionLog.httpMethod }}</td>
        </tr>
        <tr>
          <td v-lang="'action.label_name_of_action_type'"></td>
          <td>{{ actionLog.actionType }}</td>
        </tr>
        <tr v-show="dataLog">
          <td>Data</td>
          <td>
            <router-link
              class="default small"
              :to="{
                name: 'DataLogDetails',
                params: { id: actionLog.data.logId }
              }"
              v-lang="'general.label_details'"
            ></router-link>
          </td>
        </tr>
        <tr>
          <td v-lang="'general.status'"></td>
          <td>{{ actionLog.statusCode }}</td>
        </tr>
      </table>
    </div>
    <div v-if="!dataLog" class="card table-container">
      <h2>
        <i class="icon-users"></i>
        <span v-lang="'general.data'"></span>
      </h2>
      <table class="data">
        <tr>
          <!-- <td v-for="(str, key) in modelKeys" :key="key" v-lang="dataLog.objectType + '.' + str"></td> -->
          <td v-for="(str, key) in modelKeys" :key="key">{{ str }}</td>
        </tr>
        <tr>
          <td v-for="(str, key) in modelKeys" :key="key">
            {{ actionLog.data[str] }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import { lang } from "@/global-functions";

export default {
  data() {
    return {
      actionLog: {
        data: {
          logId: 0
        }
      },
      dataLog: true,
      modelKeys: []
    };
  },
  created() {
    this.getActionLogById();
  },
  methods: {
    getActionLogById() {
      var $this = this;
      store
        .dispatch("GET_ACTION_LOG_BY_ID", $this.$route.params.id)
        .then(response => {
          $this.actionLog = response.data.model;
          if ($this.actionLog.httpMethod != "GET") {
            $this.dataLog = true;
          } else {
            $this.dataLog = false;
            $this.modelKeys = Object.keys($this.actionLog.data);
          }
        })
        .catch(error => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll {
  overflow: auto;
}
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}

td:nth-child(2) {
  font-weight: bold;
}
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
}
</style>
