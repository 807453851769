<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-office"></i>
        <span v-lang="'emmaus.edit_training_organize'"></span>
      </h1>
      <div class="actions">
        <a
          class="primary"
          v-on:click="clickBack()"
          v-lang="'general.button_back'"
        ></a>
      </div>
    </div>
    <notify />
    <div class="card">
      <Form
        v-on:save="edit"
        :organizationData="organization"
        :errorModel="errorModel"
        :isSubmited="isSubmited"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "./store";
import Form from "./Form";
import { lang } from "@/global-functions";
import { mapState } from "vuex";
import globalStore from "@/store";

export default {
  components: {
    Form
  },
  data() {
    return {
      nameRoute: "",
      organization: {},
      errorModel: {
        message: "",
        key: ""
      },
      isSubmited: false
    };
  },
  created() {
    this.getEmmausOrganizationById();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeName = from.name;
      vm.query = from.query;
      vm.nameRoute = from.name;
    });
  },
  mounted() {
    globalStore.commit("HIDE_LOADER");
  },
  methods: {
    clickBack() {
      this.$router.push({
        name: "EmmausOrganizationIndex",
        query: Object.assign({}, this.query)
      });
    },
    edit: function (value) {
      var $this = this;
      $this.isSubmited = true;
      store
        .dispatch("EDIT_EMMAUS_ORGANIZATION", value)
        .then((response) => {
          $this.$success("emmaus.organization_successfully_updated");
          $this.isSubmited = false;
          // $this.$router.push({ name: "EmmausOrganizationIndex" });
          $this.$router.push({
            name: "SettingsForm",
            query: { component: "EmmausOrganizationIndex" }
          });
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    getEmmausOrganizationById() {
      var $this = this;
      store
        .dispatch("GET_EMMAUS_ORGANIZATION_BY_ID", $this.$route.query.id)
        .then((response) => {
          $this.organization = response.data.model;
        })
        .catch((error) => {});
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}
</style>
