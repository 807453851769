<template>
  <!-- :class="
      $route.name != 'OfficialRecordsDetails' &&
      $route.name != 'WatchListIndex' &&
      $route.name != 'WatchListCreateList'
        ? 'container'
        : ''
    " -->
  <div :class="'container'">
    <div class="header card">
      <h1>
        <span v-lang="'training.training_index'"></span>
      </h1>

      <div class="actions">
        <div v-if="trainings.length > 0">
          <button
            class="primary"
            v-on:click="searchForExcelReport()"
            v-lang="'victims.export_excel'"
          ></button>
          <button
            class="error"
            v-on:click="searchForReport()"
            v-lang="'general.report-print'"
          ></button>
        </div>
      </div>
    </div>
    <!-- <notify /> -->
    <div class="card">
      <form
        class="search"
        action
        v-on:submit.prevent="searchFunction()"
        v-responsive="{ small: (el) => el.width < 650 }"
      >
        <div class="input m-0">
          <label for="name" v-lang="'training.emmaus_training_name'"></label>
          <input
            class="m-0"
            v-model="tempSearchModel.name"
            type="text"
            name="name"
            autocomplete="off"
            autofocus
          />
        </div>
        <div class="input m-0">
          <label
            for="fkTypeCodeId"
            v-lang="'training.label_training_type'"
          ></label>
          <select
            name="fkTypeCodeId"
            v-model="tempSearchModel.fkTypeCodeId"
            v-validate="'required|excluded:null'"
          >
            <option
              :value="null"
              v-lang="'training.placeholder_for_training_type'"
            ></option>
            <option v-for="(obj, key) in types" :key="key" :value="obj.value">{{
              obj.text
            }}</option>
          </select>
        </div>

        <div class="input m-0">
          <label for="name" v-lang="'training.training_date'"></label>
          <date-picker
            name="date"
            v-model="tempSearchModel.date"
            :inputClass="'input-date-picker'"
            :locale="lang"
            :initDate="null"
            :placeholder="'dd.mm.gggg'"
          ></date-picker>
        </div>
        <div class="input m-0">
          <label v-lang="'emmaus.training_organize'"></label>
          <select
            name="fkEmmausOrganizationId"
            v-model="tempSearchModel.fkEmmausOrganizationId"
          >
            <option
              :value="null"
              v-lang="'user.placeholder_for_emaus_training_organize'"
            ></option>
            <option
              v-for="(obj, key) in organizationList"
              :key="key"
              :value="obj.id"
              >{{ obj.name }}</option
            >
          </select>
        </div>
        <div class="buttons-search">
          <button
            type="button"
            class="warning"
            v-on:click="resetSearch($event)"
            v-lang="'general.button_reset'"
          ></button
          >&nbsp;
          <button class="primary" v-lang="'general.button_search'"></button>
        </div>
      </form>
    </div>
    <Loader2 v-if="!resultsLoaded" />
    <span v-else>
      <NoData
        class="center-nodata"
        v-if="!trainings || trainings.length == 0"
      ></NoData>
      <span v-else>
        <Table
          :trainings="trainings"
          @updateTrainingList="getTrainings()"
          :searchModel="searchModel"
          @searchSort="searchSort"
          :orderColumn="searchModel.orderColumn"
          :orderSort="searchModel.orderSort"
        />
        <div class="pagination-container">
          <paginate
            v-if="trainings.length != 0 && pageCount > 1"
            :page-count="pageCount"
            v-model="searchModel.page"
            :click-handler="changePage"
            :prev-text="this.$lang('general.previous')"
            :next-text="this.$lang('general.next_page')"
            :container-class="'paggination'"
          ></paginate>
        </div>
      </span>
    </span>
    <ReportIndex :training="trainingForReport"></ReportIndex>
  </div>
</template>

<script>
import Vue from "vue";
import store from "./store";
import globalStore from "@/store";
import moment from "moment";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import Paginate from "vuejs-paginate";
import NoData from "../../General/NoData";
import Loader2 from "../../General/Loader2";
import Table from "./Table";
import { ResponsiveDirective } from "vue-responsive-components";
import Datepicker from "vuejs-datepicker";
import { en, sr, srCYRL, hr } from "vuejs-datepicker/dist/locale";
import { Settings } from "luxon";
import ReportIndex from "./ReportIndex.vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Arimo } from "@/assets/fonts/Arimo-Regular-normal.js";
import { ArimoBold } from "@/assets/fonts/Arimo_Bold.js";
import organizationStore from "@/components/EMMAUS/Organisation/store";

import { mapState } from "vuex";

Vue.use(Toasted);

export default {
  directives: {
    responsive: ResponsiveDirective
  },
  components: {
    NoData,
    Datepicker,
    paginate: Paginate,
    moment,
    Table,
    Loader2,
    ReportIndex
  },
  data() {
    return {
      en: en,
      sr: sr,
      srCYRL: srCYRL,
      hr: hr,
      trainingForReport: [],
      organizationList: [],
      lang:
        globalStore.state.lang == "sr"
          ? "sr-cyrl"
          : globalStore.state.lang == "bs"
          ? "sr"
          : globalStore.state.lang == "hr"
          ? "hr"
          : "en",
      trainings: [],
      resultsLoaded: false,
      tempSearchModel: {
        name:
          this.$route.query.name && this.$route.query.name != ""
            ? this.$route.query.name
            : "",
        fkTypeCodeId: this.$route.query.fkTypeCodeId
          ? this.$route.query.fkTypeCodeId
          : null,
        date: this.$route.query.date ? this.$route.query.date : null,
        page: this.$route.query.page ? this.$route.query.page : 1,
        limit: this.$route.query.limit ? this.$route.query.limit : 10,
        fkEmmausOrganizationId: this.$route.query.fkEmmausOrganizationId
          ? this.$route.query.fkEmmausOrganizationId
          : null,

        orderColumn: this.$route.query.orderColumn
          ? this.$route.query.orderColumn
          : "",
        orderSort: this.$route.query.orderSort
          ? this.$route.query.orderSort
          : ""
      },
      searchModel: {
        name:
          this.$route.query.name && this.$route.query.name != ""
            ? this.$route.query.name
            : "",
        fkTypeCodeId: this.$route.query.fkTypeCodeId
          ? this.$route.query.fkTypeCodeId
          : null,
        date: this.$route.query.date ? this.$route.query.date : null,
        page: this.$route.query.page ? this.$route.query.page : 1,
        limit: this.$route.query.limit ? this.$route.query.limit : 10,
        fkEmmausOrganizationId: this.$route.query.fkEmmausOrganizationId
          ? this.$route.query.fkEmmausOrganizationId
          : null,

        orderColumn: this.$route.query.orderColumn
          ? this.$route.query.orderColumn
          : "",
        orderSort: this.$route.query.orderSort
          ? this.$route.query.orderSort
          : ""
      },
      resultCount: 0,
      pageCount: 0,
      types: []
    };
  },
  watch: {
    "trainings.length": function(n, o) {
      if (this.trainings.length == 0 && this.searchModel.page > 1) {
        this.searchModel.page--;
        this.getTrainings();
      } else if (n < o) {
        this.getTrainings();
      }
    }
    // "$route.query.pkPersonId"() {
    //   this.getTrainings();
    // },
    // "$route.query.update"() {
    //   if (this.$route.query.update == "Person") {
    //     var query = Object.assign({}, this.$route.query);
    //     delete query.update;
    //     this.$router.push({
    //       query,
    //     });
    //     this.resetSearch();
    //   }
    // },
  },
  created() {
    this.getTypes();
    this.getTrainings();
    this.getAllOrganizations();
  },
  beforeDestroy() {
    this.$removeToast();
  },
  computed: {},
  methods: {
    getAllOrganizations() {
      organizationStore
        .dispatch("GET_ALL_EMMAUS_ORGANIZATION")
        .then((response) => {
          this.organizationList = response.data.list;
        });
    },
    getTypes() {
      var $this = this;
      store
        .dispatch("GET_CODE_BY_ID", 42906)
        .then((response) => {
          $this.types = response.data.model.items;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    createTraining() {
      this.$router.push({
        name: "EmmausTrainingsForm",
        query: { pkEmmausTrainingId: 0 }
      });
    },
    searchSort(orderColumn, orderSort) {
      this.searchModel.orderColumn = orderColumn;
      this.searchModel.orderSort = orderSort;
      this.getTrainings();
    },
    changePage: function(page) {
      this.searchModel.page = page;
      this.getTrainings();
    },
    searchFunction() {
      this.searchModel = this.tempSearchModel;
      this.searchModel.page = 1;
      this.getTrainings();
    },
    getTrainings() {
      this.$router.push({
        query: { ...this.$route.query, ...this.searchModel }
      });
      var $this = this;
      $this.resultsLoaded = false;

      store
        .dispatch("SEARCH_EMMAUS_TRAINING", this.searchModel)
        .then((response) => {
          $this.trainings = response.data.list;

          if ($this.trainings.length > 0)
            $this.pageCount = response.data.list[0].pageCount;

          // var count = $this.resultCount;
          // var size = $this.searchModel.limit;

          // if (count % size !== 0)
          //   $this.pageCount = Math.floor(count / size) + 1;
          // else $this.pageCount = Math.floor(count / size);

          $this.resultsLoaded = true;
        })
        .catch((error) => {});
    },
    resetSearch() {
      this.tempSearchModel.name = null;
      this.tempSearchModel.fkTypeCodeId = null;
      this.tempSearchModel.date = null;
      this.tempSearchModel.limit = 10;
      this.tempSearchModel.page = 1;
      this.tempSearchModel.orderColumn = "";
      this.tempSearchModel.orderSort = "";
      this.tempSearchModel.fkEmmausOrganizationId = null;
      this.searchModel = this.tempSearchModel;
      this.getTrainings();
    },
    deletePerson(id) {
      var $this = this;
      this.$removeToast();
      Vue.toasted.show(
        lang("emmaus.message_are_you_sure_you_want_to_delete_training"),
        {
          theme: "toasted-primary",
          position: "top-center",
          duration: 5000,
          action: [
            {
              text: lang("general.label_yes"),
              onClick: (e, toastObject) => {
                store
                  .dispatch("DELETE_EMMAUS_TRAINING", id)
                  .then((response) => {
                    $this.$success(
                      "emmaus.message_training_successfully_deleted"
                    );

                    $this.getTrainings();
                  })
                  .catch((error) => {
                    $this.$error("person.message_training_delete_failed");
                  });

                toastObject.goAway(0);
              }
            },
            {
              text: lang("general.label_no"),
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              }
            }
          ]
        }
      );
    },
    searchForReport() {
      this.searchModel = this.tempSearchModel;
      var oldPage = this.searchModel.page;
      var oldLimit = this.searchModel.limit;
      this.searchModel.page = 1;
      this.searchModel.limit = 999999999;
      var $this = this;
      $this.resultsLoaded = false;
      store
        .dispatch("SEARCH_EMMAUS_TRAINING", this.searchModel)
        .then((response) => {
          this.trainingForReport = response.data;
          setTimeout(() => {
            $this.resultsLoaded = true;
            this.Export2PDF();
            $this.searchModel.page = oldPage;
            $this.searchModel.limit = oldLimit;
            $this.searchFunction();
          }, 1000);
        });
    },
    searchForExcelReport() {
      this.searchModel = this.tempSearchModel;
      var oldPage = this.searchModel.page;
      var oldLimit = this.searchModel.limit;
      this.searchModel.page = 1;
      this.searchModel.limit = 999999999;
      var $this = this;
      $this.resultsLoaded = false;
      store
        .dispatch("SEARCH_EMMAUS_TRAINING", this.searchModel)
        .then((response) => {
          this.trainingForReport = response.data;
          setTimeout(() => {
            $this.resultsLoaded = true;
            $this.Export2Excel();
            $this.searchModel.page = oldPage;
            $this.searchModel.limit = oldLimit;
            $this.searchFunction();
          }, 1000);
        });
    },
    Export2Excel() {
      var $this = this;
      var title = $this.$lang("training.report_index_training");

      var element = document.getElementsByTagName("td");
      for (let i = 0; i < element.length; i++) {
        element[i].style.verticalAlign = "middle";
      }

      var tab_text = "<table border='2px'><tr >";
      var textRange;
      var j = 0;

      var tab = document.getElementById("report_training"); // id of table
      var clspan = tab.rows[0].innerHTML.split("<th").length - 1;

      tab_text +=
        "<th  bgcolor='#87AFC6' colspan='" +
        clspan.toString() +
        "' id='activity'>" +
        title +
        "</tr>";

      if ($this.generateParametersString() != "") {
        tab_text +=
          "<th  bgcolor='#87AFC6' colspan='" +
          clspan.toString() +
          "' id='activity'>" +
          "</tr>";

        tab_text +=
          "<th  bgcolor='#87AFC6' colspan='" +
          clspan.toString() +
          "' id='activity'>" +
          $this.$lang("reports.search_parameters") +
          "</tr>";
        tab_text +=
          "<th  bgcolor='#87AFC6' colspan='" +
          clspan.toString() +
          "' id='activity'>" +
          $this.generateParametersString() +
          "</tr>";
      }

      for (j = 1; j < tab.rows.length; j++) {
        tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";

        //tab_text=tab_text+"</tr>";
      }

      tab_text = tab_text.replace(
        /<td/gi,
        "<td class='xl70' width='300' align='center' vertical-align:'top' "
      );
      tab_text = tab_text.replace(/12-17/gi, "&nbsp;12-17");
      tab_text = tab_text + "</table>";
      tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, ""); //remove if u want links in your table
      tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
      tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");

      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        // If Internet Explorer
        txtArea1.document.open("txt/html", "replace");
        txtArea1.document.write(tab_text);
        txtArea1.document.close();
        txtArea1.focus();
        sa = txtArea1.document.execCommand(
          "SaveAs",
          true,
          "Say Thanks to Sumit.xls"
        );
      } //other browser not tested on IE 11
      else {
        var a = window.document.createElement("a");
        a.setAttribute(
          "href",
          "data:attachment/xlsx;charset=utf-8,%EF%BB%BF" +
            encodeURIComponent(tab_text)
        );
        a.setAttribute("download", title + ".xls");
        window.document.body.appendChild(a);
        a.click();
      }
    },
    generateParametersString() {
      var $this = this;
      var parametersString = "";

      //NAME
      if ($this.searchModel.name != "") {
        parametersString +=
          $this.$lang("general.label_name") + ": " + $this.searchModel.name;
      }

      //TRAINING TYPE
      if ($this.searchModel.fkTypeCodeId != null) {
        if (parametersString != "") parametersString += "; ";
        parametersString +=
          $this.$lang("training.label_training_type") +
          ": " +
          $this.types.find(
            (type) => type.value == $this.searchModel.fkTypeCodeId
          ).text;
      }

      //DATE
      if ($this.searchModel.date != null) {
        if (parametersString != "") parametersString += "; ";
        parametersString +=
          $this.$lang("training.training_date") +
          ": " +
          $this.$moment($this.searchModel.date).format("DD.MM.YYYY.");
      }

      //ORGANIZATION
      if ($this.searchModel.fkEmmausOrganizationId != null) {
        if (parametersString != "") parametersString += "; ";
        parametersString +=
          $this.$lang("emmaus.label_organizacija") +
          ": " +
          $this.organizationList.find(
            (organization) =>
              organization.id == $this.searchModel.fkEmmausOrganizationId
          ).name;
      }

      return parametersString;
    },
    async Export2PDF(element) {
      var element = "body";
      var doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true
      });

      var $this = this;
      doc.addFileToVFS("Arimo-Regular.ttf", Arimo);
      doc.addFont("Arimo-Regular.ttf", "Arimo", "normal");
      doc.setFont("Arimo");
      doc.addFileToVFS("Arimo-Bold.ttf", ArimoBold);
      doc.addFont("Arimo-Bold.ttf", "Arimo", "bold");

      var footer = async function(data) {};

      var title = $this.$lang("training.report_index_training");

      var outputName = title + ".pdf";

      var Font = 8;
      Font =
        $this.$route.query.id == 42901 ||
        $this.$route.query.id == 42898 ||
        $this.$route.query.id == 42899
          ? 7
          : Font;

      var pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      var number_of_pages = doc.internal.getNumberOfPages();

      // TABELA
      doc.autoTable({
        styles: {
          fontSize: Font,
          font: "Arimo",
          halign: "left",
          lineColor: [44, 62, 80],
          lineWidth: 0.55
        },
        headStyles: {
          halign: "left",
          fontStyle: "Arimo",
          fontSize: 12,
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineColor: [255, 255, 255],
          lineWidth: 0.55
        },
        columnStyles: {
          0: {
            halign: "left",
            valign: "middle",
            cellWidth: 10
          },
          1: {
            halign: "left",
            valign: "middle",
            cellWidth: "auto"
          }
        },
        html: "#report_training",

        didParseCell: function(HookData) {
          if (HookData.row.raw.id == "countryRow") {
            var rows = HookData.table.body;

            HookData.cell.styles.textColor = [0, 0, 0];
            HookData.cell.styles.fontSize = 10;
            HookData.cell.styles.fillColor = [176, 224, 230];
          }
        },
        drawHeader: function(HookData) {
          return false;
        },

        didDrawPage: async function(data) {
          data.settings.margin.top = 5;
          var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
          var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

          if (data.pageNumber === 1) {
            var splitTitle = doc.splitTextToSize(title, 250);
            var dim = doc.getTextDimensions(title);

            var yearsPos = dim.w > 179 ? 64 : 57;
            doc.setFontSize(13);
            doc.setTextColor(60);
            doc.text(splitTitle, pageWidth / 2, 15, "center");

            if ($this.generateParametersString() != "") {
              doc.setFontSize(11);
              doc.text(
                $this.$lang("reports.search_parameters"),
                pageWidth / 2,
                22,
                "center"
              );

              var parametersString = "";
              var parametersString2 = "";

              //NAME
              if ($this.searchModel.name != "") {
                parametersString +=
                  $this.$lang("general.label_name") +
                  ": " +
                  $this.searchModel.name;
              }

              //TRAINING TYPE
              if ($this.searchModel.fkTypeCodeId != null) {
                if (parametersString != "") parametersString += "; ";
                parametersString +=
                  $this.$lang("training.label_training_type") +
                  ": " +
                  $this.types.find(
                    (type) => type.value == $this.searchModel.fkTypeCodeId
                  ).text;
              }

              //DATE
              if ($this.searchModel.date != null) {
                parametersString2 +=
                  $this.$lang("training.training_date") +
                  ": " +
                  $this.$moment($this.searchModel.date).format("DD.MM.YYYY.");
              }

              //ORGANIZATION
              if ($this.searchModel.fkEmmausOrganizationId != null) {
                if (parametersString2 != "") parametersString2 += "; ";
                parametersString2 +=
                  $this.$lang("emmaus.label_organizacija") +
                  ": " +
                  $this.organizationList.find(
                    (organization) =>
                      organization.id ==
                      $this.searchModel.fkEmmausOrganizationId
                  ).name;
              }

              doc.setFontSize(10);
              doc.text(parametersString, pageWidth / 2, 26, "center");
              doc.text(parametersString2, pageWidth / 2, 30, "center");
            }
          }
        },
        didDrawCell: function(HookData) {},
        margin: { top: 30 },
        rowPageBreak: "avoid",
        pageBreak: "avoid"
      });

      number_of_pages = doc.internal.getNumberOfPages();
      for (var i = 1; i <= number_of_pages; i++) {
        doc.setPage(i);
        doc.setFontSize(12);
        doc.text(i + "/" + number_of_pages, 10, 290);
        if (i < number_of_pages) {
          doc.setFontSize(9);
        }
      }

      await doc.save(outputName);

      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        window.open(doc.output("bloburl"), "_blank");
      }
      $this.showLoader = false;
      $this.templateData = null;
      $this.selectedReportNumber = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.buttons-search {
  grid-column: 1/4;
}
#avatarImg {
  width: 30% !important;
  height: 30% !important;
}
.actions {
  position: relative;
}
.official-records {
  .form {
    grid-template-columns: 1fr;
    .submit {
      grid-column: 1/2;
      text-align: right;
    }
  }
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  .buttons-search {
    grid-column: 1/3;
    text-align: right;
  }
}
.form.small {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 10px;
  .buttons-search {
    grid-column: 1/2;
    text-align: right;
  }
}
.form-watch-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  .buttons-search {
    grid-column: 1/4;
    text-align: right;
  }
}

.left-aligment {
  text-align: right;
  .paggination {
    position: relative !important;
  }
}
.center-nodata {
  margin: 0 5px 0 5px;
}
</style>
