<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-file-text"></i>
        <span v-lang="'codes.translation_codes'"></span>
      </h1>
      <div class="actions">
        <router-link
          v-if="$role(10140)"
          class="primary"
          :to="{ name: 'TranslationCreate' }"
          v-lang="'general.label_add'"
        ></router-link>
      </div>
    </div>
    <div class="notify">
      <notify :key="notifyKey" />
    </div>
    <div class="card">
      <div class="input m-0">
        <select @change="onChangeFile($event)" name="files" id="files" v-model="selectFile">
          <option value="0" v-lang="'general.select_file'"></option>
          <option v-for="(obj, key) in files" :key="key">{{ obj }}</option>
        </select>
      </div>
    </div>

    <NoData v-if="!translations || translations.length == 0"></NoData>
    <div v-else class="card table-container">
      <table class="table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th v-lang="'language.titleId'">&nbsp;</th>
            <th v-lang="'language.title'">&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, key) in paginatedRoles" :key="key">
            <td>{{ key + 1 }}</td>
            <td class="wrap-text">{{ obj.titleId }}</td>
            <td class="wrap-text">{{ obj.titleValue }}</td>
            <td>
              <router-link
                v-if="$role(10141)"
                class="primary small"
                :to="{ name: 'TranslationCreate', params: { id: obj.titleId } }"
                v-lang="'general.label_edit'"
              ></router-link>
              <a
                v-if="$role(10142)"
                class="error small"
                v-on:click="deleteTranslation(obj.titleId)"
                v-lang="'general.label_delete'"
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination-container">
      <paginate
        v-if="translations.length != 0"
        :page-count="pageCount"
        :click-handler="changePage"
        :prev-text="this.$lang('general.previous')"
        :next-text="this.$lang('general.next_page')"
        :container-class="'paggination'"
      ></paginate>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../store";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import NoData from "../../General/NoData";
import Paginate from "vuejs-paginate";
import mainstore from "@/store";
import { mapState } from "vuex";
export default {
  components: {
    NoData,
    paginate: Paginate
  },
  data() {
    return {
      selectFile: "0",
      langauges: [],
      files: [],
      notifyKey: 0,
      translations: [],
      pageSize: 10,
      pageNum: 1,
      pageCount: 0
    };
  },
  created() {
    this.getAllFiles();
  },
  computed: {
    ...mapState(["api"]),
    paginatedRoles() {
      var proles = this.translations.slice(
        (this.pageNum - 1) * this.pageSize,
        (this.pageNum - 1) * this.pageSize + this.pageSize
      );
      return proles;
    }
  },
  methods: {
    onChangeFile(event) {
      if (event.target.value != 0)
        this.getAllTranslationsByFileName(event.target.value);
    },
    changePage: function(pageNum) {
      this.pageNum = pageNum;
    },
    getAllLanguages() {
      store
        .dispatch("GET_ALL_LANGUAGES")
        .then(response => {
          this.langauges = response.data.list;
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    getAllFiles() {
      var $this = this;
      store
        .dispatch("GET_FILES")
        .then(response => {
          $this.files = response.data;
        })
        .catch(error => {});
    },
    getAllTranslationsByFileName(fileNmae) {
      this.pageNum = 1;
      var $this = this;
      store
        .dispatch("GET_ALL_TRANSLATIONS_BY_FILE_NAME", fileNmae)
        .then(response => {
          $this.translations = response.data;
          $this.pageCount = Math.ceil(
            $this.translations.length / $this.pageSize
          );
        })
        .catch(error => {});
    },
    deleteTranslation(id) {
      this.$removeToast();
      Vue.toasted.show(lang("user.do_you_want_to_delete_this_translation"), {
        theme: "toasted-primary",
        position: "top-center",
        duration: 5000,
        action: [
          {
            text: lang("general.label_yes"),
            onClick: (e, toastObject) => {
              var $this = this;
              store
                .dispatch("DELETE_TRANSLATION", id)
                .then(response => {
                  $this.getAllTranslationsByFileName($this.selectFile);
                  this.$success("user.translation_successfully_deleted");
                  this.notifyKey += 1;
                })
                .catch(error => {});
              toastObject.goAway(0);
            }
          },
          {
            text: lang("general.label_no"),
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            }
          }
        ]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.input {
  padding-left: 4px;
  display: inline !important;
}
.left-aligment {
  margin-left: -40px;
}
.wrap-text {
  word-break: break-all;
}
</style>
