<template>
  <div
    :class="$route.name == 'EmmausTrainingsForm' ? 'container grid' : ''"
    v-responsive="{ small: (el) => el.width < 1200 }"
  >
    <div>
      <div v-if="$route.name == 'EmmausTrainingsForm'" class="header card">
        <h1>
          <i class="icon-office"></i>
          <span>{{
            $route.query.pkEmmausTrainingId &&
            $route.query.pkEmmausTrainingId != 0
              ? $lang("emmaus.edit_emmaus_training")
              : $lang("emmaus.create_emmaus_training")
          }}</span>
        </h1>
        <div class="actions">
          <a
            class="primary"
            v-on:click="$router.push({ name: 'EmmausTrainingsIndex' })"
            v-lang="'general.button_back'"
          ></a>
        </div>
      </div>
      <div class="card">
        <div>
          <Loader2 v-if="!showTranslations" />
          <form
            v-else
            v-on:submit.prevent="saveFoundTraining()"
            data-vv-scope="emmaus_training_form"
          >
            <!-- NAME -->
            <div
              class="input m-0"
              :class="
                errors.first('emmaus_training_form.name') !== undefined
                  ? 'input-error'
                  : ''
              "
            >
              <label for="name">
                <span v-lang="'training.emmaus_training_name'"></span>
                <span class="required-field-star">&#x2605;</span>
              </label>
              <input
                class="m-0"
                v-validate="'required'"
                v-model="model.name"
                type="text"
                name="name"
                autocomplete="off"
              />
              <div>
                <span
                  class="error"
                  v-html="errors.first('emmaus_training_form.name')"
                ></span>
              </div>
            </div>
            <!--  -->
            <!-- ORGANISATION -->
            <div
              v-if="superAdmin"
              class="input m-0"
              :class="
                errors.first('emmaus_training_form.fkEmmausOrganizationId') !==
                undefined
                  ? 'input-error'
                  : ''
              "
            >
              <label for="fkEmmausOrganizationId">
                <span v-lang="'emmaus.training_organize_name'"></span>
                <span class="required-field-star">&#x2605;</span>
              </label>
              <select
                v-model="model.fkEmmausOrganizationId"
                v-validate="'required|excluded:0'"
                name="fkEmmausOrganizationId"
                id="dropdownOrgUnit"
              >
                <option
                  :value="0"
                  selected
                  v-lang="'user.placeholder_for_emaus_training_organize'"
                ></option>
                <option
                  v-for="(obj, key) in allEmausOrganisationUnits"
                  :key="key"
                  :value="obj.id"
                  >{{ obj.name }}</option
                >
              </select>
              <div>
                <span
                  class="error"
                  v-html="
                    errors.first('emmaus_training_form.fkEmmausOrganizationId')
                  "
                ></span>
              </div>
            </div>
            <!--  -->
            <!-- TITLE ID TRAINING NAME -->

            <div class="translations-class">
              <div v-for="(obj, key) in langauges" :key="key" class="input m-0">
                <div
                  class="input m-0"
                  :class="
                    errors.first('emmaus_training_form.' + obj.code) !==
                    undefined
                      ? 'input-error'
                      : ''
                  "
                >
                  <label :for="obj.code">
                    <span>{{ obj.name }}</span>
                    <!-- <span class="required-field-star">&#x2605;</span> -->
                  </label>

                  <input
                    class="m-0"
                    type="text"
                    v-model="translationModel.translations[Number(key)]"
                    :name="obj.code"
                    autocomplete="off"
                  />
                  <div>
                    <span
                      class="error"
                      v-html="errors.first('emmaus_training_form.' + obj.code)"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <!-- TYPE CODE -->
            <div
              class="input m-0"
              :class="
                errors.first('emmaus_training_form.fkTypeCodeId') !== undefined
                  ? 'input-error'
                  : ''
              "
            >
              <!-- SELECT -->
              <label for="fkTypeCodeId">
                <span v-lang="'training.label_training_type'"></span>
                <span class="required-field-star">&#x2605;</span>
              </label>
              <select
                v-model="model.fkTypeCodeId"
                v-validate="'required|excluded:0'"
                name="fkTypeCodeId"
                id="dropdownTrainingType"
              >
                <option
                  :value="null"
                  selected
                  v-lang="'emmaus.placeholder_for_training_type'"
                ></option>
                <option
                  class="select-option"
                  v-for="(obj, key) in types"
                  :key="key"
                  :value="obj.value"
                  >{{ obj.text }}</option
                >
              </select>
              <div>
                <span
                  class="error"
                  v-html="errors.first('emmaus_training_form.fkTypeCodeId')"
                ></span>
              </div>
            </div>
            <!--  -->
            <!-- DATE -->
            <div
              class="input m-0"
              :class="
                errors.first('emmaus_training_form.date') !== undefined
                  ? 'input-error'
                  : ''
              "
            >
              <label for="name">
                <span v-lang="'training.training_date'"></span>
                <span class="required-field-star">&#x2605;</span>
              </label>
              <date-picker
                name="date"
                v-model="model.date"
                :inputClass="'input-date-picker'"
                :locale="lang"
                :initDate="false"
              ></date-picker>
              <input
                type="hidden"
                name="date"
                v-validate="'required'"
                v-model="model.date"
              />
              <div>
                <span
                  class="error"
                  v-html="errors.first('emmaus_training_form.date')"
                ></span>
              </div>
            </div>
            <!--  -->
            <div
              v-responsive="{ small: (el) => el.width < 800 }"
              class="fields-container"
            >
              <div style="height: 100%">
                <!-- THEMES -->
                <fieldset name="trainingsTheme">
                  <legend>
                    <span class="name-group">{{
                      $lang("emmaus.label_themes")
                    }}</span>
                  </legend>

                  <div class="fieldset-div">
                    <div
                      v-for="(func, key) in themes"
                      class="input-checkbox"
                      :key="key"
                    >
                      <label :for="func.pkCodeId">
                        <input
                          :id="func.pkCodeId"
                          v-model="model.themeMultiSelect"
                          class="m-0"
                          type="checkbox"
                          autocomplete="off"
                          :value="func.order"
                          hidden
                        />
                        <span
                          v-html="func.name"
                          v-tooltip="
                            func.description == null
                              ? null
                              : $lang('emmaus.' + func.description)
                          "
                        ></span>
                      </label>
                    </div>
                  </div>
                </fieldset>
                <!-- CERTIFIED -->

                <div
                  style="margin-top: 10px !important"
                  class="input m-0 "
                  :class="
                    errors.first('emmaus_training_certified') !== undefined
                      ? 'input-error'
                      : ''
                  "
                >
                  <fieldset>
                    <!-- CHECKBOX -->
                    <div class="input-checkbox">
                      <label for="certified">
                        <input
                          class="m-0"
                          type="checkbox"
                          v-model="model.certified"
                          id="certified"
                          autocomplete="off"
                          hidden
                        />
                        <span v-lang="'emmaus.emmaus_certified'"></span>
                      </label>
                    </div>
                    <div>
                      <span
                        class="error"
                        v-html="errors.first('emmaus_training_form.certified')"
                      ></span>
                    </div>
                  </fieldset>
                </div>
                <!--  -->
              </div>
              <!-- FIELDS -->
              <fieldset name="trainingFields">
                <legend>
                  <span class="name-group">{{
                    $lang("emmaus.label_fields")
                  }}</span>
                </legend>

                <div class="fieldset-div">
                  <div
                    v-for="(func, key) in fields"
                    class="input-checkbox"
                    :key="key"
                  >
                    <label :for="func.pkCodeId">
                      <input
                        :id="func.pkCodeId"
                        v-model="model.fieldMultiSelect"
                        class="m-0"
                        type="checkbox"
                        autocomplete="off"
                        :value="func.order"
                        hidden
                      />
                      <span
                        v-html="func.name"
                        v-tooltip="
                          func.description == null
                            ? null
                            : $lang('emmaus.' + func.description)
                        "
                      ></span>
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
            <!-- -->
            <div
              v-if="$route.name == 'EmmausTrainingsForm'"
              class="trainings-person"
            >
              <div class="header card full-row">
                <h3>
                  <span v-lang="'training.label_people'"></span>
                </h3>
              </div>
              <div class="card table-container full-row">
                <NoData
                  v-if="!model.emmausPeople || model.emmausPeople.length == 0"
                ></NoData>
                <span v-else>
                  <TablePeople
                    :people="model.emmausPeople"
                    :form="true"
                    :searchModel="searchModelForm"
                    @searchSort="searchSort"
                    :orderColumn="searchModelForm.orderColumn"
                    :orderSort="searchModelForm.orderSort"
                    @removeExistPerson="removeExistPerson"
                  />
                </span>
              </div>
            </div>
            <div class="submit" id="sub">
              <Spinner
                :showSpinner="isSubmited"
                :buttonText="
                  $route.name == 'EmmausTrainingsForm'
                    ? $lang('general.button_submit')
                    : $lang('general.label_add')
                "
                :buttonClass="'primary'"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="trainings" v-if="$route.name == 'EmmausTrainingsForm'">
      <div class="header card">
        <h1>
          <i class="icon-users"></i>
          <span class="second-header" v-lang="'persons.title_index'"></span>
        </h1>
      </div>
      <div>
        <PersonForm
          @addPerson="addPerson"
          :searchModelPerson="searchModelPerson"
        />
      </div>
      <div class="card table-container">
        <Loader2 v-if="resultLoading" />
        <NoData v-else-if="!pagedPeople || pagedPeople.length == 0"></NoData>
        <span v-else>
          <TablePeople
            :people="pagedPeople"
            :searchModel="searchModelPerson"
            @addExistPerson="addExistPerson"
            :orderColumn="searchModelPerson.orderColumn"
            :orderSort="searchModelPerson.orderSort"
            :isTraining="true"
          />
          <div class="pagination-container table-paggination">
            <paginate
              v-if="pagedPeople.length != 0 && pageCount > 1"
              :page-count="pageCount"
              v-model="searchModelPerson.page"
              :click-handler="changePage"
              :prev-text="this.$lang('general.previous')"
              :next-text="this.$lang('general.next_page')"
              :container-class="'paggination'"
            ></paginate>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import PersonForm from "../Person/CreateForm.vue";
import { lang } from "@/global-functions";
import VeeValidate from "vee-validate";
import store from "./store";
import storeLanguage from "@/components/UserAndRolesManagement/store";
import Loader2 from "../../General/Loader2";
import peopleStore from "../Person/store";
import globalStore from "@/store";
import { Settings } from "luxon";
import Spinner from "@/components/General/Spinner";
import Datepicker from "vuejs-datepicker";
import { en, sr, srCYRL, hr } from "vuejs-datepicker/dist/locale";
import { ResponsiveDirective } from "vue-responsive-components";
import TablePeople from "../Person/Table";
import NoData from "../../General/NoData";
import Paginate from "vuejs-paginate";
import institutionStore from "../Institution/store";
import organizationsStore from "../Organisation/store";
import personStore from "../Person/store";
// import { float } from "html2canvas/dist/types/css/property-descriptors/float";

Vue.use(VeeValidate);
export default {
  components: {
    Datepicker,
    Paginate,
    TablePeople,
    Loader2,
    NoData,
    Spinner,
    PersonForm
  },
  props: {
    isSubmited: {
      type: Boolean,
      default: false
    },
    searchModelTraining: {
      type: Object,
      default: {
        name: "",
        fkTypeCodeId: null,
        date: null,
        page: 1,
        limit: 5,
        orderColumn: "",
        orderSort: ""
      }
    }
  },
  directives: {
    responsive: ResponsiveDirective
  },
  data() {
    return {
      en: en,
      sr: sr,
      srCYRL: srCYRL,
      hr: hr,
      lang:
        globalStore.state.lang == "sr"
          ? "sr-cyrl"
          : globalStore.state.lang == "bs"
          ? "sr"
          : globalStore.state.lang == "hr"
          ? "hr"
          : "en",
      localStorageUser: JSON.parse(localStorage.user),
      superAdmin: false,
      showTranslations: false,
      resultLoading: true,
      allEmausOrganisationUnits: [],
      existingTraining: {},
      nonAdminOrganisation: 0,
      model: {
        pkEmmausTrainingId: 0,
        name: "",
        orgName: "",
        fkTypeCodeId: null,
        fkEmmausOrganizationId: 0,
        date: null,
        certified: false,
        emmausPeople: [],
        themeMultiSelect: [],
        fieldMultiSelect: [],
        valuesForLanguages: []
      },
      searchModelPerson: {
        firstName: "",
        lastName: "",
        fkEmmausInstitutionId: null,
        fkGenderCodeId: null,
        page: 1,
        limit: 5,
        orderColumn: "",
        orderSort: ""
      },
      tempSearchModel: {
        name:
          this.$route.query.name && this.$route.query.name != ""
            ? this.$route.query.name
            : "",
        fkTypeCodeId: this.$route.query.fkTypeCodeId
          ? this.$route.query.fkTypeCodeId
          : null,
        date: this.$route.query.date ? this.$route.query.date : null,
        page: this.$route.query.page ? this.$route.query.page : 1,
        limit: this.$route.query.limit ? this.$route.query.limit : 10,
        fkEmmausOrganizationId: this.$route.query.fkEmmausOrganizationId
          ? this.$route.query.fkEmmausOrganizationId
          : null,

        orderColumn: this.$route.query.orderColumn
          ? this.$route.query.orderColumn
          : "",
        orderSort: this.$route.query.orderSort
          ? this.$route.query.orderSort
          : ""
      },
      searchModelForm: {
        page: 1,
        limit: 5,
        orderColumn: "",
        orderSort: ""
      },
      allPeople: [],
      themes: [],
      fields: [],
      types: [],
      institutions: [],
      genders: [],
      translationModel: {
        titleId: "",
        translations: []
      },
      dateError: {
        field: "date",
        msg: this.$lang("validation.required"),
        rule: "required",
        scope: "emmaus_training_form"
      },
      langauges: [],
      validation_messages: {
        custom: {
          name: {
            required: lang("validation.required")
          },
          fkEmmausOrganizationId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          },
          fkTypeCodeId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          },
          date: {
            required: lang("validation.required")
          },
          sr: {
            required: lang("validation.required")
          },
          hr: {
            required: lang("validation.required")
          },
          bs: {
            required: lang("validation.required")
          }
        }
      }
    };
  },

  watch: {
    "$route.query": function(val) {
      if (val.pkEmmausTrainingId == null) {
        this.model = {
          pkEmmausTrainingId: 0,
          name: "",
          fkTypeCodeId: null,
          fkEmmausOrganizationId: 0,
          date: null,
          certified: false,
          emmausPeople: [],
          themeMultiSelect: [],
          fieldMultiSelect: [],
          valuesForLanguages: []
        };
        this.translationModel = {
          titleId: "",
          translations: []
        };
        this.$nextTick(() => this.$validator.reset());
      }
    },
    model: {
      deep: true,
      handler() {
        this.searchModelTraining.name = this.model.name;
        this.searchModelTraining.date = this.model.date;
        this.searchModelTraining.fkTypeCodeId =
          this.model.fkTypeCodeId == 0 || this.model.fkTypeCodeId == "null"
            ? null
            : this.model.fkTypeCodeId;
        this.searchModelTraining.fkEmmausOrganizationId == 0 ||
        this.model.fkEmmausOrganizationId == "null"
          ? null
          : this.model.fkEmmausOrganizationId;
      }
    },
    pagedPeople: function(value) {
      if (value == null && this.pageCount > 0) this.searchModelPerson.page--;

      // if (this.trainings.length == 0 && this.searchModel.page > 1) {
      //   this.searchModel.page--;
      //   this.getTrainings();
      // } else if (n < o) {
      //   this.getTrainings();
      // }
    },
    errorModel() {
      this.$importModelStateErrors(
        this.errors,
        this.errorModel.modelState,
        "emmaus_training_form"
      );
    },
    $route(to, from) {
      if (_.isEmpty(this.$route.params)) {
        this.model = {
          certified: false,
          createdBy: 0,
          createdByName: "",
          createdOn: null,
          date: null,
          emmausOrganizationName: "",
          emmausPeople: [],
          fieldFlagsum: 0,
          fieldMultiSelect: [],
          fields: [],
          fkEmmausOrganizationId: 0,
          fkTypeCodeId: 0,
          isDeleted: false,
          name: "",
          pageCount: 0,
          pkEmmausTrainingId: null,
          themeFlagsum: 0,
          themeMultiSelect: [],
          themes: [],
          titleId: "",
          typeName: "",
          valuesForLanguages: []
        };
      }
    }
  },
  beforeDestroy() {
    //this.$toasted.clear();
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
    // },
    // created() {
    this.superAdmin = this.localStorageUser.user.superAdmin;

    this.getInstitutions();
    this.getOrganisations();
    this.getGenders();
    this.getAllPeople();
    this.getAllThemes();
    this.getAllFields();
    this.getAllLanguages();
    this.getAllTypes();
    if (
      this.$route.query.pkEmmausTrainingId &&
      this.$route.query.pkEmmausTrainingId != 0
    ) {
      this.getTrainingById(this.$route.query.pkEmmausTrainingId);
      setTimeout(() => {}, 200);
    } else {
      this.showTranslations = true;
    }
  },
  computed: {
    filteredPeople() {
      var $this = this;
      this.pagedPeople = null;

      return this.$_.filter(
        this.$_.differenceBy(
          $this.allPeople,
          $this.model.emmausPeople,
          "pkEmmausPersonId"
        ),
        function(p) {
          return (
            ($this.searchModelPerson.firstName
              ? p.firstName
                  .toLowerCase()
                  .includes($this.searchModelPerson.firstName.toLowerCase())
              : true) &&
            ($this.searchModelPerson.lastName
              ? p.lastName
                  .toLowerCase()
                  .includes($this.searchModelPerson.lastName.toLowerCase())
              : true) &&
            ($this.searchModelPerson.fkGenderCodeId
              ? $this.searchModelPerson.fkGenderCodeId == p.fkGenderCodeId
              : true) &&
            ($this.searchModelPerson.fkEmmausInstitutionId
              ? $this.searchModelPerson.fkEmmausInstitutionId ==
                p.fkEmmausInstitutionId
              : true)
          );
        }
      );
    },
    pageCount() {
      return this.$_.chunk(this.filteredPeople, this.searchModelPerson.limit)
        .length;
    },
    pagedPeople() {
      return this.$_.chunk(this.filteredPeople, this.searchModelPerson.limit)[
        this.searchModelPerson.page - 1
      ];
    }
  },
  methods: {
    addPerson(person) {
      person.emmausInstitutionName = this.$_.filter(this.institutions, function(
        o
      ) {
        return o.pkEmmausInstitutionId == person.fkEmmausInstitutionId;
      })[0].name;
      person.genderName = this.$_.filter(this.genders, function(o) {
        return Number(o.value) == person.fkGenderCodeId;
      })[0].text;
      this.getAllPeople();
      this.model.emmausPeople.push(person);
    },
    getGenders() {
      var $this = this;
      personStore
        .dispatch("GET_CODE_BY_ID", 2817)
        .then((response) => {
          $this.genders = response.data.model.items;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    getInstitutions() {
      var $this = this;
      institutionStore
        .dispatch("GET_ALL_EMMAUS_INSTITUTIONS")
        .then((response) => {
          $this.institutions = response.data.list;
        })
        .catch((error) => {});
    },
    getOrganisations() {
      var $this = this;
      storeLanguage
        .dispatch("GET_ALL_EMAUS_ORGANISATION_UNITS")
        .then((response) => {
          $this.allEmausOrganisationUnits = response.data.list;
        })
        .catch((error) => {});
    },
    resetSearch() {
      this.searchModel = {
        firstName: "",
        lastName: "",
        fkEmmausInstitutionId: null,
        fkGenderCodeId: null,
        page: 1,
        limit: 5,
        orderColumn: "",
        orderSort: ""
      };
    },
    addExistPerson(id) {
      var person = this.allPeople.find((p) => p.pkEmmausPersonId == id);
      this.model.emmausPeople.push(person);
    },
    removeExistPerson(id) {
      this.model.emmausPeople = this.$_.remove(
        this.model.emmausPeople,
        function(p) {
          return p.pkEmmausPersonId != id;
        }
      );
    },

    async saveFoundTraining() {
      var $this = this;
      let tempOriginName = "";
      this.$removeToast();
      await $this.getExactTraining();

      $this.existingTraining.forEach((e) => {
        if (e.originName == $this.model.name) tempOriginName = e.originName;
      });

      if (
        $this.model.pkEmmausTrainingId == 0 &&
        $this.existingTraining.length > 0 &&
        tempOriginName == $this.model.name &&
        tempOriginName != "" &&
        ($this.existingTraining[0].fkEmmausOrganizationId ==
          $this.model.fkEmmausOrganizationId ||
          $this.existingTraining[0].fkEmmausOrganizationId ==
            $this.nonAdminOrganisation)
      ) {
        Vue.toasted.show(lang("training.training_found"), {
          theme: "toasted-primary",
          position: "top-center",
          duration: 5000,
          action: [
            {
              text: lang("emmaus_persons.person_review"),
              onClick: (e, toastObject) => {
                this.$router.push({
                  name: "EmmausTrainingsIndex",
                  query: { ...$this.$route, ...$this.tempSearchModel }
                });
              }
            },
            {
              text: lang("training.create_training"),
              onClick: (e, toastObject) => {
                this.$removeToast();
                this.save();
              }
            }
          ]
        });
      } else $this.save();
    },
    async getExactTraining() {
      var $this = this;
      $this.tempSearchModel.name = $this.model.name;

      if ($this.superAdmin) {
        $this.tempSearchModel.fkEmmausOrganizationId =
          $this.model.fkEmmausOrganizationId;
      } else {
        organizationsStore
          .dispatch("GET_USER_ORGANIZATON", $this.localStorageUser.memberId)
          .then((response) => {
            this.tempSearchModel.fkEmmausOrganizationId =
              response.data.organization.pkFkEmmausOrganizationId;
            $this.nonAdminOrganisation =
              response.data.organization.pkFkEmmausOrganizationId;
          });
      }

      $this.resultsLoaded = false;

      await store
        .dispatch("SEARCH_EMMAUS_TRAINING", $this.tempSearchModel)
        .then((response) => {
          $this.existingTraining = response.data.list;

          if ($this.trainings.length > 0) $this.resultsLoaded = true;
        })
        .catch((error) => {});
    },
    save() {
      var $this = this;
      this.model.valuesForLanguages = [];
      for (let i = 0; i < this.translationModel.translations.length; i++) {
        var model = {
          titleId: this.model.titleId,
          langCode: this.langauges[i].code,
          titleValue: this.translationModel.translations[i]
        };
        this.model.valuesForLanguages.push(model);
      }

      this.$validator.validateAll("emmaus_training_form").then((valid) => {
        console.log("validator ", valid);
        if (valid) {
          if (this.model.pkEmmausTrainingId == 0) {
            store
              .dispatch("CREATE_EMMAUS_TRAINING", this.model)
              .then((response) => {
                this.$success("training.training_successfully_created");
                $this.isSubmited = false;

                $this.model.pkEmmausTrainingId = response.data.id;
                $this.errors.clear();
                $this.$emit("addTraining", { ...this.model });
                $this.model = {
                  pkEmmausTrainingId: 0,
                  name: "",
                  fkTypeCodeId: null,
                  fkEmmausOrganizationId: 0,
                  date: null,
                  certified: false,
                  emmausPeople: [],
                  themeMultiSelect: [],
                  fieldMultiSelect: [],
                  valuesForLanguages: []
                };
                $this.translationModel = {
                  titleId: "",
                  translations: []
                };
                if ($this.$route.name == "EmmausTrainingsForm") {
                  $this.$router.push({ name: "EmmausTrainingsIndex" });
                }
              })
              .catch((error) => {
                if (error.status == 400) $this.errorModel = error.data;
              });
          } else {
            store
              .dispatch("EDIT_EMMAUS_TRAINING", this.model)
              .then((response) => {
                this.$success("training.training_successfully_updated");
                $this.isSubmited = false;
                $this.$router.push({ name: "EmmausTrainingsIndex" });
              })
              .catch((error) => {
                if (error.status == 400) $this.errorModel = error.data;
              });
          }
        }
      });
    },
    search(code) {
      for (var i = 0; i < this.model.valuesForLanguages.length; i++) {
        if (this.model.valuesForLanguages[i].langCode === code) {
          return this.model.valuesForLanguages[i].titleValue;
        }
      }
    },
    getTrainingById(id) {
      var $this = this;
      store
        .dispatch("GET_EMMAUS_TRAINING_BY_ID", id)
        .then((response) => {
          $this.model = response.data.model;
          $this.getTranslationsByID(response.data.model.titleId);
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    getTranslationsByID(id) {
      var $this = this;
      storeLanguage
        .dispatch("GET_TRANSLATION_BY_ID", id)
        .then((response) => {
          $this.model.valuesForLanguages = response.data;
          $this.model.titleId = $this.model.valuesForLanguages[0].titleId;

          for (let i = 0; i < $this.langauges.length; i++) {
            $this.translationModel.translations[i] = $this.search(
              $this.langauges[i].code
            );
          }

          $this.showTranslations = true;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    getAllTypes() {
      var $this = this;
      store
        .dispatch("GET_CODE_BY_ID", 42906)
        .then((response) => {
          $this.types = response.data.model.items;
        })
        .catch((error) => {});
    },
    getAllThemes() {
      var $this = this;
      store
        .dispatch("GET_CODES_BY_TYPE_ID", 42909)
        .then((response) => {
          $this.themes = response.data.model.children;
        })
        .catch((error) => {});
    },
    getAllFields() {
      var $this = this;
      store
        .dispatch("GET_CODES_BY_TYPE_ID", 42910)
        .then((response) => {
          $this.fields = response.data.model.children;
        })
        .catch((error) => {});
    },
    getAllLanguages() {
      var $this = this;
      storeLanguage
        .dispatch("GET_ALL_LANGUAGES")
        .then((response) => {
          $this.langauges = response.data.list;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    async getAllPeople() {
      var $this = this;
      this.resultLoading = true;
      await peopleStore
        .dispatch("GET_ALL_EMMAUS_PEOPLE")
        .then((response) => {
          $this.allPeople = response.data.list;
          console.log("all ppl:", this.allPeople);
          this.resultLoading = false;
        })
        .catch((error) => {});
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../../assets/css/colors";
.search {
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
}
.buttons-search {
  grid-column: 1/5;
}
.table-paggination {
  margin: 0 !important;
}
.trainings-person {
  grid-column: 1/3;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.fieldset-div {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
}
div.input-checkbox {
  color: black;
  display: -webkit-inline-flex;
  margin: 0px 25px;
  margin-top: 5px;

  // label {
  //   // margin-bottom: 10px !important;
  // }
}
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  & > div {
    // display: flex;
    // flex: 1;
    // align-items: center;
    justify-content: center;
  }
  .translations-class {
    display: grid;
    grid-template-columns: 34% 33% 33%;
    grid-gap: 10px;
    grid-column: 1/3;
    padding: 0px 10px;
  }
  .submit {
    color: red;
    grid-column: 1/3;
  }
}

textarea {
  height: 26px;
}
.second-header {
  padding-bottom: 5px;
}
.container.grid.small {
  grid-template-columns: 1fr !important;
}
.fields-container {
  grid-column: 1/3;
}
.fields-container.small {
  grid-column: 1/3;
}
</style>
