import Index from "./Index.vue";
import Insert from "./Create.vue";
import Edit from "./Edit.vue";
import AdminLayout from "../../UserAndRolesManagement/AdminPanel/AdminLayout.vue";
import auth from "@/middleware";

const router = [
  {
    path: "/emmaus-organizations",
    name: "AdminLayout",
    component: AdminLayout,
    meta: {
      middleware: auth,
    },
    children: [
      {
        path: "index",
        name: "EmmausOrganizationIndex",
        component: Index,
      },
      {
        path: "insert",
        name: "EmmausOrganizationInsert",
        component: Insert,
      },
      {
        path: "edit",
        name: "EmmausOrganizationEdit",
        component: Edit,
      },
    ],
  },
];

export default router;
