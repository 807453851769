<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-file-text"></i>
        <span>{{title}}</span>
      </h1>
      <div class="actions">
        <button class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></button>
      </div>
    </div>
    <div>
      <div class="card">
        <form id="user_form" v-on:submit.prevent="save()" data-vv-scope="user_form">
          <div
            class="input m-0"
            :class="errors.first('user_form.name') !== undefined ? 'input-error' : '' "
          >
            <label for="name" v-lang="'language.name'"></label>
            <input
              v-validate="'required'"
              class="m-0"
              v-model="model.name"
              type="text"
              name="name"
              autocomplete="off"
            />
            <div class="error" v-html="errors.first('user_form.name')"></div>
          </div>
          <div
            class="input m-0"
            :class="errors.first('user_form.code') !== undefined ? 'input-error' : '' "
          >
            <label for="code" v-lang="'language.code'"></label>
            <input
              v-validate="'required'"
              class="m-0"
              type="text"
              v-model="model.code"
              name="code"
              autocomplete="off"
            />
            <div class="error" v-html="errors.first('user_form.code')"></div>
          </div>
          <div class="input m-0">
            <label for="lastname" v-lang="'language.description'"></label>
            <input
              class="m-0"
              type="text"
              v-model="model.description"
              name="lastname"
              autocomplete="off"
            />
          </div>
          <div class="submit">
            <button class="primary" v-lang="'general.button_submit'"></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { lang } from "@/global-functions";
import store from "../store";

export default {
  data() {
    return {
      isFormSubmited: false,
      title: "",
      model: {
        pkLanguageId: 0,
        name: "",
        code: "",
        description: ""
      },
      validation_messages: {
        custom: {
          name: {
            required: lang("validation.required")
          },
          code: {
            required: lang("validation.required")
          }
        }
      }
    };
  },
  created() {
    if (this.$route.params.id) {
      this.title = this.$lang("language.edit_language");
      this.getByID(this.$route.params.id);
    } else {
      this.title = this.$lang("language.create_language");
    }
  },
  methods: {
    save() {
      if (this.model.pkLanguageId == 0) {
        this.create();
      } else {
        this.edit();
      }
    },
    getByID(id) {
      store
        .dispatch("GET_LANGUAGE_BY_ID", id)
        .then(response => {
          this.model = response.data.model;
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    create() {
      this.$validator.validateAll("user_form").then(valid => {
        if (valid) {
          var $this = this;
          store
            .dispatch("CREATE_LANGUAGE", this.model)
            .then(response => {
              this.$router.push({
                name: "LanguagesIndex"
              });
            })
            .catch(error => {
              if (error.status == 400) $this.errorModel = error.data;
            });
        }
      });
    },
    edit() {
      this.$validator.validateAll("user_form").then(valid => {
        if (valid) {
          var $this = this;
          store
            .dispatch("UPDATE_LANGUAGE", this.model)
            .then(response => {
              this.$router.push({
                name: "LanguagesIndex"
              });
            })
            .catch(error => {
              if (error.status == 400) $this.errorModel = error.data;
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.submit {
  margin-top: 10px;
}
</style>