<template>
  <div
    :class="$route.name == 'EmmausPersonForm' ? 'container grid' : ''"
    v-responsive="{ small: (el) => el.width < 1200 }"
  >
    <div>
      <div v-if="$route.name == 'EmmausPersonForm'" class="header card">
        <h1>
          <i class="icon-office"></i>
          <span>{{
            $route.query.pkEmmausPersonId && $route.query.pkEmmausPersonId != 0
              ? $lang("emmaus_persons.edit_emmaus_person")
              : $lang("emmaus_persons.create_emmaus_person")
          }}</span>
        </h1>
        <div class="actions">
          <a
            class="primary"
            v-on:click="$router.push({ name: 'EmmausPeopleIndex' })"
            v-lang="'general.button_back'"
          ></a>
        </div>
      </div>
      <div class="card">
        <div>
          <form v-on:submit.prevent="save()" data-vv-scope="emmaus_person_form">
            <!-- NAME -->
            <div
              class="input m-0"
              :class="
                errors.first('emmaus_person_form.firstName') !== undefined
                  ? 'input-error'
                  : ''
              "
            >
              <label for="firstName">
                <span v-lang="'general.first_name'"></span>
                <span class="required-field-star">&#x2605;</span>
              </label>
              <input
                class="m-0"
                v-validate="'required'"
                v-model="model.firstName"
                type="text"
                name="firstName"
                autocomplete="off"
              />
              <div>
                <span
                  class="error"
                  v-html="errors.first('emmaus_person_form.firstName')"
                ></span>
              </div>
            </div>
            <!--  -->
            <!-- LAST NAME -->
            <div
              class="input m-0"
              :class="
                errors.first('emmaus_person_form.lastName') !== undefined
                  ? 'input-error'
                  : ''
              "
            >
              <label for="lastName">
                <span v-lang="'general.last_name'"></span>
                <span class="required-field-star">&#x2605;</span>
              </label>
              <input
                class="m-0"
                v-validate="'required'"
                v-model="model.lastName"
                type="text"
                name="lastName"
                autocomplete="off"
              />
              <div>
                <span
                  class="error"
                  v-html="errors.first('emmaus_person_form.lastName')"
                ></span>
              </div>
            </div>
            <!--  -->
            <!-- INSTITUTION -->
            <div
              class="input m-0"
              :class="
                errors.first('emmaus_person_form.fkEmmausInstitutionId') !==
                undefined
                  ? 'input-error'
                  : ''
              "
            >
              <!-- SELECT -->
              <label for="fkEmmausInstitutionId">
                <span v-lang="'emmaus.emmaus_institution'"></span>
                <span class="required-field-star">&#x2605;</span>
              </label>
              <select
                @change="updateCurrentInstitution()"
                v-model="model.fkEmmausInstitutionId"
                v-validate="'required|excluded:0'"
                name="fkEmmausInstitutionId"
                id="dropdownInstitutions"
              >
                <option
                  value="0"
                  selected
                  v-lang="'emmaus.placeholder_for_emmaus_institution'"
                ></option>
                <option
                  v-for="(obj, key) in institutions"
                  :key="key"
                  :value="obj.pkEmmausInstitutionId"
                >
                  {{ obj.name }}
                </option>
              </select>
              <div>
                <span
                  class="error"
                  v-html="
                    errors.first('emmaus_person_form.fkEmmausInstitutionId')
                  "
                ></span>
              </div>
            </div>
            <!--  -->
            <!-- GENDER -->
            <div
              class="input m-0"
              :class="
                errors.first('emmaus_person_form.fkGenderCodeId') !== undefined
                  ? 'input-error'
                  : ''
              "
            >
              <!-- SELECT -->
              <label for="fkGenderCodeId">
                <span v-lang="'general.gender'"></span>
                <span class="required-field-star">&#x2605;</span>
              </label>
              <select
                v-model="model.fkGenderCodeId"
                v-validate="'required|excluded:0'"
                name="fkGenderCodeId"
                id="dropdownGenderType"
              >
                <option
                  value="0"
                  selected
                  v-lang="'emmaus.placeholder_for_gender'"
                ></option>
                <option
                  v-for="(obj, key) in genders"
                  :key="key"
                  :value="obj.value"
                >
                  {{ obj.text }}
                </option>
              </select>
              <div>
                <span
                  class="error"
                  v-html="errors.first('emmaus_person_form.fkGenderCodeId')"
                ></span>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="header card full-row">
              <h3>
                <span>{{ $lang("emmaus_persons.institutions_history") }}</span>
              </h3>
            </div>
            <!-- LIST OF INSTITUTION -->
            <div class="input m-0 full-row">
              <!-- SELECT -->
              <label for="fkEmmausInstitutionId">
                <span v-lang="'emmaus.emmaus_institution'"></span>
              </label>
              <select
                @change="addSelectedInstitutions()"
                v-model="tempEmmausInstitutionId"
                name="fkEmmausInstitutionId"
                id="dropdownInstitutions"
              >
                <option
                  value="0"
                  selected
                  v-lang="
                    'emmaus_persons.placeholder_for_emmaus_institution_history'
                  "
                ></option>
                <option
                  v-for="(obj, key) in filteredInstitutions"
                  :key="key"
                  :value="obj.pkEmmausInstitutionId"
                >
                  {{ obj.name }}
                </option>
              </select>
            </div>
            <div v-for="(obj, key) in model.personInstitutions" :key="key">
              <InstitutionsTag
                :institutions="obj"
                @deleteInstitutions="deleteInstitutions"
              />
            </div>

            <!--  -->
            <div
              v-if="$route.name == 'EmmausPersonForm'"
              class="trainings-person"
            >
              <div class="header card" v-if="!loading">
                <h1>
                  <span v-lang="'training.label_person_trainings'"></span>
                </h1>
              </div>
              <div class="card table-container" v-if="!loading">
                <NoData
                  v-if="
                    !model.emmausTrainings || model.emmausTrainings.length == 0
                  "
                ></NoData>
                <span v-else>
                  <TableTraining
                    :trainings="model.emmausTrainings"
                    :form="true"
                    :searchModel="searchModelForm"
                    @searchSort="searchSort"
                    :orderColumn="searchModelForm.orderColumn"
                    :orderSort="searchModelForm.orderSort"
                    @removeExistTraining="removeExistTraining"
                  />
                  <!-- <div class="pagination-container table-paggination">
                    <paginate
                      v-if="filteredTrainings.length != 0 && pageCount > 1"
                      :page-count="pageCount"
                      v-model="searchModel.page"
                      :click-handler="changePage"
                      :prev-text="this.$lang('general.previous')"
                      :next-text="this.$lang('general.next_page')"
                      :container-class="'paggination'"
                    ></paginate>
                  </div> -->
                </span>
              </div>
            </div>

            <div class="submit" id="sub">
              <!-- <button class="primary" v-lang="'general.button_submit'"></button> -->
              <Spinner
                :showSpinner="isSubmited"
                :buttonText="
                  $route.name == 'EmmausPersonForm'
                    ? $lang('general.button_submit')
                    : $lang('general.label_add')
                "
                :buttonClass="'primary'"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="$route.name == 'EmmausPersonForm'">
      <div class="header card">
        <h1>
          <i class="icon-office"></i>
          <span
            class="second-header"
            v-lang="'training.label_trainings'"
          ></span>
        </h1>
      </div>
      <div>
        <TrainingForm
          @addTraining="addTraining"
          :searchModelTraining="searchModelTraining"
        />
      </div>
      <div class="card table-container">
        <Loader2 v-if="resultLoading" />
        <NoData
          class="top-margin"
          v-else-if="!pagedTrainings || pagedTrainings.length == 0"
        ></NoData>
        <span v-else>
          <TableTraining
            :key="key"
            :trainings="pagedTrainings"
            :searchModel="searchModelTraining"
            @addExistTraining="addExistTraining"
            :orderColumn="searchModelTraining.orderColumn"
            :orderSort="searchModelTraining.orderSort"
          />
          <div class="pagination-container table-paggination">
            <paginate
              v-if="pagedTrainings.length != 0 && pageCount > 1"
              :page-count="pageCount"
              v-model="searchModelTraining.page"
              :click-handler="changePage"
              :prev-text="this.$lang('general.previous')"
              :next-text="this.$lang('general.next_page')"
              :container-class="'paggination'"
            ></paginate>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { lang } from "@/global-functions";
import VeeValidate from "vee-validate";
import store from "./store";
import institutionStore from "../Institution/store";
import trainingStore from "../Traning/store";
import Loader2 from "../../General/Loader2";
import Spinner from "@/components/General/Spinner";
import TableTraining from "../Traning/Table";
import NoData from "../../General/NoData";
import Paginate from "vuejs-paginate";
import TrainingForm from "../Traning/Form";
import { ResponsiveDirective } from "vue-responsive-components";
import InstitutionsTag from "../Person/InstitutionsTag";

Vue.use(VeeValidate);
export default {
  props: {
    isSubmited: {
      type: Boolean,
      default: false,
    },
    searchModelPerson: {
      type: Object,
      default: {
        firstName: "",
        lastName: "",
        fkEmmausInstitutionId: null,
        fkGenderCodeId: null,
        page: 1,
        limit: 5,
        orderColumn: "",
        orderSort: "",
      },
    },
  },
  directives: {
    responsive: ResponsiveDirective,
  },
  components: {
    Loader2,
    Paginate,
    Spinner,
    TableTraining,
    NoData,
    TrainingForm,
    InstitutionsTag,
  },
  data() {
    return {
      checked: true,
      resultLoading: true,
      key: 1,
      tempEmmausInstitutionId: 0,
      institutionsSearchModel: {
        page: 1,
        limit: 999,
        orderColumn: "",
        orderSort: "",
        IsDeleted: true,
      },
      historyInstitutions: [],
      model: {
        pkEmmausPersonId: 0,
        firstName: "",
        lastName: null,
        fkEmmausInstitutionId: 0,
        emmausTrainings: [],
        personInstitutions: [],
        fkGenderCodeId: 0,
        emmausInstitutionName: "",
        genderName: "",
      },
      allTrainings: [],
      filtered: [],
      types: [],
      searchModelTraining: {
        name: "",
        fkTypeCodeId: null,
        date: null,
        page: 1,
        limit: 5,
        orderColumn: "",
        orderSort: "",
      },
      searchModelForm: {
        page: 1,
        limit: 5,
        orderColumn: "",
        orderSort: "",
      },
      institutions: [],
      genders: [],
      validation_messages: {
        custom: {
          firstName: {
            required: lang("validation.required"),
          },
          lastName: {
            required: lang("validation.required"),
          },
          fkEmmausInstitutionId: {
            required: lang("validation.required"),
            excluded: lang("validation.required"),
          },
          fkGenderCodeId: {
            required: lang("validation.required"),
            excluded: lang("validation.required"),
          },
        },
      },
    };
  },
  watch: {
    model: {
      deep: true,
      handler() {
        this.searchModelPerson.firstName = this.model.firstName;
        this.searchModelPerson.lastName = this.model.lastName;
        this.searchModelPerson.fkEmmausInstitutionId =
          this.model.fkEmmausInstitutionId == 0
            ? null
            : this.model.fkEmmausInstitutionId;
        this.searchModelPerson.fkGenderCodeId =
          this.model.fkGenderCodeId == 0 ? null : this.model.fkGenderCodeId;
      },
    },
    pagedTrainings: function (value) {
      if (value == null && this.pageCount > 0) this.searchModelTraining.page--;

      // if (value == null && this.searchModel.page > 1 && this.pageCount > 0)
      //   this.searchModel.page--;

      // if (this.trainings.length == 0 && this.searchModel.page > 1) {
      //   this.searchModel.page--;
      //   this.getTrainings();
      // } else if (n < o) {
      //   this.getTrainings();
      // }
    },
    errorModel() {
      this.$importModelStateErrors(
        this.errors,
        this.errorModel.modelState,
        "emmaus_person_form"
      );
    },
  },

  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },

  created() {
    this.getTypes();
    this.getAllTrainings();
    this.getAllInstitutions();
    this.getHistoryInstitutions();
    this.getAllGenders();
    if (
      this.$route.query.pkEmmausPersonId &&
      this.$route.query.pkEmmausPersonId != 0
    ) {
      this.getPersonById(this.$route.query.pkEmmausPersonId);
    } else {
    }
  },
  computed: {
    filteredInstitutions() {
      var $this = this;
      var institutionsWithoutCurrent = this.historyInstitutions.filter(
        (institution) =>
          institution.pkEmmausInstitutionId != $this.model.fkEmmausInstitutionId
      );

      return this.$_.filter(
        this.$_.differenceBy(
          institutionsWithoutCurrent,
          $this.model.personInstitutions,
          "pkEmmausInstitutionId"
        )
      );
    },
    filteredTrainings() {
      var $this = this;
      return this.$_.filter(
        this.$_.differenceBy(
          $this.allTrainings,
          $this.model.emmausTrainings,
          "pkEmmausTrainingId"
        ),
        function (p) {
          return (
            ($this.searchModelTraining.name
              ? p.name
                  .toLowerCase()
                  .includes($this.searchModelTraining.name.toLowerCase())
              : true) &&
            ($this.searchModelTraining.fkTypeCodeId
              ? $this.searchModelTraining.fkTypeCodeId == p.fkTypeCodeId
              : true) &&
            ($this.searchModelTraining.date
              ? $this.$moment($this.searchModelTraining.date).isSame(p.date)
              : true)
          );
        }
      );
    },
    pageCount() {
      return this.$_.chunk(
        this.filteredTrainings,
        this.searchModelTraining.limit
      ).length;
    },
    pagedTrainings() {
      return this.$_.chunk(
        this.filteredTrainings,
        this.searchModelTraining.limit
      )[this.searchModelTraining.page - 1];
    },
  },
  methods: {
    updateCurrentInstitution(id) {
      if (this.model.fkEmmausInstitutionId != 0) {
        var historyInstitutions = this.model.personInstitutions.find(
          (i) => i.pkEmmausInstitutionId == this.model.fkEmmausInstitutionId
        );
        if (historyInstitutions) {
          this.deleteInstitutions(historyInstitutions.pkEmmausInstitutionId);
        }
      }
    },
    addSelectedInstitutions() {
      if (this.tempEmmausInstitutionId != 0) {
        var objInstitution = this.filteredInstitutions.find(
          (i) => i.pkEmmausInstitutionId == this.tempEmmausInstitutionId
        );
        this.model.personInstitutions.push(objInstitution);
        this.tempEmmausInstitutionId = 0;
      }
    },
    deleteInstitutions(id) {
      var $this = this;
      this.model.personInstitutions = this.$_.remove(
        $this.model.personInstitutions,
        function (i) {
          return i.pkEmmausInstitutionId != id;
        }
      );
    },

    changePage: function (page) {
      this.searchModelTraining.page = page;
    },
    addTraining(training) {
      var $this = this;
      training.typeName = this.$_.filter($this.types, function (o) {
        return Number(o.value) == training.fkTypeCodeId;
      })[0].text;
      this.model.emmausTrainings.push(training);
      this.getAllTrainings();
    },
    getTypes() {
      var $this = this;
      store
        .dispatch("GET_CODE_BY_ID", 42906)
        .then((response) => {
          $this.types = response.data.model.items;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    addExistTraining(id) {
      var training = this.allTrainings.find((t) => t.pkEmmausTrainingId == id);
      this.model.emmausTrainings.push(training);
    },
    removeExistTraining(id) {
      var $this = this;
      this.model.emmausTrainings = this.$_.remove(
        $this.model.emmausTrainings,
        function (t) {
          return t.pkEmmausTrainingId != id;
        }
      );
    },
    save() {
      var $this = this;
      this.$validator.validateAll("emmaus_person_form").then((valid) => {
        if (valid) {
          if (this.model.pkEmmausPersonId == 0) {
            store
              .dispatch("CREATE_EMMAUS_PERSON", this.model)
              .then((response) => {
                $this.$success("emmaus_persons.person_successfully_created");
                $this.isSubmited = false;

                $this.model.pkEmmausPersonId = response.data.id;
                $this.$emit("addPerson", { ...$this.model });
                $this.model = {
                  pkEmmausPersonId: 0,
                  firstName: "",
                  emmausInstitutionName: "",
                  genderName: "",
                  lastName: null,
                  fkEmmausInstitutionId: 0,
                  emmausTrainings: [],
                  personInstitutions: [],
                  fkGenderCodeId: 0,
                };
                this.$nextTick(() => this.$validator.reset());

                if ($this.$route.name == "EmmausPersonForm") {
                  $this.$router.push({ name: "EmmausPeopleIndex" });
                }
              })
              .catch((error) => {
                if (error.status == 400) $this.errorModel = error.data;
              });
          } else {
            store
              .dispatch("EDIT_EMMAUS_PERSON", this.model)
              .then((response) => {
                this.$success("emmaus_persons.person_successfully_updated");
                $this.isSubmited = false;
                $this.$router.push({ name: "EmmausPeopleIndex" });
              })
              .catch((error) => {
                if (error.status == 400) $this.errorModel = error.data;
              });
          }
        }
      });
    },
    getPersonById(id) {
      var $this = this;
      store
        .dispatch("GET_EMMAUS_PERSON_BY_ID", id)
        .then((response) => {
          $this.model = response.data.model;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    getAllGenders() {
      var $this = this;
      store
        .dispatch("GET_CODE_BY_ID", 2817)
        .then((response) => {
          $this.genders = response.data.model.items;
        })
        .catch((error) => {});
    },
    getAllInstitutions() {
      var $this = this;
      institutionStore
        .dispatch("GET_ALL_EMMAUS_INSTITUTIONS")
        .then((response) => {
          $this.institutions = response.data.list;
        })
        .catch((error) => {});
    },
    getHistoryInstitutions() {
      var $this = this;
      institutionStore
        .dispatch("SEARCH_EMMAUS_INSTITUTIONS", this.institutionsSearchModel)
        .then((response) => {
          $this.historyInstitutions = response.data.list;
        })
        .catch((error) => {});
    },
    getAllTrainings() {
      var $this = this;
      this.resultLoading = true;
      trainingStore
        .dispatch("GET_ALL_EMMAUS_TRAININGS")
        .then((response) => {
          $this.allTrainings = response.data.list;
          this.resultLoading = false;
        })
        .catch((error) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/css/colors";
.table-paggination {
  margin: 0 !important;
}
.trainings-person {
  grid-column: 1/3;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.fieldset-div {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
}
div.input-checkbox {
  color: black;
  display: -webkit-inline-flex;
  margin: 0px 25px;
  margin-top: 5px;

  label {
    margin-bottom: 10px !important;
  }
}
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  & > div {
    // display: flex;
    // flex: 1;
    // align-items: center;
    justify-content: center;
  }
  .translations-class {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    grid-column: 1/3;
  }
  .submit {
    color: red;
    grid-column: 1/3;
  }
}

textarea {
  height: 26px;
}
.second-header {
  padding-bottom: 5px;
}
.buttons-search {
  grid-column: 1/5;
}
.container.grid.small {
  grid-template-columns: 1fr !important;
}
</style>
