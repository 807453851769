<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-paperplane"></i>
        <span v-lang="'actionGroup.label_edit_action_group'"></span>
      </h1>
      <div class="actions">
        <button class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></button>
      </div>
    </div>
    <notify />
    <div>
      <div class="card">
        <Form v-on:save="edit" :actionGroupData="actionGroup" :errorModel="errorModel" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import Form from "./Form";
import { lang } from "@/global-functions";
import { mapState } from "vuex";
import globalStore from "@/store";

export default {
  components: {
    Form
  },
  data() {
    return {
      actionGroup: {},
      errorModel: {
        message: "",
        key: "",
        yes: lang("general.label_yes"),
        no: lang("general.label_no")
      }
    };
  },
  created() {
    this.getActionGroupByID();
  },
  methods: {
    edit: function(value) {
      var $this = this;
      store
        .dispatch("EDIT_ACTION_GROUP", value)
        .then(response => {
          $this.$success("actionGroup.action_group_successfully_updated");
          $this.$router.push({ name: "ActionGroups" });
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    getActionGroupByID() {
      var $this = this;
      store
        .dispatch("GET_ACTION_GROUP_BY_ID", $this.$route.params.id)
        .then(response => {
          $this.actionGroup = response.data.model;
        })
        .catch(error => {});
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}
</style>
