import Vue from "vue";
import Vuex from "vuex";
import Store from "../../../store";
import { lang } from "@/global-functions";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    list: []
  },
  actions: {
    GET_ENTITY_RELATIONS: function(store: any, model: any) {
      return new Promise((reslove, reject) => {
        Store.state.$axios_auth
          .get("ManagedEntityTypes")
          .then((response: any) => {
            reslove(response.data);
          });
      });
    },
    GET_ENTITY_RELATION_BY_IDS: function(store: any, model: any) {
      return new Promise((reslove, reject) => {
        Store.state.$axios_auth
          .get("ManagedEntityTypes", { params: model })
          .then((response: any) => {
            reslove(response);
          });
      });
    },
    CREATE_ENTITY_RELATION: function(store: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("ManagedEntityTypes", model)
          .then((response: any) => {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    }
  }
});
