<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-paperplane"></i>
        <span v-lang="'actionGroup.action_groups'"></span>
      </h1>
      <div class="actions">
        <router-link
          v-if="$role(10111)"
          class="primary"
          :to="{ name: 'ActionGroupCreate', params: { id: 0 } }"
          v-lang="'general.label_add'"
        ></router-link>
      </div>
    </div>
    <div class="card search">
      <div
        class="input m-0"
        :class="
          errors.first('action_form.titleId') !== undefined ? 'input-error' : ''
        "
      >
        <label for="name" v-lang="'action.label_name_of_action'"></label>
        <input class="m-0" v-model="name" type="text" name="name" autocomplete="off" autofocus />
      </div>
    </div>
    <notify />
    <NoData v-if="!search || search.length == 0"></NoData>
    <div v-else class="card actions table-container">
      <table class="table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th v-lang="'actionGroup.label_for_name'">&nbsp;</th>
            <th v-lang="'actionGroup.label_for_description'">&nbsp;</th>
            <th v-lang="'actionGroup.label_for_titleId'">&nbsp;</th>
            <th v-lang="'actionGroup.label_for_isSystem'">&nbsp;</th>
            <th v-lang="'actionGroup.label_for_isBuiltIn'">&nbsp;</th>

            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, key) in search" :key="key">
            <td>{{ pageSize * (pageNum - 1) + key + 1 }}</td>
            <td>{{ obj.name }}</td>
            <td>{{ obj.description }}</td>
            <td>{{ obj.titleId }}</td>
            <td>{{ obj.isSystem == false ? no : yes }}</td>
            <td>{{ obj.isBuiltin == false ? no : yes }}</td>
            <td>
              <router-link
                v-if="$role(10114)"
                class="default small"
                :to="{
                  name: 'ActionGroupDetails',
                  params: { id: obj.pkActionGroupId }
                }"
                v-lang="'general.label_details'"
              ></router-link>
              <router-link
                v-if="$role(10112)"
                class="primary small"
                :to="{
                  name: 'ActionGroupEdit',
                  params: { id: obj.pkActionGroupId }
                }"
                v-lang="'general.label_edit'"
              ></router-link>
              <a
                v-if="$role(10113)"
                class="error small"
                v-on:click="deleteActionGroup(obj.pkActionGroupId)"
                v-lang="'general.label_delete'"
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination-container">
      <paginate
        v-if="search.length != 0"
        :page-count="pageCount"
        :click-handler="changePage"
        :prev-text="this.$lang('general.previous')"
        :next-text="this.$lang('general.next_page')"
        :container-class="'paggination'"
      ></paginate>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import Paginate from "vuejs-paginate";
import NoData from "../../../General/NoData";

Vue.use(Toasted);

export default {
  components: {
    NoData,
    paginate: Paginate
  },
  data() {
    return {
      actionGroups: [],
      httpMethods: [],
      name: "",
      controller: "",
      httpMethod: 0,
      pageSize: 10,
      pageNum: 1,
      pageCount: 0,
      yes: lang("general.label_yes"),
      no: lang("general.label_no")
    };
  },
  created() {
    this.getAllActionGroups();
    this.getHttpMethods();
  },
  methods: {
    changePage: function(pageNum) {
      this.pageNum = pageNum;
    },
    findMethodForId(fkHttpMethodId) {
      var $this = this;
      var methods = $this.httpMethods.filter(
        item => item.pkHttpMethodId == fkHttpMethodId
      );
      if (methods.length > 0) return methods[0];
      else return { name: "" };
    },
    getAllActionGroups() {
      var $this = this;
      store
        .dispatch("GET_All_ACTION_GROUPS")
        .then(response => {
          $this.actionGroups = response.data.list;
        })
        .catch(error => {});
    },
    getHttpMethods() {
      var $this = this;
      store
        .dispatch("GET_ALL_HTTP_METHODS")
        .then(response => {
          $this.httpMethods = response.data.list;
        })
        .catch(error => {});
    },
    deleteActionGroup(id) {
      this.$removeToast();
      Vue.toasted.show(
        lang(
          "actionGroup.action_group_do_you_want_to_delete_this_action_group"
        ),
        {
          theme: "toasted-primary",
          position: "top-center",
          duration: 5000,
          action: [
            {
              text: lang("general.label_yes"),
              onClick: (e, toastObject) => {
                var $this = this;
                store
                  .dispatch("DELETE_ACTION_GROUP", id)
                  .then(response => {
                    this.$success(
                      "actionGroup.action_group_successfully_deleted"
                    );
                    $this.getAllActionGroups();
                    this.notifyKey += 1;
                  })
                  .catch(error => {});
                toastObject.goAway(0);
              }
            },
            {
              text: lang("general.label_no"),
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              }
            }
          ]
        }
      );
    }
  },
  computed: {
    search() {
      var $this = this;
      var allData = this.$_.filter($this.actionGroups, function(x) {
        return x.name.toLowerCase().includes($this.name.toLowerCase());
      });
      $this.pageCount =
        allData.length % $this.pageSize == 0
          ? parseInt(allData.length / $this.pageSize)
          : parseInt(allData.length / $this.pageSize) + 1;

      return allData.slice(
        (this.pageNum - 1) * this.pageSize,
        this.pageNum * this.pageSize
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/colors";

.search {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

.actions {
  position: relative;
}
.paggination {
  position: absolute !important;
}
</style>
