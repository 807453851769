<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-paperplane"></i>
        <span v-lang="'queries.create'"></span>
      </h1>
      <div class="actions">
        <button class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></button>
      </div>
    </div>
    <div>
      <Form v-on:save="save" :errorModel="errorModel" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../store";
import Form from "./Form";

export default {
  components: {
    Form
  },
  data() {
    return {
      errorModel: {}
    };
  },
  methods: {
    save: function(value) {
      var $this = this;
      store
        .dispatch("CREATE_QUERY", value)
        .then(response => {
          this.$store.commit("SUCCESS_MESSAGE", "queries.created_success");
          $this.$router.push({ name: "Queries" });
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;

          
        });
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}
</style>