<template>
  <div>
    <div class="pt-60px">
      <div v-if="hasPermission">
        <table>
          <thead>
            <th colspan="2">{{ $lang("general.user_manual") }}:</th>
          </thead>
          <tbody>
            <tr v-if="surveyUserManualPermission">
              <td>
                <span class="px-10px" v-lang="'survey.survey'"> </span>
              </td>
              <td>
                <a
                  class="primary"
                  :href="_surveyUserManual"
                  v-lang="'general.user_manual'"
                  target="_blank"
                ></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import manuals from "@/mixins/manuals.vue";
import rootStore from "@/store";

export default {
  mixins: [manuals],
  data() {
    return {
      functionWithUserManuals: [50005, 50006],
    };
  },
  computed: {
    hasPermission() {
      return rootStore.state.user.allowedFunctions.some((x) =>
        this.functionWithUserManuals.includes(x)
      );
    },
    surveyUserManualPermission() {
      return this.$role(50005) || this.$role(50006);
    },
  },
};
</script>


<style lang="scss" scoped>
div {
  text-align: center;
  img {
    margin-top: 20%;
  }
  h1 {
    margin-top: 50px;
    letter-spacing: 5px;
  }
}
.pt-60px {
  padding-top: 60px;
}
.px-10px {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
