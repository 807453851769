<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-paperplane"></i>
        <span v-lang="'action.label_of_actions'"></span>
      </h1>
      <div class="actions">
        <router-link
          v-if="$role(10098)"
          class="primary"
          :to="{ name: 'ActionCreate', params: { id: 0 } }"
          v-lang="'general.label_add'"
        ></router-link>
      </div>
    </div>
    <div
      class="card search"
      v-responsive="{
          middle: el => el.width < 1140,
          small: el => el.width < 930
        }"
    >
      <div
        class="input m-0"
        :class="
          errors.first('action_form.titleId') !== undefined ? 'input-error' : ''
        "
      >
        <label for="name" v-lang="'action.label_name_of_action'"></label>
        <input class="m-0" v-model="name" type="text" name="name" autocomplete="off" autofocus />
      </div>
      <div
        class="input m-0"
        :class="
          errors.first('action_form.controller') !== undefined
            ? 'input-error'
            : ''
        "
      >
        <label for="controller" v-lang="'action.label_name_of_controller'"></label>
        <input
          class="m-0"
          v-model="controller"
          type="text"
          name="controller"
          autocomplete="off"
          autofocus
        />
      </div>
      <div
        class="input m-0"
        :class="
          errors.first('action_form.fkHttpMethodId') !== undefined
            ? 'input-error'
            : ''
        "
      >
        <label for="httpMethod" v-lang="'action.label_name_of_http_method'"></label>
        <select v-model="httpMethod" id="dropdown">
          <option value disabled hidden v-lang="'action.placeholder_for_http_method'"></option>
          <option v-for="(obj, key) in httpMethods" :key="key" :value="obj.id">{{ obj.name }}</option>
        </select>
        <div class="error" v-html="errors.first('action_form.fkHttpMethodId')"></div>
      </div>
    </div>
    <notify />
    <NoData v-if="!search || search.length == 0"></NoData>
    <div v-else class="card table-container actions">
      <table class="table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th v-lang="'action.label_name_of_action'">&nbsp;</th>
            <th v-lang="'action.label_name_of_description'">&nbsp;</th>
            <th v-lang="'action.label_name_of_api_path'">&nbsp;</th>
            <th v-lang="'action.label_name_of_controller'">&nbsp;</th>

            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, key) in search" :key="key">
            <td>{{ pageSize * (pageNum - 1) + key + 1 }}</td>
            <td>{{ obj.action }}</td>
            <td>{{ obj.description }}</td>
            <td id="api">{{ obj.apiPath }}</td>
            <td>{{ obj.pkController }}</td>
            <td>
              <router-link
                v-if="$role(10085)"
                class="default small"
                :to="{
                  name: 'ActionDetails',
                  query: {
                    PkActionId: obj.pkActionId,
                    PkController: obj.pkController
                  }
                }"
                v-lang="'general.label_details'"
              ></router-link>
              <router-link
                v-if="$role(10099)"
                class="primary small"
                :to="{
                  name: 'EditAction',
                  query: {
                    PkActionId: obj.pkActionId,
                    PkController: obj.pkController
                  }
                }"
                v-lang="'general.label_edit'"
              ></router-link>
              <a
                v-if="$role(10100)"
                class="error small"
                v-on:click="deleteAction(obj)"
                v-lang="'general.label_delete'"
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination-container">
      <paginate
        v-if="pageCount != 0"
        :page-count="pageCount"
        v-model="pageNum"
        :prev-text="this.$lang('general.previous')"
        :next-text="this.$lang('general.next_page')"
        :container-class="'paggination'"
      ></paginate>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import Paginate from "vuejs-paginate";
import NoData from "../../../General/NoData";
import { ResponsiveDirective } from "vue-responsive-components";

Vue.use(Toasted);

export default {
  directives: {
    responsive: ResponsiveDirective
  },
  components: {
    NoData,
    paginate: Paginate
  },
  data() {
    return {
      actions: [],
      httpMethods: [],
      name: this.$route.query.name == undefined ? "" : this.$route.query.name,
      controller:
        this.$route.query.controller == undefined
          ? ""
          : this.$route.query.controller,
      httpMethod:
        this.$route.query.method == undefined ? "" : this.$route.query.method,
      pageSize: 10,
      pageNum:
        this.$route.query.page == undefined
          ? 1
          : parseInt(this.$route.query.page),
      pageCount: 0
    };
  },
  created() {
    this.getAllActions();
    this.getHttpMethods();
  },
  methods: {
    changePage: function(pageNum) {
      this.pageNum = pageNum;
    },
    findMethodForId(fkHttpMethodId) {
      var $this = this;
      var methods = $this.httpMethods.filter(
        item => item.pkHttpMethodId == fkHttpMethodId
      );
      if (methods.length > 0) return methods[0];
      else return { name: "" };
    },
    getAllActions() {
      var $this = this;
      store
        .dispatch("GET_All_ACTIONS")
        .then(response => {
          $this.actions = response.data.list;
        })
        .catch(error => {});
    },
    getHttpMethods() {
      var $this = this;
      store
        .dispatch("GET_ACTION_TYPES_AND_HTTPMETHODS_FOR_ACTION")
        .then(response => {
          $this.httpMethods = response.data.httpMethodsList;
        })
        .catch(error => {});
    },
    deleteAction(obj) {
      this.$removeToast();
      Vue.toasted.show(
        lang("action.action_do_you_want_to_delete_this_action"),
        {
          theme: "toasted-primary",
          position: "top-center",
          duration: 5000,
          action: [
            {
              text: lang("general.label_yes"),
              onClick: (e, toastObject) => {
                var $this = this;
                store
                  .dispatch("DELETE_ACTION", {
                    PkActionId: obj.pkActionId,
                    PkController: obj.pkController
                  })
                  .then(response => {
                    // $this.getAllActions();
                    $this.$success("action.action_successfully_deleted");
                    $this.actions.splice($this.actions.indexOf(obj), 1);
                    $this.search();
                    this.notifyKey += 1;
                  })
                  .catch(error => {});
                toastObject.goAway(0);
              }
            },
            {
              text: lang("general.label_no"),
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              }
            }
          ]
        }
      );
    }
  },
  computed: {
    search() {
      var $this = this;
      var allData = this.$_.filter($this.actions, function(action) {
        if ($this.httpMethod == 0)
          return (
            action.action.toLowerCase().includes($this.name.toLowerCase()) &&
            action.pkController
              .toLowerCase()
              .includes($this.controller.toLowerCase())
          );
        else
          return (
            action.action.toLowerCase().includes($this.name.toLowerCase()) &&
            action.pkController
              .toLowerCase()
              .includes($this.controller.toLowerCase()) &&
            action.fkHttpMethodId == $this.httpMethod
          );
      });
      $this.pageCount = Math.ceil(allData.length / $this.pageSize);

      if ($this.pageCount != 0 && $this.pageNum > $this.pageCount) {
        $this.pageNum = $this.pageCount;
      }
      // this.$router.push({
      //   query: {
      //     page: $this.pageNum,
      //     method: $this.httpMethod,
      //     name: $this.name,
      //     controller: $this.controller
      //   }
      // });
      return allData.slice(
        (this.pageNum - 1) * this.pageSize,
        this.pageNum * this.pageSize
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/colors";

.search {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}
.search.middle {
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
.search.small {
  grid-template-columns: 1fr;
  grid-gap: 10px;
}

.actions {
  position: relative;
}
.paggination {
  position: absolute !important;
}
.date-select-tickets {
  margin-right: 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background-color: white;
  .input {
    margin-right: 10px !important;
  }

  .show-button-div {
    display: flex;
    align-content: center;
    align-items: flex-end;
    margin-top: 11px;
    button {
      height: 37px;
    }
  }

  i,
  span {
    padding: 3px;
    cursor: pointer;
    color: $primaryColor;
    position: absolute;
    right: 15px;
    bottom: 0;
    top: 29px;
    &:hover {
      font-weight: bold;
      padding: 3px;
      color: $darkPrimaryColor;
    }
  }
}
#api {
  word-break: break-all;
}
</style>
