<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-file-text"></i>
        <span v-lang="'codes.language_codes'"></span>
      </h1>
      <div class="actions">
        <router-link
          v-if="$role(10134)"
          class="primary small"
          :to="{ name: 'LanguagesCreate' }"
          v-lang="'general.label_add'"
        ></router-link>
      </div>
    </div>
    <notify :key="notifyKey" />
    <NoData v-if="!languages || languages.length == 0"></NoData>
    <div v-else class="card table-container">
      <table class="table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th v-lang="'general.label_name'">&nbsp;</th>
            <th v-lang="'general.label_description'">&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, key) in paginatedRoles" :key="key">
            <td>{{ key + 1 }}</td>
            <td>{{ obj.name }}</td>
            <td class="word-break">{{ obj.description }}</td>
            <td>
              <router-link
                v-if="$role(10137)"
                class="default small"
                :to="{
                  name: 'LanguagesDetails',
                  params: { id: obj.pkLanguageId }
                }"
                v-lang="'general.label_details'"
              ></router-link>
              <router-link
                v-if="$role(10135)"
                class="primary small"
                :to="{
                  name: 'LanguagesCreate',
                  params: { id: obj.pkLanguageId }
                }"
                v-lang="'general.label_edit'"
              ></router-link>
              <a
                v-if="$role(10136)"
                class="error small"
                v-on:click="deleteRole(obj.pkLanguageId)"
                v-lang="'general.label_delete'"
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="left-aligment">
        <paginate
          v-if="languages.length != 0"
          :page-count="pageCount"
          :click-handler="changePage"
          :prev-text="this.$lang('general.previous')"
          :next-text="this.$lang('general.next_page')"
          :container-class="'paggination'"
        ></paginate>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import store from "../store";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import NoData from "../../General/NoData";
import Paginate from "vuejs-paginate";
import mainstore from "@/store";
import { mapState } from "vuex";

export default {
  components: {
    NoData,
    paginate: Paginate
  },
  data() {
    return {
      notifyKey: 0,
      languages: [],
      pageSize: 10,
      pageNum: 1,
      pageCount: 0
    };
  },
  created() {
    this.getAllLanguages();
  },
  computed: {
    ...mapState(["api"]),
    paginatedRoles() {
      var proles = this.languages.slice(
        (this.pageNum - 1) * this.pageSize,
        (this.pageNum - 1) * this.pageSize + this.pageSize
      );
      return proles;
    }
  },
  methods: {
    changePage: function(pageNum) {
      this.pageNum = pageNum;
    },
    getAllLanguages() {
      var $this = this;
      store
        .dispatch("GET_ALL_LANGUAGES")
        .then(response => {
          $this.languages = response.data.list;
          $this.pageCount = $this.languages.length / $this.pageSize;
        })
        .catch(error => {});
    },
    deleteRole(id) {
      this.$removeToast();
      Vue.toasted.show(lang("user.do_you_want_to_delete_this_language"), {
        theme: "toasted-primary",
        position: "top-center",
        duration: 5000,
        action: [
          {
            text: lang("general.label_yes"),
            onClick: (e, toastObject) => {
              var $this = this;
              store
                .dispatch("DELETE_LANGUAGE", id)
                .then(response => {
                  $this.getAllLanguages();
                  this.$error("user.language_successfully_deleted");
                  this.notifyKey += 1;
                })
                .catch(error => {});
              toastObject.goAway(0);
            }
          },
          {
            text: lang("general.label_no"),
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            }
          }
        ]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.left-aligment {
  margin-left: -40px;
}
.word-break {
  word-break: break-all;
}
</style>
