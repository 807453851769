<template>
  <div class="notifications">
    <div class="notifications-icon-container">
      <!-- <div v-on:click="showContent = !showContent" class="bell-container">
        <i class="icon-alarm"></i>
        <span v-if="numberOfNotifications">{{ numberOfNotifications }}</span>
      </div>-->
      <span v-on:click="logout()" class="logout items icon-logout"></span>
    </div>
    <div v-if="showContent" class="notification-container">
      <!-- <div class="header">{{$lang("notifications.look_all")}}</div> -->
      <div class="notifications-content" @scroll="handleScroll">
        <router-link
          v-for="(obj, key) in notifications"
          :key="key"
          :class="obj.seenOn ? '' : 'not-seen'"
          :to="{
            name: obj.route,
            params: JSON.parse(obj.routeValues),
            query: {
              notification_data: obj.pkNotificationId,
              seen: obj.seenOn
            }
          }"
        >
          <span class="notification-message" v-html="obj.message"></span>
          <span class="author-and-created-on">
            <small>
              {{ obj.author }}
              {{ $moment(obj.createdOn).format("DD.MM.YYYY. hh:mm") }}
            </small>
          </span>
        </router-link>
        <Loader2 v-if="searchResultsLoaded" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/components/UserAndRolesManagement/AdminPanel/Notification/store";
import { mapState } from "vuex";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import Loader2 from "@/components/General/Loader2";

Vue.use(Toasted);

export default {
  components: {
    Loader2
  },
  data() {
    return {
      searchResultsLoaded: false,
      localStorageUser: JSON.parse(localStorage.user),
      notifications: [],
      numberOfNotifications: 0,
      showContent: false,
      pageNum: 1,
      pageOffest: 10
    };
  },
  watch: {
    showContent() {
      if (this.showContent) {
        this.pageNum = 1;
        this.notifications = [];
        this.getAll();
      }
    },
    $route() {
      this.showContent = false;
    },
    "$route.query.notification_data"() {
      var $this = this;
      if (this.$route.query.opened) {
        return;
      }
      var query = Object.assign({}, this.$route.query);
      query.opened = this.random(13);
      var notification_id = query.notification_data;
      var seen = $this.$route.query.seen;
      delete query.notification_data;
      delete query.seen;
      $this.$router.replace({ query });

      if (!seen) {
        this.$axios_auth
          .put("Notifications/mark-as-read-broadcast-message", {
            PkNotificationId: notification_id
          })
          .then(respone => {
            if ($this.numberOfNotifications) {
              $this.numberOfNotifications--;
            }
          });
      }

      if ($this.$route.name == null) {
        query.broadcast_notification_id = notification_id;
        $this.$router.replace({ query });
      }
    }
  },
  created() {
    var $this = this;
  },
  computed: {
    ...mapState(["$axios_auth"])
  },
  methods: {
    logout() {
      var $this = this;
      this.$removeToast();
      Vue.toasted.show(lang("general.log_out_message"), {
        theme: "toasted-primary",
        position: "top-center",
        duration: 5000,
        action: [
          {
            text: lang("general.label_yes"),
            onClick: (e, toastObject) => {
              this.$router.push("/logout");
              toastObject.goAway(0);
            }
          },
          {
            text: lang("general.label_no"),
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            }
          }
        ]
      });
    },
    handleScroll: function(e) {
      if (e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight) {
        this.getAll();
      }
    },
    getAll() {
      var $this = this;
      if (this.searchResultsLoaded) return;
      this.searchResultsLoaded = true;
      store
        .dispatch("GET_ALL_NOTIFICATION_FOR_LOGEDIN_USER", {
          pageNum: this.pageNum,
          pageOffset: this.pageOffest
        })
        .then(response => {
          $this.notifications = [...$this.notifications, ...response.data.list];
          if (response.data.list.length > 0) {
            this.pageNum++;
          }
          $this.searchResultsLoaded = false;
        })
        .catch(error => {
          this.$store.commit("ERROR_MESSAGE", this.error_a);
        });
    },
    random(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/colors";
b {
  font-weight: bold !important;
}

.icon-alarm {
  margin-right: 10px;
}
.notifications {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 35px;
  margin-left: auto;
  position: relative;
  .notifications-icon-container {
    font-size: 30px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .logout {
    padding-bottom: 3px;
  }
  .bell-container {
    position: relative;
    margin-right: 10px;
    span {
      position: absolute;
      font-size: 10px;
      color: #919090;
      left: -10px;
      top: 0;
      bottom: 5px;
      right: 0;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }

  .author-and-created-on {
    display: flex !important;
    small {
      margin-left: auto;
      margin-top: 5px;
    }
  }
  .notification-container {
    position: absolute;
    border: 1px solid #919090;
    width: 400px;
    min-height: 400px;
    max-height: 550px;
    overflow-y: visible;
    right: 35px;
    background: $white;
    top: 40px;
    .header {
      color: $primaryColor;
      height: 15px;
      text-align: right;
      border-bottom: solid 1px #dddfe2;
    }
    .notifications-content {
      overflow-y: scroll;
      min-height: 400px - 35px;
      max-height: 550px - 35px;
    }
    a {
      display: grid !important;
      color: $secondaryColor !important;
      background: $white;
      grid-template-columns: 1fr;
      font-size: 14px !important;
      font-weight: 500 !important;
      text-transform: none !important;
      border-bottom: 1px solid rgb(221, 223, 226);
      &.not-seen {
        background: rgba($primaryColor, 0.5);
      }
      &:hover {
        color: $white !important;
        background: rgba($primaryColor, 1);
      }
    }
    a span::before {
      content: none;
    }
    .notification-message {
      display: block !important;
      word-break: break-all;
      word-wrap: break-word;
      span {
        display: block !important;
      }
    }
  }
}
</style>
