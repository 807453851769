<template>
  <div>
    <div class="container">
      <div class="header card">
        <h1>
          <i class="icon-izvjestaji"></i>
          <span v-lang="'reports.reports'"></span>
        </h1>
        <!-- <div class="actions">
          <a
            class="primary"
            v-on:click="clickBack()"
            v-lang="'general.button_back'"
          ></a>
        </div>-->
      </div>
      <div class="card">
        <form
          action
          class="form search-report"
          v-on:submit.prevent="searchFunction()"
        >
          <div class="input m-0">
            <label for="number">
              <span v-lang="'reports.searchName'"></span>
            </label>
            <input
              class="m-0"
              v-model="searchName"
              type="text"
              name="searchName"
              autocomplete="off"
            />
          </div>
          <div class="submit">
            <button
              type="button"
              class="warning"
              v-on:click="resetSearch($event)"
              v-lang="'general.button_reset'"
            ></button
            >&nbsp;
          </div>
        </form>
      </div>
      <Loader2 v-if="!resultsLoaded" />
      <span v-else>
        <NoData v-if="!search || search.length == 0"></NoData>
        <div v-else class="scroll card table-container">
          <table class="table-hover">
            <thead>
              <tr :id="tableId">
                <th>#</th>
                <th v-on:click="sort('InstitutionName')" InstitutionName>
                  {{ $lang("general.label_name") }}
                  <span></span>
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr class=".report" v-for="(obj, key) in search" :key="key">
                <td>
                  {{ key + 1 + (searchData.page - 1) * searchData.pageSize }}
                </td>
                <td>{{ obj.name }}</td>

                <td>
                  <button
                    class="primary small"
                    v-lang="'reports.select'"
                    @click="
                      showModalYear(obj.pkCodeId, obj.nameCustomField, obj.name)
                    "
                  ></button>
                </td>
              </tr>
            </tbody>
          </table>
          <modal
            name="years"
            :draggable="true"
            width="500"
            height="170"
            @before-open="beforeOpen"
            @opened="modalOpened"
          >
            <form
              class="modal-form"
              v-on:submit.prevent="generate()"
              data-vv-scope="report_form"
            >
              <div
                class="input m-0"
                :class="
                  errors.first('report_form.yearFrom') !== undefined
                    ? 'input-error'
                    : ''
                "
              >
                <label for="name" v-lang="'reports.yearFrom'"></label>
                <select id="yearFrom" v-model="yearFrom"></select>
                <div>
                  <span
                    class="error"
                    v-html="errors.first('report_form.yearFrom')"
                  ></span>
                </div>
              </div>
              <div
                class="input m-0 year-to"
                :class="
                  errors.first('report_form.yearTo') !== undefined
                    ? 'input-error'
                    : ''
                "
              >
                <label for="name" v-lang="'reports.yearTo'"></label>
                <select id="yearTo" v-model="yearTo"></select>
                <div>
                  <span
                    class="error"
                    v-html="errors.first('report_form.yearTo')"
                  ></span>
                </div>
              </div>
              <div class="submit" id="sub">
                <button class="primary" v-lang="'reports.select'"></button>
              </div>
            </form>
          </modal>
        </div>
        <div class="pagination-container">
          <paginate
            v-if="resultCount > 1"
            :page-count="resultCount"
            :click-handler="changePage"
            v-model="searchData.page"
            :prev-text="this.$lang('general.previous')"
            :next-text="this.$lang('general.next_page')"
            :container-class="'paggination'"
          ></paginate>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import moment from "moment";
import globalStore from "@/store";
import store from "./store";
import YearCalendar from "vue-material-year-calendar";
import { ResponsiveDirective } from "vue-responsive-components";
import vmodal from "vue-js-modal";
import { Datetime } from "vue-datetime";
import Paginate from "vuejs-paginate";
import Loader2 from "@/components/General/Loader2";
import NoData from "@/components/General/NoData";

Vue.use(vmodal);

Vue.use(Toasted);

export default {
  directives: {
    responsive: ResponsiveDirective,
  },
  components: {
    datetime: Datetime,
    paginate: Paginate,
    Loader2,
    NoData,
  },
  data() {
    return {
      nameRoute: "",
      reportCodes: [],
      searchData: {
        page:
          Number(this.$route.query.page) > 0
            ? Number(this.$route.query.page)
            : 1,
        pageSize: 15,
      },
      searchName: this.$route.query.searchName
        ? this.$route.query.searchName
        : "",
      resultCount: 0,
      report: {},
      template: "",
      id: 0,
      resultsLoaded: false,
      yearFrom: new Date().getFullYear().toString(),
      yearTo: new Date().getFullYear().toString(),
      reportName: "",
    };
  },
  created() {
    this.getReportCodes();
  },
  methods: {
    beforeOpen(event) {
      this.template = event.params.template;
      this.id = event.params.id;
      this.reportName = event.params.reportName;
    },
    modalOpened() {
      var min = 2010,
        max = new Date().getFullYear(),
        selectFrom = document.getElementById("yearFrom"),
        selectTo = document.getElementById("yearTo");

      for (var i = min; i <= max; i++) {
        var opt = document.createElement("option");
        if (i == max) opt.setAttribute("selected", "selected");
        opt.value = i;
        opt.innerHTML = i;
        selectFrom.appendChild(opt);
      }
      for (var i = min; i <= max; i++) {
        var opt = document.createElement("option");
        if (i == max) opt.setAttribute("selected", "selected");
        opt.value = i;
        opt.innerHTML = i;
        selectTo.appendChild(opt);
      }
    },
    generate() {
      var query = this.$route.query;
      this.$router.push({
        name: "ReportDetails",
        query: {
          id: this.id,
          template: this.template,
          yearFrom: this.yearFrom,
          yearTo: this.yearTo,
          reportName: this.reportName,
          ...query,
        },
      });
    },
    showModalYear(id, template, reportName) {
      this.$modal.show("years", {
        id: id,
        template: template,
        reportName: reportName,
      });
    },
    hide() {
      this.$modal.hide("years");
    },
    changePage: function(pageNum) {
      this.searchData.page = pageNum;
    },
    clickBack() {
      this.$router.push({
        name: this.nameRoute,
        query: Object.assign({}, this.query),
      });
    },
    getReportCodes() {
      var $this = this;
      $this.resultsLoaded = false;
      store
        .dispatch("GET_REPORT_CODES")
        .then((response) => {
          $this.reportCodes = response.data.model.children;
          $this.resultCount = Math.ceil(
            $this.reportCodes.length / $this.searchData.pageSize
          );

          // $this.resultCount = $this.reportCodes.length;
          $this.resultsLoaded = true;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    resetSearch() {
      this.searchName = "";
    },
  },
  watch: {
    searchName() {
      var $this = this;
      $this.searchData.page = 1;

      $this.search();
      $this.$router.push({
        query: {
          searchName: $this.searchName,
          ...$this.$route.query,
        },
      });
    },
  },
  computed: {
    search() {
      this.$router.push({
        query: {
          searchName: this.searchName,
          page: this.searchData.page,
        },
      });
      var $this = this;

      var allData = $this.$_.filter($this.reportCodes, function(x) {
        return x.name.toLowerCase().includes($this.searchName.toLowerCase());
      });

      $this.resultCount =
        allData.length % $this.searchData.pageSize == 0
          ? parseInt(allData.length / $this.searchData.pageSize)
          : parseInt(allData.length / $this.searchData.pageSize) + 1;

      var reports = allData.slice(
        ($this.searchData.page - 1) * $this.searchData.pageSize,
        ($this.searchData.page - 1) * $this.searchData.pageSize +
          $this.searchData.pageSize
      );

      return reports;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-template {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 10px;
}
.modal-form {
  margin: 10px;
  display: grid;
  grid-template-columns: 1fr;

  button {
  }
  .submit {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
  }
  .year-to {
    margin-bottom: 10px !important;
  }
}
.search-report {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  .submit {
    margin-top: 10px;
    grid-column: 1/4;
  }
}
</style>
