<template>
  <form v-on:submit.prevent="save()" data-vv-scope="queries">
    <div class="card">
      <div class="input-group">
        <div
          class="input m-0"
          :class="
            errors.first('queries.pkFkRoleId') !== undefined
              ? 'input-error'
              : ''
          "
        >
          <label for="pkFkRoleId" v-lang="'queries.label_role'"></label>
          <select
            v-bind:disabled="edit"
            v-model="model.pkFkRoleId"
            v-validate="'required|excluded:0'"
            name="pkFkRoleId"
            id="Roledropdown"
          >
            <option
              value
              hidden
              v-lang="'queries.label_placeholder_for_role'"
            ></option>
            <option v-for="(obj, key) in roles" :key="key" :value="obj.id">{{
              obj.name
            }}</option>
          </select>
          <div>
            <span
              class="error"
              v-html="errors.first('queries.pkFkRoleId')"
            ></span>
          </div>
        </div>

        <div
          class="input m-0"
          :class="
            errors.first('queries.pkFkActionId') !== undefined
              ? 'input-error'
              : ''
          "
        >
          <label for="pkFkActionId" v-lang="'queries.label_api_action'"></label>
          <select
            v-bind:disabled="edit"
            v-model="selectedApi"
            v-validate="'required|excluded:0'"
            id="Actiondropdown"
            v-on:change="onChange($event)"
            name="pkFkActionId"
          >
            <option
              value
              hidden
              v-lang="'queries.label_placeholder_for_action'"
            ></option>
            <option
              v-for="(obj, key) in actions"
              :key="key"
              :value="obj.pkActionId + '|' + obj.pkController"
              >{{ obj.apiPath + " - " + obj.description }}</option
            >
          </select>
          <div>
            <span
              class="error"
              v-html="errors.first('queries.pkFkActionId')"
            ></span>
          </div>
        </div>
      </div>
      <div class="input-group">
        <div
          class="input m-0"
          :class="
            errors.first('queries.whereClause') !== undefined
              ? 'input-error'
              : ''
          "
        >
          <label for="whereClause">
            <span v-lang="'queries.where_clause'"></span>
          </label>
          <input
            v-validate="'required'"
            class="m-0"
            v-model="model.whereClause"
            type="text"
            name="whereClause"
            autocomplete="off"
          />
          <div>
            <span
              class="error"
              v-html="errors.first('queries.whereClause')"
            ></span>
          </div>
        </div>
        <div
          class="input m-0"
          :class="
            errors.first('queries.orderBy') !== undefined ? 'input-error' : ''
          "
        >
          <label for="orderBy" v-lang="'queries.orderBy'"></label>
          <input
            class="m-0"
            type="text"
            v-model="model.orderBy"
            name="orderBy"
            autocomplete="off"
          />
          <div>
            <span class="error" v-html="errors.first('queries.orderBy')"></span>
          </div>
        </div>
      </div>
      <div class="input-group">
        <div
          class="input m-0"
          :class="
            errors.first('queries.groupBy') !== undefined ? 'input-error' : ''
          "
        >
          <label for="groupBy">
            <span v-lang="'queries.groupBy'"></span>
          </label>
          <input
            class="m-0"
            type="text"
            v-model="model.groupBy"
            name="groupBy"
            autocomplete="off"
          />
          <div>
            <span class="error" v-html="errors.first('queries.groupBy')"></span>
          </div>
        </div>
        <div
          class="input m-0"
          :class="
            errors.first('queries.description') !== undefined
              ? 'input-error'
              : ''
          "
        >
          <label for="description">
            <span v-lang="'queries.description'"></span>
          </label>
          <input
            class="m-0"
            type="text"
            v-model="model.description"
            name="description"
            autocomplete="off"
          />
          <div>
            <span
              class="error"
              v-html="errors.first('queries.description')"
            ></span>
          </div>
        </div>
      </div>
      <div class="submit m-0" id="sub">
        <button class="primary" v-lang="'general.button_submit'"></button>
      </div>
    </div>
  </form>
</template>

<script>
import Vue from "vue";
import { lang } from "@/global-functions";
import VeeValidate from "vee-validate";
import store from "../store";

Vue.use(VeeValidate);

export default {
  props: {
    edit: {
      default: false
    },
    queryData: {
      default: null
    },
    errorModel: {
      key: "",
      message: ""
    }
  },
  data() {
    return {
      roles: [],
      actions: [],
      selectedApi: "",
      model: {
        pkFkRoleId: "",
        pkFkActionId: "",
        PkFkController: "",
        whereClause: "",
        orderBy: "",
        groupBy: "",
        description: ""
      },
      validation_messages: {
        custom: {
          whereClause: {
            required: lang("validation.required")
          },
          pkFkRoleId: {
            required: lang("validation.required")
          },
          pkFkActionId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          },
          PkFkController: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          }
        }
      }
    };
  },
  watch: {
    queryData() {
      var $this = this;
      $this.model = $this.queryData;
      $this.selectedApi =
        $this.model.pkFkActionId + "|" + $this.model.pkFkController;
    },
    errorModel() {
      this.$importModelStateErrors(
        this.errors,
        this.errorModel.modelState,
        "function_form"
      );
    }
  },
  created() {
    this.GetActionRoleList();
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  methods: {
    GetActionRoleList() {
      var $this = this;
      store
        .dispatch("GET_ACTIONS_AND_ROLES_LIST")
        .then(response => {
          $this.roles = response.data.rolesList;
          $this.actions = response.data.actionList;
        })
        .catch(error => {});
    },
    onChange(event) {
      var value = event.target.value.split("|");
      this.model.pkFkActionId = value[0];
      this.model.PkFkController = value[1];
    },

    save() {
      this.$validator.validateAll("queries").then(valid => {
        if (valid) {
          this.$emit("save", this.model);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/colors";
@import "../../../assets/css/_paggination.scss";
.white {
  color: white;
}
label {
  input {
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
  }
}
.input-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 15px;
}
table {
  margin-bottom: 10px;
}
.actions {
  position: relative;
}
</style>
