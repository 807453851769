<template>
  <div>
    <div v-show="!showYear">
      <div>
        <div class="calendar-header">
          <button type="button" v-on:click="prevYear()">&lsaquo;</button>
          <div v-on:click="clickShowYear">{{ year }}</div>
          <button type="button" v-on:click="nextYear()">&rsaquo;</button>
        </div>
        <div class="calendar month">
          <div
            v-for="(str, key) in months"
            v-on:click="clickMounth(key)"
            :key="key"
          >{{ str.charAt(0).toUpperCase() + str.slice(1) }}</div>
        </div>
      </div>
    </div>
    <YearPicker v-show="showYear" @changeYear="changeYear" />
  </div>
</template>

<script>
import moment from "moment-with-locales-es6";
import YearPicker from "./YearPicker";

export default {
  components: { YearPicker },
  props: {
    locale: {
      type: String,
      default: "sr"
    },
    format: {
      type: String,
      default: "DD.MM.YYYY."
    }
  },
  data() {
    return {
      model: null,
      trayout: null,
      date: null,
      last: 0,
      months: [],
      first_day_of_week: null,
      month: 0,
      year: null,
      showYear: false
    };
  },
  methods: {
    changeYear: function(value) {
      this.setDate(value, 0, 0);
      this.year = value;
      this.showYear = false;
    },
    clickMounth(index) {
      this.$emit("changeMonth", index, this.year);
    },
    clickShowYear() {
      if (!this.showMonth) {
        this.showYear = true;
      } else {
        this.showYear = false;
      }
    },
    nextYear() {
      //   this.date.add(1, "Y").format(this.format);
      this.year++;
      this.init();
    },
    prevYear() {
      //   this.date.add(-1, "Y").format(this.format);
      this.year--;

      this.init();
    },
    init() {
      moment.locale(this.locale);
      this.months = moment.monthsShort();
    },
    setDate(y, m, d) {
      this.date = this.date.set("date", d);
      this.model = this.date.format(this.format);
      this.init();
    }
  },
  created() {
    moment.locale(this.locale);
    this.model = moment().locale(this.locale);
    this.date = this.model;
    this.model = this.model.format(this.format);
    this.year = this.date.format("YYYY");
    this.init();
  }
};
</script>
<style scoped>
.calendar.month,
.calendar.year {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 294px;
}
.calendar.month div,
.calendar.year div {
  padding: 15px 5px;
  font-size: 0.9em;
}
.datepicker-date .calendar-header {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
}
.datepicker-date .calendar-header > div,
.datepicker-date .calendar-header > button {
  background: var(--datepicker-calendar-header-background);
  color: var(--datepicker-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.datepicker-date .calendar-header > button {
  color: var(--datepicker-butotns-color, --datepicker-text-color);
}
button {
  background: transparent;
  border: none;
  padding: 10px 0px;
  font-size: 1.2em;
}
.calendar div:not(.empty):not(.calendar-header):hover {
  z-index: 9999;
  box-shadow: var(--datepicker-shadow-box-on-date-hover);
}
</style>
