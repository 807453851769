<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-paperplane"></i>
        <span v-lang="'action.label_edit_action'"></span>
      </h1>
      <div class="actions">
        <button class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></button>
      </div>
    </div>
    <notify />
    <div>
      <div class="card">
        <Form v-on:save="edit" :actionData="action" :errorModel="errorModel" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import Form from "./Form";
import { lang } from "@/global-functions";
import { mapState } from "vuex";
import globalStore from "@/store";

export default {
  components: {
    Form
  },
  data() {
    return {
      action: {},
      errorModel: {
        message: "",
        key: ""
      }
    };
  },
  created() {
    this.getActionByID();
  },
  methods: {
    edit: function(value) {
      var $this = this;
      store
        .dispatch("EDIT_ACTION", value)
        .then(response => {
          $this.$success("action.action_successfully_updated");
          $this.$router.push({ name: "ActionIndex" });
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;
          
        });
    },
    getActionByID() {
      var $this = this;
      store
        .dispatch("GET_ACTION_BY_ID", {
          PkActionId: $this.$route.query.PkActionId,
          PkController: $this.$route.query.PkController
        })
        .then(response => {
          $this.action = response.data.model;
        })
        .catch(error => {
          
        });
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}
</style>
