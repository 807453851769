<template>
  <div>
    <div class="container">
      <div class="header card">
        <h1>
          <i class="icon-izvjestaji"></i>
          <span v-lang="'emmaus.emmaus_reports'"></span>
        </h1>
        <!-- <div class="actions">
          <a
            class="primary"
            v-on:click="clickBack()"
            v-lang="'general.button_back'"
          ></a>
        </div>-->
      </div>
      <span>
        <Loader2 v-if="showLoader" />
        <div v-else class="scroll card table-container">
          <table class="table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>
                  {{ $lang("general.label_name") }}
                  <span></span>
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr class="report">
                <td>1</td>
                <td>
                  {{ $lang("emmaus.report_broj_osoba_koji_su_prosli_obuke") }}
                </td>
                <td>
                  <button
                    class="error small"
                    v-on:click="showModalSelectDates(1, true)"
                    v-lang="'victims.export_excel'"
                  ></button>
                  <button
                    class="primary small"
                    v-lang="'reports.generate_pdf'"
                    @click="showModalSelectDates(1)"
                  ></button>
                </td>
              </tr>
              <tr class="report">
                <td>2</td>
                <td>
                  {{ $lang("emmaus.report_broj_osoba_po_institucijama") }}
                </td>
                <td>
                  <button
                    class="error small"
                    v-on:click="showModalSelectDates(2, true)"
                    v-lang="'victims.export_excel'"
                  ></button>
                  <button
                    class="primary small"
                    v-lang="'reports.generate_pdf'"
                    @click="showModalSelectDates(2)"
                  ></button>
                </td>
              </tr>
              <tr class="report">
                <td>3</td>
                <td>
                  {{ $lang("emmaus.report_broj_osoba_po_vrsti_obuke") }}
                </td>
                <td>
                  <button
                    class="error small"
                    v-on:click="showModalSelectDates(3, true)"
                    v-lang="'victims.export_excel'"
                  ></button>
                  <button
                    class="primary small"
                    v-lang="'reports.generate_pdf'"
                    @click="showModalSelectDates(3)"
                  ></button>
                </td>
              </tr>
              <tr class="report">
                <td>4</td>
                <td>
                  {{ $lang("emmaus.report_broj_osoba_po_temi_obuke") }}
                </td>
                <td>
                  <button
                    class="error small"
                    v-on:click="showModalSelectDates(4, true)"
                    v-lang="'victims.export_excel'"
                  ></button>
                  <button
                    class="primary small"
                    v-lang="'reports.generate_pdf'"
                    @click="showModalSelectDates(4)"
                  ></button>
                </td>
              </tr>
              <tr class="report">
                <td>5</td>
                <td>
                  {{ $lang("emmaus.report_broj_osoba_po_oblasti") }}
                </td>
                <td>
                  <button
                    class="error small"
                    v-on:click="showModalSelectDates(5, true)"
                    v-lang="'victims.export_excel'"
                  ></button>
                  <button
                    class="primary small"
                    v-lang="'reports.generate_pdf'"
                    @click="showModalSelectDates(5)"
                  ></button>
                </td>
              </tr>
              <tr class="report">
                <td>6</td>
                <td>
                  {{ $lang("emmaus.report_broj_unosa_u_bazu") }}
                </td>
                <td>
                  <button
                    class="error small"
                    v-on:click="showModalSelectDates(6, true)"
                    v-lang="'victims.export_excel'"
                  ></button>
                  <button
                    class="primary small"
                    v-lang="'reports.generate_pdf'"
                    @click="showModalSelectDates(6)"
                  ></button>
                </td>
              </tr>
              <!-- <tr class="report">
                <td>7</td>
                <td>
                  {{ $lang("emmaus.report_broj_novih_unosa_u_bazu") }}
                </td>
                <td>
                  <button
                    class="error small"
                    v-on:click="showModalSelectDates(7, true)"
                    v-lang="'victims.export_excel'"
                  ></button>
                  <button
                    class="primary small"
                    v-lang="'reports.generate'"
                    @click="showModalSelectDates(7)"
                  ></button>
                </td>
              </tr> -->
              <tr class="report">
                <td>7</td>
                <td>
                  {{ $lang("emmaus.report_broj_osoba_po_polu") }}
                </td>
                <td>
                  <button
                    class="error small"
                    v-on:click="showModalSelectDates(8, true)"
                    v-lang="'victims.export_excel'"
                  ></button>
                  <button
                    class="primary small"
                    v-lang="'reports.generate_pdf'"
                    @click="showModalSelectDates(8)"
                  ></button>
                </td>
              </tr>
              <tr class="report">
                <td>8</td>
                <td>
                  {{ $lang("emmaus.report_broj_obuka_po_organizatoru") }}
                </td>
                <td>
                  <button
                    class="error small"
                    v-on:click="showModalSelectDates(9, true)"
                    v-lang="'victims.export_excel'"
                  ></button>
                  <button
                    class="primary small"
                    v-lang="'reports.generate_pdf'"
                    @click="showModalSelectDates(9)"
                  ></button>
                </td>
              </tr>
            </tbody>
          </table>
          <modal name="dates" :draggable="true" width="500" height="170">
            <form
              class="modal-form-emmaus"
              v-on:submit.prevent="generate()"
              data-vv-scope="report_form"
            >
              <div
                class="input m-0"
                :class="
                  errors.first('report_form.dateFrom') !== undefined
                    ? 'input-error'
                    : ''
                "
              >
                <label for="name" v-lang="'general.date_from'"></label>
                <date-picker
                  name="dateFrom"
                  v-model="dateFrom"
                  :inputClass="'input-date-picker'"
                  :locale="lang"
                  :initDate="false"
                  v-validate="'required'"
                ></date-picker>
                <div>
                  <span
                    class="error"
                    v-html="errors.first('report_form.dateFrom')"
                  ></span>
                </div>
              </div>
              <div
                class="input m-0 year-to"
                :class="
                  errors.first('report_form.dateTo') !== undefined
                    ? 'input-error'
                    : ''
                "
              >
                <label for="name" v-lang="'general.date_to'"></label>
                <date-picker
                  name="dateTo"
                  v-model="dateTo"
                  :inputClass="'input-date-picker'"
                  :locale="lang"
                  :initDate="false"
                  v-validate="'required'"
                ></date-picker>
                <div>
                  <span
                    class="error"
                    v-html="errors.first('report_form.dateTo')"
                  ></span>
                </div>
              </div>
              <div class="submit" id="sub">
                <button
                  class="primary"
                  v-lang="'reports.label_export'"
                ></button>
              </div>
            </form>
          </modal>
        </div>
      </span>
      <Template1
        v-if="selectedReportNumber == 1"
        :data="templateData"
        ref="template"
      />
      <Template2
        v-if="selectedReportNumber == 2"
        :data="templateData"
        ref="template"
      />
      <Template3
        v-if="selectedReportNumber == 3"
        :data="templateData"
        ref="template"
      />
      <Template4
        v-if="selectedReportNumber == 4"
        :data="templateData"
        ref="template"
      />
      <Template5
        v-if="selectedReportNumber == 5"
        :data="templateData"
        ref="template"
      />
      <Template6
        v-if="selectedReportNumber == 6"
        :data="templateData"
        ref="template"
      />
      <Template8
        v-if="selectedReportNumber == 8"
        :data="templateData"
        ref="template"
      />
      <Template9
        v-if="selectedReportNumber == 9"
        :data="templateData"
        ref="template"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import moment from "moment";
import globalStore from "@/store";
import store from "./store";
import YearCalendar from "vue-material-year-calendar";
import { ResponsiveDirective } from "vue-responsive-components";
import { en, sr, srCYRL, hr } from "vuejs-datepicker/dist/locale";
import vmodal from "vue-js-modal";
import { Datetime } from "vue-datetime";
import Loader2 from "@/components/General/Loader2";
import NoData from "@/components/General/NoData";
import Template1 from "./Templates/Template1";
import Template2 from "./Templates/Template2";
import Template3 from "./Templates/Template3";
import Template4 from "./Templates/Template4";
import Template5 from "./Templates/Template5";
import Template6 from "./Templates/Template6";
import Template8 from "./Templates/Template8";
import Template9 from "./Templates/Template9";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Arimo } from "@/assets/fonts/Arimo-Regular-normal.js";

Vue.use(vmodal);

Vue.use(Toasted);

export default {
  directives: {
    responsive: ResponsiveDirective
  },
  components: {
    datetime: Datetime,
    Loader2,
    NoData,
    Template1,
    Template2,
    Template3,
    Template4,
    Template5,
    Template6,
    Template8,
    Template9
  },
  data() {
    return {
      en: en,
      sr: sr,
      srCYRL: srCYRL,
      hr: hr,
      lang:
        globalStore.state.lang == "sr"
          ? "sr-cyrl"
          : globalStore.state.lang == "bs"
          ? "sr"
          : globalStore.state.lang == "hr"
          ? "hr"
          : "en",
      isExcelReport: false,
      showLoader: false,
      dateFrom: null,
      dateTo: null,
      templateData: null,
      selectedReportNumber: 0,
      validation_messages: {
        custom: {
          dateFrom: {
            required: lang("validation.required")
          },
          dateTo: {
            required: lang("validation.required")
          }
        }
      }
    };
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  methods: {
    Export2Excel() {
      var $this = this;

      var title;
      switch (this.selectedReportNumber) {
        case 1:
          title = this.$lang("emmaus.report_broj_osoba_koji_su_prosli_obuke");
          break;
        case 2:
          title = this.$lang("emmaus.report_broj_osoba_po_institucijama");
          break;
        case 3:
          title = this.$lang("emmaus.report_broj_osoba_po_vrsti_obuke");
          break;
        case 4:
          title = this.$lang("emmaus.report_broj_osoba_po_temi_obuke");
          break;
        case 5:
          title = this.$lang("emmaus.report_broj_osoba_po_oblasti");
          break;
        case 6:
          title = this.$lang("emmaus.report_broj_unosa_u_bazu");
          break;
        case 7:
          title = this.$lang("emmaus.report_broj_novih_unosa_u_bazu");
          break;
        case 8:
          title = this.$lang("emmaus.report_broj_osoba_po_polu");
          break;
        case 9:
          title = this.$lang("emmaus.report_broj_obuka_po_organizatoru");
          break;
      }

      var element = document.getElementsByTagName("td");
      for (let i = 0; i < element.length; i++) {
        element[i].style.verticalAlign = "middle";
      }

      // DETALJI
      var tab_text = "<table ><tr border='2px'>";
      var textRange;
      var j = 0;

      var tab = document.getElementById("my-table"); // id of table
      var clspan = tab.rows[0].innerHTML.split("<th").length - 1;

      tab_text +=
        "<th  bgcolor='#87AFC6' colspan='" +
        clspan +
        "' id='activity'>" +
        title +
        "</tr>";

      var selectedPeriod =
        this.$lang("general.from") +
        " " +
        $this.$moment($this.dateFrom).format("DD.MM.YYYY.") +
        " " +
        this.$lang("general.to") +
        " " +
        $this.$moment($this.dateTo).format("DD.MM.YYYY.");

      tab_text +=
        "<tr><th  bgcolor='#87AFC6' colspan='" +
        clspan +
        "' id='activity'>" +
        selectedPeriod +
        "</tr>";

      for (j = 0; j < tab.rows.length; j++) {
        tab_text = "<tr>" + tab_text;
        if (j != 0) tab_text += tab.rows[j].innerHTML;
        else {
          tab_text += tab.rows[j].innerHTML;

          tab_text = tab_text.replace(
            /<th(?!\s[^bgcolor])/g,
            "<th bgcolor='#87AFC6'"
          );
        }

        tab_text += "</tr>";
      }

      tab_text = tab_text.replace(
        /<td/gi,
        "<td class='xl70' width='300' align='center' vertical-align:'top' "
      );
      tab_text = tab_text.replace(/12-17/gi, "&nbsp;12-17");
      tab_text = tab_text + "</table>";

      tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, ""); //remove if u want links in your table
      tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
      tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");

      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        // If Internet Explorer
        txtArea1.document.open("txt/html", "replace");
        txtArea1.document.write(tab_text);
        txtArea1.document.close();
        txtArea1.focus();
        sa = txtArea1.document.execCommand(
          "SaveAs",
          true,
          "Say Thanks to Sumit.xls"
        );
      } //other browser not tested on IE 11
      else {
        var a = window.document.createElement("a");
        a.setAttribute(
          "href",
          "data:attachment/xlsx;charset=utf-8,%EF%BB%BF" +
            encodeURIComponent(tab_text)
        );
        a.setAttribute("download", title + ".xls");
        window.document.body.appendChild(a);
        a.click();
      }
      $this.showLoader = false;
      $this.templateData = null;
      $this.selectedReportNumber = 0;
    },
    showModalSelectDates(reportNumber, excel = false) {
      this.isExcelReport = excel;
      this.selectedReportNumber = reportNumber;
      this.dateFrom = null;
      this.dateTo = null;
      this.$modal.show("dates", {});
    },
    generate() {
      var $this = this;
      this.$validator.validateAll("report_form").then((valid) => {
        if (valid) {
          $this.showLoader = true;
          store
            .dispatch("GET_REPORT_DATA", {
              Id: $this.selectedReportNumber,
              DateFrom: $this.dateFrom,
              DateTo: $this.dateTo
            })
            .then((response) => {
              $this.templateData = response.data;
              setTimeout(() => {
                if ($this.isExcelReport) $this.Export2Excel();
                else $this.Export2PDF("body");
              }, 2000);
            });
        }
      });
    },
    async Export2PDF(element) {
      var element = "body";
      var doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true
      });

      var $this = this;
      doc.addFileToVFS("Arimo-Regular.ttf", Arimo);
      doc.addFont("Arimo-Regular.ttf", "Arimo", "normal");
      doc.setFont("Arimo");

      var footer = async function (data) {};

      var title;
      switch (this.selectedReportNumber) {
        case 1:
          title = this.$lang("emmaus.report_broj_osoba_koji_su_prosli_obuke");
          break;
        case 2:
          title = this.$lang("emmaus.report_broj_osoba_po_institucijama");
          break;
        case 3:
          title = this.$lang("emmaus.report_broj_osoba_po_vrsti_obuke");
          break;
        case 4:
          title = this.$lang("emmaus.report_broj_osoba_po_temi_obuke");
          break;
        case 5:
          title = this.$lang("emmaus.report_broj_osoba_po_oblasti");
          break;
        case 6:
          title = this.$lang("emmaus.report_broj_unosa_u_bazu");
          break;
        case 7:
          title = this.$lang("emmaus.report_broj_novih_unosa_u_bazu");
          break;
        case 8:
          title = this.$lang("emmaus.report_broj_osoba_po_polu");
          break;
        case 9:
          title = this.$lang("emmaus.report_broj_obuka_po_organizatoru");
          break;
      }
      var selectedPeriod =
        this.$lang("general.from") +
        " " +
        $this.$moment($this.dateFrom).format("DD.MM.YYYY.") +
        " " +
        this.$lang("general.to") +
        " " +
        $this.$moment($this.dateTo).format("DD.MM.YYYY.");
      var outputName = title + ".pdf";

      var Font = 8;
      Font =
        $this.$route.query.id == 42901 ||
        $this.$route.query.id == 42898 ||
        $this.$route.query.id == 42899
          ? 7
          : Font;

      var pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      var number_of_pages = doc.internal.getNumberOfPages();
      // DIJAGRAMI
      if (this.$refs.template.$refs.pieChart) {
        var image = await this.$refs.template.$refs.pieChart.chart.dataURI();
        var vPosition = 30;
        await doc.addImage(image.imgURI, "PNG", 58, vPosition);
      }

      if (this.$refs.template.$refs.pieChart2) {
        var image2 = await this.$refs.template.$refs.pieChart2.chart.dataURI();
        let vPosition = 30;
        if (this.$refs.template.$refs.pieChart) {
          vPosition = 30 + 119;
        }
        await doc.addImage(image2.imgURI, "PNG", 58, vPosition);
      }

      // TABELA
      doc.autoTable({
        styles: {
          fontSize: Font,
          font: "Arimo",
          halign: "center",
          lineColor: [44, 62, 80],
          lineWidth: 0.55
        },
        headStyles: {
          halign: "left",
          fontStyle: "Arimo",
          fontSize: 12,
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineColor: [255, 255, 255]
        },
        columnStyles: {
          0: {
            halign: "center",
            valign: "middle"
          },
          1: {
            halign: "center",
            valign: "middle",
            cellWidth: "auto"
          },
          2: {
            halign: "center",
            valign: "middle",
            cellWidth: "auto"
          }
        },
        html: "#my-table",

        didParseCell: function (HookData) {
          //
          // // if (HookData.row.raw.id == "1") {
          //   HookData.cell.styles.textColor = [0, 0, 0];
          //   HookData.cell.styles.fontSize = 9;
          //   HookData.cell.styles.fillColor = [192, 192, 192];
          // }
          // if (HookData.row.raw.id == "2") {
          //   HookData.cell.styles.fontSize = 13;
          // }
          if (HookData.row.raw.id == "countryRow") {
            var rows = HookData.table.body;

            HookData.cell.styles.textColor = [0, 0, 0];
            HookData.cell.styles.fontSize = 10;
            HookData.cell.styles.fillColor = [176, 224, 230];
          }
        },
        drawHeader: function (HookData) {
          return false;
        },

        didDrawPage: async function (data) {
          data.settings.margin.top = 5;
          var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
          var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

          if (data.pageNumber === 1) {
            var splitTitle = doc.splitTextToSize(title, 250);
            var dim = doc.getTextDimensions(title);

            var yearsPos = dim.w > 179 ? 64 : 57;
            doc.setFontSize(13);
            doc.setTextColor(60);
            doc.text(splitTitle, pageWidth / 2, 15, "center");
            //

            // PERIOD
            splitTitle = doc.splitTextToSize(selectedPeriod, 250);
            dim = doc.getTextDimensions(selectedPeriod);

            var yearsPos = dim.w > 179 ? 64 : 57;
            doc.setFontSize(13);
            doc.text(splitTitle, pageWidth / 2, 22, "center");
            doc.setFontSize(10);
            doc.setTextColor(60);
            // doc.setFontType("bold");
            doc.setFont(undefined, "bold");
          }
        },
        didDrawCell: function (HookData) {},
        margin: {
          top:
            this.$refs.template.$refs.pieChart2 &&
            this.$refs.template.$refs.pieChart
              ? 250
              : 140
        },
        rowPageBreak: "avoid"
      });

      //doc.deletePage(number_of_pages);
      number_of_pages = doc.internal.getNumberOfPages();
      for (var i = 1; i <= number_of_pages; i++) {
        doc.setPage(i);
        doc.setFontSize(12);
        doc.text(i + "/" + number_of_pages, 10, 290);
        if (i < number_of_pages) {
          doc.setFontSize(9);
          // doc.text(
          //   $this.$lang("reports.date_of_print") +
          //     " " +
          //     $this.$moment($this.today).format("DD.MM.YYYY hh:mm:ss"),
          //   pageWidth / 2 + 34,
          //   doc.internal.pageSize.height - 10
          // );
          // doc.text(
          //   $this.user.user.firstName + " " + $this.user.user.lastName,
          //   pageWidth / 2 + 58,
          //   doc.internal.pageSize.height - 5
          // );
        }
      }
      // doc.text(
      //   "_______________________",
      //   pageWidth / 2 + 34,
      //   doc.internal.pageSize.height - 14
      // );
      // doc.setFontSize(9);
      // doc.text(
      //   $this.user.user.firstName + " " + $this.user.user.lastName,
      //   pageWidth / 2 + 58,
      //   doc.internal.pageSize.height - 10
      // );
      // doc.setFontSize(9);
      // doc.text(
      //   $this.$lang("reports.date_of_print") +
      //     " " +
      //     $this.$moment($this.today).format("DD.MM.YYYY hh:mm:ss"),
      //   pageWidth / 2 + 34,
      //   doc.internal.pageSize.height - 5
      // );

      // if (
      //   this.selectedReportNumber === 1 ||
      //   this.selectedReportNumber === 2 ||
      //   this.selectedReportNumber === 6
      // ) {
      //   doc.deletePage(1);
      // }

      await doc.save(outputName);

      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        window.open(doc.output("bloburl"), "_blank");
      }
      $this.showLoader = false;
      $this.templateData = null;
      $this.selectedReportNumber = 0;
    }
  }
};
</script>

<style lang="scss">
.modal-form-emmaus {
  .datepicker-date {
    right: unset !important;
    position: fixed !important;
  }
  margin: 10px;
  display: grid;
  grid-template-columns: 1fr;

  .submit {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
  }
  .year-to {
    margin-bottom: 10px !important;
  }
}
</style>

<style lang="scss" scoped>
.select-template {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 10px;
}
.search-report {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  .submit {
    margin-top: 10px;
    grid-column: 1/4;
  }
}
</style>
