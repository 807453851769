import Vue from "vue";
import Vuex from "vuex";
import Store from "../../../../store";
import managmentStore from "../../../UserAndRolesManagement/store";

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
    GET_ALL_NOTIFICATION_FOR_LOGEDIN_USER: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get(
            "/Notifications/get-notifications-for-user/" +
              model.pageNum +
              "/" +
              model.pageOffset
          )
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_NUMBER_OF_UNREADED_NOTIFICATIONS: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Notifications/unseen-notifications")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    }
  }
});
