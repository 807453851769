<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-paperplane"></i>
        <span v-lang="'functions.function_details'"></span>
      </h1>
      <div class="actions">
        <a class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></a>
      </div>
    </div>
    <div class="card table-container">
      <table>
        <tr>
          <td v-lang="'general.label_id'"></td>
          <td>{{ functionById.pkFunctionId }}</td>
        </tr>
        <tr>
          <td v-lang="'functions.label_for_name'"></td>
          <td>{{ functionById.name }}</td>
        </tr>
        <tr>
          <td v-lang="'functions.label_for_description'"></td>
          <td>{{ functionById.description }}</td>
        </tr>
        <tr>
          <td v-lang="'functions.label_for_titleId'"></td>
          <td>{{ functionById.titleId }}</td>
        </tr>
        <tr>
          <td v-lang="'functions.label_for_isSystem'"></td>
          <td>{{ functionById.isSystem == false ? no : yes }}</td>
        </tr>
        <tr>
          <td v-lang="'functions.label_for_isBuiltIn'"></td>
          <td>{{ functionById.isBuiltin == false ? no : yes }}</td>
        </tr>
      </table>
    </div>
    <div class="card table-container">
      <div class="header card label-tabel">
        <h1>
          <span v-lang="'functions.label_of_actions_in_function'"></span>
        </h1>
      </div>
      <table class="table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th v-lang="'action.label_name_of_action'">&nbsp;</th>
            <th v-lang="'action.label_name_of_description'">&nbsp;</th>
            <th v-lang="'action.label_name_of_api_path'">&nbsp;</th>
            <th v-lang="'action.label_name_of_controller'">&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, key) in functionById.actions" :key="key">
            <td>{{ key + 1 }}</td>
            <td>{{ obj.action }}</td>
            <td>{{ obj.description }}</td>
            <td>{{ obj.apiPath }}</td>
            <td>{{ obj.pkController }}</td>

            <td>&nbsp;</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import { lang } from "@/global-functions";

export default {
  data() {
    return {
      functionById: {},
      yes: lang("general.label_yes"),
      no: lang("general.label_no")
    };
  },
  created() {
    this.getFunctionByID();
  },
  methods: {
    getFunctionByID() {
      var $this = this;
      store
        .dispatch("GET_FUNCTION_BY_ID", $this.$route.params.id)
        .then(response => {
          $this.functionById = response.data.model;
        })
        .catch(error => {});
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}

td:nth-child(1) {
  font-weight: bold;
}
label#actions {
  margin-bottom: 10px;
  display: inline-block;
  font-size: 2em;
}
.label-tabel {
  box-shadow: none;
  padding: 0;
}
</style>
