import Vue from "vue";
import Vuex from "vuex";
import Store from "../../../../store";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
    // SET_SELECTED_YEAR: function(state: any, pkSelectedYearId: any) {
    //   state.pkSelectedYearId = pkSelectedYearId;
    // },
  },
  actions: {
    UPLOAD_TEMPLATE: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .post("/Goal/upload-template/", model,{ headers: {
            'Content-Type': 'multipart/form-data'
          }})
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    DELETE_TEMPLATE: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .delete("/Goal/delete-template/"+ id,{ headers: {
            'Content-Type': 'multipart/form-data'
          }})
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
  },
});
