import Vue from "vue";
import Router from "vue-router";
import UserMRouter from "./components/UserAndRolesManagement/router";
import DeskRouter from "@/components/PageDesk/router";
import StatusRouter from "@/components/status-pages/router";
import store from "./store";
import store2 from "./components/UserAndRolesManagement/store";
import ErrorPage from "./components/General/ErrorPage.vue";
import IOMRouter from "@/components/IOM/router";
import EMMAUS from "@/components/EMMAUS/router";
import Survey from "@/components/Survey/router";
import YearsManagment from "@/components/Survey/Admin/YearManagment/router";
import TemplatesManagement from "@/components/Survey/Admin/TemplateManagement/router";
import AnswersManagement from "@/components/Survey/Admin/AnswersManagement/router";
import FilesManagement from "@/components/Survey/Admin/FilesManagement/router";
import LAP from "@/components/LAP/router";

Vue.use(Router);

var allRoutes = [
  {
    path: "/language/:lang",
    name: "Language",
  },
  {
    path: "/error/:statusCode",
    name: "ErrorPage",
    component: ErrorPage,
  },
];

const routes = [
  ...allRoutes,
  ...UserMRouter,
  ...StatusRouter,
  ...DeskRouter,
  ...IOMRouter,
  ...EMMAUS,
  ...Survey,
  ...YearsManagment,
  ...TemplatesManagement,
  ...AnswersManagement,
  ...FilesManagement,
  ...LAP,
];

var router = new Router({
  mode: "history",
  routes: routes,
});

function nextFactory(context: any, middleware: any, index: any) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters: any) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to: any, from: any, next: any) => {
  if (to.path == "/") {
    return next("/home");
  }

  //middleware
  if (to.path == "/logout") {
    store2
      .dispatch("LOGOUT_USER")
      .then((response) => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        // localStorage.removeItem("lang");
      })
      .catch((error) => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        // localStorage.removeItem("lang");
      });

    store.state.user = null;

    return next("/Login");
  }
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
      store,
    };

    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  if (to.meta.title) {
    document.title = to.meta.title;
  }

  return next();
});
export default router;
