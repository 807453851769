var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.resultsLoaded)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"header card"},[_c('h1',[_c('span',[_vm._v(_vm._s(_vm.$lang("survey.label_goal").toUpperCase() + " " + _vm.goalInfo.number + " - " + _vm.goalInfo.title))])]),_c('div',{staticClass:"actions"},[_c('button',{directives:[{name:"lang",rawName:"v-lang",value:('survey.export_to_pdf'),expression:"'survey.export_to_pdf'"}],staticClass:"primary mr-5",on:{"click":_vm.Export2PDF}}),_c('a',{directives:[{name:"lang",rawName:"v-lang",value:('survey.download_file_answer'),expression:"'survey.download_file_answer'"}],staticClass:"primary mr-5 file-answer",attrs:{"hidden":!_vm.existsingFileAnswer,"href":_vm.existsingFileAnswer
            ? _vm.mainStore.state.core_api +
              'api/FileAnswer/download-file-answer/' +
              _vm.existsingFileAnswer.filePath
            : ''}}),_c('a',{directives:[{name:"lang",rawName:"v-lang",value:('survey.upload_file_answer'),expression:"'survey.upload_file_answer'"}],staticClass:"primary mr-5 file-answer",attrs:{"hidden":!_vm.goalInfo.templatePath ||
          _vm.questionsWithAnswers[0].reportYear.isLockedForOrganisations},on:{"click":function($event){_vm.showFileUploadDialog = !_vm.showFileUploadDialog}}}),_c('a',{directives:[{name:"lang",rawName:"v-lang",value:('survey.download_template'),expression:"'survey.download_template'"}],staticClass:"primary",attrs:{"hidden":!_vm.goalInfo.templatePath,"href":_vm.mainStore.state.core_api +
          'api/Goal/download-template-anonymous/' +
          _vm.goalInfo.templatePath}})])]),(_vm.showFileUploadDialog)?_c('div',{staticClass:"card",attrs:{"transition":"expand"}},[_c('form',{attrs:{"data-vv-scope":"file_answer_form"},on:{"submit":function($event){$event.preventDefault();return _vm.uploadFileAnswer()}}},[_c('div',{staticClass:"input m-0",class:_vm.errors.first('file_answer_form.fileName') !== undefined
            ? 'input-error'
            : ''},[_c('label',{attrs:{"for":"number"}},[_c('span',{directives:[{name:"lang",rawName:"v-lang",value:('survey.label_file_name'),expression:"'survey.label_file_name'"}]})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fileAnswerName),expression:"fileAnswerName"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"m-0",attrs:{"type":"text","name":"fileName","autocomplete":"off"},domProps:{"value":(_vm.fileAnswerName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.fileAnswerName=$event.target.value}}}),_c('div',[_c('span',{staticClass:"error",domProps:{"innerHTML":_vm._s(_vm.errors.first('file_answer_form.fileName'))}})])]),_c('UploadInput',{directives:[{name:"validate",rawName:"v-validate",value:('required|ext:xlsx,xls'),expression:"'required|ext:xlsx,xls'"}],ref:'fileUpload',attrs:{"containerClass":_vm.errors.first('file_answer_form.file') !== undefined
            ? 'input-error'
            : '',"uploadAreaClass":_vm.errors.first('file_answer_form.file') !== undefined
            ? 'upload-area-error'
            : '',"placeholder":_vm.$lang('surveyTemplates.select_file_for_upload'),"name":"file","errorText":_vm.errors.first('file_answer_form.file'),"acceptFileTypes":'.xls,.xslx'},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('div',{staticClass:"question-with-answer"},[_c('div',{staticClass:"button-search"},[_c('Spinner',{attrs:{"showSpinner":_vm.fileUploadingInProgress,"buttonText":_vm.$lang('general.button_submit'),"buttonClass":'primary'}})],1)])],1)]):_vm._e(),(!_vm.resultsLoaded)?_c('Loader2'):_c('div',{staticClass:"card"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_vm._l((_vm.questionsWithAnswers),function(QA){return _c('div',{key:QA.fkReportQuestionId,staticClass:"question-with-answer"},[_c('div',{staticClass:"bold"},[_vm._v(" "+_vm._s(_vm.goalInfo.number + "." + QA.reportQuestion.questionNumber)+" "+_vm._s(QA.reportQuestion.title)+" ")]),_c('div',{staticClass:"mt-10 question-description",domProps:{"innerHTML":_vm._s(QA.reportQuestion.description)}}),_c('div',{staticClass:"mt-10"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(QA.text),expression:"QA.text"}],staticClass:"answer-input",attrs:{"disabled":QA.reportYear.isLockedForOrganisations,"maxlength":"3600"},domProps:{"value":(QA.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(QA, "text", $event.target.value)}}})])])}),(
          !(
            !_vm.questionsWithAnswers ||
            _vm.questionsWithAnswers.length == 0 ||
            _vm.questionsWithAnswers[0].reportYear.isLockedForOrganisations
          )
        )?_c('div',{staticClass:"question-with-answer"},[_c('div',{staticClass:"button-search"},[_c('Spinner',{attrs:{"showSpinner":_vm.isSubmited,"buttonText":_vm.$lang('general.button_submit'),"buttonClass":'primary'}})],1)]):_vm._e()],2)]),_c('SurveyReport',{attrs:{"model":_vm.reportAnswers}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }