import Index from "./Index.vue";
import Insert from "./Create.vue";
import Edit from "./Edit.vue";
import AdminLayout from "../../UserAndRolesManagement/AdminPanel/AdminLayout.vue";
import auth from "@/middleware";

const router = [
  {
    path: "/emmaus-institutions",
    name: "AdminLayout",
    component: AdminLayout,
    meta: {
      middleware: auth,
    },
    children: [
      {
        path: "index",
        name: "EmmausInstitutionIndex",
        component: Index,
      },
      {
        path: "insert",
        name: "EmmausInstitutionInsert",
        component: Insert,
      },
      {
        path: "edit",
        name: "EmmausInstitutionEdit",
        component: Edit,
      },
    ],
  },
];

export default router;
