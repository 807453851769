<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-paperplane"></i>
        <span v-lang="'queries.title'"></span>
      </h1>
      <div class="actions">
        <router-link
          class="primary small"
          :to="{ name: 'QueriesCreate' }"
          v-lang="'general.label_add'"
        ></router-link>
      </div>
    </div>
    <notify />
    <NoData v-if="!search || search.length == 0"></NoData>
    <div v-else class="card table-container actions">
      <table class="table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th v-lang="'queries.role'">&nbsp;</th>
            <th v-lang="'general.description'">&nbsp;</th>
            <th>API</th>
            <th v-lang="'general.description'">&nbsp;</th>
            <th v-lang="'queries.query_description'">&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, key) in search" :key="key">
            <td>{{ pageSize * (pageNum - 1) + key + 1 }}</td>
            <td>{{ obj.role.name }}</td>
            <td>{{ obj.role.description }}</td>
            <td>{{ obj.action.apiPath }}</td>
            <td>{{ obj.action.description }}</td>
            <td>{{ obj.description }}</td>
            <td>
              <router-link
                class="default small"
                :to="{
                  name: 'QueriesDetails',
                  query: {
                    PkFkRoleId: obj.pkFkRoleId,
                    PkFkActionId: obj.pkFkActionId,
                    PkFkController: obj.pkFkController
                  }
                }"
                v-lang="'general.label_details'"
              ></router-link>
              <router-link
                class="primary small"
                :to="{
                  name: 'QueriesEdit',
                  query: {
                    PkFkRoleId: obj.pkFkRoleId,
                    PkFkActionId: obj.pkFkActionId,
                    PkFkController: obj.pkFkController
                  }
                }"
                v-lang="'general.label_edit'"
              ></router-link>
              <a
                class="error small"
                v-on:click="
                  deleteQuery({
                    PkFkRoleId: obj.pkFkRoleId,
                    PkFkActionId: obj.pkFkActionId,
                    PkFkController: obj.pkFkController
                  })
                "
                v-lang="'general.label_delete'"
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
      <paginate
        v-if="search.length != 0"
        :page-count="pageCount"
        :click-handler="changePage"
        :prev-text="this.$lang('general.previous')"
        :next-text="this.$lang('general.next_page')"
        :container-class="'paggination'"
      ></paginate>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../store";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import Paginate from "vuejs-paginate";
import NoData from "../../General/NoData";

Vue.use(Toasted);

export default {
  components: {
    NoData,
    paginate: Paginate
  },
  data() {
    return {
      queries: [],
      pageSize: 10,
      pageNum: 1,
      pageCount: 0,
      yes: lang("general.label_yes"),
      no: lang("general.label_no")
    };
  },
  created() {
    this.getAllQueries();
  },
  computed: {
    search() {
      var $this = this;
      var allData = $this.queries;
      $this.pageCount =
        allData.length % $this.pageSize == 0
          ? parseInt(allData.length / $this.pageSize)
          : parseInt(allData.length / $this.pageSize) + 1;

      return allData.slice(
        (this.pageNum - 1) * this.pageSize,
        this.pageNum * this.pageSize
      );
    }
  },
  methods: {
    changePage: function(pageNum) {
      this.pageNum = pageNum;
    },
    getAllQueries() {
      var $this = this;
      store
        .dispatch("GET_ALL_QUERIES")
        .then(response => {
          $this.queries = response.data.list;
        })
        .catch(error => {});
    },
    deleteQuery(model) {
      this.$removeToast();
      Vue.toasted.show(lang("queries.delete_question"), {
        theme: "toasted-primary",
        position: "top-center",
        duration: 5000,
        action: [
          {
            text: lang("general.label_yes"),
            onClick: (e, toastObject) => {
              var $this = this;
              store
                .dispatch("DELETE_QUERY", model)
                .then(response => {
                  $this.getAllQueries();
                  this.$error("queries.delete_success");
                  this.$store.commit(
                    "SUCCESS_MESSAGE",
                    "queries.delete_success"
                  );
                  this.notifyKey += 1;
                })
                .catch(error => {});
              toastObject.goAway(0);
            }
          },
          {
            text: lang("general.label_no"),
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            }
          }
        ]
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
