<template>
  <div class="container">
    <div class="login-form">
      <h1>
        <content v-lang="'forms.reset-password'"></content>
        <p v-lang="'forms.insert_new_password'"></p>
        <span></span>
      </h1>
      <form v-on:submit.prevent="sendData()" data-vv-scope="reset">
        <div class="input">
          <label for="password" v-lang="'forms.new_password'"></label>
          <input
            v-model="model.password"
            name="password"
            v-validate="'required'"
            ref="password"
            type="password"
            autocomplete="off"
          />
          <div class="error" v-html="errors.first('reset.password')"></div>
        </div>
        <div class="input">
          <label for="confirm_password" v-lang="'forms.confirm_new_password'"></label>
          <input
            v-model="model.confirm_password"
            type="password"
            name="confirm_password"
            v-validate="'required|confirmed:password'"
            autocomplete="off"
          />
          <div class="error" v-html="errors.first('reset.confirm_password')"></div>
        </div>
        <div class="button-container">
          <button class="primary login right-angle">
            <content v-lang="'forms.submit'"></content>
            <span></span>
          </button>
          <router-link class="forgot-password" :to="{ name:'Login'}" v-lang="'forms.back_to_login'"></router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { ToastSuccess, ToastError } from "@/global-functions";
import { lang } from "@/global-functions";
import globalStore from "@/store";
import store from "@/components/UserAndRolesManagement/store";
export default {
  data() {
    return {
      model: {
        password: "",
        confirm_password: ""
      },
      validation_messages: {
        custom: {
          confirm_password: {
            confirmed: lang("user.passwords_dont_match"),
            required: lang("validation.required")
          },
          password: {
            required: lang("validation.required")
          }
        }
      },
      error: ""
    };
  },
  mounted() {
    globalStore.commit("SHOW_LOADER");
    //koji jezik za validaciju se prosledjuje nije bitno jer nam funkcija vraca odgovarajuce poruke
    this.$validator.localize("en", this.validation_messages);
    if (this.$route.query.pwdResetToken == 'undefined') {
      var messageTitle = lang("user.invalid_token_error");
      var messageContent = lang("user.invalid_token_error_desc");
      $this.$router.push({
          name: "ShowError",
          query: { messageTitle: messageTitle, messageContent: messageContent }
        });
      globalStore.commit("HIDE_LOADER_500");
    }
    else{
    var $this = this;
    store
      .dispatch("IS_TOKEN_EXPIRED", this.$route.query.pwdResetToken)
      .then(response => {})
      .catch(error => {
        
        var messageTitle = lang("user.invalid_token_error");
        var messageContent = lang("user.invalid_token_error_desc");
        $this.$router.push({
          name: "ShowError",
          query: { messageTitle: messageTitle, messageContent: messageContent }
        });
      })
      .finally(() => {
        globalStore.commit("HIDE_LOADER");
      });
      }
  },
  methods: {
    
    sendData() {
      var $this = this;
      this.$validator.validateAll("reset").then(valid => {
        if (valid) {
          globalStore.commit("SHOW_LOADER");
          store
          .dispatch("SET_NEW_PASSWORD", { ResetToken: $this.$route.query.pwdResetToken, NewPassword: $this.model.password})
          .then(response => {
            ToastSuccess(response.data.message);
            $this.$router.push("Login");
          })
          .catch(error => {
            ToastError(error.data.message);
          })
          .finally(() => {
            globalStore.commit("HIDE_LOADER_500");
          });
        }});
    }
  }
};
</script>

<style  lang="scss" scoped>
@import "../../../../assets/css/colors";
.container {
  display: grid;
  height: 100vh;
  background: $secondaryColor;
  position: fixed;
  width: 100%;
  justify-items: center;
  align-items: center;
  .user-data {
  }
  hr {
    background-color: $primaryColor;
    width: 95%;
    height: 1px;
    border: 0;
  }
  h1 {
    width: calc(65% - 30px);
    color: white;
    background: $primaryColor;
    margin: 0;
    margin-bottom: 15px;
    padding: 10px 15px;
    position: relative;
    z-index: 10;
    p {
      font-size: 10px;
      margin: 0;
      font-weight: 100;
      letter-spacing: -0px;
    }
    // border-top-left-radius: 10px;
    span {
      border-right: 30px solid transparent;
      border-top: 64px solid $primaryColor;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -30px;
    }
    &:hover {
      background: $primaryColor;
      color: $secondaryColor;
    }
  }
  .login-form {
    background: white;
    // border-radius: 10px;
    min-width: 400px;
  }
  .button-container {
    line-height: 39px;

    a {
      padding: 10px;
      font-size: 0.8rem;
      text-decoration: none;
      color: $primaryColor;
      &:hover {
        text-decoration: underline;
      }
    }

    button.login {
      float: right;
      width: calc(35% - 30px);
      position: relative;
      height: 40px;
      cursor: pointer;
      span {
        border-left: 20px solid transparent;
        border-bottom: 40px solid $primaryColor;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -20px;
      }
      &:hover {
        background: $primaryColor;
        color: $secondaryColor;
      }
    }
  }
}
</style>