<template>
  <div class="container">
    <div>
      <h1 class="message-title">
        <content v-html="messageTitle"></content>
        <span></span>
      </h1>
      <div class="message-content" v-html="messageContent"></div>
      <router-link class="forgot-password" :to="{ name:'Login'}" v-lang="'forms.back_to_login'"></router-link>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  data() {
    return {
      messageTitle: "",
      messageContent: ""
    };
  },
  mounted() {
    this.messageTitle = this.$route.query.messageTitle;
    this.messageContent = this.$route.query.messageContent;
  },
  methods: {}
};
</script>

<style  lang="scss" scoped>
@import "../../assets/css/colors";
.container {
  height: 100vh;
  display: grid;
  justify-items: center;
  align-items: center;
  padding-bottom: 10px;
  // color:
  & > div {
    background: white;
    color: $secondaryColor;
    h1 {
      width: calc(50% - 30px);
      color: white;
      background: $primaryColor;
      margin: 0;
      margin-bottom: 15px;
      padding: 10px 15px;
      position: relative;
      z-index: 10;
      p {
        font-size: 10px;
        margin: 0;
        font-weight: 100;
        letter-spacing: -0px;
      }
      span {
        border-right: 30px solid transparent;
        border-top: 53px solid $primaryColor;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -30px;
      }
    }
    & > .message-content {
      padding: 10px 15px;
    }
    .forgot-password {
      padding: 10px 15px;
      font-size: 0.8rem;
      text-decoration: none;
      color: $primaryColor;
      display: table-cell;
      &:hover {
        text-decoration: underline;
      }
    }
    // border-radius: 10px;
  }
}
</style>