import Index from "./Index.vue";
import Statistic from "./Statistic.vue";
import AdminLayout from "../UserAndRolesManagement/AdminPanel/AdminLayout.vue";
import Years from "./YearManagment/Index.vue"
import auth from "@/middleware";

const router = [
  {
    path: "/LAP",
    name: "Lap",
    component: AdminLayout,
    meta: {
      middleware: auth,
    },
    children: [
      {
        path: "index",
        name: "LapIndex",
        component: Index,
      },
      {
        path: "statistic",
        name: "LapStatistic",
        component: Statistic,
      },
      {
        path: "years",
        name: "LapYears",
        component: Years,
      },
    ],
  },
];

export default router;
