<template>
  <div>
    <div class="container">
      <div class="header card">
        <h1>
          <i class="icon-office"></i>
          <span v-lang="'emmaus.preview_institutions'"></span>
        </h1>
        <div class="actions">
          <!-- <a class="primary" v-on:click="clickBack()" v-lang="'general.button_back'"></a> -->
        </div>
      </div>
      <Loader2 v-if="!resultsLoaded" />
      <NoData v-else-if="!institutions || institutions.length == 0" />
      <span v-else>
        <div
          v-responsive="{ small: (el) => el.width < 1050 }"
          class="card table-container"
        >
          <table class="table-hover">
            <thead>
              <tr :id="tableId">
                <th>#</th>
                <th v-on:click="sort('Name')" Name>
                  {{ $lang("general.label_name") }}
                  <span></span>
                </th>
                <th
                  id="description-place"
                  v-on:click="sort('Description')"
                  Description
                >
                  {{ $lang("general.label_description") }}
                  <span></span>
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(obj, key) in institutions" :key="key">
                <td>
                  {{ (searchModel.page - 1) * searchModel.limit + key + 1 }}
                </td>
                <td>{{ obj.name }}</td>
                <td @click="showDescription" class="description-place pointer">
                  {{ obj.description }}
                </td>
                <td>
                  <router-link
                    v-if="$role(40278)"
                    class="primary small"
                    :to="{
                      name: 'EmmausInstitutionEdit',
                      query: { id: obj.pkEmmausInstitutionId }
                    }"
                    v-lang="'general.label_edit'"
                  ></router-link>
                  <a
                    v-if="$role(40279)"
                    class="error small"
                    v-lang="'general.label_delete'"
                    v-on:click="deleteInstitution(obj.pkEmmausInstitutionId)"
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination-container">
            <paginate
              v-if="institutions.length > 0 && pageCount > 0"
              :page-count="pageCount"
              v-model="searchModel.page"
              :clickHandler="changePage"
              :prev-text="this.$lang('general.previous')"
              :next-text="this.$lang('general.next_page')"
              :container-class="'paggination'"
            ></paginate>
          </div>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "./store";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import moment from "moment";
import globalStore from "@/store";
import NoData from "@/components/General/NoData";
import Loader2 from "../../General/Loader2";
import Paginate from "vuejs-paginate";
import { ResponsiveDirective } from "vue-responsive-components";

Vue.use(Toasted);

export default {
  directives: {
    responsive: ResponsiveDirective
  },
  components: {
    Loader2,
    Paginate,
    NoData
  },
  data() {
    return {
      nameRoute: "",
      institutions: [],
      searchModel: {
        page: this.$route.query.page > 0 ? Number(this.$route.query.page) : 1,
        limit: 10,
        orderColumn:
          this.$route.query.orderColumn != ""
            ? this.$route.query.orderColumn
            : "",
        orderSort:
          this.$route.query.orderSort != "" ? this.$route.query.orderSort : "",
        IsDeleted: false
      },
      resultsLoaded: true,
      pageCount: 0,
      tableId: Math.random(),
      openedDescription: false
    };
  },
  created() {
    this.getAllInstitutions();
  },
  beforeDestroy() {
    this.$removeToast();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeName = from.name;
      vm.query = from.query;
      vm.nameRoute = from.name;
    });
  },
  methods: {
    showDescription(event) {
      event.target.classList.toggle("description-place");
    },
    clickBack() {
      this.$router.push({
        name: this.nameRoute,
        query: Object.assign({}, this.query)
      });
    },
    changePage(pageNum) {
      this.searchModel.page = pageNum;
      this.getAllInstitutions();
    },
    sort(columnName) {
      if (this.searchModel.orderColumn != columnName) {
        this.searchModel.orderColumn = columnName;
        this.searchModel.orderSort = "DESC";
      } else if (this.searchModel.orderSort == "DESC") {
        this.searchModel.orderSort = "ASC";
      } else {
        this.searchModel.orderSort = "DESC";
      }
      this.getAllInstitutions();
    },
    setIcon() {
      var element = document.getElementById(this.tableId);
      if (element == null) return;
      var array = element.children;
      var item;
      for (let index = 0; index < array.length; index++) {
        if (
          array[index].hasAttribute(
            this.searchModel.orderColumn != null
              ? this.searchModel.orderColumn.replace(".", "-")
              : null
          )
        ) {
          item = array[index];
        }
      }
      if (
        this.searchModel.orderColumn != "" &&
        this.searchModel.orderSort == "DESC"
      ) {
        item.classList.add("desc");
      } else if (this.searchModel.orderSort == "ASC") {
        item.classList.add("asc");
      }
    },
    getAllInstitutions() {
      this.$router.push({
        query: { ...this.searchModel }
      });
      var $this = this;
      $this.resultsLoaded = false;
      store
        .dispatch("SEARCH_EMMAUS_INSTITUTIONS", $this.searchModel)
        .then((response) => {
          $this.resultsLoaded = true;
          $this.institutions = response.data.list;
          $this.pageCount = $this.institutions[0].pageCount;

          setTimeout(() => {
            $this.setIcon();
          }, 50);
        })
        .catch((error) => {});
    },
    deleteInstitution(id) {
      this.$removeToast();
      Vue.toasted.show(lang("emmaus.do_you_want_to_delete_this_institution"), {
        theme: "toasted-primary",
        position: "top-center",
        duration: 5000,
        action: [
          {
            text: lang("general.label_yes"),
            onClick: (e, toastObject) => {
              var $this = this;
              store
                .dispatch("DELETE_EMMAUS_INSTITUTION", id)
                .then((response) => {
                  this.$success("emmaus.institution_successfully_deleted");
                  this.notifyKey += 1;
                  if ($this.institutions.length == 1 && $this.pageCount > 1) {
                    $this.searchModel.page--;
                  }
                  $this.getAllInstitutions();
                })
                .catch((error) => {});
              toastObject.goAway(0);
            }
          },
          {
            text: lang("general.label_no"),
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            }
          }
        ]
      });
    }
  },
  watch: {},
  computed: {}
};
</script>

<style lang="scss" scoped>
.pagination-container {
  margin: 0 !important;
}
.description-place {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 500px;
  word-wrap: break-word;
  //float: left;
}
.small {
  .description-place {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 120px;
    word-wrap: break-word;
    //float: left;
  }
  #description-place {
    width: 120px;
  }
}
#description-place {
  width: 500px;
}

.pointer {
  cursor: pointer;
  word-break: break-all;
}
</style>
