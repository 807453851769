<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-users"></i>
        <span v-lang="'logs.logs_user_details'"></span>
      </h1>
      <div class="actions">
        <button
          class="primary"
          v-on:click="$router.go(-1)"
          v-lang="'general.button_back'"
        ></button>
      </div>
    </div>
    <div class="card table-container scroll">
      <table>
        <tr>
          <td>ID</td>
          <td>{{ userLog.pkUserLogId }}</td>
        </tr>
        <tr>
          <td v-lang="'user.user_ID'"></td>
          <td>{{ userLog.idUser }}</td>
        </tr>
        <tr>
          <td v-lang="'user.username'"></td>
          <td>{{ userLog.userName }}</td>
        </tr>
        <tr>
          <td>API</td>
          <td>{{ userLog.api }}</td>
        </tr>
        <tr>
          <td v-lang="'general.description'"></td>
          <td>{{ $lang(userLog.description) }}</td>
        </tr>
        <tr>
          <td v-lang="'general.ip_address'"></td>
          <td>{{ userLog.host }}</td>
        </tr>
        <tr>
          <td v-lang="'general.client_info'"></td>
          <td>{{ userLog.clientInfo }}</td>
        </tr>
        <tr>
          <td v-lang="'general.date_time'"></td>
          <td v-moment="userLog.utcDatetime"></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import { lang } from "@/global-functions";

export default {
  data() {
    return {
      userLog: {}
    };
  },
  created() {
    this.getUserLogById();
  },
  methods: {
    getUserLogById() {
      var $this = this;
      store
        .dispatch("GET_USER_LOG_BY_ID", $this.$route.params.id)
        .then(response => {
          $this.userLog = response.data.model;
        })
        .catch(error => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll {
  overflow: auto;
}
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}

td:nth-child(2) {
  font-weight: bold;
}
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
}
</style>
