<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-key"></i>
        <span v-if="$route.params.id" v-lang="'user.label_edit_role'"></span>
        <span v-else v-lang="'user.label_create_role'"></span>
      </h1>
      <div class="actions">
        <a
          v-if="$route.params.id && $role(10122)"
          class="error"
          v-on:click="redirectToPermissionsForRole()"
          v-lang="'user.button_set_functions_for_role'"
        ></a>
        <a class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></a>
      </div>
    </div>
    <notify />
    <Loader2 v-if="!showTranslations" />
    <span v-else>
      <div>
        <div class="card">
          <form id="role_form" v-on:submit.prevent="save()" data-vv-scope="role_form">
            <div
              class="input m-0"
              :class="
              errors.first('role_form.name') !== undefined ? 'input-error' : ''
            "
            >
              <label for="name">
                <span v-lang="'general.label_name'"></span>
                <span class="required-field-star">&#x2605;</span>
              </label>
              <input
                v-validate="'required'"
                class="m-0"
                v-model="model.name"
                type="text"
                name="name"
                autocomplete="off"
              />
              <div class="error" v-html="errors.first('role_form.name')"></div>
            </div>

            <div
              class="input m-0"
              :class="
              errors.first('role_form.description') !== undefined
                ? 'input-error'
                : ''
            "
            >
              <label for="description">
                <span v-lang="'general.label_description'"></span>
                <span class="required-field-star">&#x2605;</span>
              </label>
              <input
                v-validate="'required'"
                class="m-0"
                v-model="model.description"
                type="text"
                name="description"
                autocomplete="off"
              />
              <div class="error" v-html="errors.first('role_form.description')"></div>
            </div>

            <div class="translations-class">
              <div v-for="(obj,key) in langauges" :key="key" class="input m-0">
                <label for="en">{{obj.name}}</label>
                <input
                  class="m-0"
                  type="text"
                  v-model="translationModel.translations[Number(key)]"
                  name="en"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="input-checkbox first-checkbox">
              <label for="default">
                <input
                  class="m-0"
                  type="checkbox"
                  v-model="model.default"
                  id="default"
                  autocomplete="off"
                  hidden
                />
                <span v-lang="'general.label_default'"></span>
              </label>
            </div>

            <div class="input-checkbox">
              <label for="adminUserType">
                <input
                  class="m-0"
                  type="checkbox"
                  v-model="model.adminUserType"
                  id="adminUserType"
                  autocomplete="off"
                  hidden
                />
                <span v-lang="'user.label_admin_type'"></span>
              </label>
            </div>

            <div class="input-checkbox">
              <label for="isSystem">
                <input
                  class="m-0"
                  type="checkbox"
                  v-model="model.isSystem"
                  id="isSystem"
                  autocomplete="off"
                  hidden
                />
                <span v-lang="'user.label_is_system_role'"></span>
              </label>
            </div>

            <div class="input-checkbox">
              <label for="isBuiltin">
                <input
                  class="m-0"
                  type="checkbox"
                  v-model="model.isBuiltin"
                  id="isBuiltin"
                  autocomplete="off"
                  hidden
                />
                <span v-lang="'user.label_is_builtin_role'"></span>
              </label>
            </div>

            <div class="button-search">
              <Spinner
                :showSpinner="isSubmited"
                :buttonText="$lang('general.button_submit')"
                :buttonClass="'primary'"
              />
            </div>
          </form>
        </div>
      </div>

      <div v-if="this.$route.params.id" class="header card">
        <h1>
          <span>{{$lang('user.label_managed_roles_for')}}:&nbsp;</span>
          <span>&nbsp;</span>
          <span v-html="model.name"></span>
        </h1>
      </div>
      <div v-if="this.$route.params.id" class="table-container">
        <NoData v-if="!manageRoles || manageRoles.length == 0"></NoData>
        <div v-else class="card table-container">
          <table class="table-hover managedRolesTable">
            <thead>
              <tr>
                <th>#</th>
                <th v-lang="'general.label_name'">&nbsp;</th>
                <th v-lang="'general.label_description'">&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(obj, key) in this.manageRoles" :key="key">
                <td>{{ key + 1 }}</td>
                <td>{{ obj.name }}</td>
                <td>{{ obj.description }}</td>
                <td>
                  <a
                    class="error small"
                    v-on:click="deleteManagedRole(obj.pkRoleId)"
                    v-lang="'general.label_remove'"
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>

          <form
            v-if="filteredRoles.length > 0"
            v-on:submit.prevent="add()"
            data-vv-scope="add_function_form"
          >
            <div
              class="input"
              :class="
            errors.first('add_function_form.pkFkRoleIdManaged') !== undefined
              ? 'input-error'
              : ''
          "
            >
              <label for="fkActionModuleId" v-lang="'general.role'"></label>
              <select
                v-model="roleManageRoleModel.pkFkRoleIdManaged"
                name="pkFkRoleIdManaged"
                id="Roledropdown"
              >
                <option value="0" selected v-lang="'user.label_select_role'"></option>
                <option
                  v-for="(obj, key) in filteredRoles"
                  :key="key"
                  :value="obj.pkRoleId"
                >{{ obj.name }}</option>
              </select>
              <div>
                <span class="error" v-html="errors.first('add_function_form.pkFkRoleIdManaged')"></span>
              </div>
            </div>
            <div class="button-search m-0">
              <button class="primary" v-lang="'general.label_add'"></button>
            </div>
          </form>
        </div>
      </div>
    </span>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../store";
import { lang } from "@/global-functions";
import { mapState } from "vuex";
import globalStore from "@/store";
import NoData from "../../General/NoData";
import Loader2 from "../../General/Loader2";

export default {
  components: {
    NoData,
    Loader2
  },
  data() {
    return {
      model: {
        pkRoleId: 0,
        name: "",
        description: "",
        titleId: "",
        default: false,
        adminUserType: false,
        isDeleted: false,
        isSystem: false,
        isBuiltin: false,
        valuesForLanguages: []
      },
      roleManageRoleModel: {
        pkFkRoleIdManager: 0,
        pkFkRoleIdManaged: 0
      },
      langauges: [],
      translationModel: {
        titleId: "",
        translations: []
      },
      showTranslations: false,
      allRoles: [],
      manageRoles: [],
      errorModel: {
        message: "",
        key: ""
      },
      validation_messages: {
        custom: {
          name: {
            required: lang("validation.required")
          },
          description: {
            required: lang("validation.required")
          }
        }
      },
      isSubmited: false
    };
  },
  computed: {
    ...mapState(["token"])
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
    if (this.$route.params.id)
      this.roleManageRoleModel.pkFkRoleIdManager = this.$route.params.id;
  },
  created() {
    this.getAllLanguages();
    setTimeout(() => {
      if (this.$route.params.id) {
        this.getRoleByID();
        this.getRoleMangedRolesByID();
        this.getAllRoles();
      } else {
        this.showTranslations = true;
      }
    }, 300);
  },
  computed: {
    filteredRoles() {
      var $this = this;
      //Obrisati rolu u kojoj se trenutno nalazimo
      //Isfiltrirati ostale, ovo dole napisano nesto ne radi
      return this.allRoles.filter(
        role =>
          !$this.manageRoles.find(mr => mr.pkRoleId == role.pkRoleId) &&
          role.pkRoleId != $this.$route.params.id
      );
    }
  },
  methods: {
    seacrh(code) {
      for (var i = 0; i < this.model.valuesForLanguages.length; i++) {
        if (this.model.valuesForLanguages[i].langCode === code) {
          return this.model.valuesForLanguages[i].titleValue;
        }
      }
    },
    getTranslationsByID(id) {
      var $this = this;
      store
        .dispatch("GET_TRANSLATION_BY_ID", id)
        .then(response => {
          $this.model.valuesForLanguages = response.data;
          $this.model.titleId = $this.model.valuesForLanguages[0].titleId;

          for (let i = 0; i < $this.langauges.length; i++) {
            $this.translationModel.translations[i] = $this.seacrh(
              $this.langauges[i].code
            );
          }

          $this.showTranslations = true;
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    getAllLanguages() {
      var $this = this;
      store
        .dispatch("GET_ALL_LANGUAGES")
        .then(response => {
          $this.langauges = response.data.list;
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    save() {
      var $this = this;
      this.model.valuesForLanguages = [];
      for (let i = 0; i < this.translationModel.translations.length; i++) {
        var model = {
          titleId: this.model.titleId,
          langCode: this.langauges[i].code,
          titleValue: this.translationModel.translations[i]
        };
        this.model.valuesForLanguages.push(model);
      }
      this.$validator.validateAll("role_form").then(valid => {
        if (valid) {
          $this.isSubmited = true;

          if (this.$route.params.id) {
            //UPDATE
            store
              .dispatch("EDIT_ROLE", $this.model)
              .then(response => {
                this.$success("user.role_sucessfully_updated");
                this.$router.push({ name: "RolesIndex" });
                $this.isSubmited = false;
              })
              .catch(error => {
                if (error.sustus && error.status == 400)
                  $this.errorModel = error.data;
              });
          } //CREATE
          else {
            store
              .dispatch("CREATE_ROLE", $this.model)
              .then(response => {
                this.$success("user.role_sucessfully_created");
                this.$router.push({ name: "RolesIndex" });
                $this.isSubmited = false;
              })
              .catch(error => {
                if (error.sustus && error.status == 400)
                  $this.errorModel = error.data;
              });
          }
        }
      });
    },
    getRoleByID() {
      var $this = this;
      store
        .dispatch("GET_ROLE_BY_ID", $this.$route.params.id)
        .then(response => {
          $this.model = response.data.model;
          $this.getTranslationsByID(this.model.titleId);
        })
        .catch(error => {});
    },
    getRoleMangedRolesByID() {
      var $this = this;
      store
        .dispatch("GET_MANAGE_ROLES_BY_ROLE_ID", $this.$route.params.id)
        .then(response => {
          $this.manageRoles = response.data.list;
        })
        .catch(error => {});
    },
    getAllRoles() {
      var $this = this;
      store
        .dispatch("GET_All_ROLES")
        .then(response => {
          $this.allRoles = response.data.list;
        })
        .catch(error => {});
    },
    add() {
      if (this.roleManageRoleModel.pkFkRoleIdManaged != 0) {
        var $this = this;
        store
          .dispatch("ADD_MANAGED_ROLE_TO_ROLE", $this.roleManageRoleModel)
          .then(response => {
            store
              .dispatch("GET_MANAGE_ROLES_BY_ROLE_ID", $this.$route.params.id)
              .then(response => {
                $this.manageRoles = response.data.list;
                $this.$success("user.role_sucessfully_added");
              })
              .catch(error => {});
          })
          .catch(error => {});
      }
    },
    deleteManagedRole(pkFkRoleIdManaged) {
      var $this = this;
      store
        .dispatch("DELETE_MANAGED_ROLE", {
          pkFkRoleIdManager: $this.$route.params.id,
          pkFkRoleIdManaged: pkFkRoleIdManaged
        })
        .then(response => {
          store
            .dispatch("GET_MANAGE_ROLES_BY_ROLE_ID", $this.$route.params.id)
            .then(response => {
              $this.manageRoles = response.data.list;
              $this.$success("user.role_sucessfully_removed");
            })
            .catch(error => {});
        })
        .catch(error => {});
    },
    redirectToPermissionsForRole() {
      this.$router.push({
        name: "PermissionsForRole",
        params: { id: this.$route.params.id }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}
form {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  .first-checkbox,
  .button-search {
    grid-column: 1/3;
  }
}

.managedRolesTable {
  padding-bottom: 0;
  margin-bottom: 0;
}
.translations-class {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  grid-column: 1/3;
}
</style>
