<template>
  <div class="container">
    <div class="header card">
      <h1>
        <span v-lang="'entity_relations.entity_relations_create'"></span>
      </h1>
      <div class="actions">
        <a class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></a>
      </div>
    </div>
    <div class="card">
      <form v-on:submit.prevent="submit()" data-vv-scope="entity_relations">
        <div
          class="input m-0"
          :class="
              errors.first('entity_relations.pkFkRelationTypeId') !== undefined
                ? 'input-error'
                : ''
            "
        >
          <label for="pkFkRelationTypeId">{{ $lang('entity_relations.relation_type')}}</label>
          <select
            v-validate="'required|excluded:0'"
            v-model="model.pkFkRelationTypeId"
            v-on:change="setEntity($event)"
            name="pkFkRelationTypeId"
            id="pkFkRelationTypeId"
          >
            <option value="0">{{ $lang('entity_relations.select_relation_type')}}</option>
            <option
              v-for="(obj, key) in relationType"
              :key="key"
              :value="obj.pkRelationTypeId"
            >{{obj.name}}</option>
          </select>
          <div>
            <span class="error" v-html="errors.first('entity_relations.pkFkRelationTypeId')"></span>
          </div>
        </div>
        <div class="input m-0">
          <label for="pkFkEntityTypeId">{{ $lang('entity_relations.main_entity')}}</label>
          <select
            disabled
            v-model="model.pkFkEntityTypeId"
            name="pkFkEntityTypeId"
            id="pkFkEntityTypeId"
          >
            <option value="0">{{ $lang('entity_relations.select_entity')}}</option>
            <option
              v-for="(obj, key) in entites"
              :key="key"
              :value="obj.pkEntityTypeId"
            >{{obj.name}}</option>
          </select>
        </div>
        <div
          class="input m-0"
          :class="
              errors.first('entity_relations.pkFkEntityTypeIdManaged') !== undefined
                ? 'input-error'
                : ''
            "
        >
          <label
            for="pkFkEntityTypeIdManaged"
          >{{ $lang('entity_relations.entity_Type_Managed_Name')}}</label>
          <select
            v-validate="'required|excluded:0'"
            v-model="model.pkFkEntityTypeIdManaged"
            name="pkFkEntityTypeIdManaged"
            id="pkFkEntityTypeIdManaged"
          >
            <option value="0">{{ $lang('entity_relations.select_entity')}}</option>
            <option
              v-for="(obj, key) in entites"
              :key="key"
              :value="obj.pkEntityTypeId"
            >{{obj.name}}</option>
          </select>
          <div>
            <span class="error" v-html="errors.first('entity_relations.pkFkEntityTypeIdManaged')"></span>
          </div>
        </div>
        <div
          class="input m-0"
          :class="
              errors.first('entity_relations.fkRelationTypeCodeId') !== undefined
                ? 'input-error'
                : ''
            "
        >
          <label for="fkRelationTypeCodeId">{{ $lang('entity_relations.entity_property_type')}}</label>
          <select
            v-validate="'required|excluded:0'"
            v-model="model.fkRelationTypeCodeId"
            name="fkRelationTypeCodeId"
            id="fkRelationTypeCodeId"
          >
            <option value="0">{{ $lang('entity_relations.select_entity_property_type')}}</option>
            <option v-for="(obj, key) in codeTypes" :key="key" :value="obj.pkCodeId">{{obj.name}}</option>
          </select>
          <div>
            <span class="error" v-html="errors.first('entity_relations.fkRelationTypeCodeId')"></span>
          </div>
        </div>
        <div class="button-search input">
          <button class="primary">{{ $lang('general.button_submit')}}</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { lang } from "@/global-functions";
import relationTypesStore from "./RelationTypes/store";
import store from "./store";
import managmentStore from "@/components/UserAndRolesManagement/store";
export default {
  data() {
    return {
      query_model: {
        pkFkEntityTypeId: 0,
        pkFkEntityTypeIdManaged: 0,
        pkFkRelationTypeId: 0
      },
      relationType: [],
      codeTypes: [],
      entites: [],
      model: {},
      validation_messages: {
        custom: {
          pkFkRelationTypeId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          },
          pkFkEntityTypeIdManaged: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          },
          fkRelationTypeCodeId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          }
        }
      }
    };
  },
  created() {
    this.query_model = this.$route.query;
    this.initModel();
    this.getEntites();
    this.getCodeTypes();
    if (this.query_model.pkFkEntityTypeId) {
      this.getEntityRelation();
    }
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  methods: {
    setEntity(event) {
      var $this = this;
      var search = this.relationType.filter(e => {
        return $this.model.pkFkRelationTypeId == e.pkRelationTypeId;
      });
      if (search.length) {
        this.model.pkFkEntityTypeId = search[0].pkFkEntityTypeId;
      } else {
        this.model.pkFkEntityTypeId = 0;
      }
    },
    initModel() {
      this.model = {
        pkFkRelationTypeId: 0,
        pkFkEntityTypeId: 0,
        pkFkEntityTypeIdManaged: 0,
        fkRelationTypeCodeId: 0
      };
    },
    getEntityRelation() {
      var $this = this;
      store
        .dispatch("GET_ENTITY_RELATION_BY_IDS", this.query_model)
        .then(response => {
          $this.model = response.data.model;
        });
    },
    getCodeTypes() {
      var $this = this;
      managmentStore.dispatch("GET_CODES_BY_TYPE_ID", 1085).then(response => {
        $this.codeTypes = response.data.model.children;
      });
    },
    getEntites() {
      var $this = this;
      relationTypesStore.dispatch("GET_RELATION_TYPES").then(response => {
        $this.relationType = response.relationList;
        $this.entites = response.list;
      });
    },
    submit() {
      this.$validator.validateAll("entity_relations").then(valid => {
        if (valid) {
          var $this = this;
          store
            .dispatch("CREATE_ENTITY_RELATION", this.model)
            .then(respnse => {
              $this.$success("entity_relations.entity_relations_saved");
              $this.$router.go(-1);
            })
            .catch(error => {
              $this.$error("entity_relations.entity_relations_error");
            });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.button-search {
  margin-right: 0px;
  grid-column: 1/3;
  button {
    margin-left: auto;
  }
}
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
</style>