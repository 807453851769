<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-paperplane"></i>
        <span v-lang="'queries.edit'"></span>
      </h1>
      <div class="actions">
        <button class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></button>
      </div>
    </div>
    <notify />
    <div>
      <div>
        <Form v-on:save="edit" :queryData="model" :edit="true" :errorModel="errorModel" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../store";
import Form from "./Form";
import { lang } from "@/global-functions";
import { mapState } from "vuex";
import globalStore from "@/store";

export default {
  components: {
    Form
  },
  data() {
    return {
      model: {},
      errorModel: {
        message: "",
        key: "",
        yes: lang("general.label_yes"),
        no: lang("general.label_no")
      }
    };
  },
  created() {
    this.getQuery();
  },
  methods: {
    edit: function(value) {
      var $this = this;
      store
        .dispatch("UPDATE_QUERY", value)
        .then(response => {
          $this.$router.push({ name: "Queries" });
          this.$store.commit("SUCCESS_MESSAGE", "queries.updated_success");
          this.notifyKey += 1;
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;
          
        });
    },
    getQuery() {
      var $this = this;
      store
        .dispatch("GET_QUERY", {
          PkFkRoleId: $this.$route.query.PkFkRoleId,
          PkFkActionId: $this.$route.query.PkFkActionId,
          PkFkController: $this.$route.query.PkFkController
        })
        .then(response => {
          $this.model = response.data.model;
        })
        .catch(error => {
          
        });
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}
</style>
