var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"header card"},[_c('h1',[_c('span',{directives:[{name:"lang",rawName:"v-lang",value:('entity_relations.entity_relations'),expression:"'entity_relations.entity_relations'"}]})]),_c('div',{staticClass:"actions"},[(_vm.$role(10049))?_c('router-link',{directives:[{name:"lang",rawName:"v-lang",value:('entity_relations.entity_relations_types'),expression:"'entity_relations.entity_relations_types'"}],staticClass:"primary add-relation",attrs:{"to":{ name: 'EntityRelationTypesForm' }}}):_vm._e(),(_vm.$role(10049))?_c('router-link',{directives:[{name:"lang",rawName:"v-lang",value:('entity_relations.entity_relations_create'),expression:"'entity_relations.entity_relations_create'"}],staticClass:"primary",attrs:{"to":{ name: 'EntityRelationForm' }}}):_vm._e()],1)]),_c('div',{staticClass:"card table-container"},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$lang("entity_relations.entity_type_name")))]),_c('th',[_vm._v(_vm._s(_vm.$lang("entity_relations.entity_Type_Managed_Name")))]),_c('th',[_vm._v(_vm._s(_vm.$lang("entity_relations.relation_Type_Code_Name")))]),_c('th',[_vm._v(" ")])])]),_c('tbody',_vm._l((_vm.list),function(obj,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(obj.entityTypeName))]),_c('td',[_vm._v(_vm._s(obj.entityTypeManagedName))]),_c('td',[_vm._v(_vm._s(obj.relationTypeCodeName))]),_c('td',[_c('router-link',{staticClass:"small error",attrs:{"to":{
                name: 'EntityRelationForm',
                query: {
                  pkFkEntityTypeId: obj.pkFkEntityTypeId,
                  pkFkEntityTypeIdManaged: obj.pkFkEntityTypeIdManaged,
                  pkFkRelationTypeId: obj.pkFkRelationTypeId
                }
              }}},[_vm._v(_vm._s(_vm.$lang("general.label_delete")))])],1)])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }