var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notifications"},[_c('div',{staticClass:"notifications-icon-container"},[_c('span',{staticClass:"logout items icon-logout",on:{"click":function($event){return _vm.logout()}}})]),(_vm.showContent)?_c('div',{staticClass:"notification-container"},[_c('div',{staticClass:"notifications-content",on:{"scroll":_vm.handleScroll}},[_vm._l((_vm.notifications),function(obj,key){return _c('router-link',{key:key,class:obj.seenOn ? '' : 'not-seen',attrs:{"to":{
          name: obj.route,
          params: JSON.parse(obj.routeValues),
          query: {
            notification_data: obj.pkNotificationId,
            seen: obj.seenOn
          }
        }}},[_c('span',{staticClass:"notification-message",domProps:{"innerHTML":_vm._s(obj.message)}}),_c('span',{staticClass:"author-and-created-on"},[_c('small',[_vm._v(" "+_vm._s(obj.author)+" "+_vm._s(_vm.$moment(obj.createdOn).format("DD.MM.YYYY. hh:mm"))+" ")])])])}),(_vm.searchResultsLoaded)?_c('Loader2'):_vm._e()],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }