<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-office"></i>
        <span v-lang="'workingPlace.createWorkingPlace'"></span>
      </h1>
      <div class="actions">
        <a
          class="primary"
          v-on:click="clickBack()"
          v-lang="'general.button_back'"
        ></a>
      </div>
    </div>
    <div class="card">
      <Form
        v-on:save="save"
        :errorModel="errorModel"
        :isSubmited="isSubmited"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "./store";
import Form from "./Form";

export default {
  components: {
    Form
  },
  data() {
    return {
      nameRoute: "",
      user: {},
      errorModel: {},
      isSubmited: false
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeName = from.name;
      vm.query = from.query;
      vm.nameRoute = from.name;
    });
  },
  methods: {
    clickBack() {
      this.$router.push({
        name: this.nameRoute,
        query: Object.assign({}, this.query)
      });
    },
    save: function (value) {
      var $this = this;
      $this.isSubmited = true;
      store
        .dispatch("CREATE_WORK_PLACE", value)
        .then((response) => {
          this.$success("workingPlace.workingPlace_successfully_created");
          $this.isSubmited = false;
          // $this.$router.push({ name: "WorkingPlaceIndex" });
          $this.$router.push({
            name: "SettingsForm",
            query: { component: "WorkingPlaceIndex" }
          });
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}
</style>
