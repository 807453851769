<template>
  <div>
    <div class="container">
      <div class="header card">
        <h1>
          <i class="icon-survey"></i>
          <span v-lang="'menu.menu_survey'"></span>
        </h1>
        <div class="actions">
          <a
            class="primary m-right-10 m-left-10"
            :href="_surveyUserManual"
            v-lang="'general.user_manual'"
            target="_blank"
          ></a>
          <a
            name="back"
            class="primary"
            v-on:click="clickBack()"
            v-lang="'general.button_back'"
          ></a>
        </div>
      </div>

      <!-- SELECT YEAR BLOCK -->
      <div class="card">
        <div class="input m-0">
          <label for="createdBy">
            <span v-lang="'survey.label_select_year'"></span>
          </label>
          <select
            v-model="pkSelectedYearId"
            name="createdBy"
            id="dropdownStatusCode"
          >
            <option
              v-for="(obj, key) in allReportYears"
              :key="key"
              :value="obj.pkReportYearId"
            >
              {{
                obj.year +
                (obj.isLockedForOrganisations
                  ? " (" + $lang("survey.locked") + ")"
                  : "")
              }}
            </option>
          </select>
        </div>
      </div>

      <GoalsLayout />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "./store";
import rootStore from "@/store";
import Toasted from "vue-toasted";
import NoData from "@/components/General/NoData";
import Loader2 from "../General/Loader2";
import Paginate from "vuejs-paginate";
import GoalsLayout from "./GoalsLayout";
import { ResponsiveDirective } from "vue-responsive-components";
import { mapState } from "vuex";
import manuals from "@/mixins/manuals.vue";
Vue.use(Toasted);

export default {
  directives: {
    responsive: ResponsiveDirective,
  },
  mixins: [manuals],
  components: {
    Loader2,
    Paginate,
    NoData,
    GoalsLayout,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      allReportYears: [],
    };
  },
  beforeDestroy() {
    this.$removeToast();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeName = from.name;
      vm.query = from.query;
      vm.nameRoute = from.name;
    });
  },
  created() {
    this.getAllReportYears();
  },
  methods: {
    clickBack() {
      this.$router.push({
        name: this.nameRoute,
        query: Object.assign({}, this.query),
      });
    },

    getAllReportYears() {
      var $this = this;
      store
        .dispatch("GET_ALL_REPORT_YEARS")
        .then((response) => {
          $this.allReportYears = response.data;
          $this.pkSelectedYearId = $this.allReportYears.find(
            (ry) => ry.year == $this.currentYear
          ).pkReportYearId;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
  },
  computed: {
    pkSelectedYearId: {
      get() {
        return store.state.pkSelectedYearId;
      },
      set(value) {
        store.commit("SET_SELECTED_YEAR", value);
      },
    },
    selectedYear: {
      get() {
        return store.state.selectedYear;
      },
      set(value) {
        store.commit("SET_SELECTED_YEAR_VALUE", value);
      },
    },
  },
  watch: {
    pkSelectedYearId() {
      let $this = this;
      $this.selectedYear = $this.allReportYears.find(
        (ry) => ry.pkReportYearId == $this.pkSelectedYearId
      ).year;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-container {
  margin: 0 !important;
}
.description-place {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 500px;
  word-wrap: break-word;
  //float: left;
}
.small {
  .description-place {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 120px;
    word-wrap: break-word;
    //float: left;
  }
  #description-place {
    width: 120px;
  }
}
#description-place {
  width: 500px;
}

.pointer {
  cursor: pointer;
  word-break: break-all;
}
</style>
