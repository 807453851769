<template>
  <div class="container" v-if="resultsLoaded">
    <div class="header card">
      <h1>
        <span>{{
          $lang("survey.label_goal").toUpperCase() +
          " " +
          goalInfo.number +
          " - " +
          goalInfo.title
        }}</span>
      </h1>
      <div class="actions">
        <!-- Export2PDF button -->
        <button
          class="primary mr-5"
          v-lang="'survey.export_to_pdf'"
          @click="Export2PDF"
        ></button>
        <!-- DOWNLOAD EXISTING FILE ANSWER -->
        <a
          :hidden="!existsingFileAnswer"
          class="primary mr-5 file-answer"
          v-lang="'survey.download_file_answer'"
          :href="
            existsingFileAnswer
              ? mainStore.state.core_api +
                'api/FileAnswer/download-file-answer/' +
                existsingFileAnswer.filePath
              : ''
          "
        ></a>
        <!-- UPLOAD FILE BUTTON -->
        <a
          :hidden="
            !goalInfo.templatePath ||
            questionsWithAnswers[0].reportYear.isLockedForOrganisations
          "
          class="primary mr-5 file-answer"
          v-lang="'survey.upload_file_answer'"
          v-on:click="showFileUploadDialog = !showFileUploadDialog"
        ></a>
        <!-- DOWNLOAD TEMPLATE BUTTON -->
        <a
          :hidden="!goalInfo.templatePath"
          class="primary"
          :href="
            mainStore.state.core_api +
            'api/Goal/download-template-anonymous/' +
            goalInfo.templatePath
          "
          v-lang="'survey.download_template'"
        ></a>
      </div>
    </div>

    <!-- FILE ANSWER FORM -->
    <div class="card" v-if="showFileUploadDialog" transition="expand">
      <form
        v-on:submit.prevent="uploadFileAnswer()"
        data-vv-scope="file_answer_form"
      >
        <div
          class="input m-0"
          :class="
            errors.first('file_answer_form.fileName') !== undefined
              ? 'input-error'
              : ''
          "
        >
          <label for="number">
            <span v-lang="'survey.label_file_name'"></span>
          </label>
          <input
            class="m-0"
            v-model="fileAnswerName"
            v-validate="'required'"
            type="text"
            name="fileName"
            autocomplete="off"
          />
          <div>
            <span
              class="error"
              v-html="errors.first('file_answer_form.fileName')"
            ></span>
          </div>
        </div>

        <UploadInput
          v-model="file"
          :containerClass="
            errors.first('file_answer_form.file') !== undefined
              ? 'input-error'
              : ''
          "
          :uploadAreaClass="
            errors.first('file_answer_form.file') !== undefined
              ? 'upload-area-error'
              : ''
          "
          :placeholder="$lang('surveyTemplates.select_file_for_upload')"
          :ref="'fileUpload'"
          name="file"
          v-validate="'required|ext:xlsx,xls'"
          :errorText="errors.first('file_answer_form.file')"
          :acceptFileTypes="'.xls,.xslx'"
        />

        <div class="question-with-answer">
          <div class="button-search">
            <Spinner
              :showSpinner="fileUploadingInProgress"
              :buttonText="$lang('general.button_submit')"
              :buttonClass="'primary'"
            />
          </div>
        </div>
      </form>
    </div>

    <!-- QUESTIONS -->
    <Loader2 v-if="!resultsLoaded" />
    <div class="card" v-else>
      <form v-on:submit.prevent="save()">
        <div
          class="question-with-answer"
          v-for="QA in questionsWithAnswers"
          :key="QA.fkReportQuestionId"
        >
          <div class="bold">
            {{ goalInfo.number + "." + QA.reportQuestion.questionNumber }}
            {{ QA.reportQuestion.title }}
          </div>
          <div
            class="mt-10 question-description"
            v-html="QA.reportQuestion.description"
          ></div>
          <div class="mt-10">
            <textarea
              class="answer-input"
              v-model="QA.text"
              :disabled="QA.reportYear.isLockedForOrganisations"
              maxlength="3600"
            ></textarea>
          </div>
        </div>
        <!-- SAVE -->
        <div
          class="question-with-answer"
          v-if="
            !(
              !questionsWithAnswers ||
              questionsWithAnswers.length == 0 ||
              questionsWithAnswers[0].reportYear.isLockedForOrganisations
            )
          "
        >
          <div class="button-search">
            <Spinner
              :showSpinner="isSubmited"
              :buttonText="$lang('general.button_submit')"
              :buttonClass="'primary'"
            />
          </div>
        </div>
      </form>
    </div>
    <SurveyReport :model="reportAnswers" />
  </div>
</template>
<script>
import store from "./store";
import mainStore from "@/store";
import answersStore from "./Admin/AnswersManagement/store";
import { lang } from "@/global-functions";
import Loader2 from "../General/Loader2";
import UploadInput from "./UploadInput";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Arimo } from "@/assets/fonts/Arimo-Regular-normal.js";
import { ArimoBold } from "@/assets/fonts/Arimo_Bold.js";
import SurveyReport from "./SurveyReport.vue";
import { header } from "@/assets/imgs/imgs.js";
import moment from "moment";
export default {
  components: {
    Loader2,
    UploadInput,
    SurveyReport,
  },
  data() {
    return {
      moment: moment,
      goalInfo: {},
      questionsWithAnswers: [],
      existsingFileAnswer: null,
      isSubmited: false,
      showFileUploadDialog: false,
      fileUploadingInProgress: false,
      fileAnswerName: "",
      file: null,
      validation_messages: {
        custom: {
          fileName: {
            required: lang("validation.required"),
          },
          file: {
            required: lang("validation.required"),
            ext: lang("validation.ext"),
          },
        },
      },
      resultsLoaded: false,
      reportAnswers: [],
      loading: false,
    };
  },
  methods: {
    getGoalData(goal = false) {
      if (!this.pkSelectedGoalId) return;

      if (!this.pkSelectedYearId) return;

      var $this = this;
      mainStore.commit("SHOW_LOADER");
      $this.resultsLoaded = false;
      store
        .dispatch("GET_GOAL_QA", {
          fkGoalId: $this.pkSelectedGoalId,
          fkReportYearId: $this.pkSelectedYearId,
        })
        .then((response) => {
          $this.resultsLoaded = true;
          $this.goalInfo = response.data.goalInfo;
          $this.questionsWithAnswers = response.data.questionsAndAnswers;
          $this.existsingFileAnswer = response.data.fileAnswer;
          mainStore.commit("HIDE_LOADER");
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
          $this.resultsLoaded = true;
          mainStore.commit("HIDE_LOADER");
        });
    },
    save() {
      this.isSubmited = true;
      var $this = this;
      store
        .dispatch("SAVE_GOAL_QA", this.questionsWithAnswers)
        .then((response) => {
          $this.$success("survey.chаnges_successfully_saved");
          $this.isSubmited = false;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
          if (error.data.includes("Year") && error.data.includes("is locked")) {
            $this.$error($this.$lang("survey.year_is_locked"));
            $this.isSubmited = false;
          }
        });
    },
    uploadFileAnswer() {
      this.$validator.validateAll("file_answer_form").then((valid) => {
        if (valid) {
          let formData = new FormData();
          formData.append("file", this.file);
          formData.append("FileName", this.fileAnswerName);
          formData.append("FkGoalId", this.pkSelectedGoalId);
          formData.append("FkReportYearId", this.pkSelectedYearId);

          var $this = this;
          $this.fileUploadingInProgress = true;
          store
            .dispatch("UPLOAD_FILE_ANSWER", formData)
            .then((response) => {
              $this.getGoalData();
              $this.fileUploadingInProgress = false;
              $this.$success("survey.file_answer_uploaded_successfully");
              $this.showFileUploadDialog = false;
              $this.fileAnswerName = "";
              $this.file = null;
            })
            .catch((error) => {
              if (error.status == 400) $this.errorModel = error.data;
            });
        }
      });
    },
    async getAllForReport() {
      if (!this.pkSelectedYearId) return;
      let $this = this;
      this.loading = true;
      await answersStore
        .dispatch("GET_ALL_FOR_REPORT", {
          hasOrganization: true,
          fkReportYearId: $this.pkSelectedYearId,
        })
        .then((response) => {
          $this.reportAnswers = response.data;
          $this.loading = false;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
          $this.loading = false;
        });
    },
    async Export2PDF(element) {
      await this.getAllForReport();

      var $this = this;

      var element = "body";
      var doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
      });
      var base64Img = null;
      doc.addFileToVFS("Arimo-Regular.ttf", Arimo);
      doc.addFont("Arimo-Regular.ttf", "Arimo", "normal");
      doc.setFont("Arimo");

      doc.addFileToVFS("Arimo-Bold.ttf", ArimoBold);
      doc.addFont("Arimo-Bold.ttf", "Arimo", "bold");

      var footer = async function (data) {};
      base64Img = header;
      var title =
        this.$lang("survey.orgnisation") +
        this.reportAnswers[0].answers[0].emmausOrgnisationName;

      var outputName =
        this.$lang("survey.survey") +
        "_" +
        this.reportAnswers[0].answers[0].emmausOrgnisationName +
        "_" +
        this.selectedYear +
        "_" +
        this.$moment(new Date()).format("DD.MM.YYYY._HH:mm") +
        ".pdf";
      var year = this.$lang("survey.year") + this.selectedYear;

      var Font = 8;
      Font =
        $this.$route.query.id == 42901 ||
        $this.$route.query.id == 42898 ||
        $this.$route.query.id == 42899
          ? 7
          : Font;

      var pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      var number_of_pages = doc.internal.getNumberOfPages();
      if (base64Img) {
        doc.addImage(
          base64Img,
          "PNG",
          pageWidth / 2 - 25,
          2,
          50,
          35,
          undefined,
          "FAST"
        );
      }
      // TABELA
      doc.autoTable({
        styles: {
          fontSize: Font,
          font: "Arimo",
          halign: "left",
          lineColor: [44, 62, 80],
          lineWidth: 0.55,
        },
        headStyles: {
          halign: "left",
          fontStyle: "Arimo",
          fontSize: 12,
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineColor: [255, 255, 255],
        },
        columnStyles: {
          0: {
            halign: "left",
            valign: "middle",
            cellWidth: "auto",
          },
        },
        html:
          this.reportAnswers && this.reportAnswers.length > 0
            ? "#my-table"
            : "#no-data",
        margin: { top: 60 },
        didParseCell: function (HookData) {
          HookData.cell.styles.fillColor = [255, 255, 255];
          if (HookData.row.raw._element.id == "answerRow") {
            HookData.cell.styles.fillColor = [240, 240, 240];
          }
        },
        drawHeader: function (HookData) {
          return false;
        },

        didDrawPage: async function (data) {
          data.settings.margin.top = 30;

          var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
          var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
          if (data.pageNumber === 1) {
            var splitTitle = doc.splitTextToSize(title, 250);
            var splitYear = doc.splitTextToSize(year, 250);
            var dim = doc.getTextDimensions(title);
            var yearsPos = dim.w > 179 ? 64 : 57;
            doc.setFontSize(10);
            doc.setTextColor(60);
            doc.text(splitTitle, pageWidth / 2, 48, "center");
            doc.text(splitYear, pageWidth / 2, 55, "center");
          }
        },
        didDrawCell: function (HookData) {},

        rowPageBreak: "avoid",
        // pageBreak: "avoid"
      });

      number_of_pages = doc.internal.getNumberOfPages();

      for (var i = 1; i <= number_of_pages; i++) {
        doc.setPage(i);
        doc.setFontSize(12);
        doc.text(i + "/" + number_of_pages, 10, 290);
        if (i < number_of_pages) {
          doc.setFontSize(9);
        }
      }

      await doc.save(outputName);

      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        window.open(doc.output("bloburl"), "_blank");
      }

      // $this.showLoader = false;
      // $this.templateData = null;
      // $this.selectedReportNumber = 0;
    },
  },
  created() {
    this.getGoalData();
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
    this.resultsLoaded = true;
  },
  computed: {
    pkSelectedGoalId: {
      get() {
        return store.state.pkSelectedGoalId;
      },
    },
    pkSelectedYearId: {
      get() {
        return store.state.pkSelectedYearId;
      },
    },
    selectedYear: {
      get() {
        return store.state.selectedYear;
      },
    },
    mainStore: {
      get() {
        return mainStore;
      },
    },
  },
  watch: {
    pkSelectedGoalId() {
      this.getGoalData();
      this.showFileUploadDialog = false;
    },
    pkSelectedYearId: {
      deep: true,
      immediate: true,
      handler() {
        this.getGoalData();
        // this.getAllForReport();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/colors";

.container {
  position: static;
}

.answer-input {
  width: calc(100% - 15px);
  height: 80px;
}

.mt-10 {
  margin-top: 10px !important;
}

.question-with-answer {
  margin-top: 20px;
}

.button-search {
  width: 100%;
  position: relative;
  left: 10px;
  bottom: 10px;
}

.question-description {
  text-align: justify;
}

.mr-5 {
  margin-right: 5px;
}

.file-answer {
  background-color: $warningColorDark;
}

.custom-file-field-error {
  line-height: 0px;
}

.expand-transition {
  transition: all 1.5s ease;
}
/* .expand-enter defines the starting state for entering */
/* .expand-leave defines the ending state for leaving */
.expand-enter,
.expand-leave {
  height: 0;
  opacity: 0;
}
</style>
