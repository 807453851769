<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-paperplane"></i>
        <span v-lang="'functions.label_create_function'"></span>
      </h1>
      <div class="actions">
        <a class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></a>
      </div>
    </div>
    <div>
      <Form v-on:save="save" :errorModel="errorModel" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import Form from "./Form";

export default {
  components: {
    Form
  },
  data() {
    return {
      user: {},
      errorModel: {}
    };
  },
  methods: {
    save: function(value) {
      var $this = this;
      store
        .dispatch("CREATE_FUNCTION", value)
        .then(response => {
          this.$success("functions.function_successfully_created");
          $this.$router.push({ name: "Functions" });
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}
</style>