<template>
  <div class>
    <NoData v-if="!trainings || trainings.length == 0"></NoData>
    <div
      v-else
      class="table-container scroll"
      :class="$route.name == 'EmmausTrainingsIndex' ? 'card' : ''"
    >
      <table class="table-hover">
        <thead>
          <tr :id="tableId">
            <th class="text-align-center">#</th>
            <th v-on:click="sort('Name')" Name>
              {{ $lang("training.emmaus_training_name") }}
              <span></span>
            </th>
            <th v-on:click="sort('FkTypeCodeId')" FkTypeCodeId>
              {{ $lang("training.label_training_type") }}
              <span></span>
            </th>
            <th v-on:click="sort('Date')" Date>
              {{ $lang("training.training_date") }}
              <span></span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <!-- :class="$route.query.pkPersonId == obj.pkPersonId ? 'selected' : ''"
            :id="obj.pkPersonId" -->
          <tr v-for="(obj, key) in trainings" :key="key">
            <!-- :style="
                obj.value != null
                  ? 'border-left:solid 8px ' + obj.value
                  : 'padding-left: 18px;'
              "
              :title="
                (obj.watchlistTypeName != null
                  ? $lang('general.level_class')
                  : '') +
                  (obj.watchlistTypeName != null ? obj.watchlistTypeName : '')
              " -->
            <td>
              {{ (searchModel.page - 1) * searchModel.limit + key + 1 }}
            </td>
            <td class="name-training" @click="showName">
              {{ obj.name }}
            </td>
            <td>{{ obj.typeName }}</td>
            <td>
              {{
                obj.date != null
                  ? $moment(obj.date).format("DD.MM.YYYY.")
                  : $lang("general.unknow")
              }}
            </td>
            <td class="actions">
              <a
                v-if="!form && $route.name == 'EmmausPersonForm'"
                class="primary small"
                v-on:click="addTraining(obj)"
                v-lang="'general.label_add'"
              ></a>
              <a
                v-if="form && $route.name == 'EmmausPersonForm'"
                class="error small"
                v-on:click="removeTraining(obj)"
                v-lang="'general.label_delete'"
              ></a>
              <a
                v-if="$role(40284) && $route.name != 'EmmausPersonForm'"
                class="default small"
                v-on:click="trainingDetails(obj.pkEmmausTrainingId)"
                v-lang="'general.label_details'"
              ></a>
              <a
                v-if="
                  $role(40287) &&
                    $route.name != 'EmmausPersonDetails' &&
                    $route.name != 'EmmausPersonForm' &&
                    (emmausOrganizationId == obj.fkEmmausOrganizationId ||
                      superAdmin)
                "
                class="primary small"
                v-on:click="editTraining(obj.pkEmmausTrainingId)"
                v-lang="'general.label_edit'"
              ></a>
              <a
                v-if="
                  $role(40286) &&
                    $route.name != 'EmmausPersonDetails' &&
                    $route.name != 'EmmausPersonForm' &&
                    (emmausOrganizationId == obj.fkEmmausOrganizationId ||
                      superAdmin)
                "
                class="error small"
                v-on:click="deleteTraining(obj.pkEmmausTrainingId)"
                v-lang="'general.label_delete'"
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { lang } from "@/global-functions";
import store from "./store";
import globalStore from "@/store";

import NoData from "../../General/NoData";
import { mapState } from "vuex";
import Vue from "vue";
import Spinner from "../../General/Spinner";
import organizationsStore from "@/components/EMMAUS/Organisation/store";

export default {
  components: {
    NoData,
    Spinner
  },
  props: {
    trainings: {
      type: Array,
      default: function() {
        return [];
      }
    },
    searchModel: {
      type: Object,
      default: function() {
        return { page: 1, limit: 5 };
      }
    },
    form: {
      type: Object,
      default: function() {
        return false;
      }
    }
  },
  created() {
    this.superAdmin = this.localStorageUser.user.superAdmin;

    organizationsStore
      .dispatch("GET_USER_ORGANIZATON", this.localStorageUser.memberId)
      .then((response) => {
        this.emmausOrganizationId =
          response.data.organization.pkFkEmmausOrganizationId;
      });
  },
  data() {
    return {
      tmppkPersonId: null,
      showSpinner: false,
      localStorageUser: JSON.parse(localStorage.user),
      superAdmin: false,
      emmausOrganizationId: null,
      // model: {
      //   pkFkKnowledgeId: Number(this.$route.params.id),
      //   pkFkPersonId: null,
      // },
      column: JSON.parse(JSON.stringify(this.searchModel.orderColumn)),
      columnSort: JSON.parse(JSON.stringify(this.searchModel.orderSort)),
      tableId: Math.random()
    };
  },
  computed: {},
  mounted() {
    this.setIcon();
  },
  methods: {
    showName(event) {
      event.target.classList.toggle("name-training-change");
    },
    addTraining(training) {
      this.$emit("addExistTraining", training.pkEmmausTrainingId);
    },
    removeTraining(training) {
      this.$emit("removeExistTraining", training.pkEmmausTrainingId);
    },
    sort(columnName) {
      if (this.column != columnName) {
        this.column = columnName;
        this.columnSort = "DESC";
      } else if (this.columnSort == "DESC") {
        this.columnSort = "ASC";
      } else {
        this.columnSort = "DESC";
      }
      this.$emit("searchSort", this.column, this.columnSort);
    },
    setIcon() {
      var element = document.getElementById(this.tableId);
      if (element == null) return;
      var array = element.children;
      var item;
      for (let index = 0; index < array.length; index++) {
        if (
          array[index].hasAttribute(
            this.searchModel.orderColumn.replace(".", "-")
          )
        ) {
          item = array[index];
        }
      }
      if (
        this.searchModel.orderColumn != "" &&
        this.searchModel.orderSort == "DESC"
      ) {
        item.classList.add("desc");
      } else if (this.searchModel.orderSort == "ASC") {
        item.classList.add("asc");
      }
    },
    // addPersonBiometrija(id) {
    //   var $this = this;
    //   this.showSpinner = true;
    //   this.tmppkPersonId = id;
    //   store
    //     .dispatch("BIOMETRIJA_SAVE_RESULT", {
    //       jobPackageID: $this.$route.query.jobPackageID,
    //       personId: id,
    //     })
    //     .then((response) => {
    //       this.$success("person.message_person_successfully_created");
    //       this.showSpinner = false;
    //       this.tmppkPersonId = null;

    //       $this.$router.push({ name: "VerificationResults" });
    //     })
    //     .catch((error) => {});
    // },
    // createRelation(id) {
    //   this.$emit("addRelation", id);
    // },
    // addRelation(entityId) {
    //   this.$router.push({
    //     query: {
    //       pkEntityId: entityId,
    //       pkEntityTypeId: 4,
    //     },
    //   });
    // },
    // addPersonToWatchList(id) {
    //   this.$router.push({
    //     name: "WatchListCreate",
    //     query: { PkFkEntityTypeId: 4, PkFkEntityId: id },
    //   });
    // },
    // deletePersonFromWatchList(obj) {
    //   var $this = this;
    //   this.$removeToast();
    //   Vue.toasted.show(lang("watchList.do_you_want_to_delete_entity"), {
    //     theme: "toasted-primary",
    //     position: "top-center",
    //     duration: 5000,
    //     action: [
    //       {
    //         text: lang("general.label_yes"),
    //         onClick: (e, toastObject) => {
    //           watchListStore
    //             .dispatch("DELETE_WATCH_LIST", {
    //               pkFkEntityId: obj.pkPersonId,
    //               pkFkEntityTypeId: 4,
    //             })
    //             .then((response) => {
    //               $this.trainings.splice($this.trainings.indexOf(obj), 1);
    //               $this.$success("watchList.entity_successfully_deleted");
    //             })
    //             .catch((error) => {});
    //           toastObject.goAway(0);
    //         },
    //       },
    //       {
    //         text: lang("general.label_no"),
    //         onClick: (e, toastObject) => {
    //           toastObject.goAway(0);
    //         },
    //       },
    //     ],
    //   });
    // },
    // addPersonOfficialRecords(id) {
    //   this.$router.push({
    //     name: "OfficialRecordsForm",
    //     query: {
    //       pkPersonId: Number(id),
    //     },
    //   });
    // },
    // addPerson(obj) {
    //   if (this.$route.name != "OfficialRecordsDetails") {
    //     this.addPersonOfficialRecords(obj.pkPersonId);
    //     return;
    //   }
    //   this.model.pkFkPersonId = obj.pkPersonId;
    //   var $this = this;

    //   store
    //     .dispatch("ADD_EXIST_PERSON", this.model)
    //     .then((response) => {
    //       this.$success("person.message_person_successfully_added");
    //       this.$router.push({
    //         query: {
    //           pkPersonId: $this.$route.query.pkPersonId,
    //           update: "Person",
    //         },
    //       });
    //       $this.trainings.splice($this.trainings.indexOf(obj), 1);
    //     })
    //     .catch((error) => {});
    // },
    trainingDetails(id) {
      this.$router.push({
        name: "EmmausTrainingsDetails",
        query: { pkEmmausTrainingId: id, details: true }
      });
    },
    editTraining(id) {
      this.$router.push({
        name: "EmmausTrainingsForm",
        query: { pkEmmausTrainingId: id }
      });
    },
    deleteTraining(id) {
      var $this = this,
        action_name = "DELETE_EMMAUS_TRAINING",
        model_data = {
          id: id,
          pkFkKnowledgeId: null
        };
      this.$removeToast();
      Vue.toasted.show(
        lang("training.message_are_you_sure_you_want_to_delete_training"),
        {
          theme: "toasted-primary",
          position: "top-center",
          duration: 5000,
          action: [
            {
              text: lang("general.label_yes"),
              onClick: (e, toastObject) => {
                store
                  .dispatch(action_name, model_data.id)
                  .then((response) => {
                    $this.$emit("updateTrainingList");
                    $this.$success(
                      "training.message_training_successfully_deleted"
                    );
                    this.$router.push({
                      query: {
                        ...this.$route.query,
                        delete: true
                      }
                    });
                    $this.notifyKey += 1;
                    if ($this.$route.query.pkPersonId == id) {
                      var query = Object.assign({}, $this.$route.query);
                      delete query.pkPersonId;
                      delete query.details;
                      $this.$router.push({
                        query
                      });
                    }
                  })
                  .catch((error) => {});
                toastObject.goAway(0);
              }
            },
            {
              text: lang("general.label_no"),
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              }
            }
          ]
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  & > .small {
    text-align: center;
    margin: auto;
  }
}
.left {
  text-align: left;
}
#avatarImg {
  width: 40px !important;
  height: 40px !important;
}
.relation {
  background-color: blueviolet;
  &:hover {
    background-color: darkmagenta;
  }
}
.name-training {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  word-wrap: break-word;
  cursor: pointer;
}
</style>
