var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"header card"},[_c('h1',[_c('i',{staticClass:"icon-calendar"}),_c('span',{directives:[{name:"lang",rawName:"v-lang",value:('lap.year_managment_institutions'),expression:"'lap.year_managment_institutions'"}]})]),_c('div',{staticClass:"actions"},[_c('a',{directives:[{name:"lang",rawName:"v-lang",value:('general.button_back'),expression:"'general.button_back'"}],staticClass:"primary",on:{"click":function($event){return _vm.clickBack()}}})])]),(!_vm.resultsLoaded)?_c('Loader2'):(!_vm.reportYears || _vm.reportYears.length == 0)?_c('NoData'):_c('span',[_c('div',{staticClass:"card table-container scroll"},[_c('table',{staticClass:"table-hover"},[_c('thead',[_c('tr',{attrs:{"id":_vm.tableId}},[_c('th',[_vm._v("#")]),_c('th',[_vm._v(" "+_vm._s(_vm.$lang("surveyYears.year"))+" "),_c('span')]),_c('th',[_vm._v(" "+_vm._s(_vm.$lang("surveyYears.status"))+" "),_c('span')]),_c('th',[_vm._v(" ")])])]),_c('tbody',_vm._l((_vm.reportYears),function(obj,key){return _c('tr',{key:key},[_c('td',[_vm._v(" "+_vm._s(key + 1 + (_vm.searchModel.pageNum - 1) * _vm.searchModel.pageSize)+" ")]),_c('td',[_vm._v(_vm._s(obj.year))]),_c('td',[_vm._v(" "+_vm._s(obj.isLockedForInstitutions ? _vm.$lang("surveyYears.locked") : _vm.$lang("surveyYears.unlocked"))+" ")]),_c('td',[(_vm.$role(50006))?_c('a',{directives:[{name:"lang",rawName:"v-lang",value:(
                    obj.isLockedForInstitutions
                      ? 'surveyYears.unlock'
                      : 'surveyYears.lock'
                  ),expression:"\n                    obj.isLockedForInstitutions\n                      ? 'surveyYears.unlock'\n                      : 'surveyYears.lock'\n                  "}],staticClass:"small",class:obj.isLockedForInstitutions ? 'primary' : 'error',on:{"click":function($event){return _vm.updateYearStatus(
                      obj.pkReportYearId,
                      obj.isLockedForInstitutions
                    )}}}):_vm._e()])])}),0)]),_c('div',{staticClass:"pagination-container"},[_c('paginate',{attrs:{"page-count":_vm.pageCount,"click-handler":_vm.changePage,"prev-text":this.$lang('general.previous'),"next-text":this.$lang('general.next_page'),"container-class":'paggination'},model:{value:(_vm.searchModel.pageNum),callback:function ($$v) {_vm.$set(_vm.searchModel, "pageNum", $$v)},expression:"searchModel.pageNum"}})],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }