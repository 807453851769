<template></template>

<script>
import rootStore from "@/store";

export default {
  computed: {
    _surveyUserManual() {
      return `${rootStore.state.core_api}manual/Anketa - Korisnicko uputstvo.docx`;
    },
  },
};
</script>

<style>
</style>