<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-users"></i>
        <span v-lang="'queries.details'"></span>
      </h1>
      <div class="actions">
        <button
          class="primary"
          v-on:click="$router.go(-1)"
          v-lang="'general.button_back'"
        ></button>
      </div>
    </div>
    <div class="card table-container">
      <table>
        <tr>
          <td v-lang="'queries.role'"></td>
          <td>{{ role.name }}</td>
        </tr>
        <tr>
          <td v-lang="'general.description'"></td>
          <td>{{ role.description }}</td>
        </tr>
        <tr>
          <td>API</td>
          <td>{{ action.apiPath }}</td>
        </tr>
        <tr>
          <td v-lang="'general.description'"></td>
          <td>{{ action.description }}</td>
        </tr>
        <tr>
          <td v-lang="'queries.where_clause'"></td>
          <td>{{ model.whereClause }}</td>
        </tr>
        <tr>
          <td v-lang="'queries.order_by'"></td>
          <td>{{ model.orderBy }}</td>
        </tr>
        <tr>
          <td v-lang="'queries.group_by'"></td>
          <td>{{ model.groupBy }}</td>
        </tr>
        <tr>
          <td v-lang="'queries.query_description'"></td>
          <td>{{ model.description }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../store";
import { lang } from "@/global-functions";

export default {
  data() {
    return {
      model: {},
      action: {},
      role: {}
    };
  },
  created() {
    this.getQueryById();
  },
  methods: {
    getQueryById() {
      var $this = this;
      store
        .dispatch("GET_QUERY", {
          PkFkRoleId: $this.$route.query.PkFkRoleId,
          PkFkActionId: $this.$route.query.PkFkActionId,
          PkFkController: $this.$route.query.PkFkController
        })
        .then(response => {
          $this.model = response.data.model;
          $this.action = response.data.model.action;
          $this.role = response.data.model.role;
        })
        .catch(error => {});
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}

td:nth-child(1) {
  font-weight: bold;
}
</style>
