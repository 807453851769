<template>
  <div class="container main-content">
    <header>
      <img class="image-header" src="@/assets/imgs/bih.png" alt />
      <!-- <i class="icon-InsiDIA-znak"></i> -->
    </header>
    <footer>
      <p></p>
      <p>
        {{ new Date().getFullYear() }} &#9400; {{ $lang("general.all_roles") }}
      </p>
      <a href="http://page.ba" target="_blank">
        Powered by
        <!-- <img src="@/assets/imgs/page_logo_small_gray.png" alt /> -->
        <img
          src="@/assets/imgs/page-logo-new.svg"
          alt="page logo"
          class="page-logo"
        />
      </a>
    </footer>
    <div class="login-form">
      <div class="logo">
        <!-- <i class="icon-InsiDIA-znak"></i> -->
        <img class="image-header-ico" src="@/assets/imgs/bih.png" alt />

        <!-- <H5>
          Insi
          <strong>DIA</strong>
        </H5>-->
        <p>Ministarstvo sigurnosti BiH</p>
        <p>Министарство безбједности БиХ</p>
      </div>
      <form v-on:submit.prevent="login()" data-vv-scope="login">
        <div
          class="input"
          :class="
            errors.first('login.username') !== undefined ? 'input-error' : ''
          "
        >
          <label for="username" v-lang="'user.username'"></label>
          <input
            v-model="model.username"
            name="username"
            type="text"
            v-validate="'required'"
            autocomplete="off"
          />
          <div class="error" v-html="errors.first('login.username')"></div>
        </div>
        <div
          class="input"
          :class="
            errors.first('login.password') !== undefined ? 'input-error' : ''
          "
        >
          <label for="password" v-lang="'user.password'"></label>

          <input
            v-model="model.password"
            name="password"
            v-validate="'required'"
            id="passwordID"
            type="password"
          />

          <i
            class="show-password"
            v-on:click="showPass()"
            :class="!showPassword ? 'icon-eye' : 'icon-eye-blocked'"
          ></i>
          <div
            class="error error-pass"
            v-html="errors.first('login.password')"
          ></div>
        </div>
        <div class="input error" v-html="error"></div>
        <div class="button-container">
          <button class="primary login">
            <content v-lang="'forms.sing_in'"></content>
            <span></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import globalStore from "@/store";
import { lang, ToastError } from "@/global-functions";
import { mapState } from "vuex";
export default {
  data() {
    return {
      showPassword: false,
      model: {
        username: "",
        password: "",
      },
      validation_messages: {
        custom: {
          username: {
            required: lang("validation.required"),
          },
          password: {
            required: lang("validation.required"),
          },
        },
      },
      error: "",
    };
  },
  created() {
    window.localStorage.clear();
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  beforeMount() {
    if (localStorage.token != null) {
      this.$router.push({ name: "AdminHome" });
    }
  },
  methods: {
    showPass() {
      if (!this.showPassword) {
        document.getElementById("passwordID").setAttribute("type", "text");
        this.showPassword = true;
      } else {
        document.getElementById("passwordID").setAttribute("type", "password");
        this.showPassword = false;
      }
    },
    async login() {
      var $this = this;

      this.$validator.validateAll("login").then((valid) => {
        if (valid) {
          globalStore.commit("SHOW_LOADER");
          store
            .dispatch("LOGIN_USER", this.model)
            .then((response) => {
              var promise = new Promise((resolve, reject) => {
                localStorage.setItem(
                  "token",
                  response.data.token_type + " " + response.data.access_token
                );

                globalStore.commit(
                  "SET_TOKEN",
                  response.data.token_type + " " + response.data.access_token
                );

                resolve(
                  response.data.token_type + " " + response.data.access_token
                );
              });

              promise.then((response) => {
                setTimeout(() => {
                  $this.getCurrentUser(response);
                }, 200);
              });
            })
            .catch((error) => {
              $this.error = lang(error.data.error_description).replace(
                "/restart/",
                window.location.host + "/restart-password/"
              );
            })
            .finally(() => {
              globalStore.commit("HIDE_LOADER_500");
            });
        }
      });
    },
    getCurrentUser(token) {
      var $this = this;
      store
        .dispatch("GET_CURRENT_USER", token)
        .then((response) => {
          globalStore.commit("SET_USER", response.data);
          console.log("Login response:  ", response);
          $this.getCurrentLanguage(response.data.user.fkLanguageId);
          localStorage.setItem("user", JSON.stringify(response.data));
          setTimeout(() => {
            if (response.data.user.superAdmin)
              $this.$router.push({ name: "AdminHome" });
            else if (response.data.user.requiredPasswordChange)
              $this.$router.push({ name: "RequiredPasswordChange" });
            else $this.$router.push("/");
          }, 200);
        })
        .catch((error) => {});
    },
    getCurrentLanguage(languageID) {
      var $this = this;
      store
        .dispatch("GET_LANGUAGE_FOR_USER", languageID)
        .then((response) => {
          globalStore.commit("SET_LANGUAGE", response.data.model.code);
          localStorage.setItem("lang", response.data.model.code);
        })
        .catch((error) => {});
    },
  },
  computed: {
    ...mapState(["lang"]),
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/colors";
.error-pass {
  right: 30px !important;
}
.show-password {
  position: absolute;
  top: 56%;
  right: 10px;
  color: $secondaryColor;
}
.main-content {
  display: grid;
  position: fixed;
  width: 100%;
  height: 100vh;
  background: url("../../../../assets/insiDIA-bkg-orig-big.jpg");
  justify-items: center;
  align-items: center;
  background-size: cover;
  .image-header {
    width: 40px;
  }
  .image-header-ico {
    width: 60px;
  }
  .input {
    margin: 15px 0px !important;
  }
  header {
    color: white !important;
    background: $secondaryColor;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.5em;
  }
  footer {
    color: white !important;
    background: $secondaryColor;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 70px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-around;
    align-items: center;
    p {
      text-align: center;
      // width: 33.3333333%;
    }
    & > :last-child {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: max-content;
      text-transform: capitalize;
      img {
        margin-left: 5px;
      }
    }
  }
  .logo {
    display: grid;
    justify-items: center;
    align-items: center;
    // padding: 10px;
    // font-size: 5em;
    p {
      margin-bottom: 0px;
    }
    h5 {
      padding: 0px;
      margin: 0px;
      font-weight: 100;
      font-size: 0.8em;
      strong {
        font-size: 1.1em;
        font-weight: 800;
        margin-left: -17px;
      }
    }
  }
  h1 {
    width: calc(50% - 30px);
    color: white;
    background: $primaryColor;
    margin: 0;
    margin-bottom: 15px;
    padding: 10px 15px;
    position: relative;
    z-index: 10;
    p {
      font-size: 10px;
      margin: 0;
      font-weight: 100;
      letter-spacing: -0px;
    }
  }
  .login-form {
    color: white;
    padding: 40px;
    min-width: 400px;
    background-color: $secondaryColor;
  }
  .button-container {
    line-height: 39px;
    height: 39px;
    position: relative;
    display: block;
    a.forgot-password {
      position: absolute;
      padding: 0px;
      font-size: 0.8rem;
      text-decoration: none;
      color: $primaryColor;
      &:hover {
        text-decoration: underline;
      }
    }
    button.login {
      width: 100%;
      position: relative;
      font-weight: bold;
      height: 40px;
      cursor: pointer;
      &:hover {
        background: $primaryColor;
        color: $secondaryColor;
      }
    }
  }
  .en-title-span {
    border-top: 76px solid $primaryColor !important;
  }
}
.page-logo {
  max-height: 20px;
  width: auto;
}
</style>
