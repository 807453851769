<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-users"></i>
        <span v-lang="'logs.logs_user'"></span>
      </h1>
      <div class="actions">
        <button class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></button>
      </div>
    </div>
    <div
      v-responsive="{
          middle: el => el.width < 1140,
          small: el => el.width < 930
        }"
      class="card search_form"
      v-on:keyup.enter="getAllUserLogs"
    >
      <div class="input m-0">
        <label for="name" v-lang="'user.username'"></label>
        <input class="m-0" v-model="searchData.username" type="text" autocomplete="off" autofocus />
      </div>
      <div class="input m-0">
        <label for="name" v-lang="'general.ip_address'"></label>
        <input class="m-0" v-model="searchData.host" type="text" autocomplete="off" autofocus />
      </div>
      <div
        class="input m-0"
        :class="
          errors.first('action_form.titleId') !== undefined ? 'input-error' : ''
        "
      >
        <label for="name">API</label>
        <input class="m-0" v-model="searchData.api" type="text" autocomplete="off" autofocus />
      </div>
      <div class="input m-0">
        <label for="name" v-lang="'general.description'"></label>
        <input
          class="m-0"
          v-model="searchData.description"
          type="text"
          autocomplete="off"
          autofocus
        />
      </div>
      <div class="input m-0">
        <label for="name" v-lang="'general.date_from'"></label>
        <date-picker
          name="dateOfReportTo"
          v-model="searchData.minDate"
          :inputClass="'input-date-picker'"
          :locale="lang"
          :initDate="false"
        ></date-picker>
      </div>
      <div class="input m-0">
        <label for="name" v-lang="'general.date_to'"></label>
        <date-picker
          name="dateOfReportTo"
          v-model="searchData.maxDate"
          :inputClass="'input-date-picker'"
          :locale="lang"
          :initDate="false"
        ></date-picker>
      </div>
      <div class="buttons-search">
        <button class="primary" v-on:click="getAllUserLogs" v-lang="'general.search'"></button>&nbsp;
        <button
          type="button"
          class="warning reset"
          v-on:click="resetSearch()"
          v-lang="'general.button_reset'"
        ></button>
      </div>
    </div>
    <notify :key="notifyKey" />
    <NoData v-if="!logData || logData.length == 0"></NoData>
    <div v-else class="card table-container scroll">
      <table class="table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th v-lang="'user.username'">&nbsp;</th>
            <th v-lang="'general.ip_address'">&nbsp;</th>
            <th>API</th>
            <th v-lang="'general.description'">&nbsp;</th>
            <th v-lang="'general.date_time'">&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, key) in logData" :key="key">
            <td>{{ (searchData.page - 1) * searchData.pageSize + key + 1 }}</td>
            <td>{{ obj.userName }}</td>
            <td>{{ obj.host }}</td>
            <td>{{ obj.api }}</td>
            <td>{{ $lang(obj.description) }}</td>
            <td>{{ $moment(obj.utcDatetime).format("DD.MM.YYYY. HH:mm") }}</td>
            <td>
              <router-link
                class="default small"
                :to="{
                  name: 'UserLogDetails',
                  params: { id: obj.pkUserLogId }
                }"
                v-lang="'general.label_details'"
              ></router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination-container">
      <paginate
        v-if="resultCount != 0"
        :page-count="pages"
        :click-handler="changePage"
        :prev-text="this.$lang('general.previous')"
        :next-text="this.$lang('general.next_page')"
        :container-class="'paggination'"
      ></paginate>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import { mapState } from "vuex";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import NoData from "../../../General/NoData";
import Datepicker from "vuejs-datepicker";
import { en, sr, srCYRL, hr } from "vuejs-datepicker/dist/locale";
import { Settings } from "luxon";
import globalStore from "@/store";
import Paginate from "vuejs-paginate";
import { ResponsiveDirective } from "vue-responsive-components";

Vue.use(Toasted);
Settings.defaultLocale = globalStore.state.lang == "sr" ? "sr-latn" : "en";
export default {
  directives: {
    responsive: ResponsiveDirective
  },
  components: {
    Datepicker,
    NoData,
    paginate: Paginate
  },
  data() {
    return {
      en: en,
      sr: sr,
      srCYRL: srCYRL,
      hr: hr,
      lang:
        globalStore.state.lang == "sr"
          ? "sr-cyrl"
          : globalStore.state.lang == "bs"
          ? "sr"
          : globalStore.state.lang == "hr"
          ? "hr"
          : "en",
      notifyKey: 0,
      logData: [],
      searchData: {
        page: 1,
        pageSize: 25,
        username: "",
        host: "",
        api: "",
        description: "",
        minDate: null,
        maxDate: null
      },
      resultCount: 0
    };
  },
  computed: {
    pages() {
      return this.resultCount % this.searchData.pageSize == 0
        ? parseInt(this.resultCount / this.searchData.pageSize)
        : parseInt(this.resultCount / this.searchData.pageSize) + 1;
    },
    ...mapState(["$axios_auth"])
  },
  created() {
    this.getAllUserLogs();
  },
  methods: {
    customFormatter(date) {
      return this.$moment(date).format("DD.MM.YYYY.");
    },
    resetSearch() {
      this.searchData = {
        page: 1,
        pageSize: 25,
        username: "",
        host: "",
        api: "",
        description: "",
        minDate: null,
        maxDate: null
      };
      this.getAllUserLogs();
    },
    changePage: function(pageNum) {
      this.searchData.page = pageNum;
      this.getAllUserLogs();
    },
    getAllUserLogs() {
      var $this = this;
      store
        .dispatch("GET_All_USER_LOGS", this.searchData)
        .then(response => {
          $this.logData = response.data.list;
          $this.resultCount = response.data.count;
        })
        .catch(error => {});
    }
  }
};
</script>

<style lang="scss">
@import "../../../../assets/css/vue-datetime.css";
@import "../../../../assets/css/_colors.scss";
@import "../../../../assets/css/_paggination.scss";
.theme-page .vdatetime-popup__header,
.theme-page .vdatetime-calendar__month__day--selected > span > span,
.theme-page .vdatetime-calendar__month__day--selected:hover > span > span {
  background: $darkPrimaryColor;
}
.theme-page .vdatetime-popup {
  background: $secondaryColor;
  color: $white;
}
.theme-page .vdatetime-year-picker__item--selected,
.theme-page .vdatetime-time-picker__item--selected,
.theme-page .vdatetime-popup__actions__button {
  color: $white;
}
.theme-page .vdatetime-month-picker__item,
.theme-page .vdatetime-month-picker__item--selected {
  color: $white;
}
.left-aligment {
  margin-left: -40px;
}
.search {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  .button-search {
    grid-column: 1/4;
  }
}
.card {
  position: relative;
}
.scroll {
  overflow: auto;
}
.search_form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  .buttons-search {
    grid-column: 1/7;
  }
}
.search_form.middle {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  .buttons-search {
    grid-column: 1/4;
  }
}
.search_form.small {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  .buttons-search {
    grid-column: 1/2;
  }
}
.reset {
  background-color: orange;
  &:hover {
    background-color: red;
  }
}
</style>
