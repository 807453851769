<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-key"></i>
        <span v-lang="'user.label_set_functions_for_user'"></span>
        <span>&nbsp;</span>
        {{ data.user.firstName + " " }}
        {{ data.user.lastName }}
      </h1>
      <div class="actions">
        <button
          class="primary"
          v-on:click="$router.go(-1)"
          v-lang="'general.button_back'"
        ></button>
      </div>
    </div>
    <notify />
    <div>
      <div class="card">
        <fieldset>
          <legend>{{ $lang("menu.menu_item_roles") }}</legend>
          <div class="input m-0">
            <label for="firstname">
              <span>{{ $lang("general.role") }}</span>
            </label>
            <select v-model="data.fkRoleId" @change="changeRole($event)">
              <option value="0">{{
                $lang("queries.label_placeholder_for_role")
              }}</option>
              <option
                v-for="(obj, key) in roles"
                :key="key"
                :value="obj.pkRoleId"
                >{{ obj.name }}</option
              >
            </select>
          </div>
        </fieldset>
        <form
          id="functions_form"
          v-on:submit.prevent="save()"
          data-vv-scope="functions_form"
        >
          <fieldset
            v-for="(value, key) in AllFunctionsWithGroup"
            :key="key"
            :disabled="value[0].actionGroup.anonymousGroup"
            :name="key"
            :class="value[0].actionGroup.anonymousGroup ? 'anonymous' : ''"
          >
            <legend>
              <span class="name-group" v-html="key"></span>
              <span v-if="value[0].actionGroup.anonymousGroup">&nbsp;(</span>
              <span
                v-html="
                  value[0].actionGroup.anonymousGroup ? labelAnonymous : ''
                "
              ></span>
              <span v-if="value[0].actionGroup.anonymousGroup">)</span>
            </legend>

            <div class="fieldset-div">
              <div
                v-for="(func, key) in value"
                class="input-checkbox"
                :key="key"
              >
                <label :for="func.pkFunctionId">
                  <input
                    v-if="func.actionGroup.anonymousGroup"
                    :id="func.pkFunctionId"
                    class="m-0"
                    type="checkbox"
                    autocomplete="off"
                    :value="func"
                    hidden
                    checked
                  />
                  <input
                    v-else
                    :id="func.pkFunctionId"
                    v-model="data.functions"
                    class="m-0"
                    type="checkbox"
                    autocomplete="off"
                    :value="func"
                    hidden
                  />
                  <span v-html="func.name"></span>
                </label>
              </div>
            </div>
          </fieldset>
          <div class="button-search">
            <button class="primary" v-lang="'general.button_submit'"></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import { lang } from "@/global-functions";
import { mapState } from "vuex";
import globalStore from "@/store";
import NoData from "../../../General/NoData";

export default {
  components: {
    NoData,
  },
  data() {
    return {
      data: {
        pkFkUserId: 0,
        user: {},
        fkRoleId: null,
        functions: [],
        allFunctions: {},
      },
      roles: [],
      labelAnonymous: this.$lang("user.label_anonymous"),
    };
  },
  created() {
    this.getAllRoles();
    this.getAllData();
  },
  methods: {
    changeRole(event) {
      this.getFunctionsByRoleID(event.target.value);
      this.data.fkRoleId = event.target.value;
    },
    getAllRoles() {
      var $this = this;
      store
        .dispatch("GET_All_ROLES")
        .then((response) => {
          $this.roles = response.data.list;
        })
        .catch((error) => {});
    },
    getFunctionsByRoleID(id) {
      var $this = this;
      store
        .dispatch("GET_ALL_PERMISIONS_FOR_ROLE", id)
        .then((response) => {
          // $this.functions = [];
          $this.data.functions = response.data.model.functions;
        })
        .catch((error) => {});
    },
    save() {
      var $this = this;
      store
        .dispatch("SET_FUNCTIONS_FOR_USER", $this.data)
        .then((response) => {
          $this.$success("user.permissions_for_user_sucessfully_setted");
          $this.$router.push({
            name: "UserDetails",
            params: { id: $this.$route.params.id },
          });
        })
        .catch((error) => {});
    },
    getAllData() {
      var $this = this;
      store
        .dispatch("GET_ALL_FUNCTIONS_FOR_USER", $this.$route.params.id)
        .then((response) => {
          $this.data = response.data.model;
        })
        .catch((error) => {});
    },
  },
  computed: {
    AllFunctionsWithGroup() {
      var $this = this;
      for (let value of Object.values($this.data.allFunctions)) {
        value.forEach(function(element) {
          var index = $this.data.functions.findIndex(function(e, index, array) {
            if (e.pkFunctionId == element.pkFunctionId) {
              return true;
            }
            return false;
          });
          if (index >= 0) {
            $this.data.functions[index] = element;
          }
        });
      }
      return $this.data.allFunctions;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/colors";
form {
  display: grid;
  grid-template-columns: 1fr;
  .anonymous {
    border-color: $lightSecondaryColor;
    color: $lightSecondaryColor;
  }
  fieldset {
    margin-bottom: 10px;
  }
  .fieldset-div {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
  }
  .button-search {
    margin-top: 10px;
    grid-column: 1/2;
  }
}
.name-group {
  text-transform: uppercase;
}
.input {
  select {
    width: 33%;
  }
}
</style>
