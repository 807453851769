import Vue from "vue";
import Vuex from "vuex";
import Store from "@/store";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    GET_USERS_IN_ORG_UNITS: function(state: any, accessLevel: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("Notifications/get-users-and-org-units/" + accessLevel)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    BROADCAST_MESSAGE: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        if (model.notificationModel)
          model = { ...model.model, ...model.notificationModel };

        Store.state.$axios_auth
          .post("Notifications/send-broadcast-message", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_BROADCAST_NOTIFICATIONS(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("Notifications/get-broadcast-messages", {
            params: model
          })
          .then((response: any) => {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    }
  }
});
