<template>
  <div>
    <div class="institutions-list">
      <div class="element">
        <span>{{ institutions.name }}</span>

        <a
          class="error small"
          v-on:click="deleteInstitutions"
          v-lang="'general.label_remove'"
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    institutions: []
  },
  methods: {
    deleteInstitutions() {
      this.$emit("deleteInstitutions", this.institutions.pkEmmausInstitutionId);
    }
  }
};
</script>

<style>
.institutions-list {
  display: grid;
  grid-column: 1/2;
}
.element {
  border: 1px solid #5a9c0d;
  padding: 5px;
  grid-column: 1fr;
}
.element > a {
  float: right;
}
</style>
