import Vue from "vue";
import Vuex from "vuex";
import Store from "../../store";
import axios from "axios";
import { lang } from "@/global-functions";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    LOGIN_USER: function(state: any, user_data: any) {
      var params = new URLSearchParams();
      params.append("grant_type", "password");
      params.append("password", user_data.password);
      params.append("username", user_data.username);
      return new Promise((resolve, reject) => {
        Store.state.$axios
          .post(`/Token`, params, {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
            },
          })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    LOGOUT_USER: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get(`Users/Logout`)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_CURRENT_USER: function(state: any, token: string) {
      return new Promise((resolve, reject) => {
        axios
          .get(Store.state.api + "api/Users/current", {
            headers: {
              Authorization: token,
            },
          })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_CURRENT_USER_ATUH: function(state: any, token: string) {
      return new Promise((resolve, reject) => {
        axios
          .get(Store.state.api + "api/Users/current", {
            headers: {
              Authorization: localStorage.token,
            },
          })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_All_USERS: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Users", {
            params: model,
          })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_ROLES: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Roles")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_All_ACTIONS: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Actions")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    CREATE_ACTION: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/Actions", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ACTION_BY_ID: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get(
            "/Actions/?PkActionId=" +
              model.PkActionId +
              "&PkController=" +
              model.PkController
          )
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_STATUS_MESSAGES_BY_ACTION_ID: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/StatusMessages/", { params: model })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_STATUS_MESSAGES_BY_ID: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/StatusMessages/", { params: model })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    EDIT_STATUS_MESSAGE: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .put("/StatusMessages/", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    DELETE_STATUS_MESSAGE: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .delete("/StatusMessages/", { params: model })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    CREATE_STATUS_MESSAGE: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/StatusMessages", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ACTION_TYPE_BY_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/ActionTypes/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_HTTP_METHOD_BY_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/HttpMethods/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ACTION_MODULE_BY_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/ActionModules/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_ACTION_TYPES: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/ActionTypes")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_ACTION_MODULES: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/ActionModules")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_HTTP_METHODS: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/HttpMethods")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ACTION_TYPES_AND_HTTPMETHODS_FOR_ACTION: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Actions/GetHttpMethodsAndActionTypesList")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    DELETE_ACTION: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .delete(
            "/Actions/?PkActionId=" +
              model.PkActionId +
              "&PkController=" +
              model.PkController
          )
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    EDIT_ACTION: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .put("/Actions", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_All_ENTITY_TYPES: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/EntityTypes")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_All_FUNCTIONS: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Functions")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_All_FUNCTIONS_WITH_ACTION_GROUP: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Functions/get-all-with-action-group")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_FUNCTION_BY_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Functions/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    DELETE_FUNCTION: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .delete("/Functions/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    CREATE_FUNCTION: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/Functions", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    EDIT_FUNCTION: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .put("/Functions", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_All_ACTION_GROUPS: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/ActionGroups")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    DELETE_ACTION_GROUP: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .delete("/ActionGroups/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ACTION_GROUP_BY_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/ActionGroups/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    CREATE_ACTION_GROUP: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/ActionGroups", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    EDIT_ACTION_GROUP: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .put("/ActionGroups", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_STATUS_CODES_PRIORITIES_LIST: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("StatusMessages/GetStatusCodesAndPrioritiesList")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    CREATE_USER: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/Users?fkInstitutionId=" + model.fkInstitutionId, model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    DELETE_USER: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .delete("/Users/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_USER_BY_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Users/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    EDIT_USER: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .put("/Users", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    EDIT_USER_SELF: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .put("/Users/SelfUserUpdate", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    REQUEST_NEW_PASSWORD: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        var messageContent = lang("user.restart-password-email-text");
        messageContent = messageContent.replace(
          "{hostname}",
          window.location.host
        );
        // ovako ne smijemo pisati query-e, ako je metod post onda se postaju podaci.
        //email="+model+"&messageTitle="+lang("user.password-reset-mail-title")+"&messageContent="+messageContent
        var data = {
          email: model,
          messageTitle: lang("user.password-reset-mail-title"),
          messageContent: messageContent,
        };
        Store.state.$axios
          .post("api/users/RequestPasswordReset", data)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    IS_TOKEN_EXPIRED: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios
          .get("api/users/IsPasswordResetTokenExpired?resetToken=" + model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    SET_NEW_PASSWORD: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios
          .post("api/users/SetNewPassword", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_All_TYPES_OF_CODES: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/TypeOfCodesAPI/")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    CREATE_TYPE_OF_CODES: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/TypeOfCodesAPI/", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_TYPE_OF_CODES_BY_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/TypeOfCodesAPI/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    EDIT_TYPE_OF_CODES: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .put("/TypeOfCodesAPI", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_CODES_BY_TYPE_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/CodesAPI/GetCodesForType/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_CODE_BY_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/CodesAPI/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    UPDATE_CODE: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .put("/CodesAPI/" + model.pkCodeId, model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    CREATE_CODE: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/CodesAPI/", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    DELETE_CODE: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .delete("/CodesAPI/" + model.id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    UPLOAD_AVATAR_IMAGE: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        let form = new FormData();
        let xhr = new XMLHttpRequest();
        let data = Object.assign({}, "form");

        for (let key in data) {
          form.append(key, data[key]);
        }

        form.append("form", model.avatar, "avatar.png");

        Store.state.$axios_auth
          .post("/Users/UploadAvatar/" + model.username, form)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_All_ACTION_LOGS: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/ActionLogs/", { params: model })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ACTION_LOG_BY_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/ActionLogs/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_All_DATA_LOGS: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/DataLogs/", { params: model })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_DATA_LOG_BY_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/DataLogs/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_All_ERROR_LOGS: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/ErrorLogs/", { params: model })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ERROR_LOG_BY_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/ErrorLogs/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_All_USER_LOGS: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/UserLogs/", { params: model })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_USER_LOG_BY_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/UserLogs/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    CHECK_AVATAR_EXIST: function(state: any, username: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/users/avatars/exist?username=" + username)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    DELETE_AVATAR_IMAGE: function(state: any, username: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .delete("/users/avatars?username=" + username)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    CHANGE_PASSWORD_SELF: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/Users/ChangePassword", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    CHANGE_PASSWORD_FOR_USER: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/Users/ChangePasswordForUser", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    CHANGE_PASSWORD_REQUIRED: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios
          .put("/api/Users/RequiredPasswordChange", model, {
            headers: {
              Authorization: localStorage.token,
            },
          })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_LANGUAGES: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Languages")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    CREATE_LANGUAGE: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/Languages", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    CREATE_TRANSLATION: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/Languages/CreateNewTitles", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_TRANSLATION_BY_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Languages/GeTitleByIDForAllLangs", { params: { titleId: id } })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    UPDATE_LANGUAGE: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .put("/Languages", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    UPDATE_TRANSLATION: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/Languages/UpdateNewTitles", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    DELETE_LANGUAGE: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .delete("/Languages/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    DELETE_TRANSLATION: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .delete("/Languages/DeleteTitleForAllLanguages", {
            params: { titleId: id },
          })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_LANGUAGE_BY_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Languages/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_FILES: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Languages/GetAllLangFiles")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_TRANSLATIONS_BY_FILE_NAME: function(state: any, fileName: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Languages/GetAllTitlesForFile", {
            params: { fileName: fileName },
          })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_LANGUAGE_FOR_USER: function(state: any, languageID: number) {
      return new Promise((resolve, reject) => {
        Store.state.$axios
          .get("api/Languages/" + languageID, {
            headers: {
              Authorization: localStorage.token,
            },
          })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_QUERIES: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Queries")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_QUERY: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Queries", { params: model })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    DELETE_QUERY: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .delete("/Queries", { params: model })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    UPDATE_QUERY: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .put("/Queries", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    CREATE_QUERY: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/Queries", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_All_ROLES: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Roles")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ROLE_BY_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Roles/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    EDIT_ROLE: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .put("/Roles", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    DELETE_ROLE: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .delete("/Roles/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    CREATE_ROLE: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/Roles", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_MANAGE_ROLES_BY_ROLE_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/RoleManageRoles/get-all-managed-roles-for-role/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    ADD_MANAGED_ROLE_TO_ROLE: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/RoleManageRoles", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    DELETE_MANAGED_ROLE: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .delete("/RoleManageRoles", { params: model })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    SET_FUNCTIONS_FOR_ROLE: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/Permissions/set-permissions-for-role", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    SET_FUNCTIONS_FOR_USER: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/PermissionsForUsers/set-functions-for-user", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_PERMISIONS_FOR_ROLE: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Permissions/get-all-permisions-for-role/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_FUNCTIONS_FOR_USER: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get(
            "/PermissionsForUsers/get-all-functions-and-functions-for-user/" +
              id
          )
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_FUNCTIONS_FOR_USER: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/PermissionsForUsers/get-functions-for-user/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_DATA_FOR_CREATE_FUNCTION: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Functions/GetDataForCreateFunction")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_CUSTOM_FILED_TYPE: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/CodesAPI/GetCustomFiledType")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_ORGANISATION_UNITS: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Institution")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_CODES: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/Users/get-codes")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_EMAUS_ORGANISATION_UNITS: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/EmmausOrganization")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
  },
});
