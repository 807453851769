<template>
  <div>
    <table
      id="my-table"
      style="display: none"
      v-if="this.data && this.data.report && this.data.report.length > 0"
    >
      <thead>
        <tr>
          <th style="align:center;">{{ $lang("emmaus.label_organizator") }}</th>
          <th style="align:center;">{{ $lang("emmaus.label_broj_obuka") }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="data && data.report && data.report.length > 0">
          <tr v-for="row in data.report" :key="row">
            <td>{{ row.users }}</td>
            <td>{{ row.trainingCount }}</td>
          </tr></template
        >
      </tbody>
    </table>
    <table id="my-table" style="display: none" v-else>
      <tbody>
        <tr>
          <td>{{ $lang("general.no_data_to_show") }}</td>
        </tr>
      </tbody>
    </table>

    <apexchart
      v-if="renderChart"
      style="visibility: hidden"
      type="pie"
      width="380"
      height="400"
      :options="chartOptions"
      :series="series"
      ref="pieChart"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts
  },
  props: ["data"],
  data() {
    return {
      renderChart: false,
      series: [],
      chartOptions: {
        legend: {
          position: "bottom",
          horizontalAlign: "center"
        },
        chart: {
          width: 200,
          type: "pie"
        },
        labels: []
      }
    };
  },
  methods: {
    trainingsMap() {
      if (this.data && this.data.chartData && this.data.chartData.length > 0) {
        var trainingsMap = new Map();
        this.data.chartData.forEach((element) => {
          if (!trainingsMap.has(element.users)) {
            trainingsMap.set(element.users, element.trainingCount);
          } else
            trainingsMap.set(
              element.users,
              trainingsMap.get(element.users) + element.trainingCount
            );
        });
        return trainingsMap;
      } else return new Map();
    }
  },
  watch: {
    data(val) {
      this.chartOptions.labels = Array.from(this.trainingsMap().keys());
      this.series = Array.from(this.trainingsMap().values());

      this.renderChart = true;
    }
  }
};
</script>
<style lang="scss">
.apexcharts-legend {
  margin-top: 20px;
}
</style>
