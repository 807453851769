<template>
  <div :class="'container'">
    <div class="header card">
      <h1>
        <span v-lang="'training.label_training_details'"></span>
      </h1>
      <div class="actions">
        <button
          class="primary"
          v-on:click="Export2Excel()"
          v-lang="'victims.export_excel'"
        ></button>
        <button
          class="error"
          v-on:click="Export2PDF"
          v-lang="'general.report-print'"
        ></button>
        <button
          class="primary"
          v-on:click="$router.go(-1)"
          v-lang="'general.button_back'"
        ></button>
      </div>
    </div>
    <Loader v-if="loading" />
    <div v-else class="card table-container">
      <table>
        <tr>
          <td v-lang="'training.emmaus_training_name'"></td>
          <td class="title_name">{{ model.titleName }}</td>
        </tr>
        <tr>
          <td v-lang="'training.emmaus_training_type'"></td>
          <td>{{ model.typeName }}</td>
        </tr>
        <tr>
          <td v-lang="'training.emmaus_training_created_by'"></td>
          <!-- <td v-lang="'training.emmaus_organization_name'"></td> -->
          <td>{{ model.emmausOrganizationName }}</td>
        </tr>
        <tr>
          <td v-lang="'training.emmaus_training_date'"></td>
          <td>{{ $moment(model.date).format("DD.MM.YYYY.") }}</td>
        </tr>
        <tr>
          <td v-lang="'training.emmaus_training_certified'"></td>
          <td>
            {{
              model.certified
                ? $lang("general.label_yes")
                : $lang("general.label_no")
            }}
          </td>
        </tr>
        <!-- <tr>
          <td v-lang="'training.emmaus_training_created_by'"></td>
          <td>{{ model.createdByName }}</td>
        </tr> -->
      </table>
    </div>
    <div class="card" v-if="!loading">
      <div class="functions-description">
        {{ $lang("emmaus.label_themes") }}
      </div>
      <NoData v-if="model.themes && model.themes.length == 0" />
      <fieldset v-else>
        <div class="fieldset-div">
          <div
            v-for="(t, key) in model.themes"
            class="input-checkbox"
            :key="key"
          >
            <label :for="t.pkCodeId">
              <span v-html="t.name"></span>
            </label>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="card" v-if="!loading">
      <div class="functions-description" v-lang="'emmaus.label_fields'"></div>
      <NoData v-if="model.fields && model.fields.length == 0" />
      <fieldset v-else>
        <div class="fieldset-div">
          <div
            v-for="(f, key) in model.fields"
            class="input-checkbox"
            :key="key"
          >
            <label :for="f.pkCodeId">
              <span v-html="f.name"></span>
            </label>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="header card" v-if="!loading">
      <h1>
        <span v-lang="'training.label_people'"></span>
      </h1>
    </div>
    <div class="card table-container" v-if="!loading">
      <NoData
        v-if="!model.emmausPeople || model.emmausPeople.length == 0"
      ></NoData>
      <span v-else>
        <TablePerson
          :people="filteredPeople"
          :searchModel="searchModel"
          @searchSort="searchSort"
          :orderColumn="searchModel.orderColumn"
          :orderSort="searchModel.orderSort"
        />
        <div class="pagination-container table-paggination">
          <paginate
            v-if="filteredPeople.length != 0 && pageCount > 1"
            :page-count="pageCount"
            v-model="searchModel.page"
            :click-handler="changePage"
            :prev-text="this.$lang('general.previous')"
            :next-text="this.$lang('general.next_page')"
            :container-class="'paggination'"
          ></paginate>
        </div>
      </span>
    </div>
    <ReportDetails
      :model="model"
      :peoples="model.emmausPeople"
      :themes="model.themes"
      :fileds="model.fields"
    ></ReportDetails>
  </div>
</template>

<script>
import Vue from "vue";
import store from "./store";
import globalStore from "@/store";
import moment from "moment";
import { lang } from "@/global-functions";
import { mapState } from "vuex";
import NoData from "../../General/NoData";
import Loader2 from "../../General/Loader2";
import TablePerson from "../Person/Table.vue";
import Paginate from "vuejs-paginate";
import ReportDetails from "./ReportDetails.vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Arimo } from "@/assets/fonts/Arimo-Regular-normal.js";
import { ArimoBold } from "@/assets/fonts/Arimo_Bold.js";

export default {
  components: {
    TablePerson,
    Paginate,
    NoData,
    Loader2,
    ReportDetails
  },
  data() {
    return {
      moment: moment,
      model: {},
      organization: {},
      loading: true,
      searchModel: {
        page: 1,
        limit: 5,
        orderColumn: "",
        orderSort: ""
      }
    };
  },
  computed: {
    filteredPeople() {
      return this.$_.chunk(this.model.emmausPeople, this.searchModel.limit)[
        this.searchModel.page - 1
      ];
    },
    pageCount() {
      return this.$_.chunk(this.model.emmausPeople, this.searchModel.limit)
        .length;
      // var resultCount = this.model.emmausPeople.length;
      // return parseInt(
      //   resultCount > this.searchModel.limit
      //     ? resultCount / this.searchModel.limit + 1
      //     : resultCount / this.searchModel.limit
      // );
    }
  },

  created() {
    if (this.$route.query.pkEmmausTrainingId != null) this.getTrainingByID();
  },
  methods: {
    changePage: function(page) {
      this.searchModel.page = page;
    },
    searchSort(orderColumn, orderSort) {
      this.searchModel.orderColumn = orderColumn;
      this.searchModel.orderSort = orderSort;
    },
    getTrainingByID() {
      var $this = this;
      $this.loading = true;
      store
        .dispatch(
          "GET_EMMAUS_TRAINING_BY_ID",
          $this.$route.query.pkEmmausTrainingId
        )
        .then((response) => {
          $this.model = response.data.model;
          console.log("Details model:", $this.model);

          $this.loading = false;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    Export2Excel() {
      var $this = this;
      var title = $this.$lang("training.label_training_details");

      var element = document.getElementsByTagName("td");
      for (let i = 0; i < element.length; i++) {
        element[i].style.verticalAlign = "middle";
      }

      // DETALJI
      var tab_text = "<table ><tr border='2px'>";
      var textRange;
      var j = 0;

      var tab = document.getElementById("detils_training_report"); // id of table
      var clspan = tab.rows[0].innerHTML.split("<th").length - 1;

      tab_text +=
        "<th  bgcolor='#87AFC6' colspan='2' id='activity'>" + title + "</tr>";

      for (j = 1; j < tab.rows.length; j++) {
        tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";
      }

      //  TEME
      tab_text += "<tr></tr>";
      tab_text += "<tr></tr>";
      tab_text += "<tr></tr>";
      textRange;
      j = 0;

      tab = document.getElementById("report_trianing_themes"); // id of table
      clspan = tab.rows[0].innerHTML.split("<th").length - 1;

      tab_text +=
        "<th  bgcolor='#87AFC6' colspan='1' id='activity'>" +
        $this.$lang("emmaus.label_themes_report") +
        "</tr>";

      for (j = 1; j < tab.rows.length; j++) {
        tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";
      }

      //  OBLAST
      tab_text += "<tr></tr>";
      tab_text += "<tr></tr>";
      tab_text += "<tr></tr>";
      textRange;
      j = 0;

      tab = document.getElementById("report_trianing_fileds"); // id of table
      clspan = tab.rows[0].innerHTML.split("<th").length - 1;

      tab_text +=
        "<th  bgcolor='#87AFC6' colspan='1' id='activity'>" +
        $this.$lang("emmaus.label_fields") +
        "</tr>";

      for (j = 1; j < tab.rows.length; j++) {
        tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";
      }

      //  OSOBE NA OBUCI
      tab_text += "<tr></tr>";
      tab_text += "<tr></tr>";
      tab_text += "<tr></tr>";
      textRange;
      j = 0;

      tab = document.getElementById("report_details_peoples"); // id of table
      clspan = tab.rows[0].innerHTML.split("<th").length - 1;

      tab_text +=
        "<th  bgcolor='#87AFC6' colspan='4' id='activity'>" +
        $this.$lang("training.label_people") +
        "</tr>";

      for (j = 1; j < tab.rows.length; j++) {
        tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";
      }

      tab_text = tab_text.replace(
        /<td/gi,
        "<td class='xl70' width='300' align='center' vertical-align:'top' "
      );
      tab_text = tab_text.replace(/12-17/gi, "&nbsp;12-17");
      tab_text = tab_text + "</table>";

      tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, ""); //remove if u want links in your table
      tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
      tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");

      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        // If Internet Explorer
        txtArea1.document.open("txt/html", "replace");
        txtArea1.document.write(tab_text);
        txtArea1.document.close();
        txtArea1.focus();
        sa = txtArea1.document.execCommand(
          "SaveAs",
          true,
          "Say Thanks to Sumit.xls"
        );
      } //other browser not tested on IE 11
      else {
        var a = window.document.createElement("a");
        a.setAttribute(
          "href",
          "data:attachment/xlsx;charset=utf-8,%EF%BB%BF" +
            encodeURIComponent(tab_text)
        );
        a.setAttribute("download", title + ".xls");
        window.document.body.appendChild(a);
        a.click();
      }
    },
    async Export2PDF(element) {
      var element = "body";
      var doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true
      });

      var $this = this;
      doc.addFileToVFS("Arimo-Regular.ttf", Arimo);
      doc.addFont("Arimo-Regular.ttf", "Arimo", "normal");
      doc.setFont("Arimo");
      doc.addFileToVFS("Arimo-Bold.ttf", ArimoBold);
      doc.addFont("Arimo-Bold.ttf", "Arimo", "bold");

      var footer = async function(data) {};

      var title =
        $this.$lang("emmaus_persons.title_report_details_training") +
        " " +
        this.model.titleName;

      var outputName = title + ".pdf";

      var Font = 8;
      Font =
        $this.$route.query.id == 42901 ||
        $this.$route.query.id == 42898 ||
        $this.$route.query.id == 42899
          ? 7
          : Font;

      var pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      var number_of_pages = doc.internal.getNumberOfPages();

      // TABELA

      doc.autoTable({
        styles: {
          fontSize: Font,
          font: "Arimo",
          halign: "left",
          lineColor: [44, 62, 80],
          lineWidth: 0.55
        },
        headStyles: {
          halign: "left",
          fontStyle: "Arimo",
          fontSize: 12,
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineColor: [255, 255, 255]
        },
        columnStyles: {
          0: {
            halign: "left",
            valign: "middle",
            cellWidth: 100
          },
          1: {
            halign: "left",
            valign: "middle",
            cellWidth: "auto",
            fontStyle: "bold"
          }
        },
        html: "#detils_training_report",

        didParseCell: function(HookData) {
          if (HookData.row.raw.id == "countryRow") {
            var rows = HookData.table.body;

            HookData.cell.styles.textColor = [0, 0, 0];
            HookData.cell.styles.fontSize = 10;
            HookData.cell.styles.fillColor = [176, 224, 230];
          }
        },
        drawHeader: function(HookData) {
          return false;
        },

        didDrawPage: async function(data) {
          data.settings.margin.top = 5;
          var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
          var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

          if (data.pageNumber === 1) {
            var splitTitle = doc.splitTextToSize(title, 250);
            var dim = doc.getTextDimensions(title);

            var yearsPos = dim.w > 179 ? 64 : 57;
            doc.setFontSize(13);
            doc.setTextColor(60);
            doc.text(splitTitle, pageWidth / 2, 15, "center");
          }
        },
        didDrawCell: function(HookData) {},
        margin: { top: 30 },
        rowPageBreak: "avoid",
        pageBreak: "avoid"
      });
      if (this.model.themes.length > 0) {
        doc.autoTable({
          styles: {
            fontSize: Font,
            font: "Arimo",
            halign: "left",
            lineColor: [44, 62, 80],
            lineWidth: 0.55
          },
          headStyles: {
            halign: "left",
            fontStyle: "Arimo",
            fontSize: 12,
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            lineColor: [255, 255, 255]
          },
          columnStyles: {
            0: {
              halign: "left",
              valign: "middle"
            },
            1: {
              halign: "left",
              valign: "middle",
              cellWidth: "auto",
              fontStyle: "bold"
            }
          },
          html: "#report_trianing_themes",

          didParseCell: function(HookData) {
            if (HookData.row.raw.id == "countryRow") {
              var rows = HookData.table.body;

              HookData.cell.styles.textColor = [0, 0, 0];
              HookData.cell.styles.fontSize = 10;
              HookData.cell.styles.fillColor = [176, 224, 230];
            }
          },
          drawHeader: function(HookData) {
            return false;
          },

          didDrawPage: async function(data) {
            data.settings.margin.top = 5;
            var pageHeight =
              doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            var pageWidth =
              doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

            if (data.pageNumber === 1) {
              var splitTitle = doc.splitTextToSize(title, 250);
              var dim = doc.getTextDimensions(title);

              var yearsPos = dim.w > 179 ? 64 : 57;
              doc.setFontSize(13);
              doc.setTextColor(60);
              doc.text(splitTitle, pageWidth / 2, 15, "center");
            }
          },
          didDrawCell: function(HookData) {},
          margin: { top: 30 },
          rowPageBreak: "avoid",
          pageBreak: "avoid"
        });
      }

      if (this.model.fields.length > 0) {
        doc.autoTable({
          styles: {
            fontSize: Font,
            font: "Arimo",
            halign: "left",
            lineColor: [44, 62, 80],
            lineWidth: 0.55
          },
          headStyles: {
            halign: "left",
            fontStyle: "Arimo",
            fontSize: 12,
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            lineColor: [255, 255, 255]
          },
          columnStyles: {
            0: {
              halign: "left",
              valign: "middle"
            },
            1: {
              halign: "left",
              valign: "middle",
              cellWidth: "auto",
              fontStyle: "bold"
            }
          },
          html: "#report_trianing_fileds",

          didParseCell: function(HookData) {
            if (HookData.row.raw.id == "countryRow") {
              var rows = HookData.table.body;

              HookData.cell.styles.textColor = [0, 0, 0];
              HookData.cell.styles.fontSize = 10;
              HookData.cell.styles.fillColor = [176, 224, 230];
            }
          },
          drawHeader: function(HookData) {
            return false;
          },

          didDrawPage: async function(data) {
            data.settings.margin.top = 5;
            var pageHeight =
              doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            var pageWidth =
              doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

            if (data.pageNumber === 1) {
              var splitTitle = doc.splitTextToSize(title, 250);
              var dim = doc.getTextDimensions(title);

              var yearsPos = dim.w > 179 ? 64 : 57;
              doc.setFontSize(13);
              doc.setTextColor(60);
              doc.text(splitTitle, pageWidth / 2, 15, "center");
            }
          },
          didDrawCell: function(HookData) {},
          margin: { top: 30 },
          rowPageBreak: "avoid",
          pageBreak: "avoid"
        });
      }

      if (this.model.emmausPeople.length > 0) {
        var position;
        if (
          doc.lastAutoTable.finalY > 230 ||
          this.model.emmausPeople.length > 3
        ) {
          doc.addPage();
          doc.text(20, 20, this.$lang("training.label_people"));
        } else {
          doc.text(
            20,
            doc.lastAutoTable.finalY + 20,
            this.$lang("training.label_people")
          );
          position = doc.lastAutoTable.finalY + 30;
        }
        doc.autoTable({
          startY: position,
          styles: {
            fontSize: Font,
            font: "Arimo",
            halign: "left",
            lineColor: [44, 62, 80],
            lineWidth: 0.55
          },
          headStyles: {
            halign: "left",
            fontStyle: "Arimo",
            fontSize: 12,
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            lineColor: [255, 255, 255]
          },
          columnStyles: {
            0: {
              halign: "left",
              valign: "middle",
              cellWidth: 10
            },
            1: {
              halign: "left",
              valign: "middle",
              cellWidth: "auto"
            }
          },
          html: "#report_details_peoples",

          didParseCell: function(HookData) {
            if (HookData.row.raw.id == "countryRow") {
              var rows = HookData.table.body;

              HookData.cell.styles.textColor = [0, 0, 0];
              HookData.cell.styles.fontSize = 10;
              HookData.cell.styles.fillColor = [176, 224, 230];
            }
          },
          drawHeader: function(HookData) {
            return false;
          },

          didDrawPage: async function(data) {
            data.settings.margin.top = 20;
            var pageHeight =
              doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            var pageWidth =
              doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
          },
          didDrawCell: function(HookData) {},
          margin: { top: 30 },
          rowPageBreak: "avoid",
          pageBreak: "avoid"
        });
        number_of_pages = doc.internal.getNumberOfPages();
        for (var i = 1; i <= number_of_pages; i++) {
          doc.setPage(i);
          doc.setFontSize(12);
          doc.text(i + "/" + number_of_pages, 10, 290);
          if (i < number_of_pages) {
            doc.setFontSize(9);
          }
        }
      }

      await doc.save(outputName);

      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        window.open(doc.output("bloburl"), "_blank");
      }
      $this.showLoader = false;
      $this.templateData = null;
      $this.selectedReportNumber = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
td:nth-child(2) {
  font-weight: bold;
}
.functions-description {
  font-size: 1.4em;
  padding-bottom: 20px;
}
.fieldset-div {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
fieldset {
  margin-bottom: 10px;
  legend {
    text-transform: uppercase;
  }
}
.table-paggination {
  margin: 0 !important;
}
.title_name {
  word-break: break-all;
  width: 50%;
}
</style>
