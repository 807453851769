<template>
  <div>
    <div :id="rndID" class="datepicker-container">
      <div :class="inputWrapperClass">
        <input
          id="date-time-picker-id"
          :maxlength="format.length"
          type="text"
          :class="inputClass"
          v-model="model"
          v-on:keyup.delete="deleteData()"
          v-on:keyup.backspace="deleteData()"
          :placeholder="format.toLowerCase()"
          v-on:click="click()"
          autocomplete="off"
          :disabled="disabled"
        />
        <span v-if="showRemoveButton" v-on:click="deleteData" class="btn-delete"
          >&#10539;</span
        >
      </div>

      <div v-if="showCalendar" class="datepicker-date">
        <div v-show="!showMonth">
          <div class="calendar-header">
            <button type="button" v-on:click="prevMonth()">&lsaquo;</button>
            <div v-on:click="clickShowMount()">
              {{ date.locale(this.locale).format("MMMM") }}
              {{ date.format("YYYY") }}
            </div>
            <button type="button" v-on:click="nextMonth()">&rsaquo;</button>
          </div>
          <div class="calendar">
            <div class="calendar-header" v-for="(str, key) in days" :key="key">
              {{ str.substr(0, 3) }}
            </div>
            <div
              class="empty"
              v-for="n in first_day_of_week"
              :key="n + '12222'"
            ></div>
            <div
              v-for="n in last"
              v-on:click="clickDay(n)"
              :key="n + '12222455'"
              :class="n == selectedDay ? 'selected-day' : ''"
            >
              {{ n }}
            </div>
          </div>
        </div>
        <MonthPicker
          v-show="showMonth"
          :locale="locale"
          @changeMonth="changeMonth"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-with-locales-es6";
import MonthPicker from "./MonthPicker";
export default {
  components: {
    MonthPicker,
  },
  props: {
    value: {
      type: String,
    },
    locale: {
      type: String,
      default: "sr",
    },
    format: {
      type: String,
      default: "DD.MM.YYYY.",
    },
    separator: {
      type: String,
      default: ".",
    },
    inputClass: {
      type: String,
      default: "input-class",
    },
    inputWrapperClass: {
      type: String,
      default: "input-wrapper-class",
    },
    startOfWeek: {
      type: Number,
      default: 1,
    },
    showRemoveButton: {
      type: Boolean,
      default: true,
    },
    initDate: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    model(newVal, oldVal) {
      var isValidDate = moment(newVal, this.format).isValid();
      var numberOfDots = newVal.split(this.separator).length - 1;
      var numberofSeparatorsInFormat = this.format.split(this.separator).length;
      if (numberOfDots > 3) {
        this.model = oldVal;
      }
      if (this.model.length == 1 && this.model[0] == ".") {
        this.model = this.model.slice(0, -1);
        return;
      }
      if (this.model.length == 11 && this.model[10] != ".") {
        this.model = this.model.slice(0, -1);
        this.model += ".";
      }

      for (var i = 0; i < this.model.length; i++) {
        if (
          isNaN(Number(this.model[i])) == true &&
          this.model[i] !== this.separator
        ) {
          this.model = this.model.substring(0, this.model.length - 1);
        }
      }
      this.model = this.model.replace(
        this.separator + this.separator,
        this.separator
      );
      var last_index_of_separator = this.model.lastIndexOf(this.separator);
      if (
        this.locations(this.separator, this.model).length ==
        numberofSeparatorsInFormat
      ) {
        this.model = this.model.splice(last_index_of_separator, -1);
      }

      if (this.separator_positions.indexOf(this.model.length) > -1) {
        this.model += this.separator;
      }
      var tmpModel = this.model.split(this.separator);

      if (tmpModel.length > numberofSeparatorsInFormat) {
        this.model =
          tmpModel
            .slice(0, numberofSeparatorsInFormat - 1)
            .join(this.separator) + this.separator;
      }
      if (Number(tmpModel[0]) > 31 || tmpModel[0].length > 2) {
        tmpModel[0] = "31";
        this.model = tmpModel.join(this.separator);
      }
      if (
        Number(tmpModel[0]) == 31 &&
        tmpModel[1] != "" &&
        tmpModel[1] != null &&
        (tmpModel[1] == "04" ||
          tmpModel[1] == "06" ||
          tmpModel[1] == "09" ||
          tmpModel[1] == "11")
      ) {
        tmpModel[0] = "30";
        this.model = tmpModel.join(this.separator);
      }

      if (
        Number(tmpModel[0]) > 29 &&
        tmpModel[1] == "02" &&
        !this.isLeap(Number(tmpModel[2]))
      ) {
        tmpModel[0] = "29";
        this.model = tmpModel.join(this.separator);
      }
      if (
        tmpModel[1] != null &&
        tmpModel[1] != "" &&
        tmpModel[1] == "02" &&
        tmpModel[2] != "" &&
        tmpModel[2] != null &&
        tmpModel[2].length == 4 &&
        Number(tmpModel[0]) > 28 &&
        !this.isLeap(Number(tmpModel[2]))
      ) {
        tmpModel[0] = "28";
        this.model = tmpModel.join(this.separator);
      }
      if (
        tmpModel[1] != null &&
        (Number(tmpModel[1]) > 12 || tmpModel[1].length > 2)
      ) {
        tmpModel[1] = "12";
        this.model = tmpModel.join(this.separator);
      }
      if (tmpModel[1] == "00") {
        tmpModel[1] = "01";
        this.model = tmpModel.join(this.separator);
      }
      if (tmpModel[0] == "00") {
        tmpModel[0] = "01";
        this.model = tmpModel.join(this.separator);
      }
      var tmpFormat = this.format.split(this.separator);
      if (
        (tmpFormat[0] == "DD" || tmpFormat[0] == "MM") &&
        tmpModel[0].length < 2 &&
        event.key == "."
      ) {
        tmpModel[0] = "0" + tmpModel[0];
        this.model = tmpModel.join(this.separator);
      }

      if (
        (tmpFormat[1] == "DD" || tmpFormat[1] == "MM") &&
        tmpModel[1] != "" &&
        tmpModel[1] != null &&
        tmpModel[1].length < 2 &&
        event.key == "."
      ) {
        tmpModel[1] = "0" + tmpModel[1];
        this.model = tmpModel.join(this.separator);
      }
      if (
        tmpFormat[2] == "YYYY" &&
        tmpModel[2] != "" &&
        tmpModel[2] != null &&
        tmpModel[2].length < 4 &&
        event.key == "."
      ) {
        tmpModel[2] += "0";
        if (tmpModel[2].length < 4) tmpModel[2] += "0";
        if (tmpModel[2].length < 4) tmpModel[2] += "0";
        this.model = tmpModel.join(this.separator);
      }
      if (
        tmpModel[2] != null &&
        tmpModel[2].length == 4 &&
        Number(tmpModel[2]) < 1900
      ) {
        tmpModel[2] = "1900";
        this.model = tmpModel.join(this.separator);
      }
      if (
        tmpModel[2] != null &&
        tmpModel[2].length == 4 &&
        Number(tmpModel[2]) > 3000
      ) {
        tmpModel[2] = "3000";
        this.model = tmpModel.join(this.separator);
      }
      if (isValidDate && this.model.length == this.format.length) {
        this.date = moment(this.model, this.format);
        this.selectedDay = this.date.format("DD");
        this.first_day_of_week =
          this.date.startOf("month").day() > 0
            ? this.first_day_of_week--
            : (this.first_day_of_week = 6);
        this.init();
        this.$emit("input", moment(this.model, this.format).format());

        return;
      }
      this.$emit("input", null);
    },
    value(newVal, oldVal) {
      if (newVal == null || newVal == "") {
        this.model = "";
        this.date = moment();
        this.selectedDay = this.date.format("DD");
      } else if (newVal.length == this.format.length) {
        this.model = newVal;
      } else if (newVal.length > this.format.length) {
        this.model = this.$moment(newVal).format("DD.MM.YYYY.");
      }
    },
  },
  data() {
    return {
      selectedDay: null,
      separator_positions: [],
      isInputDataDeleating: false,
      model: null,
      trayout: null,
      date: null,
      last_valid_date: null,
      last: 0,
      days: [],
      first_day_of_week: null,
      last_day_of_week: null,
      month: 0,
      showMonth: false,
      showCalendar: false,
      clicked: null,
      rndID: Math.random(),
    };
  },
  methods: {
    click() {
      this.showCalendar = this.showCalendar ? false : true;
    },
    isLeap(year) {
      if (year % 4 == 0 && (!(year % 100 == 0) || year % 400 == 0)) return true;
      else return false;
    },
    isDescendant(elem) {
      var parents = [];
      while (
        elem.parentNode &&
        elem.parentNode.nodeName.toLowerCase() != "body"
      ) {
        elem = elem.parentNode;
        if (elem == document.getElementById(this.rndID)) {
          return true;
        }
      }
      return false;
    },
    deleteData() {
      this.model = "";
      this.isInputDataDeleating = true;
    },
    clickShowCalendar() {
      if (this.showCalendar) {
        this.showCalendar = false;
        this.showMonth = false;
      } else {
        this.showCalendar = true;
      }
    },
    locations(substring, string) {
      var a = [],
        i = -1;
      while ((i = string.indexOf(substring, i + 1)) >= 0) a.push(i);
      return a;
    },
    clickDay(day) {
      this.selectedDay = day;
      this.setDate(this.date.format("YYYY"), this.date.format("MM") - 1, day);
      this.showCalendar = false;
    },
    changeMonth: function(month, year) {
      this.date = this.date.set("month", month);
      this.date = this.date.set("year", year);
      this.selectedDay = 1;
      this.init();
      this.showMonth = false;
      this.showDay = true;
    },
    clickShowMount() {
      if (!this.showMonth) {
        this.showMonth = true;
        this.showDay = false;
      } else {
        this.showMonth = false;
        this.showDay = true;
      }
    },
    nextMonth() {
      this.date = this.date.add(1, "M");
      this.init();
      this.$forceUpdate();
    },
    prevMonth() {
      this.date = this.date.add(-1, "M");
      this.init();
      this.$forceUpdate();
    },
    init() {
      this.last = parseInt(this.date.endOf("month").format("DD"));
      this.first_day_of_week =
        this.date.startOf("month").day() == 0
          ? this.date.startOf("month").day()
          : this.date.startOf("month").day() - 1;
      this.last_day_of_week = 6 - (this.date.endOf("month").day() - 1);

      if (this.last_day_of_week == 7) {
        this.last_day_of_week = 0;
      }
    },
    setDate(y, m, d) {
      this.date = this.date.set("year", y);
      this.date = this.date.set("month", m);
      this.date = this.date.set("date", d);
      this.model = this.date.format(this.format);
      this.date = moment(this.model, this.format);
      this.first_day_of_week =
        this.date.startOf("month").day() == 0
          ? this.date.startOf("month").day()
          : this.date.startOf("month").day() - 1;
      this.last_day_of_week = 6 - (this.date.endOf("month").day() - 1);
      this.$emit("input", moment(this.model, this.format).format());

      if (this.last_day_of_week == 7) {
        this.last_day_of_week = 0;
      }
    },
  },

  created() {
    this.separator_positions = this.locations(this.separator, this.format);

    moment.locale(this.locale, {
      week: {
        dow: this.startOfWeek,
      },
    });
    moment.locale(this.locale);
    this.date = moment();
    this.selectedDay = this.date.format("DD");
    this.days = moment.weekdays(true);
    if (this.initDate) {
      this.model = this.date.format(this.format);
    }
    this.init();
  },
  mounted() {
    var $this = this;
    document.body.addEventListener("click", function(e) {
      $this.trayout = document.getElementById(this.rndID);
      var hasParent = $this.isDescendant(e.target);
      if (!hasParent) {
        $this.showCalendar = false;
        $this.showMonth = false;
      }
    });
    document
      .getElementById("date-time-picker-id")
      .addEventListener("keydown", function(ev) {
        var key;
        ev = ev || event;
        key = ev.keyCode;
        // if (key == 37 || key == 38 || key == 39 || key == 40 || key == 32) {
        //   ev.cancelBubble = true;
        //   ev.returnValue = false;
        // }
        if (key < 96 || key > 105) {
          if (key == 110 || key == 190) {
            return;
          }
          if (key < 48 || key > 57) {
            ev.cancelBubble = true;
            ev.returnValue = false;
          }
        }
      });

    if (this.value != null) {
      this.model = this.$moment(this.value).format("DD.MM.YYYY.");
    }
  },
};
</script>
<style>
:root {
  --datepicker-backgorund: whitesmoke;
  --datepicker-text-color: black;
  --datepicker-butotns-color: green;
  --datepicker-border-color: whitesmoke;
  --datepicker-hover-color: whitesmoke;
  --datepicker-calendar-header-background: white;
  --datepicker-selected-date: lightblue;
  --datepicker-calendar-header-bottom-border: rgb(209, 209, 209);
  --datepicker-set-column-width: 30px;
  --datepicker-main-shadow: 0px 1px 1px -5px var(--datepicker-text-color);
  --datepicker-shadow-box-on-date-hover: 0px 0px 2px 0px
    var(--datepicker-text-color);
}
</style>
<style scoped>
.btn-delete {
  position: absolute;
  top: 10px;
  right: 6px;
  font-weight: unset;
  color: var(--datepicker-butotns-color, --datepicker-text-color);
  cursor: pointer;
}
.btn-delete::before {
  content: "";
}
div,
button {
  cursor: pointer;
}
button {
  background: transparent;
  border: none;
  padding: 10px 0px;
  font-size: 1.2em;
}

.datepicker-container {
  position: relative;
  width: max-content;
  color: var(--datepicker-text-color);
  width: 100%;
}
.datepicker-date {
  right: 0;
  z-index: 9998;
  position: absolute;
  box-shadow: var(--datepicker-main-shadow);
  background: var(--datepicker-backgorund);
}
.datepicker-date .calendar-header {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
}
.datepicker-date .calendar-header > div,
.datepicker-date .calendar-header > button {
  background: var(--datepicker-calendar-header-background);
  color: var(--datepicker-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.datepicker-date .calendar-header > button {
  color: var(--datepicker-butotns-color, --datepicker-text-color);
}
.calendar {
  display: grid;
  grid-column: 1/4;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  text-align: center;
}
.input-wrapper-class {
  width: 100%;
  display: flex;
}
.input-class {
  width: 100%;
  border: 1px solid black;
  padding: 10px;
}
.calendar div.calendar-header {
  background: var(--datepicker-backgorund);
  border-bottom-color: var(
    --datepicker-calendar-header-bottom-border
  ) !important;
}
.calendar:not(.month):not(.year) div:not(.empty) {
  border: 1px solid var(--datepicker-border-color);
  padding: 5px;
  width: var(--datepicker-set-column-width);
  z-index: 19;
  font-size: 0.9em;
}
.calendar:not(.month):not(.year) div:not(.empty):not(.calendar-header) {
  background: white;
}

.calendar div:not(.empty):not(.calendar-header):hover {
  z-index: 9999;
  box-shadow: var(--datepicker-shadow-box-on-date-hover);
}
.selected-day {
  background-color: var(--datepicker-selected-date) !important;
}
</style>
