<template>
  <div>
    <apexchart
      id="statisticBarChart"
      v-if="renderChart"
      type="bar"
      width="100%"
      height="400"
      :options="chartOptions"
      :series="series"
      ref="barChart"
      :key="mountedTrigger"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import Vue from "vue";
export default {
  components: {
    apexchart: VueApexCharts
  },
  props: ["data"],
  data() {
    return {
      mountedTrigger: 0,
      renderChart: false,
      lastZoom: null,
      series: [],
      chartOptions: {
        legend: {
          position: "bottom",
          horizontalAlign: "center"
        },
        chart: {
          width: 200,
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          },
          zoom: {
            enabled: false
          }
        },
        tooltip: {
          shared: true,
          intersect: false
        },
        dataLabels: {
          dropShadow: {
            enabled: true,
            left: 1,
            top: 1,
            opacity: 0.4
          }
        },
        labels: [],
        xaxis: {
          tickAmount: 8,
          tickPlacement: "between",
          labels: {
            show: true,
            rotate: 0,
            minHeight: 80,
            rotateAlways: false,
            align: "left",
            style: {
              fontSize: "10px",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label"
            }
          }
        },
        zoom: {
          enabled: false
        }
      }
    };
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(val) {
        this.chartOptions.labels = this.data.map((e) =>
          e.organizationName.length > 30
            ? e.organizationName.split(" ")
            : e.organizationName
        );
        const notStarted = this.data.map((e) => e.notStarted);
        const inProgress = this.data.map((e) => e.inProgress);
        const implemented = this.data.map((e) => e.implemented);
        this.series = [
          {
            name: this.$lang("lap.statistic_totalNotStarted"),
            data: notStarted,
            color: "#CE0000"
          },
          {
            name: this.$lang("lap.statistic_totalInProgress"),
            data: inProgress,
            color: "#FF9F05"
          },
          {
            name: this.$lang("lap.statistic_totalImplemented"),
            data: implemented,
            color: "#86C232"
          }
        ];

        this.renderChart = true;
        this.mountedTrigger++;
      }
    }
  }
};
</script>

<style lang="scss">
@media screen and (max-width: 1400px) {
  #statisticBarChart tspan {
    display: none !important;
  }
}
</style>
