<template>
  <div notify-content>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
      <div v-if="success !== undefined" class="notify-content success">
        <i class="icon-success"></i>
        <span v-lang="success"></span>
        <i class="icon-cross" v-on:click="SUCCESS_MESSAGE()"></i>
      </div>
    </transition>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
      <div v-if="error !== undefined" class="notify-content error">
        <i class="icon-error"></i>
        <span v-lang="error"></span>
        <i class="icon-cross" v-on:click="ERROR_MESSAGE()"></i>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      success_a: undefined,
      error_a: undefined
    };
  },
  created() {
    this.success_a = this.$session.flash.get("success");
    this.error_a = this.$session.flash.get("error");
    this.$store.commit("SUCCESS_MESSAGE", this.success_a);
    this.$store.commit("ERROR_MESSAGE", this.error_a);
  },
  computed: {
    ...mapState(["success", "error"])
  },
  methods: {
    ERROR_MESSAGE() {
      this.$store.commit("ERROR_MESSAGE", undefined);
    },
    SUCCESS_MESSAGE() {
      this.$store.commit("SUCCESS_MESSAGE", undefined);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";
[notify-content] {
  .notify-content {
    margin: 5px 0;
    padding: 10px;
    display: flex;
    align-items: center;
    i {
      cursor: pointer;
      padding: 2px;
    }
  }
  .success {
    background: rgba($darkPrimaryColor, 0.3);
    color: $darkPrimaryColor;
    i {
      color: $white;
      background: rgba($darkPrimaryColor, 0.7);
      // border: 1px solid rgba($darkPrimaryColor, 0.7);
      border-radius: 2px;
      margin-left: auto;
    }
    .icon-success {
      display: flex;
      margin-left: 5px;
      margin-right: 5px;
      font-size: 1.5em;
      align-content: center;
      color: $darkPrimaryColor;
      background: rgba($darkPrimaryColor, 0);
      border: none;
    }
  }
  .error {
    background: rgba($errorColor, 0.3);
    i {
      color: $white;
      background: rgba($errorColor, 0.7);
      // border: 1px solid rgba($errorColor, 0.7);
      border-radius: 2px;
      margin-left: auto;
    }
    .icon-error {
      display: flex;
      margin-left: 5px;
      margin-right: 5px;
      font-size: 1.5em;
      align-content: center;
      color: $errorColor;
      background: rgba($errorColor, 0);
      border: none;
    }
    .icon-cross {
      background-color: none;
    }
  }
}
</style>
