<template>
  <form id="user_form" v-on:submit.prevent="save()" data-vv-scope="user_form">
    <fieldset>
      <legend v-lang="'user.personal_data'"></legend>
      <div class="form-inputs">
        <div
          class="input m-0"
          :class="
            errors.first('user_form.firstname') !== undefined
              ? 'input-error'
              : ''
          "
        >
          <label for="firstname">
            <span v-lang="'user.label_first_name'"></span>
            <span class="required-field-star">&#x2605;</span>
          </label>
          <input
            v-validate="'required'"
            class="m-0"
            v-model="model.firstName"
            type="text"
            name="firstname"
            autocomplete="off"
          />
          <div class="error" v-html="errors.first('user_form.firstname')"></div>
        </div>
        <div
          class="input m-0"
          :class="
            errors.first('user_form.lastname') !== undefined
              ? 'input-error'
              : ''
          "
        >
          <label for="lastname">
            <span v-lang="'user.label_last_name'"></span>
            <span class="required-field-star">&#x2605;</span>
          </label>
          <input
            v-validate="'required'"
            class="m-0"
            type="text"
            v-model="model.lastName"
            name="lastname"
            autocomplete="off"
          />
          <div class="error" v-html="errors.first('user_form.lastname')"></div>
        </div>

        <div
          class="input m-0"
          :class="
            errors.first('user_form.email') !== undefined ? 'input-error' : ''
          "
        >
          <label for="email" v-lang="'user.label_email'"></label>
          <input
            v-validate="'email'"
            class="m-0"
            type="text"
            v-model="model.email"
            name="email"
            autocomplete="off"
          />
          <div class="error" v-html="errors.first('user_form.email')"></div>
        </div>
        <div class="input m-0">
          <label for="phone" v-lang="'user.phone'"></label>
          <input
            class="m-0"
            type="text"
            v-model="model.phone"
            name="phone"
            autocomplete="off"
          />
        </div>
        <div class="input m-0">
          <label for="lang" v-lang="'user.language'"></label>
          <select name="lang" id="lang" v-model="model.fkLanguageId">
            <option
              v-for="(obj, key) in lanuguages"
              :key="key"
              :value="obj.pkLanguageId"
              >{{ obj.name }}</option
            >
          </select>
        </div>
      </div>
    </fieldset>

    <fieldset id="avatar">
      <legend v-lang="'general.label_avatar'"></legend>
      <div class="text-center avatar_field">
        <img
          id="avatarImg"
          :src="
            api +
              'api/users/avatars?username=' +
              model.username +
              '&nocache=' +
              new Date()
          "
          :key="aKey"
        />
        <br />
        <button
          class="small primary"
          id="pick-avatar"
          v-lang="'user.select_avatar_image'"
        ></button>
        <button
          id="removeAvatarBtn"
          v-if="avatarExist"
          class="small error"
          v-lang="'user.remove_avatar'"
          v-on:click="removeAvatar($event)"
        ></button>
        <avatar-cropper @submitImg="submitedImg" trigger="#pick-avatar" />
      </div>
    </fieldset>
    <div class="submit">
      <button class="primary" v-lang="'general.button_submit'"></button>
    </div>
  </form>
</template>

<script>
import Vue from "vue";
import { lang } from "@/global-functions";
import VeeValidate from "vee-validate";
import AvatarCropper from "../../../General/AvatarCropper";
import mainstore from "@/store";
import { mapState } from "vuex";
import store from "../../store";

Vue.use(VeeValidate);
export default {
  components: { AvatarCropper },
  props: {
    errorModel: {
      key: "",
      message: ""
    }
  },
  data() {
    return {
      userData: null,
      model: {
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        password: "",
        superAdmin: null,
        fkLanguageId: null
      },
      lanuguages: [],
      imgBlob: {},
      avatarExist: undefined,
      aKey: 0,
      deleteAvatar: false,
      validation_messages: {
        custom: {
          firstname: {
            required: lang("validation.required")
          },
          lastname: {
            required: lang("validation.required")
          },
          username: {
            required: lang("validation.required")
          },
          email: {
            required: lang("validation.required"),
            email: lang("validation.email")
          },
          password: {
            required: lang("validation.required")
          },
          confirm_password: {
            required: lang("validation.required"),
            confirmed: lang("user.passwords_dont_match")
          }
        }
      }
    };
  },
  watch: {
    userData() {
      this.model = this.userData;
    },
    errorModel() {
      this.$importModelStateErrors(
        this.errors,
        this.errorModel.modelState,
        "user_form"
      );
    }
  },
  created() {
    this.userData = JSON.parse(JSON.stringify(this.user.user));
    this.isAvatarExist();
    this.getAllLanguages();
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  computed: {
    ...mapState(["api", "user"])
  },
  methods: {
    save() {
      //this.$emit("uploadAvatar", this.imgBlob);
      this.$validator.validateAll("user_form").then((valid) => {
        if (valid) {
          //formData.append("avatar", this.imgBlob, "avatar");*/
          this.$emit("save", this.model, this.imgBlob, this.deleteAvatar);
        }
      });
    },
    submitedImg(blob) {
      this.imgBlob = blob;
      var avatarImg = document.getElementById("avatarImg");
      let reader = new FileReader();
      reader.readAsDataURL(blob.slice());
      reader.onload = function() {
        avatarImg.setAttribute("src", reader.result);
      };
    },
    isAvatarExist() {
      var $this = this;

      if ($this.avatarExist == undefined) {
        store
          .dispatch("CHECK_AVATAR_EXIST", $this.userData.username)
          .then((response) => {
            $this.avatarExist = response.data;
          })
          .catch((error) => {});
      }
    },
    removeAvatar(e) {
      this.deleteAvatar = true;

      var avatarImg = document.getElementById("avatarImg");
      avatarImg.setAttribute("src", "/default_avatar.png");

      var removeAvatarBtn = document.getElementById("removeAvatarBtn");
      e.preventDefault();
      e.stopPropagation();

      setTimeout(function() {
        removeAvatarBtn.style.display = "none";
        this.avatarKey += 1;
      }, 500);
    },
    getAllLanguages() {
      var $this = this;
      store
        .dispatch("GET_ALL_LANGUAGES")
        .then((response) => {
          $this.lanuguages = response.data.list;
        })
        .catch((error) => {});
    }
  }
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .form-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 18px;
  }
  & > div {
    // display: flex;
    // flex: 1;
    // align-items: center;
    justify-content: center;
  }

  .submit {
    color: red;
    grid-column: 1/3;
  }
}

label {
  input {
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
  }
}

.avatar_field {
  margin-top: 20px;
  text-align: center;
  grid-area: 1 / span 2;

  img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }
}
#avatar {
  height: 220.3px;
}
</style>
