import Vue from "vue";
import Vuex from "vuex";
import Store from "../../../store";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    GET_REPORT_DATA: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/Report/GetEmmausReport", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
  },
});
