import Login from "./User/Login/Login.vue";
import UserIndex from "./User/Register/Index.vue";
import ForegetPassword from "./User/Login/ForegetPassword.vue";
import ResetPassword from "./User/Login/ResetPassword.vue";
import RequiredPasswordChange from "./User/Login/RequiredPasswordChange.vue";
import RolesIndex from "./Roles/Index.vue";
import RoleEditCreate from "./Roles/EditCreate.vue";
import RoleDetails from "./Roles/Details.vue";
import PermissionsForRole from "./Roles/PermissionsForRole.vue";
import PermissionsForUser from "./User/Register/PermissionsForUser.vue";
import AdminLayout from "./AdminPanel/AdminLayout.vue";
import UserCreate from "./User/Register/Create.vue";
import UserEdit from "./User/Register/Edit.vue";
import SetPassword from "./User/Register/SetPassword.vue";
import EditAccount from "./User/EditAccount/EditAccount.vue";
import ChangePassword from "./User/EditAccount/ChangePassword.vue";
import UserDetails from "./User/Register/Details.vue";
import ShowError from "../General/ShowError.vue";
import CodesForm from "@/components/UserAndRolesManagement/TypeOfCodes/Form.vue";
import ShowTypeOfCode from "@/components/UserAndRolesManagement/TypeOfCodes/Details.vue";
import TypesOfCodesIndex from "@/components/UserAndRolesManagement/TypeOfCodes/Index.vue";
import ValuesForm from "@/components/UserAndRolesManagement/TypeOfCodes/Codes/Form.vue";
import AdminHome from "@/components/UserAndRolesManagement/AdminPanel/Home.vue";
import ActionLogs from "@/components/UserAndRolesManagement/Logs/ActionLogs/Index.vue";
import ErrorLogs from "@/components/UserAndRolesManagement/Logs/ErrorLogs/Index.vue";
import DataLogs from "@/components/UserAndRolesManagement/Logs/DataLogs/Index.vue";
import UserLogs from "@/components/UserAndRolesManagement/Logs/UserLogs/Index.vue";
import ActionLogDetails from "@/components/UserAndRolesManagement/Logs/ActionLogs/Details.vue";
import DataLogDetails from "@/components/UserAndRolesManagement/Logs/DataLogs/Details.vue";
import ErrorLogDetails from "@/components/UserAndRolesManagement/Logs/ErrorLogs/Details.vue";
import UserLogDetails from "@/components/UserAndRolesManagement/Logs/UserLogs/Details.vue";

import Action from "./Action/ListOfActions/Index.vue";
import auth from "@/middleware";
import ActionCreate from "./Action/ListOfActions/Create.vue";
import ActionDetails from "./Action/ListOfActions/Details.vue";
import EditAction from "./Action/ListOfActions/Edit.vue";
import Functions from "./Action/Functions/Index.vue";
import FunctionDetails from "./Action/Functions/Details.vue";
import FunctionCreate from "./Action/Functions/Create.vue";
import FunctionEdit from "./Action/Functions/Edit.vue";
import ActionGroups from "./Action/ActionGroups/Index.vue";
import ActionGroupCreate from "./Action/ActionGroups/Create.vue";
import ActionGroupEdit from "./Action/ActionGroups/Edit.vue";
import ActionGroupDetails from "./Action/ActionGroups/Details.vue";
import StatusMessagesDetails from "./Action/StatusMessages/Details.vue";
import StatusMessages from "./Action/StatusMessages/Index.vue";
import Queries from "./Queries/Index.vue";
import QueriesCreate from "./Queries/Create.vue";
import QueriesDetails from "./Queries/Details.vue";
import QueriesEdit from "./Queries/Edit.vue";

import StatusMessagesCreate from "./Action/StatusMessages/Create.vue";
import StatusMessagesEdit from "./Action/StatusMessages/Edit.vue";

import LanguagesIndex from "./Languages/Index.vue";
import LanguagesCreate from "./Languages/Create.vue";
import LanguagesDetails from "./Languages/Details.vue";

import TranslationIndex from "./Translation/Index.vue";
import TranslationCreate from "./Translation/Create.vue";

import Settings from "@/components/UserAndRolesManagement/Settings/SettingsLayout.vue";

import Vue from "vue";

const router = [
  {
    path: "/home",
    name: "AdminHome",
    component: AdminHome,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/settings",
    name: "SettingsForm",
    component: Settings
  },
  {
    path: "/show-error",
    name: "ShowError",
    component: ShowError
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },

  {
    path: "/foreget-password",
    name: "ForegetPassword",
    component: ForegetPassword
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword
  },
  {
    path: "/reqired-password-change",
    name: "RequiredPasswordChange",
    component: RequiredPasswordChange
  },
  {
    path: "/admin",
    name: "AdminLayout",
    component: AdminLayout,
    meta: {
      middleware: auth
    },
    children: [
      {
        path: "action",
        component: AdminLayout,
        children: [
          {
            path: "index",
            name: "ActionIndex",
            component: Action,
            meta: {
              middleware: auth
            }
          },
          {
            path: "create",
            name: "ActionCreate",
            component: ActionCreate,
            meta: {
              middleware: auth
            }
          },
          {
            path: "details",
            name: "ActionDetails",
            component: ActionDetails,
            meta: {
              middleware: auth
            }
          },
          {
            path: "edit",
            name: "EditAction",
            component: EditAction,
            meta: {
              middleware: auth
            }
          }
        ]
      },
      {
        path: "Functions",
        component: AdminLayout,
        children: [
          {
            path: "index",
            name: "Functions",
            component: Functions,
            meta: {
              middleware: auth
            }
          },
          {
            path: "details/:id",
            name: "FunctionDetails",
            component: FunctionDetails,
            meta: {
              middleware: auth
            }
          },
          {
            path: "create",
            name: "FunctionCreate",
            component: FunctionCreate,
            meta: {
              middleware: auth
            }
          },
          {
            path: "edit/:id",
            name: "FunctionEdit",
            component: FunctionEdit,
            meta: {}
          }
        ]
      },
      {
        path: "ActionGroups",
        component: AdminLayout,
        children: [
          {
            path: "index",
            name: "ActionGroups",
            component: ActionGroups,
            meta: {
              middleware: auth
            }
          },
          {
            path: "create",
            name: "ActionGroupCreate",
            component: ActionGroupCreate,
            meta: {
              middleware: auth
            }
          },
          {
            path: "edit",
            name: "ActionGroupEdit",
            component: ActionGroupEdit,
            meta: {
              middleware: auth
            }
          },
          {
            path: "details/:id",
            name: "ActionGroupDetails",
            component: ActionGroupDetails,
            meta: {
              middleware: auth
            }
          }
        ]
      },
      {
        path: "queries",
        component: AdminLayout,
        children: [
          {
            path: "",
            name: "Queries",
            component: Queries,
            meta: {
              middleware: auth
            }
          },
          {
            path: "create",
            name: "QueriesCreate",
            component: QueriesCreate,
            meta: {
              middleware: auth
            }
          },
          {
            path: "edit",
            name: "QueriesEdit",
            component: QueriesEdit,
            meta: {
              middleware: auth
            }
          },
          {
            path: "details",
            name: "QueriesDetails",
            component: QueriesDetails,
            meta: {
              middleware: auth
            }
          }
        ]
      },
      {
        path: "StatusMessages",
        component: AdminLayout,
        children: [
          {
            path: "",
            name: "StatusMessagesDetails",
            component: StatusMessagesDetails,
            meta: {
              middleware: auth
            }
          },
          {
            path: "index",
            name: "StatusMessages",
            component: StatusMessages,
            meta: {
              middleware: auth
            }
          },
          {
            path: "create",
            name: "StatusMessagesCreate",
            component: StatusMessagesCreate,
            meta: {
              middleware: auth
            },
            props: true
          },
          {
            path: "edit",
            name: "StatusMessagesEdit",
            component: StatusMessagesEdit,
            meta: {
              middleware: auth
            },
            props: true
          }
        ]
      },
      {
        path: "roles",
        component: AdminLayout,
        children: [
          {
            path: "index",
            name: "RolesIndex",
            component: RolesIndex,
            meta: {
              middleware: auth
            }
          },
          {
            path: "details/:id",
            name: "RoleDetails",
            component: RoleDetails,
            meta: {
              middleware: auth
            }
          },
          {
            path: "form/:id?",
            name: "RoleEditCreate",
            component: RoleEditCreate,
            meta: {
              middleware: auth
            }
          },
          {
            path: "permissions/:id",
            name: "PermissionsForRole",
            component: PermissionsForRole,
            meta: {
              middleware: auth
            }
          }
        ]
      },
      {
        path: "user",
        component: AdminLayout,
        children: [
          {
            path: "index",
            name: "UserIndex",
            component: UserIndex,
            meta: {
              middleware: auth
            }
          },
          {
            path: "create",
            name: "UserCreate",
            component: UserCreate,
            meta: {
              middleware: auth
            }
          },
          {
            path: "edit/:id",
            name: "UserEdit",
            component: UserEdit,
            meta: {
              middleware: auth
            }
          },
          {
            path: "setpassword/:id",
            name: "SetPassword",
            component: SetPassword,
            meta: {
              middleware: auth
            }
          },
          {
            path: "details/:id",
            name: "UserDetails",
            component: UserDetails,
            meta: {
              middleware: auth
            }
          },
          {
            path: "editaccount",
            name: "EditAccount",
            component: EditAccount,
            meta: {
              middleware: auth
            }
          },
          {
            path: "changepassword",
            name: "ChangePassword",
            component: ChangePassword,
            meta: {
              middleware: auth
            }
          },
          {
            path: "permissions/:id",
            name: "PermissionsForUser",
            component: PermissionsForUser,
            meta: {
              middleware: auth
            }
          }
        ]
      },
      {
        path: "codes",
        component: AdminLayout,
        children: [
          {
            path: "index",
            name: "TypesOfCodesIndex",
            component: TypesOfCodesIndex,
            meta: {
              middleware: auth
            }
          },
          {
            path: "form/:id",
            name: "CodesForm",
            component: CodesForm,
            meta: {
              middleware: auth
            }
          },
          {
            path: "show/:id",
            name: "ShowTypeOfCode",
            component: ShowTypeOfCode,
            meta: {
              middleware: auth
            }
          },
          {
            path: "values/:id",
            name: "ValuesForm",
            component: ValuesForm,
            meta: {
              middleware: auth
            }
          }
        ]
      },
      {
        path: "logs",
        component: AdminLayout,
        children: [
          {
            path: "action",
            name: "ActionLogsIndex",
            component: ActionLogs,
            meta: {
              middleware: auth
            }
          },
          {
            path: "action/details/:id",
            name: "ActionLogDetails",
            component: ActionLogDetails,
            meta: {
              middleware: auth
            }
          },
          {
            path: "data",
            name: "DataLogsIndex",
            component: DataLogs,
            meta: {
              middleware: auth
            }
          },
          {
            path: "data/details/:id",
            name: "DataLogDetails",
            component: DataLogDetails,
            meta: {
              middleware: auth
            }
          },
          {
            path: "error",
            name: "ErrorLogsIndex",
            component: ErrorLogs,
            meta: {
              middleware: auth
            }
          },
          {
            path: "error/details/:id",
            name: "ErrorLogDetails",
            component: ErrorLogDetails,
            meta: {
              middleware: auth
            }
          },
          {
            path: "user",
            name: "UserLogsIndex",
            component: UserLogs,
            meta: {
              middleware: auth
            }
          },
          {
            path: "user/details/:id",
            name: "UserLogDetails",
            component: UserLogDetails,
            meta: {
              middleware: auth
            }
          }
        ]
      },
      {
        path: "languages",
        component: AdminLayout,
        children: [
          {
            path: "index",
            name: "LanguagesIndex",
            component: LanguagesIndex,
            meta: {
              middleware: auth
            }
          },
          {
            path: "create/:id?",
            name: "LanguagesCreate",
            component: LanguagesCreate,
            meta: {
              middleware: auth
            }
          },
          {
            path: "details/:id",
            name: "LanguagesDetails",
            component: LanguagesDetails,
            meta: {
              middleware: auth
            }
          }
        ]
      },
      {
        path: "translations",
        component: AdminLayout,
        children: [
          {
            path: "index",
            name: "TranslationIndex",
            component: TranslationIndex,
            meta: {
              middleware: auth
            }
          },
          {
            path: "create/:id?",
            name: "TranslationCreate",
            component: TranslationCreate,
            meta: {
              middleware: auth
            }
          }
        ]
      }
    ]
  }
];

export default router;
