import Vue from "vue";
import Vuex from "vuex";
import Store from "../../../store";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
    // SET_SELECTED_YEAR: function(state: any, pkSelectedYearId: any) {
    //   state.pkSelectedYearId = pkSelectedYearId;
    // },
  },
  actions: {
    GET_ALL_REPORT_YEARS_WITH_PAGINATION: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .post("/reportyear/get-all-with-pagination", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    UPDATE_STATUS: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .put("/reportyear/update-status-institutions", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_REPORT_YEARS: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .get("/reportyear/get-all")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
  },
});
