import Vue from "vue";
import Vuex from "vuex";
import Store from "../../../store";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    CREATE_EMMAUS_INSTITUTION: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/EmmausInstitution", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_EMMAUS_INSTITUTIONS: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/EmmausInstitution")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },

    SEARCH_EMMAUS_INSTITUTIONS: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/EmmausInstitution/Search", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_EMMAUS_INSTITUTION_BY_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/EmmausInstitution/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    EDIT_EMMAUS_INSTITUTION: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .put("/EmmausInstitution", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    DELETE_EMMAUS_INSTITUTION: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .delete("/EmmausInstitution/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    }
  }
});
