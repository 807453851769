<template>
  <form id="user_form" v-on:submit.prevent="save()" data-vv-scope="user_form">
    <fieldset>
      <legend v-lang="'user.personal_data'"></legend>
      <div
        class="input m-0"
        :class="
          errors.first('user_form.firstname') !== undefined ? 'input-error' : ''
        "
      >
        <label for="firstname">
          <span v-lang="'user.label_first_name'"></span>
          <span class="required-field-star">&#x2605;</span>
        </label>
        <input
          v-validate="'required'"
          class="m-0"
          v-model="model.firstName"
          type="text"
          name="firstname"
          autocomplete="off"
        />
        <div>
          <span
            class="error"
            v-html="errors.first('user_form.firstname')"
          ></span>
        </div>
      </div>
      <div
        class="input m-0"
        :class="
          errors.first('user_form.lastname') !== undefined ? 'input-error' : ''
        "
      >
        <label for="lastname">
          <span v-lang="'user.label_last_name'"></span>
          <span class="required-field-star">&#x2605;</span>
        </label>
        <input
          v-validate="'required'"
          class="m-0"
          type="text"
          v-model="model.lastName"
          name="lastname"
          autocomplete="off"
        />
        <div>
          <span
            class="error"
            v-html="errors.first('user_form.lastname')"
          ></span>
        </div>
      </div>
      <div
        class="input m-0"
        :class="
          errors.first('user_form.username') !== undefined ? 'input-error' : ''
        "
      >
        <label for="username">
          <span for="username" v-lang="'user.username'"></span>
          <span class="required-field-star">&#x2605;</span>
        </label>
        <input
          v-validate="'required'"
          class="m-0"
          type="text"
          v-model="model.username"
          name="username"
          autocomplete="off"
        />
        <div>
          <span
            class="error"
            v-html="errors.first('user_form.username')"
          ></span>
        </div>
      </div>
      <div class="input m-0">
        <label for="phone">
          <span for="phone" v-lang="'user.phone'"></span>
        </label>
        <input
          class="m-0"
          type="text"
          v-model="model.phone"
          name="phone"
          autocomplete="off"
        />
      </div>
      <div
        class="input m-0"
        :class="
          errors.first('user_form.email') !== undefined ? 'input-error' : ''
        "
      >
        <label for="email">
          <span for="email" v-lang="'user.label_email'"></span>
          <span class="required-field-star">&#x2605;</span>
        </label>
        <input
          v-validate="'email|required'"
          class="m-0"
          type="text"
          v-model="model.email"
          name="email"
          autocomplete="off"
        />
        <div>
          <span class="error" v-html="errors.first('user_form.email')"></span>
        </div>
      </div>

      <div
        v-if="userData == null"
        class="input m-0"
        :class="
          errors.first('user_form.password') !== undefined ? 'input-error' : ''
        "
      >
        <label for="password">
          <span v-lang="'user.password'"></span>
          <span class="required-field-star">&#x2605;</span>
        </label>
        <input
          class="m-0"
          type="password"
          v-model="model.password"
          name="password"
          autocomplete="off"
          v-validate="'required'"
          ref="password"
        />
        <div>
          <span
            class="error"
            v-html="errors.first('user_form.password')"
          ></span>
        </div>
      </div>
      <div
        v-if="userData == null"
        class="input m-0"
        :class="
          errors.first('user_form.confirm_password') !== undefined
            ? 'input-error'
            : ''
        "
      >
        <label for="confirm_password">
          <span v-lang="'user.label_confirm_password'"></span>
          <span class="required-field-star">&#x2605;</span>
        </label>
        <input
          class="m-0"
          type="password"
          name="confirm_password"
          autocomplete="off"
          v-validate="'required|confirmed:password'"
          data-vv-as="password"
        />
        <div>
          <span
            class="error"
            v-html="errors.first('user_form.confirm_password')"
          ></span>
        </div>
      </div>

      <!-- INSTITUCIJA -->
      <div
        v-if="!$role(50004)"
        class="input m-0 m-top-10"
        :class="
          errors.first('user_form.organizationUnit') !== undefined
            ? 'input-error'
            : ''
        "
      >
        <label for="organizationUnit">
          <span v-lang="'user.label_organisation_unit'"></span>
          <span class="required-field-star">&#x2605;</span>
        </label>
        <select
          v-model="model.fkInstitutionId"
          v-validate="'required|excluded:0'"
          name="organizationUnit"
          id="dropdownOrgUnit"
        >
          <option
            value="0"
            selected
            v-lang="'user.placeholder_for_organisation_unit'"
          ></option>
          <option
            v-for="(obj, key) in allOrganisationUnits"
            :key="key"
            :value="obj.id"
          >
            {{ obj.name }}
          </option>
        </select>
        <div>
          <span
            class="error"
            v-html="errors.first('user_form.organizationUnit')"
          ></span>
        </div>
      </div>
      <!-- EMAUS ORGANIZACIJA -->
      <div
        v-if="model.fkInstitutionId == 100 || model.fkInstitutionId == 10101"
        class="input m-0 m-top-10"
        :class="
          errors.first('user_form.emausOrganisationUnit') !== undefined
            ? 'input-error'
            : ''
        "
      >
        <label for="emausOrganisationUnit">
          <span v-lang="organizationTitle" :key="organizationTitle"></span>
          <span class="required-field-star">&#x2605;</span>
        </label>
        <select
          v-model="model.fkEmmausOrganizationId"
          v-validate="'required|excluded:0'"
          name="emausOrganisationUnit"
          id="dropdownOrgUnit"
        >
          <option
            value="0"
            selected
            v-lang="
              model.fkInstitutionId == 10101
                ? 'general.placeholder_coordination_team'
                : 'user.placeholder_organization'
            "
            :key="organizationTitle"
          ></option>
          <option
            v-for="(obj, key) in filteredOrganization"
            :key="key"
            :value="obj.id"
          >
            {{ obj.name }}
          </option>
        </select>
        <div>
          <span
            class="error"
            v-html="errors.first('user_form.emausOrganisationUnit')"
          ></span>
        </div>
      </div>

      <fieldset
        v-if="model.fkInstitutionId == 100"
        class="fieldset user-type-fieldset"
        :class="
          errors.first('user_form.accessRights') !== undefined
            ? 'input-error'
            : ''
        "
      >
        <legend v-lang="'user.legend_user_type'"></legend>
        <div class="fieldset-container">
          <div class="input-checkbox">
            <label for="isSurveyFiller">
              <input
                class="m-0"
                type="checkbox"
                name="accessRights"
                v-model="model.isSurveyFiller"
                id="isSurveyFiller"
                autocomplete="off"
                v-validate="!model.isTrainingOragnizer ? 'required' : ''"
                hidden
              />
              <span v-lang="'user.survey'"></span>
            </label>
          </div>
          <div class="input-checkbox">
            <label for="isTrainingOrganizer">
              <input
                class="m-0"
                type="checkbox"
                v-model="model.isTrainingOragnizer"
                id="isTrainingOrganizer"
                name="accessRights"
                autocomplete="off"
                v-validate="!model.isSurveyFiller ? 'required' : ''"
                hidden
              />
              <span v-lang="'user.trainings'"></span>
            </label>
          </div>
          <div>
            <span
              class="error"
              v-html="errors.first('user_form.accessRights')"
            ></span>
          </div>
          <!-- <div class="input-checkbox">
            <label for="isPersonsAndVictims">
              <input
                class="m-0"
                type="checkbox"
                v-model="model.isPersonsAndVictims"
                id="isPersonsAndVictims"
                autocomplete="off"
                hidden
              />
              <span v-lang="'user.persons_and_victims'"></span>
            </label>
          </div> -->
        </div>
      </fieldset>

      <fieldset
        :key="model.fkInstitutionId"
        class="fieldset user-type-fieldset"
        v-if="
          model.fkInstitutionId &&
          model.fkInstitutionId != 100 &&
          model.fkInstitutionId != 10101
        "
      >
        <legend v-lang="'user.legend_user_type'"></legend>

        <div class="input-checkbox">
          <label for="isPersonsAndVictims">
            <input
              class="m-0"
              type="checkbox"
              id="isPersonsAndVictims"
              autocomplete="off"
              hidden
              checked
              disabled
            />
            <span v-lang="'user.persons_and_victims'"></span>
          </label>
        </div>
      </fieldset>
      <fieldset
        :key="model.fkInstitutionId"
        class="fieldset user-type-fieldset"
        v-if="model.fkInstitutionId && model.fkInstitutionId == 10101"
      >
        <legend v-lang="'user.legend_user_type'"></legend>

        <div class="input-checkbox">
          <label for="isTeamCoordinator">
            <input
              v-model="model.isTeamCoordinator"
              name="accessRights"
              class="m-0"
              type="checkbox"
              id="isTeamCoordinator"
              autocomplete="off"
              hidden
              checked
              v-validate="
                !model.isTeamCoordinator && !model.isPersonsAndVictims
                  ? 'required'
                  : ''
              "
            />
            <span v-lang="'user.team_coordinator'"></span>
          </label>

          <label for="isPersonsAndVictims">
            <input
              v-model="model.isPersonsAndVictims"
              name="accessRights"
              class="m-0"
              type="checkbox"
              id="isPersonsAndVictims"
              autocomplete="off"
              hidden
              checked
              v-validate="
                !model.isTeamCoordinator && !model.isPersonsAndVictims
                  ? 'required'
                  : ''
              "
            />
            <span v-lang="'user.persons_and_victims'"></span>
          </label>
        </div>
        <div>
          <span
            class="error"
            v-html="errors.first('user_form.accessRights')"
          ></span>
        </div>
      </fieldset>
    </fieldset>

    <fieldset v-if="usernameForAvatar != 'undefined'">
      <legend v-lang="'general.label_avatar'"></legend>
      <div class="text-center avatar_field">
        <img
          id="avatarImg"
          :src="
            api +
            'api/users/avatars?username=' +
            usernameForAvatar +
            '&nocache=' +
            new Date()
          "
        />
        <br />
        <!--<button class="small primary" id="pick-avatar" v-lang="'user.select_avatar_image'"></button>-->
        <button
          id="removeAvatarBtn"
          v-if="avatarExist"
          class="small error"
          v-lang="'user.remove_avatar'"
          v-on:click="removeAvatar($event)"
        ></button>
        <avatar-cropper
          @submitImg="submitedImg"
          trigger="#pick-avatar"
          upload-url="/files/upload"
        />
      </div>
    </fieldset>

    <div class="button-search">
      <Spinner
        :showSpinner="isSubmited"
        :buttonText="$lang('general.button_submit')"
        :buttonClass="'primary'"
      />
      <!-- <button class="primary" v-lang="'general.button_submit'"></button> -->
    </div>
  </form>
</template>

<script>
import Vue from "vue";
import { lang } from "@/global-functions";
import VeeValidate from "vee-validate";
import AvatarCropper from "../../../General/AvatarCropper";
import mainstore from "@/store";
import { mapState } from "vuex";
import store from "../../store";
import lapStore from "@/components/LAP/store.ts";

Vue.use(VeeValidate);

export default {
  components: { AvatarCropper },
  props: {
    userData: {
      default: null,
    },
    errorModel: {
      key: "",
      message: "",
    },
    isSubmited: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allOrganisationUnits: [],
      allEmausOrganisationUnits: [],
      allRegions: [],
      allAccessLevels: [],
      model: {
        firstName: "",
        lastName: "",
        username: "",
        phone: "",
        email: "",
        password: "",
        fkInstitutionId: this.$role(50004) ? 100 : 0,
        fkEmmausOrganizationId: 0,
        isSurveyFiller: false,
        isTrainingOragnizer: false,
        isPersonsAndVictims: false,
        isTeamCoordinator: false,
      },
      imgBlob: {},
      avatarExist: undefined,
      avatarSrc: "/default_avatar.png",
      deleteAvatar: false,
      validation_messages: {
        custom: {
          firstname: {
            required: lang("validation.required"),
          },
          lastname: {
            required: lang("validation.required"),
          },
          username: {
            required: lang("validation.required"),
          },
          email: {
            email: lang("validation.email"),
          },
          password: {
            required: lang("validation.required"),
          },
          confirm_password: {
            required: lang("validation.required"),
            confirmed: lang("user.passwords_dont_match"),
          },
          organizationUnit: {
            required: lang("validation.required"),
            excluded: lang("validation.required"),
          },
          accessLevel: {
            required: lang("validation.required"),
            excluded: lang("validation.required"),
          },
          emausOrganisationUnit: {
            required: lang("validation.required"),
            excluded: lang("validation.required"),
          },
          accessRights: {
            required: lang("validation.at_least_one"),
          },
        },
      },
    };
  },
  watch: {
    userData() {
      this.model = Object.assign({}, this.userData);
      this.isAvatarExist();
    },
    errorModel() {
      this.$importModelStateErrors(
        this.errors,
        this.errorModel.modelState,
        "user_form"
      );
    },
    "model.fkInstitutionId"() {
      if (this.model.fkInstitutionId == this.userData.fkInstitutionId) return;
      // if (this.model.fkInstitutionId != 100) {
      this.model.isSurveyFiller = false;
      this.model.isTrainingOragnizer = false;
      this.model.isPersonsAndVictims = false;
      this.model.isTeamCoordinator = false;
      // }
    },
  },
  created() {
    this.getAllOrganisationUnits();
    this.getAllEmausOrganisationUnits();
    this.getAllRegions();
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  computed: {
    ...mapState(["api"]),
    usernameForAvatar() {
      if (this.userData) return this.userData.username;
      else return "undefined";
    },
    filteredOrganization() {
      if (this.model.fkInstitutionId == 100)
        return this.allEmausOrganisationUnits;
      if (this.model.fkInstitutionId == 10101) return this.allRegions;
    },
    organizationTitle() {
      if (this.model.fkInstitutionId == 100) {
        return "general.organization";
      }
      if (this.model.fkInstitutionId == 10101)
        return "general.coordination_team";
    },
  },
  methods: {
    getAllOrganisationUnits() {
      var $this = this;
      store
        .dispatch("GET_ALL_ORGANISATION_UNITS")
        .then((response) => {
          $this.allOrganisationUnits = response.data.list;
        })
        .catch((error) => {});
    },
    getAllEmausOrganisationUnits() {
      var $this = this;
      store
        .dispatch("GET_ALL_EMAUS_ORGANISATION_UNITS")
        .then((response) => {
          $this.allEmausOrganisationUnits = response.data.list;
        })
        .catch((error) => {});
    },
    getAllRegions() {
      lapStore
        .dispatch("GET_REGIONS")
        .then((response) => {
          this.allRegions = response.data.list;
        })
        .catch((error) => {});
    },
    save() {
      //this.$emit("uploadAvatar", this.imgBlob);
      this.$validator.validateAll("user_form").then((valid) => {
        if (valid) {
          //formData.append("avatar", this.imgBlob, "avatar");*/
          this.$emit("save", this.model, this.imgBlob, this.deleteAvatar);
        }
      });
    },
    submitedImg(blob) {
      this.imgBlob = blob;
      var avatarImg = document.getElementById("avatarImg");
      let reader = new FileReader();
      reader.readAsDataURL(blob.slice());
      reader.onload = function () {
        avatarImg.setAttribute("src", reader.result);
      };
    },
    isAvatarExist() {
      var $this = this;

      if ($this.avatarExist == undefined) {
        store
          .dispatch("CHECK_AVATAR_EXIST", $this.userData.username)
          .then((response) => {
            $this.avatarExist = response.data;
          })
          .catch((error) => {});
      }
    },
    removeAvatar(e) {
      this.deleteAvatar = true;

      var avatarImg = document.getElementById("avatarImg");
      avatarImg.setAttribute("src", "/default_avatar.png");

      var removeAvatarBtn = document.getElementById("removeAvatarBtn");
      e.preventDefault();
      e.stopPropagation();

      setTimeout(function () {
        removeAvatarBtn.style.display = "none";
        this.avatarKey += 1;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .form-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
  }
  fieldset div {
    // display: flex;
    // flex: 1;
    // align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  fieldset {
    height: 100%;
    align-content: center;
  }

  .user-type-fieldset {
    margin-top: 3px;
    height: unset;
  }

  .button-search {
    margin-top: 10px;
    color: red;
    grid-column: 1/3;
  }
}

label {
  input {
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
  }
}

.avatar_field {
  text-align: center;
  grid-area: 1 / span 2;

  img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }
}
.input-error {
  border-color: #900000 !important;
}
.required:after {
  content: " *" !important;
  color: red !important;
}
</style>
