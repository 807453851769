<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-key"></i>
        <span v-lang="'user.change_password'"></span>
      </h1>
      <div class="actions">
        <button
          class="primary"
          v-on:click="$router.go(-1)"
          v-lang="'general.button_back'"
        ></button>
      </div>
    </div>
    <notify :key="notifyKey" />
    <div>
      <div class="card">
        <form v-on:submit.prevent="save()" data-vv-scope="password_form">
          <div
            class="input m-0"
            :class="
              errors.first('password_form.oldPassword') !== undefined
                ? 'input-error'
                : ''
            "
          >
            <label for="oldPassword">
              <span v-lang="'user.old_password'"></span>
              <span class="required-field-star">&#x2605;</span>
            </label>
            <input
              class="m-0"
              type="password"
              v-model="model.oldPassword"
              name="oldPassword"
              autocomplete="off"
              v-validate="'required'"
              ref="oldPassword"
            />
            <div
              class="error"
              v-html="errors.first('password_form.oldPassword')"
            ></div>
          </div>

          <div
            class="input m-0"
            :class="
              errors.first('password_form.newPassword') !== undefined
                ? 'input-error'
                : ''
            "
          >
            <label for="newPassword">
              <span v-lang="'user.new_password'"></span>
              <span class="required-field-star">&#x2605;</span>
            </label>
            <input
              class="m-0"
              type="password"
              v-model="model.newPassword"
              name="newPassword"
              autocomplete="off"
              v-validate="'required'"
              ref="newPassword"
            />
            <div
              class="error"
              v-html="errors.first('password_form.newPassword')"
            ></div>
          </div>

          <div
            class="input m-0"
            :class="
              errors.first('password_form.confirm_new_password') !== undefined
                ? 'input-error'
                : ''
            "
          >
            <label for="confirm_new_password">
              <span v-lang="'user.confirm_new_password'"></span>
              <span class="required-field-star">&#x2605;</span>
            </label>
            <input
              class="m-0"
              type="password"
              name="confirm_new_password"
              autocomplete="off"
              v-validate="'required|confirmed:newPassword'"
              ref="confirm_new_password"
            />
            <div
              class="error"
              v-html="errors.first('password_form.confirm_new_password')"
            ></div>
          </div>

          <div class="submit">
            <button class="primary" v-lang="'general.button_submit'"></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import VeeValidate from "vee-validate";
import store from "../../store";
export default {
  data() {
    return {
      model: {
        oldPassword: "",
        newPassword: ""
      },
      notifyKey: 0,
      validation_messages: {
        custom: {
          oldPassword: {
            required: this.$lang("validation.required")
          },
          newPassword: {
            required: this.$lang("validation.required")
          },
          confirm_new_password: {
            required: this.$lang("validation.required"),
            confirmed: this.$lang("user.passwords_dont_match")
          }
        }
      }
    };
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  methods: {
    save() {
      var $this = this;
      this.$validator.validateAll("password_form").then(valid => {
        if (valid) {
          store
            .dispatch("CHANGE_PASSWORD_SELF", $this.model)
            .then(response => {
              this.$success("user.password_successfully_changed");
              this.notifyKey += 1;
              this.$router.push({ name: "EditAccount" });
            })
            .catch(error => {
              if (error.status == 400)
                this.$importModelStateErrors(
                  this.errors,
                  error.data.modelState,
                  "password_form"
                );
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr;
  div {
    padding-top: 5px;
  }
  .submit {
    grid-column: 1/2;
  }
}
</style>
