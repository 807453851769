import Vue from "vue";
import Vuex from "vuex";
import Store from "../../../store";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    CREATE_EMMAUS_ORGANIZATION: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/EmmausOrganization", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_EMMAUS_ORGANIZATION: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/EmmausOrganization")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_USER_ORGANIZATON: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/EmmausTraining/GetUserOrganization/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    SEARCH_EMMAUS_ORGANIZATION: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("/EmmausOrganization/Search", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_EMMAUS_ORGANIZATION_BY_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/EmmausOrganization/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    EDIT_EMMAUS_ORGANIZATION: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .put("/EmmausOrganization", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    DELETE_EMMAUS_ORGANIZATION: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .delete("/EmmausOrganization/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    }
  }
});
