<script>
export default {
  methods: {
    stimulsoftCreateByStrategicProgram() {
      let year = this.allReportYears.find(
        (ry) => ry.pkReportYearId == this.searchModel.fkReportYearId
      ).year;
      var report = Stimulsoft.Report.StiReport.createNewReport();
      report.loadFile("/stimulsoft/MRT/LapStrategicProgram.mrt");

      var options = new Stimulsoft.Viewer.StiViewerOptions();
      options.appearance.scrollbarsMode = true;

      options.height = "600px";
      options.width = "100%";
      options.toolbar.zoom = 75;
      var viewer = new Stimulsoft.Viewer.StiViewer(options, "StiViewer", false);

      var dsInvoice_Data = new Stimulsoft.System.Data.DataSet("data");
      dsInvoice_Data.readJson(this.strategicReportData);
      report.regData("data", "data", dsInvoice_Data);
      report.dictionary.synchronize();
      report.dictionary.variables.getByName("trans_activity").valueObject =
        this.$lang("lap.activity");
      report.dictionary.variables.getByName("trans_deadline").valueObject =
        this.$lang("lap.deadline");
      report.dictionary.variables.getByName("trans_institution").valueObject =
        this.$lang("lap.institution");
      report.dictionary.variables.getByName(
        "trans_implement_indicators"
      ).valueObject = this.$lang("lap.indicators");

      report.dictionary.variables.getByName(
        "trans_required_resources"
      ).valueObject = this.$lang("lap.required_resources");
      report.dictionary.variables.getByName("trans_lap_report").valueObject =
        this.$lang("lap.lap_report_title");
      report.dictionary.variables.getByName("trans_status").valueObject =
        this.$lang("lap.status");
      report.dictionary.variables.getByName("trans_not_started").valueObject =
        this.$lang("lap.status_not_started");
      report.dictionary.variables.getByName("trans_in_progress").valueObject =
        this.$lang("lap.status_in_progress");
      report.dictionary.variables.getByName("trans_implemented").valueObject =
        this.$lang("lap.status_implemented");
      report.dictionary.variables.getByName("trans_year").valueObject =
        String(year);
      viewer.report = report;
      viewer.renderHtml("viewerRender");
    }
  }
};
</script>
