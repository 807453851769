<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-paperplane"></i>
        <span v-lang="'actionGroup.action_group_details'"></span>
      </h1>
      <div class="actions">
        <a class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></a>
      </div>
    </div>
    <div class="card table-container">
      <table>
        <tr>
          <td v-lang="'actionGroup.label_for_name'"></td>
          <td>{{ actionGroup.name }}</td>
        </tr>
        <tr>
          <td v-lang="'actionGroup.label_for_description'"></td>
          <td>{{ actionGroup.description }}</td>
        </tr>
        <tr>
          <td v-lang="'actionGroup.label_for_titleId'"></td>
          <td>{{ actionGroup.titleId }}</td>
        </tr>
        <tr>
          <td v-lang="'actionGroup.label_for_default'"></td>
          <td>{{ actionGroup.default == false ? no : yes }}</td>
        </tr>
        <tr>
          <td v-lang="'actionGroup.label_for_isBuiltIn'"></td>
          <td>{{ actionGroup.isBuiltin == false ? no : yes }}</td>
        </tr>
        <tr>
          <td v-lang="'actionGroup.label_for_adminActionGroup'"></td>
          <td>{{ actionGroup.adminActionGroup == false ? no : yes }}</td>
        </tr>
        <tr>
          <td v-lang="'actionGroup.label_for_isSystem'"></td>
          <td>{{ actionGroup.isSystem == false ? no : yes }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import { lang } from "@/global-functions";

export default {
  data() {
    return {
      actionGroup: {},
      yes: lang("general.label_yes"),
      no: lang("general.label_no")
    };
  },
  created() {
    this.getActionGroupByID();
  },
  methods: {
    getActionGroupByID() {
      var $this = this;
      store
        .dispatch("GET_ACTION_GROUP_BY_ID", $this.$route.params.id)
        .then(response => {
          $this.actionGroup = response.data.model;
        })
        .catch(error => {});
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}

td:nth-child(2) {
  font-weight: bold;
}
</style>
