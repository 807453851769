<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-paperplane"></i>
        <span v-lang="'statusMessages.status_message_details'"></span>
      </h1>
      <div class="actions">
        <button
          class="primary"
          v-on:click="$router.go(-1)"
          v-lang="'general.button_back'"
        ></button>
      </div>
    </div>
    <div class="card table-container">
      <table>
        <tbody>
          <tr>
            <td v-lang="'statusMessages.status_code'"></td>
            <td>{{ statusMessage.statusCode.statusCode }}</td>
          </tr>
          <tr>
            <td v-lang="'statusMessages.priority'"></td>
            <td>
              {{
                statusMessage.priority == null
                  ? ""
                  : statusMessage.priority.name
              }}
            </td>
          </tr>
          <tr>
            <td v-lang="'statusMessages.user_message'"></td>
            <td>{{ statusMessage.userMessage }}</td>
          </tr>
          <tr>
            <td v-lang="'statusMessages.user_message_titleId'"></td>
            <td>{{ statusMessage.userMessageTitleId }}</td>
          </tr>
          <tr>
            <td v-lang="'statusMessages.user_log_message'"></td>
            <td>{{ statusMessage.userLogMessage }}</td>
          </tr>
          <tr>
            <td v-lang="'statusMessages.user_log_message_titleId'"></td>
            <td>{{ statusMessage.userLogMessageTitleId }}</td>
          </tr>
          <tr>
            <td v-lang="'statusMessages.action_log_message'"></td>
            <td>{{ statusMessage.actionLogMessage }}</td>
          </tr>
          <tr>
            <td v-lang="'statusMessages.action_log_message_titleId'"></td>
            <td>{{ statusMessage.actionLogMessageTitleId }}</td>
          </tr>
          <tr>
            <td v-lang="'statusMessages.data_log_message'"></td>
            <td>{{ statusMessage.dataLogMessage }}</td>
          </tr>
          <tr>
            <td v-lang="'statusMessages.data_log_message_titleId'"></td>
            <td>{{ statusMessage.dataLogMessageTitleId }}</td>
          </tr>
          <tr>
            <td v-lang="'statusMessages.error_log_message'"></td>
            <td>{{ statusMessage.errorLogMessage }}</td>
          </tr>
          <tr>
            <td v-lang="'statusMessages.error_log_message_titleId'"></td>
            <td>{{ statusMessage.errorLogMessageTitleId }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import { lang } from "@/global-functions";

export default {
  data() {
    return {
      statusMessage: {},

      yes: lang("general.label_yes"),
      no: lang("general.label_no")
    };
  },

  created() {
    this.getStatusMessagesByID();
  },

  methods: {
    getStatusMessagesByID() {
      var $this = this;
      store
        .dispatch("GET_STATUS_MESSAGES_BY_ID", {
          PkFkActionId: $this.$route.query.PkFkActionId,
          PkFkController: $this.$route.query.PkFkController,
          PkFkStatusCodeId: $this.$route.query.PkFkStatusCodeId,
          PkFkPriorityId: $this.$route.query.PkFkPriorityId
        })
        .then(response => {
          $this.statusMessage = response.data.model;
        })
        .catch(error => {});
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}

td:nth-child(2) {
  font-weight: bold;
}

td {
  width: 100px;
  text-align: left !important;
}
</style>
