<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-paperplane"></i>
        <span v-lang="'action.label_action_details'"></span>
      </h1>
      <div class="actions">
        <a class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></a>
      </div>
    </div>
    <div class="card table-container">
      <table>
        <tr>
          <td v-lang="'action.label_name_of_action'"></td>
          <td>{{ action.action }}</td>
        </tr>
        <tr>
          <td v-lang="'action.label_description_action'"></td>
          <td>{{ action.description }}</td>
        </tr>
        <tr>
          <td v-lang="'action.label_titleId'"></td>
          <td>{{ action.titleId }}</td>
        </tr>
        <tr>
          <td v-lang="'action.label_name_of_http_method'"></td>
          <td>{{ typeof httpMethod != "undefined" ? httpMethod.name : "" }}</td>
        </tr>
        <tr>
          <td v-lang="'action.label_name_of_action_type'"></td>
          <td>{{ typeof actionType != "undefined" ? actionType.name : "" }}</td>
        </tr>
        <tr>
          <td v-lang="'action.label_data_log'"></td>
          <td>{{ action.dataLog == false ? no : yes }}</td>
        </tr>
        <tr>
          <td v-lang="'action.label_user_log'"></td>
          <td>{{ action.userLog == false ? no : yes }}</td>
        </tr>
        <tr>
          <td v-lang="'action.label_action_log'"></td>
          <td>{{ action.actionLog == false ? no : yes }}</td>
        </tr>
        <tr>
          <td v-lang="'action.label_error_log'"></td>
          <td>{{ action.errorLog == false ? no : yes }}</td>
        </tr>
        <tr>
          <td v-lang="'action.label_admin_action'"></td>
          <td>{{ action.adminAction == false ? no : yes }}</td>
        </tr>
        <tr>
          <td v-lang="'action.label_superadmin_action'"></td>
          <td>{{ action.superAdminAction == false ? no : yes }}</td>
        </tr>
        <tr>
          <td v-lang="'action.statusMessages'"></td>
          <td>
            <router-link
              class="default small"
              :to="{
                name: 'StatusMessages',
                query: {
                  PkFkActionId: action.pkActionId,
                  PkFkController: action.pkController
                }
              }"
              v-lang="'action.statusMessages'"
            ></router-link>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import { lang } from "@/global-functions";

export default {
  data() {
    return {
      action: {},
      actionType: {},
      yes: lang("general.label_yes"),
      no: lang("general.label_no")
    };
  },
  computed: {
    httpMethod() {
      return this.action.httpMethod;
    }
  },
  created() {
    this.getActionByID();
  },
  methods: {
    getActionByID() {
      var $this = this;
      store
        .dispatch("GET_ACTION_BY_ID", {
          PkActionId: $this.$route.query.PkActionId,
          PkController: $this.$route.query.PkController
        })
        .then(response => {
          $this.action = response.data.model;
          $this.actionType = response.data.model.actionType;
          $this.httpMethod = respone.adata.model.httpMethod;
        })
        .catch(error => {});
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}

td:nth-child(2) {
  font-weight: bold;
}
</style>
