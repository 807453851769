<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-paperplane"></i>
        <span v-lang="'statusMessages.update_status_message'"></span>
      </h1>
      <div class="actions">
        <button class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></button>
      </div>
    </div>
    <notify />
    <div>
      <div class="card">
        <Form
          v-bind:update="true"
          v-on:save="edit"
          :messageData="statusMessage"
          :errorModel="errorModel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import Form from "./Form";
import { lang } from "@/global-functions";
import { mapState } from "vuex";
import globalStore from "@/store";

export default {
  components: {
    Form
  },
  data() {
    return {
      statusMessage: {},
      errorModel: {
        message: "",
        key: ""
      }
    };
  },
  created() {
    this.getStatusMessagesByID();
  },
  methods: {
    edit: function(value) {
      var $this = this;
      store
        .dispatch("EDIT_STATUS_MESSAGE", value)
        .then(response => {
          $this.$success("statusMessages.status_message_successfully_updated");
          $this.$router.push({
            name: "StatusMessages",
            query: {
              PkFkActionId: $this.$route.query.PkFkActionId,
              PkFkController: $this.$route.query.PkFkController
            }
          });
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    getStatusMessagesByID() {
      var $this = this;
      store
        .dispatch("GET_STATUS_MESSAGES_BY_ID", {
          PkFkActionId: $this.$route.query.PkFkActionId,
          PkFkController: $this.$route.query.PkFkController,
          PkFkStatusCodeId: $this.$route.query.PkFkStatusCodeId,
          PkFkPriorityId: $this.$route.query.PkFkPriorityId
        })
        .then(response => {
          $this.statusMessage = response.data.model;
        })
        .catch(error => {});
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}
</style>
