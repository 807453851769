import Vue from "vue";
import Vuex from "vuex";
import Store from "../../store";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pkSelectedYearId: null,
    pkSelectedGoalId: null,
    selectedYear: null,
  },
  mutations: {
    SET_SELECTED_YEAR: function(state: any, pkSelectedYearId: any) {
      state.pkSelectedYearId = pkSelectedYearId;
    },
    SET_SELECTED_GOAL: function(state: any, pkSelectedGoalId: any) {
      state.pkSelectedGoalId = pkSelectedGoalId;
    },
    SET_SELECTED_YEAR_VALUE: function(state: any, selectedYear: any) {
      state.selectedYear = selectedYear;
    },
  },
  actions: {
    GET_ALL_REPORT_YEARS: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .get("/reportyear/get-all")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },

    GET_ACTVITIES_WITH_ANSWERS: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .post(
            "/activityAnswers/get-activities-with-answers-for-sp-and-organisation",
            model
          )
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_ALL_GOALS: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .post("/ModuleIIIGoals/get-all", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_STRATEGIC_PROGRAMS: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .post("/StrategicPrograms/get-all-for-goal", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    SAVE_ANSWERS: function(state: any, model: any) {
      // save-answers-for-activities
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .post("/ActivityAnswers/save-answers-for-activities", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_REPORT_BY_GOAL: function(state: any, model: any) {
     return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .post("/ModuleIIIReport/get-report-by-goal", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
        });
      },
    GET_REGIONS: function(state: any, model: any) {
      // save-answers-for-activities
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("/EmmausOrganization/GetRegions")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_STATISTIC: function(state:any, model:any){
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core.post("ModuleIIIReport/statistic-per-statuses", model)
        .then(function(res: any) {
          resolve(res);
        })
        .catch(function(error:any)
        {
          reject(error.response)
        })

      })
    },
    GET_REPORT_BY_STRATEGIC_PROGRAM: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
         Store.state.$axios_auth_core
           .post("ModuleIIIReport/get-report-by-strategic-program", model)
           .then(function(response: any) {
             resolve(response);
           })
           .catch(function(error: any) {
             reject(error.response);
           });
         });
       },

    GET_ORGANIZATION_REDIRECT(state:any, model:any)
    {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth_core
          .get("Goal/get-organization-redirect/"+model.goalNumber +"/" + model.fkReportYearId)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
        });
    }

    },
});
