<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-paperplane"></i>
        <span v-lang="'functions.functions'"></span>
      </h1>
      <div class="actions">
        <router-link
          v-if="$role(10104)"
          class="primary"
          :to="{ name: 'FunctionCreate', params: { id: 0 } }"
          v-lang="'general.label_add'"
        ></router-link>
      </div>
    </div>
    <div class="card search">
      <div class="input m-0">
        <label for="name" v-lang="'functions.label_for_name'"></label>
        <input class="m-0" v-model="name" type="text" name="name" autocomplete="off" autofocus />
      </div>
    </div>
    <notify />
    <NoData v-if="!search || search.length == 0"></NoData>
    <div v-else class="card table-container actions">
      <table class="table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th v-lang="'general.label_id'">&nbsp;</th>
            <th v-lang="'functions.label_for_name'">&nbsp;</th>
            <th v-lang="'functions.label_for_description'">&nbsp;</th>
            <th v-lang="'functions.label_for_isSystem'">&nbsp;</th>
            <th v-lang="'functions.label_for_action_group'">&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, key) in search" :key="key">
            <td>{{ pageSize * (pageNum - 1) + key + 1 }}</td>
            <td>{{ obj.pkFunctionId }}</td>
            <td>{{ obj.name }}</td>
            <td>{{ obj.description }}</td>
            <td>{{ obj.isSystem == false ? yes : no }}</td>
            <td>{{ obj.actionGroup.name }}</td>

            <td>
              <router-link
                v-if="$role(10107)"
                class="default small"
                :to="{
                  name: 'FunctionDetails',
                  params: { id: obj.pkFunctionId }
                }"
                v-lang="'general.label_details'"
              ></router-link>
              <router-link
                v-if="$role(10105)"
                class="primary small"
                :to="{ name: 'FunctionEdit', params: { id: obj.pkFunctionId } }"
                v-lang="'general.label_edit'"
              ></router-link>
              <a
                v-if="$role(10106)"
                class="error small"
                v-on:click="deleteFunction(obj.pkFunctionId)"
                v-lang="'general.label_delete'"
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination-container">
      <paginate
        v-if="search.length != 0"
        :page-count="pageCount"
        :click-handler="changePage"
        :prev-text="this.$lang('general.previous')"
        :next-text="this.$lang('general.next_page')"
        :container-class="'paggination'"
      ></paginate>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import Paginate from "vuejs-paginate";
import NoData from "../../../General/NoData";
import { ResponsiveDirective } from "vue-responsive-components";

Vue.use(Toasted);

export default {
  directives: {
    responsive: ResponsiveDirective
  },
  components: {
    NoData,
    paginate: Paginate
  },
  data() {
    return {
      allFunctions: [],
      name: this.$route.query.name == undefined ? "" : this.$route.query.name,
      pageSize: 10,
      pageNum:
        this.$route.query.page == undefined
          ? 1
          : parseInt(this.$route.query.page),
      pageCount: 0,
      yes: lang("general.label_yes"),
      no: lang("general.label_no")
    };
  },
  created() {
    this.getAllFunctions();
  },
  methods: {
    changePage: function(pageNum) {
      this.pageNum = pageNum;
    },
    getAllFunctions() {
      var $this = this;
      store
        .dispatch("GET_All_FUNCTIONS")
        .then(response => {
          $this.allFunctions = response.data.list;
        })
        .catch(error => {});
    },
    deleteFunction(id) {
      this.$removeToast();
      Vue.toasted.show(lang("functions.do_you_want_to_delete_this_function"), {
        theme: "toasted-primary",
        position: "top-center",
        duration: 5000,
        action: [
          {
            text: lang("general.label_yes"),
            onClick: (e, toastObject) => {
              var $this = this;
              store
                .dispatch("DELETE_FUNCTION", id)
                .then(response => {
                  $this.getAllFunctions();
                  this.$success("functions.function_successfully_deleted");
                  this.notifyKey += 1;
                })
                .catch(error => {});
              toastObject.goAway(0);
            }
          },
          {
            text: lang("general.label_no"),
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            }
          }
        ]
      });
    }
  },
  computed: {
    search() {
      var $this = this;
      var allData = this.$_.filter($this.allFunctions, function(x) {
        return x.name.toLowerCase().includes($this.name.toLowerCase());
      });
      $this.pageCount =
        allData.length % $this.pageSize == 0
          ? parseInt(allData.length / $this.pageSize)
          : parseInt(allData.length / $this.pageSize) + 1;
      // this.$router.push({
      //   query: {
      //     page: $this.pageNum,
      //     name: $this.name
      //   }
      // });
      return allData.slice(
        (this.pageNum - 1) * this.pageSize,
        this.pageNum * this.pageSize
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/colors";
@import "../../../../assets/css/_paggination.scss";

.search {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}
select#dropdown {
  outline: none !important;
  height: 38px;
  border: 1px solid $primaryColor;
}
.actions {
  position: relative;
}
</style>
