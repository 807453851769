<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-users"></i>
        <span v-lang="'user.label_create_user'"></span>
      </h1>
      <div class="actions">
        <a
          class="primary"
          v-on:click="$router.go(-1)"
          v-lang="'general.button_back'"
        ></a>
      </div>
    </div>
    <div>
      <div class="card">
        <Form
          v-on:save="save"
          :errorModel="errorModel"
          :isSubmited="isSubmited"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import Form from "./Form";

export default {
  components: {
    Form
  },
  data() {
    return {
      userAvatar: undefined,
      user: {},
      errorModel: {},
      isSubmited: false
    };
  },
  methods: {
    save: function(value, imgBlob) {
      var $this = this;
      $this.isSubmited = true;
      store
        .dispatch("CREATE_USER", value)
        .then((response) => {
          if (imgBlob.type == "image/png") {
            store
              .dispatch("UPLOAD_AVATAR_IMAGE", {
                username: $this.user.username,
                avatar: imgBlob
              })
              .then((response) => {
                $this.$success("user.user_successfully_created");
                $this.$router.push({ name: "UserIndex" });
                $this.isSubmited = false;
              })
              .catch((error) => {});
          } else {
            $this.$success("user.user_successfully_created");
            $this.$router.push({ name: "UserIndex" });
            $this.isSubmited = false;
          }
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
          $this.isSubmited = false;
          console.log("error", error.data);
        });
    },
    uploadAvatar(blob) {
      var $this = this;
      store
        .dispatch("UPLOAD_AVATAR_IMAGE", blob)
        .then((response) => {})
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
h1 i {
  padding-right: 5px;
  font-size: 0.9em;
}
</style>
