<template>
  <div class="menu-container settings">
    <div class="z-index">
      <ul class="settings-menu">
        <li
          v-for="goal in allGoals"
          :key="goal.pkGoalId"
          v-on:click="pkSelectedGoalId = goal.pkGoalId"
        >
          <span
            class="bold"
            :class="pkSelectedGoalId == goal.pkGoalId ? 'active' : ''"
            >{{
              $lang("survey.label_goal").toUpperCase() +
                " " +
                goal.number +
                " - " +
                goal.title
            }}</span
          >
        </li>
      </ul>
    </div>
    <div>
      <GoalQuestions />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "./store";
import Toasted from "vue-toasted";
import GoalQuestions from "./GoalQuestions";

Vue.use(Toasted);

export default {
  components: {
    GoalQuestions
  },
  data() {
    return {
      allGoals: []
    };
  },
  created() {
    this.getAllGoals();
  },
  methods: {
    getAllGoals() {
      var $this = this;
      store
        .dispatch("GET_ALL_GOALS")
        .then((response) => {
          $this.allGoals = response.data;
          if ($this.allGoals.length > 0) {
            $this.pkSelectedGoalId = $this.allGoals[0].pkGoalId;
          }
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    }
  },
  computed: {
    pkSelectedGoalId: {
      get() {
        return store.state.pkSelectedGoalId;
      },
      set(value) {
        store.commit("SET_SELECTED_GOAL", value);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/colors";
.z-index {
  z-index: 0;
  color: $white;
  background: $lightSecondaryColor;
  margin-left: -10px;
  height: calc(100% - 16px);
}
.menu-container.settings {
  display: grid;
  grid-template-columns: 0.15fr 1fr;
  position: absolute;
  left: 15px;
  top: 150px;
  right: 10px;
  bottom: 2px;
  .container {
    position: relative;
    top: -5px;
  }
  .has-submenu::before {
    top: 10px;
  }
  .settings-menu {
    list-style: none;
    padding: 0px;
    background: transparent !important;
    margin-top: 0;
    .submenu-settings {
      display: none;
      background: transparent !important;
      padding: 0px;

      li {
        span {
          display: block;
        }
        background: transparent !important;
        cursor: pointer;
      }
      &.active {
        display: block;
      }
    }
    ul {
      list-style: none;
      background: transparent !important;
      display: none;
      li {
        span {
          display: block;
        }
        background: transparent !important;
        cursor: pointer;
        padding: 0 10px;
      }
    }
    li {
      span {
        text-align: center;
        font-size: 14px;
        display: block;
        padding: 10px;
        border-left: 5px solid $lightSecondaryColor;
        text-align: left;
        &:hover,
        &.active {
          border-left: 5px solid $darkPrimaryColor;
          border-right: 5px solid $darkPrimaryColor;
          color: $darkPrimaryColor;
          box-shadow: 0px 4px 5px -5px black;
        }
      }
      background: transparent !important;
      cursor: pointer;
    }
  }
}
</style>
