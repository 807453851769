<template>
  <div class="input m-0 mt-10" :class="containerClass">
    <div class="upload-area" @click="showUpload()" :class="uploadAreaClass">
      {{ this.file ? this.file.name : this.placeholder }}
    </div>
    <input
      :key="key"
      :ref="ref"
      class="hide-input"
      type="file"
      :name="name"
      autocomplete="off"
      v-on:change="selectedFile($event)"
      :accept="acceptFileTypes"
    />
    <div>
      <span class="error custom-file-field-error" v-html="errorText"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: File,
      default: null
    },
    containerClass: {
      type: String,
      default: ""
    },
    uploadAreaClass: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    ref: {
      type: String,
      default: "fileUpload"
    },
    name: {
      type: String,
      default: "name"
    },
    errorText: {
      type: String,
      default: ""
    },
    triggerReset: {
      type: Number,
      default: 0
    },
    acceptFileTypes: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      file: null,
      key: Math.random()
    };
  },
  methods: {
    showUpload() {
      this.$refs[this.ref].click();
    },
    selectedFile(event) {
      this.file = event.target.files[0];
      this.$emit("input", this.file);
    }
  },
  computed: {
    model: {
      get() {
        return this.file;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  watch: {
    triggerReset(newVal) {
      this.file = null;
      this.$refs[this.ref].value = null;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/colors";
.custom-file-field-error {
  line-height: 0px;
}

.upload-area {
  height: 40px;
  padding: 10px;
  border: 1px dashed $primaryColor;
  text-align: center;
  background-color: rgb(248, 248, 248);
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  &-error {
    border: 1px solid $darkErrorColor !important;
  }
}
.hide-input {
  visibility: hidden;
  display: none;
  opacity: 0;
  height: 0px;
}
</style>
