// window.gFun = function(lang)
import Vue from "vue";
import Toasted from "vue-toasted";
const VueSession = require("vue-session");
import Moment from "moment";
import router from "@/router";

Vue.use(VueSession);
Vue.use(Toasted);

export function lang(path: any) {
  if (!path) {
    return path;
  }
  let splitedValues = path.split(".");

  if (splitedValues.length === 1) {
    return path;
  }
  let lang = localStorage.lang == null ? "bs" : localStorage.lang;

  var json = require(`@/lang/${lang}/${splitedValues[0]}.json`);
  var translation = "";
  try {
    translation =
      json[splitedValues[1]] == undefined
        ? splitedValues[1]
        : json[splitedValues[1]];
  } catch {
    translation = path;
  }
  return translation;
}

Vue.prototype.$lang = function(path: any) {
  return lang(path);
};

export function directives() {
  Vue.directive("role", {
    inserted(el: any, binding: any, vnode: any) {
      let hasUserPermission = Vue.prototype.$role(binding.value);
      if (!hasUserPermission) {
        el.parentElement.removeChild(el);
      }
    },
  });

  Vue.prototype.$role = function(functionId: Number) {
    try {
      var userData =
        localStorage.user != null ? JSON.parse(localStorage.user) : null;
    } catch (e) {
      window.localStorage.clear();
      router.push({ name: "Login" });
      return false;
    }

    return userData ? userData.allowedFunctions.includes(functionId) : null;
  };

  Vue.directive("lang", {
    inserted(el: any, binding: any, vnode: any) {
      vnode.elm.innerText = lang(binding.value); // + childern;
    },
  });

  Vue.directive("moment", {
    inserted(el: any, binding: any, vnode: any) {
      vnode.elm.innerText = Moment(binding.value).format(
        "HH:MM:ss DD.MM.YYYY."
      );
    },
  });
}

// export function exportToPDF(table_id: string) {
//   const jsPDF = require("jspdf");
//   require("jspdf-autotable");
//   var element = "body";
//   var doc = new jsPDF({
//     orientation: "p",
//     unit: "mm",
//     format: "a4",
//     putOnlyUsedFonts: true
//   });

//   var $this = this;
//   var base64Img = null;
//   doc.addFileToVFS("Arimo-Regular.ttf", Arimo);
//   doc.addFont("Arimo-Regular.ttf", "Arimo", "normal");
//   doc.setFont("Arimo");

//   var footer = async function(data) {};
//   base64Img = header;
//   //doc.addPage();
//   var htmlTable = document.getElementById("report");
//
//   doc.autoTable({
//     styles: {
//       fontSize: 12,
//       font: "Arimo",
//       halign: "center",
//       lineColor: [44, 62, 80],
//       lineWidth: 0.55
//     },
//     headStyles: {
//       halign: "left",
//       fontStyle: "Arimo",
//       fontSize: 12,
//       fillColor: [176, 196, 222],
//       textColor: [0, 0, 0]
//     },
//     columnStyles: {},
//     html: "#report",
//     didParseCell: function(HookData) {
//       if (HookData.row.raw.id == "1") {
//         HookData.cell.styles.textColor = [0, 0, 0];
//         HookData.cell.styles.fontSize = 9;
//         HookData.cell.styles.fillColor = [192, 192, 192];
//       }
//       if (HookData.row.raw.id == "2") {
//         HookData.cell.styles.fontSize = 13;
//       }
//     },

//     didDrawCell: function(HookData) {},
//     willDrawCell(HookData) {},
//     margin: { top: 10 }
//   });

//   var pageHeight =
//     doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
//   var pageWidth =
//     doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
//   var number_of_pages = doc.internal.getNumberOfPages();
//   //doc.deletePage(number_of_pages);
//   number_of_pages = doc.internal.getNumberOfPages();
//   for (var i = 1; i <= number_of_pages; i++) {
//     doc.setPage(i);
//     doc.setFontSize(12);
//     doc.text(i + "/" + number_of_pages, 10, 290);
//     if (i < number_of_pages) {
//       doc.setFontSize(9);
//       doc.text(
//         $this.$lang("reports.date_of_print") +
//           " " +
//           $this.$moment($this.today).format("DD.MM.YYYY hh:mm:ss"),
//         pageWidth / 2 + 34,
//         doc.internal.pageSize.height - 10
//       );
//       doc.text(
//         $this.user.user.firstName + " " + $this.user.user.lastName,
//         pageWidth / 2 + 58,
//         doc.internal.pageSize.height - 5
//       );
//     }
//   }
//   doc.text(
//     "_______________________",
//     pageWidth / 2 + 34,
//     doc.internal.pageSize.height - 14
//   );
//   doc.setFontSize(9);
//   doc.text(
//     $this.user.user.firstName + " " + $this.user.user.lastName,
//     pageWidth / 2 + 58,
//     doc.internal.pageSize.height - 10
//   );
//   doc.setFontSize(9);
//   doc.text(
//     this.lang("reports.date_of_print") +
//       " " +
//       $this.$moment($this.today).format("DD.MM.YYYY hh:mm:ss"),
//     pageWidth / 2 + 34,
//     doc.internal.pageSize.height - 5
//   );
//   window.open(doc.output("bloburl"), "_blank");
//   var outputName = $this.$lang("reports.details_title");
//   doc.save(outputName);
// }

export function ToastSuccess(message: string) {
  Vue.toasted.show(lang(message), {
    theme: "toasted-primary",
    position: "top-center",
    duration: 5000,
    action: [
      {
        text: "OK",
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
          Vue.toasted.clear();
        },
      },
    ],
  });
}

export function ToastError(message: string) {
  Vue.toasted.show(lang(message), {
    theme: "toasted-primary",
    position: "top-center",
    className: "error",
    duration: 5000,
    action: [
      {
        text: "OK",
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
        },
      },
    ],
  });
}

export function ToastRemove() {
  var toast = document.getElementsByClassName("toasted-container")[0];
  var toastNumber =
    document.getElementsByClassName("toasted-container")[0] != null
      ? document.getElementsByClassName("toasted-container")[0]
          .childElementCount
      : 0;
  if (toastNumber > 0) {
    toast.removeChild(toast.childNodes[0]);
  }
}

Vue.prototype.$success = (message: string) => {
  ToastRemove();
  ToastSuccess(message);
};

Vue.prototype.$error = (message: string) => {
  ToastRemove();
  ToastError(message);
};

Vue.prototype.$removeToast = () => {
  ToastRemove();
};

Vue.prototype.$importModelStateErrors = function(
  errors: any,
  modelState: any,
  formKey: string
) {
  for (var key in modelState) {
    errors.add({ field: formKey + "." + key, msg: lang(modelState[key][0]) });
  }
};

Vue.prototype.$setAdminPageTitleFromLangCode = function(titleCode: any) {
  return (
    lang(titleCode) + " - Page Startup " + lang("general.label_administration")
  );
};
Vue.prototype.$setPageTitle = function(titleCode: any) {
  return lang(titleCode) + " - InsiDIA ";
};

var _ = require("lodash");
Vue.prototype.$_ = _;

Vue.prototype.$moment = Moment;
Vue.directive("moment", {
  inserted(el: any, binding: any, vnode: any) {
    vnode.elm.innerText = Moment(binding.value).format("DD.MM.YYYY. HH:mm");
  },
});

export function SetTitle(message: string) {
  document.title = message + " | InsiDIA";
}
Vue.prototype.$setTitle = (message: string) => {
  SetTitle(message);
};
