<template>
  <form v-on:submit.prevent="save()" data-vv-scope="function_form">
    <div class="card">
      <div class="input-group">
        <div
          class="input m-0"
          :class="errors.first('function_form.name') !== undefined ? 'input-error' : '' "
        >
          <label for="name">
            <span v-lang="'functions.label_for_name'"></span>
          </label>
          <input
            v-validate="'required'"
            class="m-0"
            v-model="model.name"
            type="text"
            name="name"
            autocomplete="off"
          />
          <div>
            <span class="error" v-html="errors.first('function_form.name')"></span>
          </div>
        </div>
        <div
          class="input m-0"
          :class="errors.first('function_form.description') !== undefined ? 'input-error' : '' "
        >
          <label for="description" v-lang="'functions.label_for_description'"></label>
          <input
            class="m-0"
            type="text"
            v-model="model.description"
            name="description"
            autocomplete="off"
          />
          <div>
            <span class="error" v-html="errors.first('function_form.description')"></span>
          </div>
        </div>
        <div
          class="input m-0"
          :class="errors.first('function_form.titleId') !== undefined ? 'input-error' : '' "
        >
          <label for="titleId">
            <span v-lang="'functions.label_for_titleId'"></span>
          </label>
          <input class="m-0" type="text" v-model="model.titleId" name="titleId" autocomplete="off" />
          <div>
            <span class="error" v-html="errors.first('function_form.titleId')"></span>
          </div>
        </div>
        <div
          class="input m-0"
          :class="errors.first('function_form.fkEntityTypeId') !== undefined ? 'input-error' : '' "
        >
          <label for="fkEntityTypeId" v-lang="'functions.label_for_entity_type'"></label>
          <select
            v-model="model.fkEntityTypeId"
            v-validate="'required|excluded:-1'"
            name="fkEntityTypeId"
            id="dropdown"
          >
            <option
              value="-1"
              disabled
              hidden
              v-lang="'functions.label_placeholder_for_entity_type'"
            ></option>
            <option
              v-for="(obj, key) in entityTypes"
              :key="key"
              :value="obj.pkEntityTypeId"
            >{{obj.name}}</option>
          </select>
          <div>
            <span class="error" v-html="errors.first('function_form.fkEntityTypeId')"></span>
          </div>
        </div>
        <div
          class="input m-0"
          :class="errors.first('function_form.fkActionGroupId') !== undefined ? 'input-error' : '' "
        >
          <label for="fkActionGroupId" v-lang="'functions.label_for_action_group'"></label>
          <select
            v-model="model.fkActionGroupId"
            v-validate="'required|excluded:0'"
            name="fkActionGroupId"
            id="dropdown"
          >
            <option value disabled hidden v-lang="'functions.label_placeholder_for_action_group'"></option>
            <option
              v-for="(obj, key) in actionGroups"
              :key="key"
              :value="obj.pkActionGroupId"
            >{{obj.name}}</option>
          </select>
          <div>
            <span class="error" v-html="errors.first('function_form.fkActionGroupId')"></span>
          </div>
        </div>
        <div
          class="input m-0"
          :class="errors.first('function_form.fkActionModuleId') !== undefined ? 'input-error' : '' "
        >
          <label for="fkActionModuleId" v-lang="'functions.label_for_action_module'"></label>
          <select v-model="model.fkActionModuleId" id="dropdown">
            <option value selected v-lang="'functions.label_placeholder_for_action_module'"></option>
            <option
              v-for="(obj, key) in actionModules"
              :key="key"
              :value="obj.pkActionModuleId"
            >{{obj.name}}</option>
          </select>
          <div>
            <span class="error" v-html="errors.first('function_form.fkActionModuleId')"></span>
          </div>
        </div>
      </div>
      <div class="fieldset">
        <fieldset>
          <legend v-lang="'functions.label_function_type'"></legend>
          <div class="input-checkbox">
            <label for="isSystem">
              <input
                class="m-0"
                type="checkbox"
                v-model="model.isSystem"
                id="isSystem"
                autocomplete="off"
                hidden
              />
              <span v-lang="'functions.label_for_isSystem'"></span>
            </label>
          </div>

          <div class="input-checkbox">
            <label for="isBuiltin">
              <input
                class="m-0"
                type="checkbox"
                v-model="model.isBuiltin"
                id="isBuiltin"
                autocomplete="off"
                hidden
              />
              <span v-lang="'functions.label_for_isBuiltIn'"></span>
            </label>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="card actions">
      <div class="search">
        <div
          class="input m-0"
          :class="errors.first('action_form.titleId') !== undefined ? 'input-error' : '' "
        >
          <label for="name" v-lang="'action.label_name_of_action'"></label>
          <input class="m-0" v-model="name" type="text" name="name" autocomplete="off" autofocus />
        </div>
        <div
          class="input m-0"
          :class="errors.first('action_form.controller') !== undefined ? 'input-error' : '' "
        >
          <label for="controller" v-lang="'action.label_name_of_controller'"></label>
          <input
            class="m-0"
            v-model="controller"
            type="text"
            name="controller"
            autocomplete="off"
            autofocus
          />
        </div>
        <div
          class="input m-0"
          :class="errors.first('action_form.fkHttpMethodId') !== undefined ? 'input-error' : '' "
        >
          <label for="httpMethod" v-lang="'action.label_name_of_http_method'"></label>
          <select v-model="httpMethod" id="dropdown">
            <option value="0" v-lang="'action.placeholder_for_http_method'"></option>
            <option
              v-for="(obj, key) in httpMethods"
              :key="key"
              :value="obj.pkHttpMethodId"
            >{{obj.name}}</option>
          </select>
          <div class="error" v-html="errors.first('action_form.fkHttpMethodId')"></div>
        </div>
      </div>
      <NoData v-if="!search || search.length == 0"></NoData>
      <table v-else class="table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th v-lang="'action.label_name_of_action'">&nbsp;</th>
            <th v-lang="'action.label_name_of_description'">&nbsp;</th>
            <th v-lang="'action.label_name_of_controller'">&nbsp;</th>

            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, key) in search" :key="key">
            <td>{{pageSize*(pageNum-1) + key + 1}}</td>
            <td>{{ obj.action }}</td>
            <td>{{ obj.description }}</td>
            <td>{{ obj.pkController}}</td>

            <td>
              <div class="input-checkbox">
                <label>
                  <input
                    class="m-0"
                    type="checkbox"
                    :value="obj.pkActionId"
                    :key="obj.pkActionId"
                    v-model="model.actions"
                    autocomplete="off"
                    hidden
                  />
                  <span></span>
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination-container">
        <paginate
          v-if="search.length!=0"
          :page-count="pageCount"
          :click-handler="changePage"
          :prev-text="this.$lang('general.previous')"
          :next-text="this.$lang('general.next_page')"
          :container-class="'paggination'"
        ></paginate>
      </div>
      <div class="submi" id="sub">
        <button class="primary" v-lang="'general.button_submit'"></button>
      </div>
    </div>
  </form>
</template>

<script>
import Vue from "vue";
import { lang } from "@/global-functions";
import VeeValidate from "vee-validate";
import store from "../../store";
import Paginate from "vuejs-paginate";
import NoData from "../../../General/NoData";
Vue.use(VeeValidate);
export default {
  components: {
    NoData,
    paginate: Paginate
  },
  props: {
    functionData: {
      default: null
    },
    errorModel: {
      key: "",
      message: ""
    }
  },
  data() {
    return {
      isFormSubmited: false,
      pageSize: 5,
      pageNum: 1,
      pageCount: 0,
      actions: [],
      actionGroups: [],
      entityTypes: [],
      actionModules: [],
      httpMethods: [],
      name: "",
      controller: "",
      httpMethod: 0,

      model: {
        name: "",
        description: "",
        titleId: "",
        fkEntityTypeId: "-1",
        fkActionGroupId: "",
        fkActionModuleId: "",
        isSystem: false,
        isDeleted: false,
        isBuiltin: false,
        actions: []
      },
      validation_messages: {
        custom: {
          titleId: {
            required: lang("validation.required")
          },
          name: {
            required: lang("validation.required")
          },
          fkEntityTypeId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          },
          fkActionGroupId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          }
        }
      }
    };
  },

  watch: {
    functionData() {
      var $this = this;
      $this.model = Object.assign({}, this.functionData);
      var oldActions = [].concat(this.functionData.actions);
      $this.model.actions = [];
      oldActions.forEach(function(element) {
        $this.model.actions.push(element.pkActionId);
      });
    },
    errorModel() {
      this.$importModelStateErrors(
        this.errors,
        this.errorModel.modelState,
        "function_form"
      );
    }
  },
  created() {
    this.getAllActions();
    this.getHttpMethods();
    this.getAllEntityTypes();
    this.getAllActionGroups();
    this.getAllActionModules();
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  methods: {
    changePage: function(pageNum) {
      this.pageNum = pageNum;
    },
    getHttpMethods() {
      var $this = this;
      store
        .dispatch("GET_ALL_HTTP_METHODS")
        .then(response => {
          $this.httpMethods = response.data.list;
        })
        .catch(error => {});
    },
    getAllActionGroups() {
      var $this = this;
      store
        .dispatch("GET_All_ACTION_GROUPS")
        .then(response => {
          $this.actionGroups = response.data.list;
        })
        .catch(error => {});
    },
    getAllActionModules() {
      var $this = this;
      store
        .dispatch("GET_ALL_ACTION_MODULES")
        .then(response => {
          $this.actionModules = response.data.list;
        })
        .catch(error => {});
    },
    getAllEntityTypes() {
      var $this = this;
      store
        .dispatch("GET_All_ENTITY_TYPES")
        .then(response => {
          $this.entityTypes = response.data.list;
        })
        .catch(error => {});
    },
    getAllActions() {
      var $this = this;
      store
        .dispatch("GET_All_ACTIONS")
        .then(response => {
          $this.actions = response.data.list;
        })
        .catch(error => {});
    },
    save() {
      var $this = this;

      this.$validator.validateAll("function_form").then(valid => {
        if (valid && !this.isFormSubmited) {
          var i;
          var actionIds = [].concat($this.model.actions);
          $this.model.actions = [];

          for (i = 0; i < actionIds.length; i++) {
            $this.model.actions.push(
              $this.actions.find(function(action) {
                return action.pkActionId == actionIds[i];
              })
            );
          }
          this.$emit("save", this.model);
          this.isFormSubmited = true;
        }
      });
    }
  },
  computed: {
    search() {
      var $this = this;
      var allData = this.$_.filter(this.actions, function(action) {
        if ($this.httpMethod == 0)
          return (
            action.action.toLowerCase().includes($this.name.toLowerCase()) &&
            action.pkController
              .toLowerCase()
              .includes($this.controller.toLowerCase())
          );
        else
          return (
            action.action.toLowerCase().includes($this.name.toLowerCase()) &&
            action.pkController
              .toLowerCase()
              .includes($this.controller.toLowerCase()) &&
            action.fkHttpMethodId == $this.httpMethod
          );
      });

      $this.pageCount =
        allData.length % $this.pageSize == 0
          ? parseInt(allData.length / $this.pageSize)
          : parseInt(allData.length / $this.pageSize) + 1;
      var sortData = [];
      var flag;
      // for (var i = 0; i < allData.length; i++)
      //   for (var j = 0; j < $this.model.actions.length; j++)
      //     if (allData[i].pkActionId == $this.model.actions[j].pkActionId)
      //       sortData.push(allData[i]);
      // for (var i = 0; i < allData.length; i++) {
      //   flag = 0;
      //   for (var j = 0; j < $this.model.actions.length; j++)
      //     if (allData[i].pkActionId == $this.model.actions[j].pkActionId)
      //       flag = 1;
      //   if (flag == 0) sortData.push(allData[i]);
      // }
      return allData.slice(
        (this.pageNum - 1) * this.pageSize,
        this.pageNum * this.pageSize
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/colors";
@import "../../../../assets/css/_paggination.scss";
.white {
  color: white;
}
label {
  input {
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
  }
}
.input-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 15px;
}
.search {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 10px;
}
div.input-checkbox {
  display: -webkit-inline-flex;
  color: black;
  width: 150px;
}

.fieldset {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  white-space: nowrap;
}
table {
  margin-bottom: 10px;
}
.actions {
  position: relative;
}
#dropdown {
  margin-bottom: 0;
  height: 37px;
}
</style>
