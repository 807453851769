import Vue from "vue";
import Vuex from "vuex";
import Store from "../../../../store";
import { lang } from "@/global-functions";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    list: []
  },
  actions: {
    GET_RELATION_TYPES: function(store: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth.get("RelationType").then((response: any) => {
          resolve(response.data);
        });
      });
    },
    GET_RELATIONS_BY_MASTER_ID: function(store: any, master_id: number) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("RelationType/GetByEntityId", {
            params: {
              pkEntityTypeId: master_id
            }
          })
          .then((response: any) => {
            resolve(response.data);
          });
      });
    },
    CREATE_RELATION_TYPE: function(store: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("RelationType", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    }
  }
});
