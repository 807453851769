<template>
  <div class="container">
    <div class="header card">
      <h1>
        <i class="icon-file-text"></i>
        <span v-lang="'language.details_language'"></span>
      </h1>
      <div class="actions">
        <button
          class="primary"
          v-on:click="$router.go(-1)"
          v-lang="'general.button_back'"
        ></button>
      </div>
    </div>
    <div class="card table-container">
      <table>
        <tr>
          <td v-lang="'language.name'"></td>
          <td>{{ model.name }}</td>
        </tr>
        <tr>
          <td v-lang="'language.code'"></td>
          <td>{{ model.code }}</td>
        </tr>
        <tr>
          <td v-lang="'language.description'"></td>
          <td class="description">{{ model.description }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { lang } from "@/global-functions";
import store from "../store";
export default {
  data() {
    return {
      model: {
        pkLanguageId: 0,
        name: "",
        code: "",
        description: ""
      }
    };
  },
  created() {
    this.getByID(this.$route.params.id);
  },
  methods: {
    getByID(id) {
      store
        .dispatch("GET_LANGUAGE_BY_ID", id)
        .then(response => {
          this.model = response.data.model;
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    edit() {
      this.$validator.validateAll("user_form").then(valid => {
        if (valid) {
          var $this = this;
          store
            .dispatch("UPDATE_LANGUAGE", this.model)
            .then(response => {
              this.$router.push({
                name: "LanguagesIndex"
              });
            })
            .catch(error => {
              if (error.status == 400) $this.errorModel = error.data;
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.description {
  word-break: break-all;
}
</style>
