<template>
  <!-- :class="
      $route.name != 'OfficialRecordsDetails' &&
      $route.name != 'WatchListIndex' &&
      $route.name != 'WatchListCreateList'
        ? 'container'
        : ''
    " -->
  <div :class="'container'">
    <div class="header card">
      <h1>
        <span v-lang="'emmaus_persons.person_index'"></span>
      </h1>
      <div class="actions">
        <div v-if="people.length > 0">
          <button
            class="primary"
            v-on:click="searchForExcelReport()"
            v-lang="'victims.export_excel'"
          ></button>
          <button
            class="error"
            v-on:click="searchForReport()"
            v-lang="'general.report-print'"
          ></button>
        </div>
      </div>
    </div>
    <!-- <notify /> -->
    <div class="card">
      <form
        class="search"
        action
        v-on:submit.prevent="searchFunction()"
        v-responsive="{ small: (el) => el.width < 650 }"
      >
        <div class="input m-0">
          <label for="firstName" v-lang="'general.firstName'"></label>
          <input
            class="m-0"
            v-model="tempSearchModel.firstName"
            type="text"
            name="firstName"
            autocomplete="off"
            autofocus
          />
        </div>
        <div class="input m-0">
          <label for="lastName" v-lang="'general.lastName'"></label>
          <input
            class="m-0"
            v-model="tempSearchModel.lastName"
            type="text"
            name="lastName"
            autocomplete="off"
            autofocus
          />
        </div>
        <div class="input m-0">
          <label for="fkGenderCodeId" v-lang="'general.gender'"></label>
          <select
            name="fkGenderCodeId"
            v-model="tempSearchModel.fkGenderCodeId"
            v-validate="'required|excluded:null'"
          >
            <option
              :value="null"
              v-lang="'emmaus.placeholder_for_gender'"
            ></option>
            <option v-for="(obj, key) in genders" :key="key" :value="obj.value">
              {{ obj.text }}
            </option>
          </select>
        </div>
        <div class="input m-0">
          <label
            for="fkEmmausInstitutionId"
            v-lang="'emmaus.emmaus_institution'"
          ></label>
          <select
            name="fkEmmausInstitutionId"
            v-model="tempSearchModel.fkEmmausInstitutionId"
            v-validate="'required|excluded:null'"
          >
            <option
              :value="null"
              v-lang="'emmaus.placeholder_for_emmaus_institution'"
            ></option>
            <option
              v-for="(obj, key) in institutions"
              :key="key"
              :value="obj.pkEmmausInstitutionId"
            >
              {{ obj.name }}
            </option>
          </select>
        </div>
        <div class="buttons-search">
          <button
            type="button"
            class="warning"
            v-on:click="resetSearch($event)"
            v-lang="'general.button_reset'"
          ></button
          >&nbsp;
          <button class="primary" v-lang="'general.button_search'"></button>
        </div>
      </form>
    </div>
    <Loader2 v-if="!resultsLoaded" />
    <span v-else>
      <NoData
        class="center-nodata"
        v-if="!people || people.length == 0"
      ></NoData>

      <span v-else>
        <Table
          :people="people"
          @updatePeopleList="getPeople()"
          :search="false"
          :searchModel="searchModel"
          @searchSort="searchSort"
          :orderColumn="searchModel.orderColumn"
          :orderSort="searchModel.orderSort"
        />
        <div class="pagination-container">
          <paginate
            v-if="people.length != 0 && pageCount > 1"
            :page-count="pageCount"
            v-model="searchModel.page"
            :click-handler="changePage"
            :prev-text="this.$lang('general.previous')"
            :next-text="this.$lang('general.next_page')"
            :container-class="'paggination'"
          ></paginate>
        </div>
      </span>
    </span>
    <IndexReport :peoples="peopleForReport"></IndexReport>
  </div>
</template>

<script>
import Vue from "vue";
import store from "./store";
import globalstore from "@/store";
import moment from "moment";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import Paginate from "vuejs-paginate";
import NoData from "../../General/NoData";
import Loader2 from "../../General/Loader2";
import Table from "./Table";
import { ResponsiveDirective } from "vue-responsive-components";
import institutionStore from "../Institution/store";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Arimo } from "@/assets/fonts/Arimo-Regular-normal.js";
import { ArimoBold } from "@/assets/fonts/Arimo_Bold.js";

import IndexReport from "./IndexReport.vue";

import { mapState } from "vuex";

Vue.use(Toasted);

export default {
  directives: {
    responsive: ResponsiveDirective,
  },
  components: {
    NoData,
    Paginate,
    moment,
    Table,
    Loader2,
    IndexReport,
  },
  data() {
    return {
      resultsLoaded: false,
      people: [],
      peopleForReport: [],
      tempSearchModel: {
        firstName:
          this.$route.query.firstName && this.$route.query.firstName != ""
            ? this.$route.query.firstName
            : "",
        lastName:
          this.$route.query.lastName && this.$route.query.lastName != ""
            ? this.$route.query.lastName
            : "",
        fkEmmausInstitutionId: this.$route.query.fkEmmausInstitutionId
          ? this.$route.query.fkEmmausInstitutionId
          : null,
        fkGenderCodeId: this.$route.query.fkGenderCodeId
          ? this.$route.query.fkGenderCodeId
          : null,
        page: this.$route.query.page ? this.$route.query.page : 1,
        limit: this.$route.query.limit ? this.$route.query.limit : 10,

        orderColumn: this.$route.query.orderColumn
          ? this.$route.query.orderColumn
          : "",
        orderSort: this.$route.query.orderSort
          ? this.$route.query.orderSort
          : "",
        isDeleted: null,
      },
      searchModel: {
        firstName:
          this.$route.query.firstName && this.$route.query.firstName != ""
            ? this.$route.query.firstName
            : "",
        lastName:
          this.$route.query.lastName && this.$route.query.lastName != ""
            ? this.$route.query.lastName
            : "",
        fkEmmausInstitutionId: this.$route.query.fkEmmausInstitutionId
          ? this.$route.query.fkEmmausInstitutionId
          : null,
        fkGenderCodeId: this.$route.query.fkGenderCodeId
          ? this.$route.query.fkGenderCodeId
          : null,
        page: this.$route.query.page ? this.$route.query.page : 1,
        limit: this.$route.query.limit ? this.$route.query.limit : 10,

        orderColumn: this.$route.query.orderColumn
          ? this.$route.query.orderColumn
          : "",
        orderSort: this.$route.query.orderSort
          ? this.$route.query.orderSort
          : "",
        isDeleted: null,
      },
      resultCount: 0,
      pageCount: 0,
      genders: [],
      institutions: [],
    };
  },
  watch: {
    "people.length": function (n, o) {
      if (this.people.length == 0 && this.searchModel.page > 1) {
        this.searchModel.page--;
        this.getPeople();
      } else if (n < o) {
        this.getPeople();
      }
    },
    // "$route.query.pkPersonId"() {
    //   this.getPeople();
    // },
    // "$route.query.update"() {
    //   if (this.$route.query.update == "Person") {
    //     var query = Object.assign({}, this.$route.query);
    //     delete query.update;
    //     this.$router.push({
    //       query,
    //     });
    //     this.resetSearch();
    //   }
    // },
  },
  created() {
    this.getGenders();
    this.getInstitutions();
    this.getPeople();
  },
  beforeDestroy() {
    this.$removeToast();
  },
  computed: {},
  methods: {
    searchSort(orderColumn, orderSort) {
      this.searchModel.orderColumn = orderColumn;
      this.searchModel.orderSort = orderSort;
      this.getPeople();
    },
    getGenders() {
      var $this = this;
      store
        .dispatch("GET_CODE_BY_ID", 2817)
        .then((response) => {
          $this.genders = response.data.model.items;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    getInstitutions() {
      var $this = this;
      institutionStore
        .dispatch("GET_ALL_EMMAUS_INSTITUTIONS")
        .then((response) => {
          $this.institutions = response.data.list;
        })
        .catch((error) => {});
    },

    createPerson() {
      this.$router.push({
        name: "EmmausPersonForm",
        query: { pkEmmausPersonId: 0 },
      });
    },
    changePage: function (page) {
      this.searchModel.page = page;
      this.getPeople();
    },
    searchFunction() {
      this.searchModel = this.tempSearchModel;
      this.searchModel.page = 1;
      this.getPeople();
    },
    getPeople() {
      this.$router.push({
        query: { ...this.$route.query, ...this.searchModel },
      });
      var $this = this;
      $this.resultsLoaded = false;
      if (this.$role(50003)) {
        this.searchModel.isDeleted = null;
      } else {
        this.searchModel.isDeleted = false;
      }
      store
        .dispatch("SEARCH_EMMAUS_PEOPLE", this.searchModel)
        .then((response) => {
          console.log("this ppl:", response.data.list);
          $this.people = response.data.list;

          if ($this.people.length > 0)
            $this.pageCount = response.data.list[0].pageCount;

          // var count = $this.resultCount;
          // var size = $this.searchModel.limit;

          // if (count % size !== 0)
          //   $this.pageCount = Math.floor(count / size) + 1;
          // else $this.pageCount = Math.floor(count / size);

          $this.resultsLoaded = true;
        })
        .catch((error) => {});
    },
    resetSearch() {
      this.tempSearchModel = {
        firstName: "",
        lastName: "",
        fkEmmausInstitutionId: null,
        fkGenderCodeId: null,
        page: 1,
        limit: 10,
        orderColumn: "",
        orderSort: "",
        isDeleted: null,
      };
      this.searchModel = {
        firstName: "",
        lastName: "",
        fkEmmausInstitutionId: null,
        fkGenderCodeId: null,
        page: 1,
        limit: 10,
        orderColumn: "",
        orderSort: "",
        isDeleted: null,
      };
      this.getPeople();
    },
    deletePerson(id) {
      var $this = this;
      this.$removeToast();
      Vue.toasted.show(lang("emmaus_persons.message_delete_person"), {
        theme: "toasted-primary",
        position: "top-center",
        duration: 5000,
        action: [
          {
            text: lang("general.label_yes"),
            onClick: (e, toastObject) => {
              store
                .dispatch("DELETE_EMMAUS_PERSON", id)
                .then((response) => {
                  $this.$success(
                    "emmaus_persons.message_person_successfully_deleted"
                  );

                  $this.getPeople();
                })
                .catch((error) => {
                  $this.$error("emmaus_persons.message_person_delete_failed");
                });

              toastObject.goAway(0);
            },
          },
          {
            text: lang("general.label_no"),
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        ],
      });
    },
    searchForReport() {
      // this.searchModel.firstName = "";
      // this.searchModel.lastName = "";
      // this.searchModel.fkEmmausInstitutionId = null;
      // this.searchModel.fkGenderCodeId = null;
      this.searchModel = this.tempSearchModel;
      var oldPage = this.searchModel.page;
      var oldLimit = this.searchModel.limit;
      this.searchModel.page = 1;
      this.searchModel.limit = 999999999;
      // this.searchModel.orderColumn = "";
      // this.searchModel.orderSort = "";
      // this.searchModel.isDeleted = false;
      var $this = this;
      $this.resultsLoaded = false;
      store
        .dispatch("SEARCH_EMMAUS_PEOPLE", this.searchModel)
        .then((response) => {
          this.peopleForReport = response.data;
          setTimeout(() => {
            $this.resultsLoaded = true;
            $this.Export2PDF();
            $this.searchModel.page = oldPage;
            $this.searchModel.limit = oldLimit;
            $this.searchFunction();
          }, 1000);
        });
    },
    searchForExcelReport() {
      this.searchModel = this.tempSearchModel;
      var oldPage = this.searchModel.page;
      var oldLimit = this.searchModel.limit;
      this.searchModel.page = 1;
      this.searchModel.limit = 999999999;
      var $this = this;
      $this.resultsLoaded = false;
      store
        .dispatch("SEARCH_EMMAUS_PEOPLE", this.searchModel)
        .then((response) => {
          this.peopleForReport = response.data;
          setTimeout(() => {
            $this.resultsLoaded = true;
            $this.Export2Excel();
            $this.searchModel.page = oldPage;
            $this.searchModel.limit = oldLimit;
            $this.searchFunction();
          }, 1000);
        });
    },
    generateParametersString() {
      var $this = this;
      var parametersString = "";

      //FIRST NAME
      if ($this.searchModel.firstName != "") {
        parametersString +=
          $this.$lang("general.firstName") + ": " + $this.searchModel.firstName;
      }

      //LAST NAME
      if ($this.searchModel.lastName != "") {
        if (parametersString != "") parametersString += "; ";
        parametersString +=
          $this.$lang("general.lastName") + ": " + $this.searchModel.lastName;
      }

      //GENDER
      if ($this.searchModel.fkGenderCodeId != null) {
        if (parametersString != "") parametersString += "; ";
        parametersString +=
          $this.$lang("general.gender") +
          ": " +
          $this.genders.find(
            (gender) => gender.value == $this.searchModel.fkGenderCodeId
          ).text;
      }

      //INSTITUTION
      if ($this.searchModel.fkEmmausInstitutionId != null) {
        if (parametersString != "") parametersString += "; ";
        parametersString +=
          $this.$lang("general.emmaus_institution") +
          ": " +
          $this.institutions.find(
            (institution) =>
              institution.pkEmmausInstitutionId ==
              $this.searchModel.fkEmmausInstitutionId
          ).name;
      }

      return parametersString;
    },
    Export2Excel() {
      var $this = this;
      var title = $this.$lang("emmaus_persons.title_report_index");

      var element = document.getElementsByTagName("td");
      for (let i = 0; i < element.length; i++) {
        element[i].style.verticalAlign = "middle";
      }

      var tab_text = "<table border='2px'><tr >";
      var textRange;
      var j = 0;

      var tab = document.getElementById("report_people"); // id of table
      var clspan = tab.rows[0].innerHTML.split("<th").length - 1;

      tab_text +=
        "<th  bgcolor='#87AFC6' colspan='" +
        clspan.toString() +
        "' id='activity'>" +
        title +
        "</tr>";

      if ($this.generateParametersString() != "") {
        tab_text +=
          "<th  bgcolor='#87AFC6' colspan='" +
          clspan.toString() +
          "' id='activity'>" +
          "</tr>";

        tab_text +=
          "<th  bgcolor='#87AFC6' colspan='" +
          clspan.toString() +
          "' id='activity'>" +
          $this.$lang("reports.search_parameters") +
          "</tr>";
        tab_text +=
          "<th  bgcolor='#87AFC6' colspan='" +
          clspan.toString() +
          "' id='activity'>" +
          $this.generateParametersString() +
          "</tr>";
      }

      for (j = 1; j < tab.rows.length; j++) {
        tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";

        //tab_text=tab_text+"</tr>";
      }

      tab_text = tab_text.replace(
        /<td/gi,
        "<td class='xl70' width='300' align='center' vertical-align:'top' "
      );
      tab_text = tab_text.replace(/12-17/gi, "&nbsp;12-17");
      tab_text = tab_text + "</table>";
      tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, ""); //remove if u want links in your table
      tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
      tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");

      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        // If Internet Explorer
        txtArea1.document.open("txt/html", "replace");
        txtArea1.document.write(tab_text);
        txtArea1.document.close();
        txtArea1.focus();
        sa = txtArea1.document.execCommand(
          "SaveAs",
          true,
          "Say Thanks to Sumit.xls"
        );
      } //other browser not tested on IE 11
      else {
        var a = window.document.createElement("a");
        a.setAttribute(
          "href",
          "data:attachment/xlsx;charset=utf-8,%EF%BB%BF" +
            encodeURIComponent(tab_text)
        );
        a.setAttribute("download", title + ".xls");
        window.document.body.appendChild(a);
        a.click();
      }
    },
    async Export2PDF(element) {
      var element = "body";
      var doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
      });

      var $this = this;
      doc.addFileToVFS("Arimo-Regular.ttf", Arimo);
      doc.addFont("Arimo-Regular.ttf", "Arimo", "normal");
      doc.setFont("Arimo");

      doc.addFileToVFS("Arimo-Bold.ttf", ArimoBold);
      doc.addFont("Arimo-Bold.ttf", "Arimo", "bold");

      var footer = async function (data) {};

      var title = $this.$lang("emmaus_persons.title_report_index");

      var outputName = title + ".pdf";

      var Font = 8;
      Font =
        $this.$route.query.id == 42901 ||
        $this.$route.query.id == 42898 ||
        $this.$route.query.id == 42899
          ? 7
          : Font;

      var pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      var number_of_pages = doc.internal.getNumberOfPages();

      // TABELA
      doc.autoTable({
        styles: {
          fontSize: Font,
          font: "Arimo",
          halign: "left",
          lineColor: [44, 62, 80],
          lineWidth: 0.55,
        },
        headStyles: {
          halign: "left",
          fontStyle: "Arimo",
          fontSize: 12,
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineColor: [255, 255, 255],
          lineWidth: 0.55,
        },
        columnStyles: {
          0: {
            halign: "left",
            valign: "middle",
            cellWidth: 10,
          },
          1: {
            halign: "left",
            valign: "middle",
            cellWidth: "auto",
          },
        },
        html: "#report_people",

        didParseCell: function (HookData) {
          if (HookData.row.raw.id == "countryRow") {
            var rows = HookData.table.body;

            HookData.cell.styles.textColor = [0, 0, 0];
            HookData.cell.styles.fontSize = 10;
            HookData.cell.styles.fillColor = [176, 224, 230];
          }
        },
        drawHeader: function (HookData) {
          return false;
        },

        didDrawPage: async function (data) {
          data.settings.margin.top = 5;
          var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
          var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

          if (data.pageNumber === 1) {
            var splitTitle = doc.splitTextToSize(title, 250);
            var dim = doc.getTextDimensions(title);

            var yearsPos = dim.w > 179 ? 64 : 57;
            doc.setFontSize(13);
            doc.setTextColor(60);
            doc.text(splitTitle, pageWidth / 2, 15, "center");

            if (
              $this.searchModel.firstName != "" ||
              $this.searchModel.lastName != "" ||
              $this.searchModel.fkGenderCodeId != null ||
              $this.searchModel.fkEmmausInstitutionId != null
            ) {
              doc.setFontSize(11);
              doc.text(
                $this.$lang("reports.search_parameters"),
                pageWidth / 2,
                22,
                "center"
              );

              var parametersString = "";
              var parametersString2 = "";

              //FIRST NAME
              if ($this.searchModel.firstName != "") {
                parametersString +=
                  $this.$lang("general.firstName") +
                  ": " +
                  $this.searchModel.firstName;
              }

              //LAST NAME
              if ($this.searchModel.lastName != "") {
                if (parametersString != "") parametersString += "; ";
                parametersString +=
                  $this.$lang("general.lastName") +
                  ": " +
                  $this.searchModel.lastName;
              }

              //GENDER
              if ($this.searchModel.fkGenderCodeId != null) {
                parametersString2 +=
                  $this.$lang("general.gender") +
                  ": " +
                  $this.genders.find(
                    (gender) => gender.value == $this.searchModel.fkGenderCodeId
                  ).text;
              }

              //INSTITUTION
              if ($this.searchModel.fkEmmausInstitutionId != null) {
                if (parametersString2 != "") parametersString2 += "; ";
                parametersString2 +=
                  $this.$lang("general.emmaus_institution") +
                  ": " +
                  $this.institutions.find(
                    (institution) =>
                      institution.pkEmmausInstitutionId ==
                      $this.searchModel.fkEmmausInstitutionId
                  ).name;
              }
              doc.setFontSize(10);
              doc.text(parametersString, pageWidth / 2, 26, "center");
              doc.text(parametersString2, pageWidth / 2, 30, "center");
            }
          }
        },
        didDrawCell: function (HookData) {},
        margin: { top: 30 },
        rowPageBreak: "avoid",
        pageBreak: "avoid",
      });

      number_of_pages = doc.internal.getNumberOfPages();
      for (var i = 1; i <= number_of_pages; i++) {
        doc.setPage(i);
        doc.setFontSize(12);
        doc.text(i + "/" + number_of_pages, 10, 290);
        if (i < number_of_pages) {
          doc.setFontSize(9);
        }
      }

      await doc.save(outputName);

      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        window.open(doc.output("bloburl"), "_blank");
      }
      $this.showLoader = false;
      $this.templateData = null;
      $this.selectedReportNumber = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-search {
  grid-column: 1/5;
}
#avatarImg {
  width: 30% !important;
  height: 30% !important;
}
.actions {
  position: relative;
}
.official-records {
  .form {
    grid-template-columns: 1fr;
    .submit {
      grid-column: 1/2;
      text-align: right;
    }
  }
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  .buttons-search {
    grid-column: 1/5;
    text-align: right;
  }
}
.form.small {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 10px;
  .buttons-search {
    grid-column: 1/2;
    text-align: right;
  }
}
.form-watch-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  .buttons-search {
    grid-column: 1/5;
    text-align: right;
  }
}

.left-aligment {
  text-align: right;
  .paggination {
    position: relative !important;
  }
}
.search {
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
}
</style>
