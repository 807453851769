<template>
  <div>
    <Loader2 v-if="!showTranslations" />
    <form
      v-else
      v-on:submit.prevent="save()"
      data-vv-scope="emmaus_organization_form"
    >
      <div
        class="input m-0"
        :class="
          errors.first('emmaus_organization_form.name') !== undefined
            ? 'input-error'
            : ''
        "
      >
        <label for="name">
          <span v-lang="'emmaus.training_organize_name'"></span>
          <span class="required-field-star">&#x2605;</span>
        </label>
        <input
          class="m-0"
          v-validate="'required'"
          v-model="model.name"
          type="text"
          name="name"
          autocomplete="off"
        />
        <div>
          <span
            class="error"
            v-html="errors.first('emmaus_organization_form.name')"
          ></span>
        </div>
      </div>
      <div class="input m-0 textarea">
        <label
          for="note"
          v-lang="'emmaus.training_organize_description'"
        ></label>
        <textarea name="note" v-model="model.description"></textarea>
      </div>
      <!-- PREVODI -->
      <div class="translations-class">
        <div v-for="(obj, key) in langauges" :key="key" class="input m-0">
          <div
            class="input m-0"
            :class="
              errors.first('emmaus_organization_form.' + obj.code) !== undefined
                ? 'input-error'
                : ''
            "
          >
            <label :for="obj.code">
              <span>{{ obj.name }}</span>
              <span class="required-field-star">&#x2605;</span>
            </label>
            <input
              class="m-0"
              type="text"
              v-model="translationModel.translations[Number(key)]"
              :name="obj.code"
              autocomplete="off"
              v-validate="'required'"
            />
            <div>
              <span
                class="error"
                v-html="errors.first('emmaus_organization_form.' + obj.code)"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div class="submit" id="sub">
        <!-- <button class="primary" v-lang="'general.button_submit'"></button> -->
        <Spinner
          :showSpinner="isSubmited"
          :buttonText="$lang('general.button_submit')"
          :buttonClass="'primary'"
        />
      </div>
    </form>
  </div>
</template>

<script>
import Vue from "vue";
import { lang } from "@/global-functions";
import VeeValidate from "vee-validate";
import store from "./store";
import storeLanguage from "@/components/UserAndRolesManagement/store";
import Loader2 from "../../General/Loader2";
import Spinner from "@/components/General/Spinner";

Vue.use(VeeValidate);
export default {
  props: {
    organizationData: {
      default: null
    },
    errorModel: {
      key: "",
      message: ""
    },
    isSubmited: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Loader2,
    Spinner
  },
  data() {
    return {
      showTranslations: false,
      model: {
        name: "",
        description: "",
        valuesForLanguages: []
      },
      translationModel: {
        titleId: "",
        translations: []
      },
      langauges: [],
      validation_messages: {
        custom: {
          name: {
            required: lang("validation.required")
          },
          sr: {
            required: lang("validation.required")
          },
          hr: {
            required: lang("validation.required")
          },
          bs: {
            required: lang("validation.required")
          }
        }
      }
    };
  },

  watch: {
    organizationData() {
      this.model = this.organizationData;
    },
    errorModel() {
      this.$importModelStateErrors(
        this.errors,
        this.errorModel.modelState,
        "emmaus_organization_form"
      );
    }
  },

  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  created() {
    this.getAllLanguages();
    setTimeout(() => {
      if (this.model.pkEmmausOrganizationId != null) {
        this.getTranslationsByID(this.model.titleId);
      } else {
        this.showTranslations = true;
      }
    }, 700);
  },
  methods: {
    save() {
      this.model.valuesForLanguages = [];
      for (let i = 0; i < this.translationModel.translations.length; i++) {
        var model = {
          titleId: this.model.titleId,
          langCode: this.langauges[i].code,
          titleValue: this.translationModel.translations[i]
        };
        this.model.valuesForLanguages.push(model);
      }
      this.$validator.validateAll("emmaus_organization_form").then((valid) => {
        if (valid) {
          this.$emit("save", this.model);
        }
      });
    },
    seacrh(code) {
      for (var i = 0; i < this.model.valuesForLanguages.length; i++) {
        if (this.model.valuesForLanguages[i].langCode === code) {
          return this.model.valuesForLanguages[i].titleValue;
        }
      }
    },
    getTranslationsByID(id) {
      var $this = this;
      storeLanguage
        .dispatch("GET_TRANSLATION_BY_ID", id)
        .then((response) => {
          $this.model.valuesForLanguages = response.data;
          $this.model.titleId = $this.model.valuesForLanguages[0].titleId;

          for (let i = 0; i < $this.langauges.length; i++) {
            $this.translationModel.translations[i] = $this.seacrh(
              $this.langauges[i].code
            );
          }

          $this.showTranslations = true;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    getAllLanguages() {
      var $this = this;
      storeLanguage
        .dispatch("GET_ALL_LANGUAGES")
        .then((response) => {
          $this.langauges = response.data.list;
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/colors";
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  & > div {
    // display: flex;
    // flex: 1;
    // align-items: center;
    justify-content: center;
  }
  .translations-class {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    grid-column: 1/3;
  }
  .submit {
    color: red;
    grid-column: 1/3;
  }
}

textarea {
  height: 26px;
}
</style>
