<template>
  <div>
    <table id="detils_training_report" style="display:none;">
      <thead></thead>
      <tbody>
        <tr>
          <td>{{ $lang("training.emmaus_training_name") }}</td>
          <td>{{ model.titleName }}</td>
        </tr>
        <tr>
          <td>{{ $lang("training.emmaus_training_type") }}</td>
          <td>{{ model.typeName }}</td>
        </tr>
        <tr>
          <td>{{ $lang("training.emmaus_training_created_by") }}</td>
          <!-- <td>{{ $lang("training.emmaus_organization_name") }}</td> -->
          <td>{{ model.emmausOrganizationName }}</td>
        </tr>
        <tr>
          <td>{{ $lang("training.emmaus_training_date") }}</td>
          <td>{{ $moment(model.date).format("DD.MM.YYYY.") }}</td>
        </tr>
        <tr>
          <td>{{ $lang("training.emmaus_training_certified") }}</td>
          <td>
            {{
              model.certified
                ? $lang("general.label_yes")
                : $lang("general.label_no")
            }}
          </td>
        </tr>
        <!-- <tr>
          <td>{{ $lang("training.emmaus_training_created_by") }}</td>
          <td>{{ model.createdByName }}</td>
        </tr> -->
      </tbody>
    </table>
    <table style="display:none;" id="report_trianing_themes">
      <thead>
        <tr>
          <th>{{ $lang("emmaus.label_themes_report") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(obj, key) in themes" :key="key">
          <td>{{ obj.name }}</td>
        </tr>
      </tbody>
    </table>
    <table style="display:none;" id="report_trianing_fileds">
      <thead>
        <tr>
          <th>{{ $lang("emmaus.label_fields") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(obj, key) in fileds" :key="key">
          <td>{{ obj.name }}</td>
        </tr>
      </tbody>
    </table>
    <table style="display:none;" id="report_details_peoples">
      <thead>
        <tr>
          <th class="text-align-center">#</th>
          <th>
            {{ $lang("general.first_name") }}
            <span></span>
          </th>
          <th>
            {{ $lang("general.last_name") }}
            <span></span>
          </th>
          <th>
            {{ $lang("general.gender") }}
            <span></span>
          </th>
          <th>{{ $lang("emmaus.emmaus_institution") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(obj, key) in peoples" :key="key">
          <td>
            {{ key + 1 }}
          </td>
          <td>{{ obj.firstName }}</td>
          <td>{{ obj.lastName }}</td>
          <td>
            {{ obj.genderName }}
          </td>
          <td>{{ obj.emmausInstitutionName }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    model: Object,
    peoples: Array,
    themes: Array,
    fileds: Array
  }
};
</script>
