<template>
  <div class="spinner" :class="show ? 'p-5' : ''">
    <div v-if="show" class="sk-chase">
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
    </div>
    <button v-on:click.stop="$emit('click')" v-else :class="buttonClass">
      <i v-if="iconClass" :class="iconClass"></i>
      {{ buttonText }}
    </button>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    iconClass: {
      type: String,
      default: null
    },
    buttonClass: {
      type: String,
      default: "primary"
    },
    buttonText: {
      type: String,
      default: ""
    },
    showSpinner: {
      type: Boolean,
      default: null
    }
  },
  computed: {
    ...mapState(["spinner"]),
    show() {
      if (this.showSpinner == null) {
        return this.spinner;
      } else {
        return this.showSpinner;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/colors";

.p-5 {
  padding: 5px !important;
}
.spinner {
  width: 100%;
  margin: 0 !important;
  // display: inline-flex;
  // min-height: 30px;
  // min-width: 30px;
  // margin: 5px;
  // align-items: center;
  // justify-content: center;
}
.sk-chase {
  width: 30px;
  height: 30px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  margin: auto;
  padding: 5px;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: $secondaryColor;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
</style>
