<template>
  <div>
    <template v-if="isUserValid">
      <Admin />
      <Loader />
      <div v-if="notification.pkNotificationId">
        <div class="back-end">
          <div class="message">
            <div class="message-content">{{ notification.messageContent }}</div>
            <div class="buttons">
              <button
                class="primary btn-ok"
                type="button"
                v-lang="'general.btn_ok'"
                v-on:click="messageRead()"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else class="spinner-container">
      <div class="spnner spinner-container">
        <div class="sk-chase">
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import App from "./App";
import store from "./store";
import { mapState } from "vuex";
import userStore from "@/components/UserAndRolesManagement/store";
import Admin from "./components/UserAndRolesManagement/AdminPanel/AdminPanel";
import Loader from "@/components/General/Loader";
var $this = this;

export default {
  components: {
    App,
    Admin,
    Loader
  },
  updated() {
    this.headerDataAxios();

    // if (this.$route.name != "Login") {
    //   this.unseenBroadcastMessage();
    // }
  },
  data() {
    return {
      notification: {},
      notifications: [],
      isUserValid: false
    };
  },
  computed: {
    ...mapState(["user"])
  },
  beforeMount() {
    this.getCurrentUser();
  },
  watch: {
    $route(newRoute, oldRoute) {
      if (
        (newRoute.name === "Home" && oldRoute.name === "Login") ||
        newRoute.name === "Login"
      ) {
        this.isUserValid = true;
      }
    },
    notifications() {
      if (this.notifications.length > 0) {
        this.notification = this.notifications[0];
      }
    },
    "$route.query.broadcast_notification_id"() {
      if (!this.$route.query.broadcast_notification_id) {
        return;
      }
      // this.getBroadcastMessage();
    }
  },
  created() {
    window.document.title = "MSB";
    $this = this;
    this.headerDataAxios();
    this.intNotificationModel();
    if (this.$route.name == "Login") {
      this.isUserValid = true;
    }
  },

  methods: {
    intNotificationModel() {
      this.notification = {
        messageContent: "",
        pkNotificationId: 0
      };
    },
    headerDataAxios() {
      //AXIOS AUTH
      store.state.$axios_auth.interceptors.request.handlers = [];
      store.state.$axios_auth.interceptors.request.use(
        (config) => {
          if (localStorage.token) {
            config.headers["Authorization"] = localStorage.token;
            config.headers["entity_id"] = this.$route.params.entity_type
              ? this.$route.params.entity_id
              : 1;
            config.headers["entity_type"] = this.$route.params.entity_type
              ? this.$route.params.entity_id
              : 1;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      //AXIOS AUTH CORE
      store.state.$axios_auth_core.interceptors.request.handlers = [];
      store.state.$axios_auth_core.interceptors.request.use(
        (config) => {
          if (localStorage.token) {
            config.headers["Authorization"] = localStorage.token;
            config.headers["entity_id"] = this.$route.params.entity_type
              ? this.$route.params.entity_id
              : 1;
            config.headers["entity_type"] = this.$route.params.entity_type
              ? this.$route.params.entity_id
              : 1;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    messageRead() {
      store
        .dispatch(
          "MARK_AS_READ_BRODCAST_MESSAGE",
          this.notification.pkNotificationId
        )
        .then((response) => {
          $this.notifications.splice(
            $this.notifications.indexOf($this.notification),
            1
          );
          $this.intNotificationModel();
        })
        .catch((error) => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    getCurrentUser() {
      let token = localStorage.token;

      if (!token) {
        this.$router.push({ name: "Login" });
        return;
      }
      var $this = this;
      userStore
        .dispatch("GET_CURRENT_USER", token)
        .then((response) => {
          $this.isUserValid = true;
        })
        .catch((error) => {
          window.localStorage.clear();
          $this.$router.push({ name: "Login" });
          if (error && error.status == 600) $this.$router.push("/logout");
        });
    }
  }
};
</script>

<style lang="scss">
@import "/assets/css/main";
@import "/assets/css/animate";
@import "/assets/css/spinner";
@import "./assets/icons/style.css";

.back-end {
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 99999;
  overflow: auto;
  padding: 10px;
}
.message {
  position: relative;
  background: white;
  color: black;
  width: 500px;
  height: 500px;
  padding: 20px;
  margin: auto;
  word-wrap: break-word;
  height: auto;
}
// .btn-ok {
//   position: absolute;
//   bottom: 10px;
//   left: 50%;
//   transform: translateX(-50%);
// }
.buttons {
  display: flex;

  justify-content: center;
}
.message-content {
  margin-bottom: 20px;
}

.spinner-container {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
</style>
