<template>
  <div>
    <div class="container">
      <div class="header card">
        <h1>
          <i class="icon-survey"></i>
          <span v-lang="'lap.title_statistic'"> </span>
        </h1>
        <div class="actions">
          <button
            v-if="
              reportSearchModel.fkModuleIIIGoalId &&
              reportSearchModel.fkReportYearId &&
              $role(50007)
            "
            class="primary mr-5"
            v-lang="'survey.export_to_pdf'"
            @click="exportToPDF"
          ></button>
          <a
            class="primary"
            v-on:click="$router.go(-1)"
            v-lang="'general.button_back'"
          ></a>
        </div>
      </div>
      <!-- SELECT YEAR BLOCK -->
      <div class="card">
        <div
          class="form grid grid-cols-3"
          :class="{ 'grid-cols-2': !$role(50007) }"
          v-responsive="{ small: (el) => el.width < 650 }"
        >
          <div class="input m-0 col-span-2">
            <label for="createdBy">
              <span v-lang="'lap.label_select_year'"></span>
            </label>
            <select
              v-model="searchModel.fkReportYearId"
              name="createdBy"
              id="dropdownStatusCode"
            >
              <option :value="null">
                {{ $lang("lap.option_select_year") }}
              </option>
              <option
                v-for="(obj, key) in allReportYears"
                :key="key"
                :value="obj.pkReportYearId"
              >
                {{ obj.year }}
              </option>
            </select>
          </div>

          <!-- SELECT GOAL BLOCK -->

          <div class="input m-0 col-span-2">
            <label for="createdBy">
              <span v-lang="'lap.label_select_goal'"></span>
            </label>
            <select
              v-model="searchModel.fkModuleIIIGoalId"
              name="createdBy"
              id="dropdownStatusCode"
            >
              <option :value="null">
                {{ $lang("lap.option_select_goal") }}
              </option>
              <option
                v-for="(obj, key) in allGoals"
                :key="key"
                :value="obj.pkModuleIIIGoalId"
              >
                {{ obj.translated }}
              </option>
            </select>
          </div>

          <!-- SELECT ORGANISATION BLOCK -->

          <div v-if="$role(50007)" class="input m-0 col-span-4">
            <label for="createdBy">
              <span v-lang="'lap.label_select_team_coordinator'"></span>
            </label>
            <select
              v-model="searchModel.fkEmmausOrganisationId"
              name="createdBy"
              id="dropdownStatusCode"
            >
              <option :value="null">
                {{ $lang("lap.option_select_team_coordinator") }}
              </option>
              <option v-for="(obj, key) in regions" :key="key" :value="obj.id">
                {{ obj.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="card" v-if="searchModel.fkModuleIIIGoalId">
        <table class="statistic-total-table">
          <thead>
            <tr>
              <th colspan="4" class="border-b-white">
                <h2 class="margin-0">
                  {{ $lang("lap.statistic_for_goal") }}:
                  {{
                    allGoals.find(
                      ({ pkModuleIIIGoalId }) =>
                        pkModuleIIIGoalId == searchModel.fkModuleIIIGoalId
                    ).translated
                  }}
                </h2>
              </th>
            </tr>
            <tr>
              <th>
                <div class="flex-center">
                  {{ $lang("lap.statistic_totalNotStarted") }}
                  <i class="icon-circle icon-not-started"></i>
                </div>
              </th>
              <th>
                <div class="flex-center">
                  {{ $lang("lap.statistic_totalInProgress") }}
                  <i class="icon-circle icon-in-progress"></i>
                </div>
              </th>
              <th>
                <div class="flex-center">
                  {{ $lang("lap.statistic_totalImplemented") }}
                  <i class="icon-circle icon-implemented"></i>
                </div>
              </th>
              <th v-lang="'lap.statistic_total'"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ statistic.totalNotStarted }}</td>
              <td>{{ statistic.totalInProgress }}</td>
              <td>{{ statistic.totalImplemented }}</td>
              <td>{{ statistic.total }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="card"
        v-if="
          searchModel.fkModuleIIIGoalId &&
          !searchModel.fkEmmausOrganisationId &&
          statistic.groupedActivies
        "
      >
        <BarChart
          :data="statistic.groupedActivies"
          :key="searchModel.fkEmmausOrganisationId"
        />
      </div>
      <div
        class="card"
        v-if="
          searchModel.fkModuleIIIGoalId &&
          !searchModel.fkEmmausOrganisationId &&
          statistic.groupedActivies
        "
      >
        <table class="statistic-table">
          <thead>
            <tr>
              <th v-lang="'lap.label_select_team_coordinator'"></th>
              <th>
                <div class="flex-center">
                  {{ $lang("lap.statistic_totalNotStarted") }}
                  <i class="icon-circle icon-not-started"></i>
                </div>
              </th>
              <th>
                <div class="flex-center">
                  {{ $lang("lap.statistic_totalInProgress") }}
                  <i class="icon-circle icon-in-progress"></i>
                </div>
              </th>
              <th>
                <div class="flex-center">
                  {{ $lang("lap.statistic_totalImplemented") }}
                  <i class="icon-circle icon-implemented"></i>
                </div>
              </th>
              <th v-lang="'lap.statistic_total'"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(obj, key) in statistic.groupedActivies" :key="key">
              <td>{{ obj.organizationName }}</td>
              <td>
                {{ obj.notStarted }}
              </td>
              <td>
                {{ obj.inProgress }}
              </td>
              <td>
                {{ obj.implemented }}
              </td>
              <td>
                {{ obj.total }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <modal name="pdfViewer" :draggable="false" width="50%" height="600">
        <Loader2 v-show="loading" />
        <div v-show="!loading">
          <div id="viewerRender"></div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import store from "./store";
import rootStore from "@/store";
import Vue from "vue";
import vmodal from "vue-js-modal";
import Loader2 from "../General/Loader2";
import statisticReport from "./mixins/StatisticReport.vue";
import BarChart from "./BarChart.vue";
Vue.use(vmodal);
export default {
  mixins: [statisticReport],
  components: {
    Loader2,
    BarChart
  },
  data() {
    return {
      searchModel: {
        fkEmmausOrganisationId:
          this.$role(60007) && !rootStore.state.user.superAdmin
            ? rootStore.state.user.fkEmmausOrganizationId
            : null,
        fkModuleIIIGoalId: null,
        fkReportYearId: null
      },
      regions: [],
      allGoals: [],
      allReportYears: [],
      statistic: [],
      reportSearchModel: {
        fkEmmausOrganisationId: null,
        fkModuleIIIGoalId: null,
        fkReportYearId: null
      },
      reportData: [],
      loading: false
    };
  },
  created() {
    this.getAllReportYears();
    this.getAllGoals();
    this.getAllOrganizations();
  },
  methods: {
    getAllReportYears() {
      store
        .dispatch("GET_ALL_REPORT_YEARS")
        .then((response) => {
          this.allReportYears = response.data;
          this.searchModel.fkReportYearId = response.data.find(
            ({ year }) => year == new Date().getFullYear()
          ).pkReportYearId;
        })
        .catch((error) => {
          if (error.status == 400) this.errorModel = error.data;
        });
    },
    getAllGoals() {
      store
        .dispatch("GET_ALL_GOALS", { pageNum: 1, pageSize: 1000 })
        .then((response) => {
          this.allGoals = response.data.moduleIIIGoals;
        })
        .catch((error) => {
          if (error.status == 400) this.errorModel = error.data;
        });
    },
    getAllOrganizations() {
      store.dispatch("GET_REGIONS").then((response) => {
        this.regions = response.data.list;
      });
    },
    getStatistic() {
      store.dispatch("GET_STATISTIC", this.searchModel).then((res) => {
        this.statistic = res.data;
      });
    },
    async getStatisticForReport() {
      await store
        .dispatch("GET_STATISTIC", this.reportSearchModel)
        .then((res) => {
          this.reportData = res.data;
        });
    },
    async exportToPDF() {
      if (
        !this.reportSearchModel.fkReportYearId ||
        !this.reportSearchModel.fkModuleIIIGoalId
      )
        return;

      this.$modal.show("pdfViewer", {});

      this.loading = true;
      try {
        await this.getStatisticForReport();
        this.stimulsoftCreate();
      } catch (error) {
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    searchModel: {
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal.fkModuleIIIGoalId) return;
        this.reportSearchModel.fkModuleIIIGoalId =
          this.searchModel.fkModuleIIIGoalId;
        this.reportSearchModel.fkReportYearId = this.searchModel.fkReportYearId;
        this.getStatistic();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;

  grid-gap: 10px;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}
.statistic-table {
  border-collapse: collapse;
  th.th-title {
    text-align: left !important;
  }
  th,
  td {
    text-align: center !important;
    border-bottom: 1px solid black;
  }
  td {
    border-left: 1px solid black;
    border-right: 1px solid black;
  }
  th {
    border-left: 1px solid #474b4f;
    border-right: 1px solid #474b4f;
  }
}
.statistic-total-table {
  th,
  td {
    text-align: center !important;
  }
  td {
    font-weight: 600;
  }
}
.border-b-white {
  border-bottom: solid 1px white;
}
.margin-0 {
  margin: 0;
}
.icon-not-started {
  background: rgb(206, 0, 0);
  color: rgb(206, 0, 0);
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;
}
.icon-in-progress {
  background: #ff9f05;
  color: #ff9f05;
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;
}
.icon-implemented {
  background: #86c232;
  color: #86c232;
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mr-5 {
  margin-right: 5px;
}
</style>
<style lang="scss">
#viewerRender td {
  text-align: left !important;
}
</style>
