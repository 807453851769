<template>
  <form v-on:submit.prevent="save()" data-vv-scope="action_form">
    <div
      class="input m-0"
      :class="errors.first('action_form.pkController') !== undefined ? 'input-error' : '' "
    >
      <label for="pkController" v-lang="'action.label_name_of_controller'"></label>
      <input
        v-validate="'required'"
        class="m-0"
        type="text"
        v-model="model.pkController"
        name="pkController"
        autocomplete="off"
      />
      <div>
        <span class="error" v-html="errors.first('action_form.pkController')"></span>
      </div>
    </div>

    <div
      class="input m-0"
      :class="errors.first('action_form.action') !== undefined ? 'input-error' : '' "
    >
      <label for="action" v-lang="'action.label_name_of_action'"></label>
      <input class="m-0" v-model="model.action" type="text" name="action" autocomplete="off" />
      <div>
        <span class="error" v-html="errors.first('action_form.action')"></span>
      </div>
    </div>
    <div
      class="input m-0"
      :class="errors.first('action_form.fkHttpMethodId') !== undefined ? 'input-error' : '' "
    >
      <label for="fkHttpMethodId" v-lang="'action.label_name_of_http_method'"></label>
      <select
        v-model="model.fkHttpMethodId"
        v-validate="'required|excluded:0'"
        name="fkHttpMethodId"
        id="dropdown"
      >
        <option value disabled hidden v-lang="'action.placeholder_for_http_method'"></option>
        <option v-for="(obj, key) in httpMethods" :key="key" :value="obj.id">{{obj.name}}</option>
      </select>
      <div>
        <span class="error" v-html="errors.first('action_form.fkHttpMethodId')"></span>
      </div>
    </div>
    <div
      class="input m-0"
      :class="errors.first('action_form.fkActionTypeId') !== undefined ? 'input-error' : '' "
    >
      <label for="fkActionTypeId" v-lang="'action.label_name_of_action_type'"></label>
      <select
        v-model="model.fkActionTypeId"
        v-validate="'required|excluded:0'"
        name="fkActionTypeId"
        id="dropdown"
      >
        <option value disabled hidden v-lang="'action.placeholder_for_action_type'"></option>
        <option v-for="(obj, key) in actionTypes" :key="key" :value="obj.id">{{obj.name}}</option>
      </select>
      <div>
        <span class="error" v-html="errors.first('action_form.fkActionTypeId')"></span>
      </div>
    </div>
    <div
      class="input m-0"
      :class="errors.first('action_form.description') !== undefined ? 'input-error' : '' "
    >
      <label for="description" v-lang="'action.label_description_action'"></label>
      <input
        class="m-0"
        type="text"
        v-model="model.description"
        name="description"
        autocomplete="off"
      />
      <div>
        <span class="error" v-html="errors.first('action_form.description')"></span>
      </div>
    </div>
    <div
      class="input m-0"
      :class="errors.first('action_form.areas') !== undefined ? 'input-error' : '' "
    >
      <label for="areas" v-lang="'action.label_name_of_area'"></label>
      <input
        v-validate="'required'"
        class="m-0"
        type="text"
        v-model="model.areas"
        name="areas"
        autocomplete="off"
      />
      <div>
        <span class="error" v-html="errors.first('action_form.areas')"></span>
      </div>
    </div>
    <div
      class="input m-0"
      :class="errors.first('action_form.titleId') !== undefined ? 'input-error' : '' "
    >
      <label for="titleId">
        <span v-lang="'action.label_titleId'"></span>
      </label>
      <input
        v-validate="'required'"
        class="m-0"
        type="text"
        v-model="model.titleId"
        name="titleId"
        autocomplete="off"
      />
      <div>
        <span class="error" v-html="errors.first('action_form.titleId')"></span>
      </div>
    </div>

    <div
      class="input m-0"
      :class="errors.first('action_form.ApiPath') !== undefined ? 'input-error' : '' "
    >
      <label for="apiPath" v-lang="'action.label_name_of_api_path'"></label>
      <input class="m-0" type="text" v-model="model.apiPath" name="ApiPath" autocomplete="off" />
      <div class="error" v-html="errors.first('action_form.ApiPath')"></div>
    </div>
    <div class="fieldset">
      <div class="logs-for-action">
        <fieldset>
          <legend v-lang="'action.logs_action'"></legend>
          <div class="input-checkbox">
            <label for="userLog">
              <input
                class="m-0"
                type="checkbox"
                v-model="model.userLog"
                id="userLog"
                autocomplete="off"
                hidden
              />
              <span v-lang="'action.label_user_log'"></span>
            </label>
          </div>

          <div class="input-checkbox">
            <label for="dataLog">
              <input
                class="m-0"
                type="checkbox"
                v-model="model.dataLog"
                id="dataLog"
                autocomplete="off"
                hidden
              />
              <span v-lang="'action.label_data_log'"></span>
            </label>
          </div>
          <div class="input-checkbox">
            <label for="errorLog">
              <input
                class="m-0"
                type="checkbox"
                v-model="model.errorLog"
                id="errorLog"
                autocomplete="off"
                hidden
              />
              <span v-lang="'action.label_error_log'"></span>
            </label>
          </div>
          <div class="input-checkbox">
            <label for="actionLog">
              <input
                class="m-0"
                type="checkbox"
                v-model="model.actionLog"
                id="actionLog"
                autocomplete="off"
                hidden
              />
              <span v-lang="'action.label_action_log'"></span>
            </label>
          </div>
        </fieldset>
      </div>
      <fieldset>
        <legend v-lang="'action.admin_action'"></legend>

        <div class="input-checkbox">
          <label for="adminAction">
            <input
              class="m-0"
              type="checkbox"
              v-model="model.adminAction"
              id="adminAction"
              autocomplete="off"
              hidden
            />
            <span v-lang="'action.label_admin_action'"></span>
          </label>
        </div>
        <div class="input-checkbox">
          <label for="superAdminAction">
            <input
              class="m-0"
              type="checkbox"
              v-model="model.superAdminAction"
              id="superAdminAction"
              autocomplete="off"
              hidden
            />
            <span v-lang="'action.label_superadmin_action'"></span>
          </label>
        </div>
      </fieldset>
    </div>
    <div class="submit" id="sub">
      <button class="primary" v-lang="'general.button_submit'"></button>
    </div>
  </form>
</template>

<script>
import Vue from "vue";
import { lang } from "@/global-functions";
import VeeValidate from "vee-validate";
import store from "../../store";
Vue.use(VeeValidate);
export default {
  props: {
    actionData: {
      default: null
    },
    errorModel: {
      key: "",
      message: ""
    }
  },
  data() {
    return {
      actionTypes: [],
      httpMethods: [],
      model: {
        pkController: "",
        fkHttpMethodId: "",
        action: "",
        apiPath: "",
        areas: "",
        fkActionTypeId: "",
        titleId: "",
        description: "",
        userLog: false,
        actionLog: false,
        dataLog: false,
        errorLog: false,
        adminAction: false,
        superAdminAction: false
      },
      validation_messages: {
        custom: {
          titleId: {
            required: lang("validation.required")
          },
          areas: {
            required: lang("validation.required")
          },
          pkController: {
            required: lang("validation.required")
          },
          fkHttpMethodId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          },
          fkActionTypeId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          }
        }
      }
    };
  },

  watch: {
    actionData() {
      this.model = this.actionData;
    },
    errorModel() {
      this.$importModelStateErrors(
        this.errors,
        this.errorModel.modelState,
        "action_form"
      );
    }
  },
  created() {
    this.getAllActionTypesHttpMethods();
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  methods: {
    getAllActionTypesHttpMethods() {
      var $this = this;
      store
        .dispatch("GET_ACTION_TYPES_AND_HTTPMETHODS_FOR_ACTION")
        .then(response => {
          $this.actionTypes = response.data.actionTypesList;
          $this.httpMethods = response.data.httpMethodsList;
        })
        .catch(error => {});
    },
    save() {
      this.$validator.validateAll("action_form").then(valid => {
        if (valid) {
          this.$emit("save", this.model);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/colors";
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  & > div {
    // display: flex;
    // flex: 1;
    // align-items: center;
    justify-content: center;
  }

  .submit {
    color: red;
    grid-column: 1/3;
  }
}
.white {
  color: white;
}
label {
  input {
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
  }
}

div.input-checkbox {
  color: black;
  display: -webkit-inline-flex;
  width: 150px;
  margin: 0px 25px;
  margin-top: 5px;
}

.fieldset {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  display: -webkit-inline-flex;
  font-size: 0.9em;
}
.logs-for-action {
  display: grid;
  grid-template-rows: auto auto;
  grid-column: 1 / 2;
  div {
    box-sizing: border-box;
    max-width: 25%;
    label {
      white-space: nowrap;
    }
  }
}
fieldset {
  border: 1px solid $primaryColor;
}
legend {
  font-size: 0.9em;
}
</style>
